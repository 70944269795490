<template>
  <div class="update-comment-modal">
    <modal name="update-comment-modal"
           :click-to-close="false"
           width="700px"
           height="auto"
           class="select-group-modal modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale"/>
      <md-card class="modal-header-custom">
        <template>
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal" >Chỉnh sửa bình luận</span>
            <span class="material-icons float-right cursor-pointer" @click="$modal.hide('update-comment-modal')">close</span>
          </md-dialog-title>
        </template>
      </md-card>

      <md-card-content>
        <md-list class="nav p-0px">
          <template>
            <div class="comment-wrapper">
              <md-textarea class="comment" v-model="html_content" @click="hiddenDialogEmoji"></md-textarea>
              <img class="img-emoji" src="/img/post/footer/smile.png" @click="toogleDialogEmoji">
              <VEmojiPicker class="p-absolute emoji" v-if="showDialogEmoji" @select="onSelectEmoji" v-click-outside="hiddenDialogEmoji"/>
            </div>
          </template>
        </md-list>
      </md-card-content>
        <md-dialog-actions class="p-15px w_100">
          <button type="submit" class="btn-active md-button w_100" :class="{'disabled': (html_content === '')}"
                  @click="updatePost()">Cập nhật
          </button>
          <button class="btn-stroke md-button" @click="$modal.hide('update-comment-modal')">Huỷ</button>
        </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import PostService from "../../../store/services/PostService";
import {VEmojiPicker} from 'v-emoji-picker';

export default {
  name: 'update-comment-modal',
  components: {VEmojiPicker},
  mounted() {
  },
  destroyed() {
  },
  created() {
  },

  data() {
    return {
      commentData: {},
      show: false,
      showDialogEmoji: false,
      html_content: "",
      postParentId: null
    }
  },
  methods: {
    beforeOpened(event) {
      this.commentData = event.params.commentData;
      this.html_content = this.commentData.content;
      this.postParentId = event.params.postParentId;
      // this.$store.commit("updateModalIsOpen", true);
    },

    beforeClosed(event){
      // this.$store.commit("updateModalIsOpen", false);
    },

    updatePost() {
      this.show = true;
      const formData = new FormData();

      formData.append("html_content", this.html_content);

      PostService.updatePost(this.commentData.id, formData).then(res => {
        this.show = false;
        if (res.status) {
          this.commentData.content = this.html_content;
          let commentUpdated = {
            commentData: this.commentData,
            postParentId: this.postParentId
          };
          this.$emit('update-comment', commentUpdated);
          this.$modal.hide('update-comment-modal')
        }
      });
    },

    toogleDialogEmoji() {
      this.showDialogEmoji = !this.showDialogEmoji;
    },

    hiddenDialogEmoji() {
      this.showDialogEmoji = false;
    },

    onSelectEmoji(emoji) {
      this.html_content += emoji.data;
    },
  },
}

</script>

<style lang="scss">
.comment-wrapper {
  textarea {
    width: 100%;
    height: 150px;
    resize: none;
    padding: 10px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
  }
  .img-emoji {
    bottom: 15px !important;
    right: 10px !important;
  }
}
</style>
