<template>
  <div class="group-list-by-type-container background_neutral_white">
    <div class="content-main background_neutral_white">
      <div class="title-main mb-15px">
        <div class="heading-700"></div>
        <div class="d-flex" v-if="$route.params.group_type == 'joined'">
          <div>
            <v-select :options="dropdownOptions" :clearable="false"
                      v-model="groupFilter">
            </v-select>
          </div>
          <div class="search-file-container ml-5px">
            <img class="search-icon" src="/img/search-icon.svg">
            <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group"
                   v-model="groupSearch" @keyup="getGroups(true)">
          </div>
        </div>
      </div>
      <vue-loadmore
          :on-loadmore="onLoadForGroup"
          :finished="groupParams.finished"
          :loading-text="'Đang tải'"
          :finished-text="''">
        <table>
          <tr>
            <th class="text-400-semibold">Tên nhóm</th>
            <th class="text-400-semibold group-type">Loại nhóm</th>
            <th class="text-400-semibold number-member">Số thành viên</th>
            <td class="text-400-semibold action">Thao tác</td>
          </tr>
          <tr v-for="(group, index) in groups" :key="group.id">
            <td class="gs-mem-avt">
              <router-link :to="'/group/' + group.id">
                <md-list-item>
<!--                  <img class="group-avatar cursor-pointer hover-link"-->
<!--                       :src="group.avatar_url ? group.avatar_url : '/img/group/group_image_low.jpg'" alt="">-->
                  <div class="avt-gs">
                    <image-template :img-type="'group'" :class="'group-avatar'" :width="'40px'" :height="'40px'" :size="'small'" :image="group.avatar_url" :thumbnail="group.thumbnail"/>
                  </div>
                  <div class="w_100 ml-15px text-400-medium neutral_1 cursor-pointer hover-link">{{ group.name }}</div>
                </md-list-item>
              </router-link>
            </td>
            <td class="text-400-medium group-type">{{ group.typeName }}</td>
            <td class="text-400-medium number-member">{{ group.member_count }}</td>
            <td class="action">
              <div v-if="![0, 3, 4].includes(group.type)">
                <img class="cursor-pointer mr-5px" :src="group.is_pin == 0 ? hasNotPinIcon : pinnedIcon" alt="" @click="handlePinGroup(group)">
                <md-icon class="cursor-pointer">notifications</md-icon>
                <img class="ml-5px cursor-pointer" src="/img/group/ic_logout.png" alt="" @click="showConfirmModal(group.name, group.id, index)">
              </div>
              <div v-else>
                <div @click="toggleNotification(group)">
                  <md-icon class="cursor-pointer" :class="{active: group.enable_notification === 'ON'}">notifications</md-icon>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </vue-loadmore>

    </div>
  </div>
</template>

<script>
import GroupService from "@/store/services/GroupService";
import {GROUP_TYPE} from "../../../../const/const";
import {helpers} from "../../../../helper/helpers";
import {jsonStringify} from "jsona/lib/cache";
import ImageTemplate from "../../../../components/ImageTemplate";

export default {
  name: "group-list",
  components: {
    ImageTemplate
  },
  data() {
    return {
      groups: [],
      pinnedIcon: '/img/group/pinned_icon.svg',
      hasNotPinIcon: '/img/group/ic_unpin.svg',
      groupFilter: this.$route.query.isAdmin ? {code: "ADMIN", label: "Admin",} : {code: "ALL", label: "Tất cả",},
      groupSearch: "",
      isLoad: false,
      groupParams: {
        page: 1,
        limit: 20,
        lastPage: 1,
        finished: false,
      },
      dropdownOptions: [
        {
          code: "ALL",
          label: "Tất cả",
        },
        {
          code: "ADMIN",
          label: "Admin",
        },
        {
          code: "MEMBER",
          label: "Thành viên"
        },
        {
          code: "PINNED",
          label: "Đã ghim"
        },
        {
          code: "NOT_PIN",
          label: "Không ghim"
        }],
    }
  },
  mounted() {
    this.getGroups();
  },
  watch: {
    'groupFilter': function (newVal, oldVal) {
      this.getGroups(true);
    },
    '$route.params.group_type': function (newVal, oldVal) {
      this.refreshParamFilter();
      this.getGroups(true);
    }
  },
  methods: {
    getGroups(isSearch = false) {
      this.isLoad = true;
      if (this.timer1) {
        clearTimeout(this.timer1);
        this.timer1 = null;
      }
      this.timer1 = setTimeout(() => {
        if (isSearch) {
          this.groupParams.page = 1;
          this.groups = [];
        }
        let params = {
          page: this.groupParams.page,
          per_page: this.groupParams.limit,
        }
        switch (this.$route.params.group_type) {
          case 'company':
            params.types = jsonStringify([GROUP_TYPE.COMPANY_WALL_GROUP, GROUP_TYPE.BRANCH_GROUP]);
            break;
          case 'work':
            params.types = jsonStringify([GROUP_TYPE.WORK_GROUP]);
            break;
          case 'joined':
            params.keyword = this.groupSearch;
            switch (this.groupFilter.code) {
              case 'ADMIN':
                params.is_admin = 1;
                break;
              case 'MEMBER':
                params.is_amdin = 0;
                break
              case 'PINNED':
                params.is_pin = 1;
                break;
              case 'NOT_PIN':
                params.is_pin = 0;
                break;
            }
        }
        GroupService.getGroups(params).then(res => {
          if (res && res.data.length !== 0) {
            this.isLoad = false;
            for (let i = 0; i < res.data.length; i++) {
              let group = res.data[i];
              let typeNameAndColor = helpers.getGroupTypeNameAndColor(res.data[i].type);
              group.typeName = typeNameAndColor.typeName;
              this.groups.push(group);
            }
            this.groupParams.lastPage = res.meta.last_page;
          }
        });
      }, 800);
    },
    onRefreshForGroup(done) {
      this.groups = [];
      this.groupParams.page = 1;
      this.groupParams.finished = false;
      this.getGroups();
      done();
    },
    onLoadForGroup(done) {
      if (this.groupParams.page < this.groupParams.lastPage) {
        this.groupParams.page++
        this.getGroups();
      } else {
        this.groupParams.finished = true;
      }
      done();
    },
    handlePinGroup(groupItem) {
      let changePin = groupItem.is_pin === 0 ? 1 : 0;
      groupItem.is_pin = changePin;
      GroupService.pinGroup({groupId: groupItem.id, is_pin: changePin}).then(res => {
        this.$store.dispatch("getGroups");
        if (!changePin) {
          this.$store.dispatch("alerts/success", 'Bạn bỏ ghim nhóm ' + groupItem.name + ' thành công.');
        } else {
          this.$store.dispatch("alerts/success", 'Bạn ghim nhóm ' + groupItem.name + ' thành công.');
        }
      });
    },
    getGroupLink(id) {
      return '/group/' + id;
    },
    showConfirmModal(groupName, groupId, index) {
      this.$modal.show('dialog', {
        title: 'Rời khỏi?',
        text: 'Bạn có muốn rời khỏi nhóm ' + groupName + ' không?',
        buttons: [
          {
            title: 'Hủy',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Rời khỏi nhóm',
            handler: () => {
              this.outGroup(groupId, index);
            }
          }
        ]
      })
    },
    redirectToGroupMembers() {
      this.$router.push("/group/" + this.groupItem.id + "/members");
    },
    refreshParamFilter() {
      this.groupFilter = this.$route.query.isAdmin ? "ADMIN" : "ALL";
      this.groupSearch = "";
      this.groupParams = {
        page: 1,
        limit: 20,
        lastPage: 1,
        finished: false,
      };
    },
    outGroup(groupId, index) {
      GroupService.outGroup({groupId: groupId}).then(res => {
        if (res && res.status) {
          this.groups.splice(index, 1);
          this.$modal.hide('dialog');
        }
      });
    },
    toggleNotification(group) {
      let newStage = group.enable_notification === 'ON' ? 'OFF' : 'ON';
      GroupService.toggleNotification(group.id, {stage: newStage}).then(res => {;
        if (res.status) {
          group.enable_notification = newStage;
        }
      });
    }
  }
};

</script>


<style lang="scss">
.group-list-by-type-container {
  padding: 20px;

  .vuejs-loadmore-finished-text {
    display: none;
  }

  .vuejs-refresh-head {
    display: none;
  }

  table {
    font-family: arial, sans-serif;
    width: 100%;

    .group-avatar {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      object-fit: cover;
    }
    .md-ripple {
      padding: 0 !important;
    }

    td, th {
      text-align: left;
      border-bottom: 1px solid #F0F0F0;
    }

    th {
      padding: 10px 0px;
    }

    td {
      padding: 6px 0px;
    }

    .action {
      width: 200px;
      text-align: center;
      img {
        height: 24px;
        padding: 2px;
      }
      .md-icon.active {
        color: #ffa940;
      }
    }
    .group-type {
      width: 110px;
      text-align: right;
    }
    .number-member {
      width: 130px;
      text-align: right;
    }

    .gs-mem-avt{
      .md-button-clean{
        white-space: break-spaces !important;
      }
      .avt-gs {
        width: 50px;
        height: 50px;
      }
    }
  }

  .title-main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .md-list-item {
      display: flex;
      align-items: center;
    }

    .search-file-container {
      width: 185px;
    }
  }

  .v-select.vs--single.vs--searchable {
    width: 135px;
  }

  .vs__selected {
    font-size: 15px;
  }

  .vs__open-indicator {
    cursor: pointer;
  }
}
</style>