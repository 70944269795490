<template>
  <div>
    <div class="message-file cursor-pointer">
      <div class="message-action">
        <div class="file file-item full-radius" v-for="file in currentMess.files"
             :key="file.id">
          <div class="fileData" @click="dowloadFile(file.url)">
            <div class="file-thumb">
              <span class="material-symbols-outlined">description</span>
            </div>
            <div class="file-content">
              <div class="heading-400 filename">{{ file.name }}</div>
              <div class="text-300-default">
                {{ file.extension + ' - ' + bytesToSize(file.file_size) }}
              </div>
            </div>
            <div class="file-open">
              <span class="open">Tải</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="emojis" v-if="displayEmoji"
         @click="$modal.show('reaction-message-detail-modal', {message_id : currentMess.id})">
      <div v-for="(emoji_icon, icon) in currentMess.reaction_stats" v-if="emoji_icon > 0"
           :key="icon" class="emoji-wrapper">
        <span class="mr-2px text-300-medium neutral_3">{{ emoji_icon }}</span>
        <div :class="'reaction-' + icon" class="emoji_icon"></div>
      </div>
    </div>
    <div class="content-display-timestamp mt-5px mr-10px"
         v-if="!isMessageInBlock">
      <span>{{ messageTime }}</span>
    </div>
  </div>
</template>

<script>
  import {helpers} from "../../../../../helper/helpers";

  export default {
    name: "file-chat-content",
    components: {},
    props: {
      displayName: {
        type: String,
        default: "",
      },
      currentMess: {
        type: Object,
        default: () => {
          return {};
        }
      },
      nextMess: {
        type: Object,
        default: null
      }
    },
    computed: {
      displayEmoji() {
        return helpers.sumEmoji(this.currentMess.reaction_stats) > 0
      },
      messageTime() {
        return helpers.getMessageTime(this.currentMess)
      },
      isMessageInBlock() {
        return helpers.checkMessageInBlock(this.currentMess, this.nextMess)
      },
    },
    methods: {
      dowloadFile(item) {
        window.open(item);
      },
      bytesToSize(file_size) {
        return helpers.bytesToSize(file_size)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .message-file {
    .file-content {
      width: 100%;
    }
  }
</style>