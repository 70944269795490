<template>
  <div class="view-file__container">
    <iframe :src="'https://docs.google.com/gview?url=' + link + '&embedded=true'"></iframe>
  </div>
</template>

<script>
export default {
  name: "ViewFile",
  data() {
    return {
      link: this.$route.query.link,
      extension: this.$route.query.extension,
    }
  },
}
</script>

<style lang="scss">
.view-file__container {
  width: 100%;
  height: 100vh;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>