import CategoryService from "../services/CategoryService";

export default {
    state: {
        category: {},
        subCategory: {},
        categories: [],
    },

    getters: {
        getCategory(state) {
            return state.category;
        },
        getCategories(state) {
            return state.categories;
        },
    },

    mutations: {
        updateCategory(state, payload) {
            state.category = payload;
        },
        updateSubCategory(state, payload) {
            state.subCategory = payload;
        },
        updateCategories(state, payload) {
            state.categories = payload;
        },
    },

    actions: {
        getCategories({commit, dispatch}, payload) {
            return CategoryService.getCategories().then(res => {
                if (res) {
                    commit("updateCategories", res.data.categories);
                }
            });
        },
    }
};
