<template>
  <div class="group-admin-info-wrapper">
    <div class="user-info-wrapper">
      <div class="user-info-container">
        <div class="info-image-wrapper cursor-pointer" @click="goToProfile(userInfo.id)">
          <md-avatar>
            <image-template :size="'small'" :image="userInfo.avatar_url" :thumbnail="userInfo.thumbnail"/>
          </md-avatar>
        </div>
        <div class="info-text ml-10px">
          <div class="heading-500 mb-10px cursor-pointer hover-link" @click="goToProfile(userInfo.id)">{{ userInfo.fullname }}</div>
          <div class="detail-info" v-if="userInfo.parent_department">
            <span class="material-icons">schema</span>
            <span class="ml-5px text-2-line">
            <span>{{ userInfo.parent_department.name }}</span>
            <span class="ml-5px" v-if="userInfo.department">></span>
            <span class="ml-5px" v-if="userInfo.department">{{ userInfo.department.name }}</span>
          </span>
          </div>
          <div v-else>
            <div class="detail-info" v-if="userInfo.department">
              <span class="material-icons">schema</span>
              <span style="margin-left: 5px">{{ userInfo.department.name }}</span>
            </div>
          </div>
          <div class="detail-info" v-if="userInfo.position">
            <span class="material-icons">account_box</span>
            <span style="margin-left: 5px">{{ userInfo.position.name }}</span>
          </div>
          <div class="detail-info">
            <span class="material-icons">email</span>
            <span style="margin-left: 5px">{{ userInfo.email }}</span>
          </div>
          <div class="sendMessage mt-10px"
               v-if="router_name !== 'messenger' || (type === 'GROUP' && router_name === 'messenger')">
            <md-button class="neutral_1 text-400-medium messageSend" @click="goToMessager(userInfo)">
              <md-icon class="mr-5px">chat</md-icon>
              Nhắn tin
            </md-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {GROUP_CHAT_TYPE} from "../../../../const/const";
import ChatService from "../../../../store/services/ChatService";
import moment from "moment";
import ImageTemplate from "../../../../components/ImageTemplate";

export default {
  name: "GroupAdminInfo",
  components: {
    ImageTemplate
  },
  data() {
    return {
      type: this.$store.state.chat.chatGroupSelected.type,
      router_name: this.$route.name,
    }
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {
      },
    },
  },
  methods: {
    goToProfile(userId){
      window.open('/profile/' + userId, '_blank');
    },
    async goToMessager(member) {

      //================CHECK-EXITS-GROUP-CHAT===============//
      let isChatExits = await this.checkGroupExits(member.id);

      if (isChatExits) {
        //================CREATE-GROUP-CHAT===============//
        const formData = new FormData();

        //ADD VALUE
        formData.append("name", member.fullname);
        formData.append("model_type", GROUP_CHAT_TYPE.USER);
        formData.append("model_ids[]", member.id);

        // POST CHAT IS GROUP ALREADY EXIST
        await ChatService.createGroupChat(formData).then(res => {
          if (res.status) {
            // UPDATE STATE & GO TO MESSAGER
            delete res.data.isCreateChat;
            this.$store.commit('chat/updateChatGroups', res.data);
            this.$store.commit('chat/updateChatGroupSelected', res.data);
            this.$store.dispatch("chat/getChatGroups");
            this.$router.push("/messenger/" + res.data.id);
          } else {
            this.$store.dispatch("alerts/error", 'Bạn tạo cuộc trò chuyện không thành công. Nguyên nhân: ' + res.message);
          }
        });
      } else {
        const selectedChatGroup = {
          avatar: member.avatar_url,
          created_at: moment().format(),
          name: member.fullname,
          last_message: {},
          updated_at: moment().format(),
          user_id: member.id,
          isCreateChat: true,
          type: GROUP_CHAT_TYPE.USER
        };

        // UPDATE STATE & GO TO MESSAGER
        this.$store.commit('chat/updateChatGroupSelected', selectedChatGroup);
        this.$router.push("/messenger/new" + moment());
      }

    },
    async checkGroupExits(user_id) {
      let status = false;

      await ChatService.checkChatExits({user_id: user_id}).then(res => {
        if (res.status) {
          status = res.data.status;
        }
      });

      return status;
    },
  }
}
</script>

<style lang="scss">
.group-admin-info-wrapper {
  width: 100%;
  .user-info-wrapper {
    width: 100%!important;
  }
  .sendMessage {
    float: initial;
  }
}
</style>