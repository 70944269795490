<template>
  <div>
    <modal name="create-poll-chat-modal"
           :click-to-close="false"
           :width="750"
           height="auto"
           class="select-group-modal modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale"/>
      <div>
        <md-card class="modal-header-custom">
          <template>
            <md-dialog-title>
              <div class="poll-header">
                <span class="neutral_1 heading-500 text-header-modal">Tạo bình chọn</span>
              <span class="material-icons cursor-pointer"
                    @click="$modal.hide('create-poll-chat-modal')">close</span>
                
              </div>
            </md-dialog-title>
          </template>
        </md-card>
        <md-card-content class="custom-scroll-bar-2">
          <template>
            <div>
              <div class="poll-container">
                <div class="md-gutter">
                  <label class="required heading-400 neutral_2">Câu hỏi</label>
                  <input type="text" placeholder="Nhập câu hỏi" class="form-control input-custom mt-10px" name="name"
                         v-model="pollForm.question">
                </div>
                <div class="md-gutter mt-20px">
                  <label class="required heading-400 neutral_2">Thêm lựa chọn</label>
                  <div class="poll-option-wrapper">
                    <div class="poll-option mt-10px" v-for="(item, index) in pollForm.answers"
                         :key="'poll-option' + index">
                      <input v-model="pollForm.answers[index]" type="text" :placeholder="'Lựa chọn ' + (index + 1)"
                             class="form-control input-custom" required name="name">
                      <img class="remove-poll-option-btn cursor-pointer ml-5px" src="/img/modal/close.png"
                           v-if="pollForm.answers.length > 2" @click="deletePollOption(index)">
                    </div>
                  </div>
                </div>
                <button class="mt-10px add-option-btn text-400-medium" :disabled="pollForm.answers.length > 9"
                        @click="pollForm.answers.push('')">
                  <md-icon class="mr-5px">add</md-icon>
                  Thêm lựa chọn
                </button>
                <div class="mt-20px heading-400 neutral_2">Cài đặt</div>
                <div class="mt-10px">
                  <md-checkbox v-model="pollForm.multi_choice" class="md-success">Cho phép chọn nhiều câu trả lời
                  </md-checkbox>
                </div>
                <div>
                  <md-checkbox v-model="pollForm.is_add_option" class="md-success">Cho phép chọn thêm trả lời
                  </md-checkbox>
                </div>
                <div>
                  <md-checkbox @change="pollForm.expired_time = null" v-model="pollForm.time" class="md-success">Giới
                    hạn thời gian cho cuộc bình chọn
                  </md-checkbox>
                </div>
                <div class="poll-expired-time mt-20px pt-20px" v-show="pollForm.time">
                  <div class="heading-400 neutral_2">Kết thúc lúc</div>
                  <datetime type="datetime" v-model="pollForm.expired_time" format="hh:mm:ss dd-MM-yyyy"></datetime>
                </div>
              </div>
              <div class="custom-scroll-bar-2" style="max-height: 500px; overflow-y: auto;">
                <poll-content v-if="pollData != null" :poll-data="pollData" :is-create-post-modal="true"
                              @update-poll="changeToPollPopup" @remove-poll="removePoll"></poll-content>
              </div>
            </div>
          </template>
        </md-card-content>
        <md-dialog-actions class="p-15px w_100">
          <button type="submit" class="create-post-btn btn-active md-button w_100 text-400-medium" @click="createPoll(pollForm)">Tạo bình chọn
          </button>
        </md-dialog-actions>
      </div>
      <tooltip/>
    </modal>
  </div>
</template>

<script>
import {
  BRANCH_GROUP_TYPE,
  COMPANY_WALL_GROUP_TYPE,
  CREATE_POST_LEVEL,
  MODEL_TYPE,
  POST_TYPE,
  WORK_GROUP_TYPE
} from "../../../const/const";

import $ from "jquery";
import GroupService from "@/store/services/GroupService";
import PostService from "../../../store/services/PostService";
import {helpers} from "../../../helper/helpers";
import {Datetime} from 'vue-datetime';
import PollContent from "../Post/Content/PollContent";
import SettingService from "../../../store/services/SettingService";
import moment from 'moment';

export default {
  name: 'create-poll-chat-modal',
  components: {
    datetime: Datetime,
    PollContent,
  },
  data() {
    return {
      workGroups: [],
      organizationGroups: [],
      joinedGroups: [],
      searchInput: "",
      groupImageDefault: '/img/group/group_image_low.jpg',
      selectedGroup: '',
      user: this.$store.state.auth.user,
      userPosition: this.$store.state.auth.userPosition,
      html_content: '',
      POST_TYPE: POST_TYPE,
      medias: [],
      selectTypeUpload: null,
      files: [],
      file_ids: [],
      file_urls: [],
      show: false,
      showDialogEmoji: false,
      router_name: this.$route.name,
      editorConfig: {
        toolbar: [
          ['Source'],
          ['Styles', 'Format', 'Font', 'FontSize'],
          ['Bold', 'Italic'],
          ['Undo', 'Redo'],
          ['Link'],
          ['numberedList', 'bulletedList']
          // [ 'About' ]
        ]
      },
      isPoll: false,
      pollForm: {
        question: [],
        answers: []
      },
      pollData: null,
      isUpdatePoll: false,
      loadingUploadFile: false,
      isTagUser: false,
      tagUserItems: [],
      isEditingMedia: false,
      linkMetadata: null,
      canShowLinkPreview: false,
      isLoadingLinkPreview: false,
      sharedPostData: null,
      isSelectingBackground: false,
      postBackground: null,
      canShowBackgroundList: false,
      postBackgroundImageList: [],
      disableBackgroundBtn: false,
      hasAllowedBackground: false,
    }
  },
  mounted() {
    window.addEventListener("storage", this.storageListener);
    $('.poll-btn').hover(function (event) {
      $(".vue-popover").show();
    });
  },
  destroyed() {
    window.removeEventListener("storage", this.storageListener);
  },
  created() {

  },
  computed: {
    disablePollCreateBtn() {
      let answerSet = new Set();
      for (let i = 0; i < this.pollForm.answers.length; i++) {
        if (this.pollForm.answers[i].trim() == '') {
          return true;
        }
        answerSet.add(this.pollForm.answers[i].trim().toLowerCase());
      }

      if (answerSet.size != this.pollForm.answers.length) {
        return true;
      }

      if (this.pollForm.time) {
        if (this.pollForm.expired_time == null || this.pollForm.expired_time == '') {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    '$route'(to, from) {
      this.router_name = to.name
    },
    '$route.params.group_id'(to, from) {
      this.getGroup();
    },
    html_content(newV) {
      if (this.file_ids.length !== 0) {
        return;
      }
      if (newV.length == 0) {
        return;
      }
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

      let urls = newV.match(urlRegex);

      if (urls && urls.length !== 0) {
        this.canShowLinkPreview = true;
        let url = urls[urls.length - 1].replace('&nbsp;&nbsp', '').replace('&nbsp', '');
        if (this.linkMetadata && url === this.linkMetadata.url) {
          return;
        }
        this.isLoadingLinkPreview = true;
        PostService.getLinkMetadata({url: url}).then(res => {
          this.isLoadingLinkPreview = false;
          this.linkMetadata = res.data;
          this.linkMetadata.url = url;
        });
      }
      if (this.postBackground && newV.length > 190) {
        this.hasAllowedBackground = false;
        this.canShowBackgroundList = false;
        this.disableBackgroundBtn = true;
      } else if (this.postBackground && newV.length < 190) {
        this.disableBackgroundBtn = false;
        this.hasAllowedBackground = true;
      }
    }
  },
  methods: {
    getGroups() {
      let param = {
        per_page: 25
      };
      if (this.searchInput) {
        param.keyword = this.searchInput;
      }
      GroupService.getGroups(param).then(res => {
        if (res && res.data.length !== 0) {
          let groups = res.data;
          let workGroups = [];
          let organizationGroups = [];
          let joinedGroups = [];
          for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            let typeNameAndColor = helpers.getGroupTypeNameAndColor(group.type);
            group.typeName = typeNameAndColor.typeName;
            group.classColor = typeNameAndColor.classColor;
            switch (group.type) {
              case WORK_GROUP_TYPE:
                workGroups.push(group);
                break;
              case COMPANY_WALL_GROUP_TYPE:
              case BRANCH_GROUP_TYPE:
                organizationGroups.push(group);
                break;
              default:
                joinedGroups.push(group);
            }
          }
          this.joinedGroups = joinedGroups;
          this.workGroups = workGroups;
          this.organizationGroups = organizationGroups;
        }
      });
    },

    beforeOpened(event) {
      this.pollData = null;
      this.pollForm = {
        answers: ['', ''],
        question: '',
        multi_choice: true,
        is_add_option: true,
        time: false,
        expired_time: moment().format('hh:mm:ss dd-MM-yyyy')
      };

    },

    beforeClosed() {
      localStorage.setItem("create_post_modal_open", false);
      this.$store.commit("updateModalIsOpen", false);
    },

    createPoll(){
      let pollData = this.pollForm;
      if (this.pollForm != null) {
        if (this.pollForm.expired_time != '') {
          let date = new Date(this.pollForm.expired_time);
          this.pollForm.expired_time = moment(date).format('yyyy-MM-DD HH:mm:ss');
        }

        pollData = {
          "question": this.pollForm.question,
          "single_choice": !this.pollForm.multi_choice,
          "is_add_option": this.pollForm.is_add_option,
          "expired_time": this.pollForm.expired_time == '' ? null : this.pollForm.expired_time,
          "answers": this.pollForm.answers,
          "data": this.pollForm.answers.map((item) => {
            return {
              "answer": item,
              "vote_ids": []
            }
          })
        };
      }
      this.$emit('create-poll', pollData);
    },

    removePoll() {
      this.pollForm = null;
      this.pollData = null;
    },

    changeToPollPopup() {
      //  this.isPoll = false;
      //   let options = [];
      //   for (let i = 0; i < this.pollForm.answers.length; i++) {
      //     let option = {
      //       "answer": this.pollForm.answers[i],
      //       "vote_ids": []
      //     };
      //     options.push(option);
      //   }
      //   this.pollData = {
      //     "question": this.pollForm.question,
      //     "single_choice": !this.pollForm.multi_choice,
      //     "is_add_option": this.pollForm.is_add_option,
      //     "expired_time": this.pollForm.expired_time == '' ? null : this.pollForm.expired_time,
      //     "data": options
      //   };
      //   console.log(this.pollForm);
    },
  },
}

</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
}

.group-section {
  padding: 0 0 15px;
}

.select-group-modal .group-info-wrapper {
  height: auto;
  padding: 0 10px;
}

.select-group-modal .bookmark {
  height: 12px !important;
}

.image-content {
  position: relative;
}

.image-content .close-image {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0;
  font-size: 25px;
  color: #ffffff;
  z-index: 2;
  cursor: pointer;
}

.image-content:hover .close-image {
  opacity: 1;
}

.image-content .close-file {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 25px;
  color: #ffffff;
  z-index: 2;
  cursor: pointer;
}

.cke_bottom {
  display: none !important;
}

.cke_contents {
  height: 300px !important;
}

.select-group-modal .img-emoji {
  position: absolute;
  width: 22px !important;
  cursor: pointer;
  right: 10px;
  bottom: 10px;
}

.select-group-modal .emoji {
  top: 45px;
}

.select-group-modal .md-dialog-actions .create-post-btn {
  width: 100% !important;
}

.user-group-info {
  .md-list-item-text * {
    width: auto;
  }
}

.poll-container {
  .poll-option-wrapper {
    max-height: 350px;
    overflow-y: auto;

    .poll-option {
      display: flex;
      align-items: center;

      .remove-poll-option-btn {
        width: 20px;
        height: 20px;
      }
    }
  }

  .add-option-btn {
    cursor: pointer;
    text-align: center;
    background-color: rgb(207 228 255);
    font-weight: bold;
    display: flex;
    border-radius: 20px;
    height: 36px;
    width: 100%;
    border: none;
    align-items: center;
    justify-content: center;

    i {
      margin: initial;
    }
  }

  .md-checkbox {
    margin: initial;
  }

  .poll-finish-btn {
    cursor: pointer;
    height: 40px;
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: rgb(55, 84, 100);
    font-weight: bold;
    color: white;
  }

  .poll-finish-btn:disabled {
    background-color: rgb(200, 202, 207);
  }

  .poll-expired-time {
    border-top: 1px solid #D8DAE5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .vdatetime-input {
      height: 30px;
    }
  }
}

.input-auto-complete {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border: none;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  max-height: 90px;
  overflow-y: auto;
  padding: 8px 12px;
  min-height: 58px;

  .item-select {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 24px;
    margin: 5px;
    background-color: rgb(236, 247, 231);
    border: none;
    border-radius: 6px;
    box-sizing: content-box;
    padding: 4px 8px;
    outline: 0px;
    overflow: hidden;
    color: rgb(48, 169, 96);
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    width: fit-content;

    .close-icon {
      display: flex;
      width: 16px;
      height: 16px;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      border: none;
      border-radius: 50%;
      background: white;
      color: rgba(0, 0, 0, 0.5);
      font-size: 16px;
      margin-left: 8px;
    }
  }
}

.cs-search-container {
  padding: 0 8px;

  .input-container {
    border-radius: 4px !important;
    padding-left: 10px !important;
    background-color: rgba(20, 33, 65, 0.06);
    border: 1px solid #e1e1e1;

    .md-icon {
      margin: auto !important;
    }
  }
}

.tag-user-container {
  .member-option {
    display: flex;
    border-top: 1px solid #efefef;
    cursor: pointer;
    justify-content: space-between;
  }

  .member-option:hover {
    background-color: rgba(20, 33, 65, 0.06);
  }

  .tag-form-button-wrapper {
    width: 100%;
    text-align: center;

    button {
      background-color: rgba(20, 33, 65, 0.06);
      border: none;
      width: 75px;
      height: 35px;
      cursor: pointer;
      border-radius: 8px;
    }
  }
}

.edit-img-btn {
  border: none;
  background: #5b53d9;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  height: 32px;
  left: 12px;
  line-height: 16px;
  min-width: 64px;
  padding: 8px 10px;
  position: absolute;
  top: 12px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  z-index: 6;
}
</style>
