<template>
  <div class="gs-more__container">
    <div class="more-btn-wrapper">
      <md-button
          class="gs-member-more-btn" @click="toggleMoreBtn" v-on-clickaway="onClickMoreBtnAway">
        <md-icon class="cursor-pointer neutral_2 gs-more-btn">more_horiz</md-icon>
      </md-button>
      <div class="more-dropdown" v-show="moreBtnActive">
        <md-list class="p-0px" v-if="tableType === 'MEMBER_LIST'">
          <md-list-item class="cursor-pointer" @click="handleUserRole()">
            <div class="md-list-item-text comment-more-option">{{isAdmin ? 'Hủy tư cách quản trị viên' : 'Đặt làm quản trị viên'}}</div>
          </md-list-item>
          <md-list-item class="cursor-pointer" @click="removeFromGroup()">
            <div class="md-list-item-text comment-more-option">Xóa khỏi nhóm</div>
          </md-list-item>
        </md-list>
        <md-list class="p-0px" v-else>
          <md-list-item class="cursor-pointer" @click="$emit('add-member')">
            <div class="md-list-item-text comment-more-option">Thêm thành viên</div>
          </md-list-item>
          <md-list-item class="cursor-pointer" @click="removeFromGroup()">
            <div class="md-list-item-text comment-more-option">Vô hiệu hóa nhóm</div>
          </md-list-item>
        </md-list>
      </div>
    </div>
  </div>
</template>
<script>

import {mixin as clickaway} from "vue-clickaway";

export default  {
  mixins: [ clickaway ],
  mounted() {
  },

  props: {
    isAdmin: {
      type: Boolean,
    },
    tableType: {
      type: String,
      default: 'MEMBER_LIST'
    }
  },

  data() {
    return {
      moreBtnActive: false,
    };
  },
  methods: {
    toggleMoreBtn() {
      this.moreBtnActive = !this.moreBtnActive;
    },
    onClickMoreBtnAway() {
      this.moreBtnActive = false;
    },
    handleUserRole() {
      this.$emit("handle-user-role");
    },
    removeFromGroup() {
      this.$emit("remove-from-group");
    }
  }

};
</script>
<style lang="scss">
.gs-more__container {
  .more-btn-wrapper {
    position: relative;
  }
  .gs-member-more-btn {
    width: 30px;
    height: 30px;
  }
  .more-dropdown {
    z-index: 6;
  }
}
</style>
