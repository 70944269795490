<template>
  <div>
    <template v-if="link.is_sub_category && ([1,2,119,141,845,921,920,869].includes(user.id)||
     (([1,2,119,141,845,921,920,869].includes(user.id)) && (link.category['subcategories'] && link.category['subcategories'].length > 0)))">
      <md-icon class="cursor-pointer m-0px category-next"
               :class="'navigate_next_'+link.category.id" :data-id="link.category.id"
               style="margin: 3px 0px 0px 0px !important;">navigate_next
      </md-icon>
      <md-icon class="cursor-pointer m-0px category-next display-none"
               :class="'keyboard_arrow_down_'+link.category.id" :data-id="link.category.id"
               style="margin: 3px 0px 0px 0px !important;">keyboard_arrow_down
      </md-icon>
    </template>
    <component
        :is="baseComponent"
        :to="link.path ? link.path : '/'"
        tag="li"
    >
      <slot
          name="title"
          v-if="children.length === 0 && !$slots.default && link.path"
      >
        <component
            :to="link.path"
            :is="elementType(link, false)"
            :class="{ active: link.active ,is_group: link.is_group, is_category: link.is_category}"
            class="nav-link"
            :target="link.target"
            @click.native="clickLink(link)"
        >
          <md-icon :style="{'background-color': link.is_category ? link.color : '' }"
                   v-if="link.icon">{{ link.icon }}
          </md-icon>
          <md-icon v-if="link.image">
            <template v-if="!link.is_group">
              <md-image style="height: auto; width: auto; min-width: auto;">
                <img :src="link.image">
              </md-image>
            </template>
            <template v-else>
              <md-image style="height: 38px; width: 38px">
                <img :src="link.image" style="height: 100%; width: 100%; border-radius: 4px; object-fit: cover"
                >
              </md-image>
            </template>

          </md-icon>
          <p class="text-400-semibold" :class="{secondary_g400: link.add_group}">
            {{ link.name }}
          </p>
        </component>
      </slot>
    </component>
    <template v-if="link.is_sub_category">
      <div style="margin-left: 15px" class="display-none" :class="'category_'+link.category.id"
      >
        <div v-for="(item) in link.category['subcategories']" :key="item.id">
          <template v-if="([1,2,119,141,845,921,920,869].includes(user.id))">
            <md-icon class="cursor-pointer m-0px sub-category-next"
                     :class="'sub_navigate_next_'+item['id']" :data-id="item['id']"
                     style="margin: 3px 0px 0px 0px !important;">navigate_next
            </md-icon>
            <md-icon class="cursor-pointer m-0px sub-category-next display-none"
                     :class="'sub_keyboard_arrow_down_'+item['id']" :data-id="item['id']"
                     style="margin: 3px 0px 0px 0px !important;">keyboard_arrow_down
            </md-icon>
          </template>

          <li :to="'/category/'+item.id" class="nav-item "
              :class="{'active': $route.params.category_id == item.id}">
            <router-link @click.native="clickLink(item, true)" :to="'/category/'+item.id"
                         class="nav-link is_category sub_category"
            >
              <md-icon :style="{'background-color': item.color}"></md-icon>
              <p class="text-400-semibold"> {{ item.title }} </p>
            </router-link>
          </li>

          <div style="margin-left: 45px" class="display-none" :class="'sub_category_'+item['id']">
            <div v-for="(itemSub) in item['subcategories']" :key="itemSub.id">
              <li :to="'/category/'+itemSub.id" class="nav-item "
                  :class="{'active': $route.params.category_id == itemSub.id}">
                <router-link @click.native="clickLink(itemSub, true)"
                             :to="'/category/'+itemSub.id" class="nav-link is_category sub_category"
                >
                  <p class="text-400-semibold"> {{ itemSub.title }} </p>
                </router-link>
              </li>
            </div>
            <li v-if="[1,2,119,141,845,921,920,869].includes(user.id)">
              <a class="nav-link is_group"
                 @click="$modal.show('create-category-modal', {categoryParent:item})"><i
                  class="md-icon md-icon-font md-theme-default">
                <div class="md-image md-theme-default" style="height: auto; width: auto; min-width: auto;"><img
                    src="/img/sidebar/right/workplace/add.svg"></div>
              </i>
                <p class="text-400-semibold secondary_g400">Tạo hạng mục con</p></a>
            </li>
          </div>
        </div>
        <li v-if="[1,2,119,141,845,921,920,869].includes(user.id)">
          <a class="nav-link is_group"
             @click="$modal.show('create-category-modal', {categoryParent:link.category})"><i
              class="md-icon md-icon-font md-theme-default">
            <div class="md-image md-theme-default" style="height: auto; width: auto; min-width: auto;"><img
                src="/img/sidebar/right/workplace/add.svg"></div>
          </i>
            <p class="text-400-semibold secondary_g400">Tạo hạng mục con</p></a>
        </li>
      </div>
    </template>
  </div>
</template>
<script>
import CreateGroup from "../Modal/CreateGroupModal";
import $ from "jquery"

export default {
  name: "sidebar-item",
  components: {CreateGroup},
  props: {
    menu: {
      type: Boolean,
      default: false
    },
    opened: {
      type: Boolean,
      default: false
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          icon: "",
          image: "",
          path: "",
          children: [],
          user: this.$store.state.auth.user,
        };
      }
    }
  },
  watch: {
    '$route'(to, from) {
      this.router_name = to.name
    },
  },

  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild
    };
  },
  inject: {
    addLink: {default: null},
    removeLink: {default: null},
    autoClose: {
      default: true
    }
  },
  data() {
    return {
      children: [],
      collapsed: !this.opened,
      showDialog: false,
      showCategory: false,
      user: this.$store.state.auth.user,
      router_name: this.$route.name,
    };
  },
  computed: {
    baseComponent() {
      return this.isMenu || this.link.isRoute ? "li" : "router-link";
    },
    linkPrefix() {
      if (this.link.name) {
        let words = this.link.name.split(" ");
        return words.map(word => word.substring(0, 1)).join("");
      }
      return false;
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
  },
  methods: {
    addChild(item) {
      const index = this.$slots.default.indexOf(item.$vnode);
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? "li" : "a";
      } else {
        return "router-link";
      }
    },
    linkAbbreviation(name) {
      const matches = name.match(/\b(\w)/g);
      return matches.join("");
    },
    clickLink(link, isCategory = false) {
      if (isCategory) {
        this.$store.commit("updateCategory", link);
      }

      if (link.is_category && link.category) {
        this.$store.commit("updateCategory", link.category);
      }

      if (link.path == '/new-feeds') {
        window.location.reload();
      }

      if (link.category && link.category.id) {
        this.showHideCategory(link.category.id);
        this.showHideSubCategory(link.category.id);
      }

    },
    collapseMenu() {
      if (this.collapsed) {
        this.$sidebar.addSidebarLink(this);
        this.$sidebar.collapseAllMenus();
      }

      this.collapsed = !this.collapsed;
    },
    collapseSubMenu(link) {
      link.collapsed = !link.collapsed;
    },
    showHideCategory(categoryId) {
      const ele = $(".category_" + categoryId);
      const isNone = ele.hasClass('display-none');
      const arrowNext = $(".navigate_next_" + categoryId);
      const arrowDown = $(".keyboard_arrow_down_" + categoryId);

      if (isNone) {
        ele.removeClass('display-none').addClass('display-block');
        arrowNext.removeClass('display-block').addClass('display-none')
        arrowDown.removeClass('display-none').addClass('display-block')
      } else {
        ele.removeClass('display-block').addClass('display-none');
        arrowNext.removeClass('display-none').addClass('display-block')
        arrowDown.removeClass('display-block').addClass('display-none')
      }
    },

    showHideSubCategory(categoryId) {
      const ele = $(".sub_category_" + categoryId);
      const isNone = ele.hasClass('display-none');
      const arrowNext = $(".sub_navigate_next_" + categoryId);
      const arrowDown = $(".sub_keyboard_arrow_down_" + categoryId);

      if (isNone) {
        ele.removeClass('display-none').addClass('display-block');
        arrowNext.removeClass('display-block').addClass('display-none')
        arrowDown.removeClass('display-none').addClass('display-block')
      } else {
        ele.removeClass('display-block').addClass('display-none');
        arrowNext.removeClass('display-none').addClass('display-block')
        arrowDown.removeClass('display-block').addClass('display-none')
      }
    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }

    if (this.router_name === 'category' || this.router_name === 'sud_category') {
      $(".category-next").click((event) => {
        const categoryId = event.target.getAttribute("data-id");
        this.showHideCategory(categoryId)
        event.stopPropagation();
        event.stopImmediatePropagation();
      });

      $(".sub-category-next").click((event) => {
        const categoryId = event.target.getAttribute("data-id");
        this.showHideSubCategory(categoryId);
        event.stopPropagation();
        event.stopImmediatePropagation();
      });

      const categories = this.$store.state.category.categories;
      const categoryId = this.$route.params.category_id;

      for (var i = 0; i < categories.length; i++) {
        if (categories[i]['subcategories'] && categories[i]['subcategories'].length > 0) {
          var item1 = categories[i]['subcategories'];
          for (var x = 0; x < item1.length; x++) {
            if (item1[x]['subcategories'] && item1[x]['subcategories'].length > 0) {
              var item2 = categories[i]['subcategories'][x]['subcategories'];
              for (var z = 0; z < item2.length; z++) {
                if (item2[z].id && item2[z].id == categoryId) {
                  $(".category_" + item1[x].id).removeClass('display-none').addClass('display-block');
                  $(".navigate_next_" + item1[x].id).removeClass('display-block').addClass('display-none')
                  $(".keyboard_arrow_down_" + item1[x].id).removeClass('display-none').addClass('display-block')
                  break;
                }
                var item3 = categories[i]['subcategories'][x]['subcategories'][z]['subcategories'];
                if (item3) {
                  for (var y = 0; y < item3.length; y++) {
                    if (item3[y].id && item3[y].id == categoryId) {
                      $(".category_" + item1[x].id).removeClass('display-none').addClass('display-block');
                      $(".navigate_next_" + item1[x].id).removeClass('display-block').addClass('display-none')
                      $(".keyboard_arrow_down_" + item1[x].id).removeClass('display-none').addClass('display-block')

                      $(".sub_category_" + item2[z].id).removeClass('display-none').addClass('display-block');
                      $(".sub_navigate_next_" + item2[z].id).removeClass('display-block').addClass('display-none')
                      $(".sub_keyboard_arrow_down_" + item2[z].id).removeClass('display-none').addClass('display-block')
                      break;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  },
  destroyed() {
    this.$sidebar.removeSidebarLink(this);
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  }
};


</script>
<style lang="scss">
.sidebar-menu-item {
  cursor: pointer;
}

.is_category {
  i {
    color: white !important;
  }
}
</style>
