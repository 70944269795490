<template>
  <div class="group-setting-container">
    <vue-element-loading :active="isLoad" is-full-screen/>
    <div class="gs-left-main">
      <div class="md-list-item-content">
        <div @click="redirectToGroupDetail">
          <md-icon class="gs-close-btn cursor-pointer">cancel</md-icon>
        </div>
        <span class="md-list-item-text heading-500">Cài đặt nhóm</span>
      </div>
      <div class="gs-info">
        <div class="md-list-item-content">
          <md-image style="height: 38px; width: 38px">
            <img :src="group.avatar_url ? group.avatar_url : groupImageDefault"
                 style="height: 100%; width: 100%; border-radius: 4px; object-fit: cover">
          </md-image>
          <span class="md-list-item-text heading-500 ml-15px">{{ group.name }}</span>
        </div>
      </div>
      <div class="gs-category text-400-medium cursor-pointer" :class="{'gs-category-active' : tabSetting === 'setting'}"
           @click="changeCategory('setting')">
        Thiết lập nhóm
      </div>
      <div class="gs-category text-400-medium cursor-pointer" :class="{'gs-category-active' : tabSetting === 'request-member'}"
           @click="changeCategory('request-member')">
        Yêu cầu làm thành viên
      </div>
      <div class="gs-category text-400-medium cursor-pointer" :class="{'gs-category-active' : tabSetting === 'members'}"
           @click="changeCategory('members')">
        Quản lý thành viên
      </div>
    </div>
    <div class="gs-right-main">
      <div class="heading-600 gs-header">{{headerName}}</div>
      <div class="gs-content-main" v-if="tabSetting === 'setting'">
        <div class="gs-setting-form__main">
          <div class="gs-group">
            <label class="heading-400 neutral_2 required">Tên nhóm</label>
            <div class="heading-300 secondary_r400" v-show="showError.name">Bạn cần nhập tên nhóm</div>
            <input type="text" placeholder="Tên nhóm" class="form-control input-custom mt-10px" required name="name"
                   v-model="form.name">
          </div>
          <div class="gs-group">
            <label class="heading-400 neutral_2">Mô tả nhóm</label>
            <textarea placeholder="Mô tả nhóm" name="" cols="30" rows="10" class="input-custom form-control mt-10px"
                      v-model="form.description"></textarea>
          </div>
          <div class="gs-group" v-if="typeOption">
            <label class="heading-400 neutral_2">Loại nhóm</label>
            <div class="mt-10px gs-type-group">
              <div class="text-400-medium">{{ typeOption.title }}</div>
              <div class="text-300-default">{{ typeOption.description }}</div>
            </div>
          </div>

          <div class="gs-group" v-if="typeOption && typeOption.id === 0">
            <label class="required heading-400 neutral_2">Chọn quy trình</label>
            <div class="heading-300 secondary_r400" v-show="showError.category">Bạn cần chọn quy trình</div>
            <multiselect v-model="categoryModel" class="mt-10px"
                         label="title" track-by="id"
                         placeholder="Chọn quy trình" open-direction="bottom"
                         :options="categoryOptions" :multiple="false"
                         :selectLabel="'Chọn'"
                         :deselectLabel="'Bỏ chọn'"
                         :selectedLabel="'Đã chọn'"
                         :searchable="true"
                         :internal-search="false"
                         :clear-on-select="false"
                         :close-on-select="true"
                         :show-no-results="false" :hide-selected="false" @search-change="onSearchCategory">
              <template slot="option" slot-scope="{ option }">
                <md-list-item>
                  <div class="md-list-item-text">
                    <span>{{ option.title }}</span>
                    <p>{{ option.description }}</p>
                  </div>
                </md-list-item>
              </template>
              <template slot="afterList">
                <div v-observe-visibility="reachedEndOfListForCategory" v-if="hasNextPageForCategory"/>
              </template>
              <span slot="noResult">Chưa có dữ liệu!</span>
            </multiselect>
          </div>

          <div class="gs-group">
            <label class="heading-400 neutral_2">Quyền tham gia</label>
            <v-select class="mt-10px" :options="joinPolicyOptions" label="title"
                      :reduce="(option) => option.id"
                      v-model="form.join_policy">
              <template slot="option" slot-scope="origin">
                <div class="flex">
                  <div class="col text-overflow-ellipsis">
                    <span class="text-400-medium neutral-1">{{ origin.title }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </div>

          <button class="cursor-pointer gs-btn-active gs-save-btn text-400-medium" @click="updateGroup()">Lưu thay đổi
          </button>
        </div>
      </div>
      <div class="gs-content-main" v-if="tabSetting === 'request-member'">
        <member-table v-if="requestMembers.length !== 0" :member-list="requestMembers"
                      :table-type="'GROUP_SETTING_REQUEST_MEM'" @approve-request="approveRequest" />
        <div v-else class="text-center">
          Không có yêu cầu phê duyệt mới
        </div>

      </div>
      <div class="gs-content-main" v-if="tabSetting === 'members'">
        <div class="d-flex-center d-flex-space-between">
          <div>
            <button class="btn btn-circle" :class="{active: member_tab_active === 1}" @click="changeMemberTab(1)">Tất cả</button>
            <button class="btn btn-circle ml-10px" :class="{active: member_tab_active === 2}" @click="changeMemberTab(2)">Quản trị viên</button>
          </div>
          <div class="title-main">
            <div class="md-list-item">
              <div class="search-file-container" v-show="member_tab_active === 1">
                <img class="search-icon" src="/img/search-icon.svg">
                <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group"
                       v-model="memberData.searchMemberInput" @keyup="getMembers(true)">
              </div>
              <md-button class="md-raised add-member-btn neutral_2 ml-15px" @click="showAddMemberModal">
                <img class="cursor-pointer bookmark pr-15px neutral_4" src="/img/group/add_user.svg">
                Thêm người
              </md-button>
            </div>
          </div>
        </div>

        <div class="gs-member-tbl" v-if="member_tab_active === 1">
          <vue-loadmore
              :on-loadmore="onLoadForMember"
              :finished="memberData.finished"
              :loading-text="loadingText"
              :finished-text="''">
            <member-table :member-list="memberData.memberList"
                          :table-type="'GROUP_SETTING_MEMBERS'" @remove-from-group="removeFromGroup"
                          @handle-user-role="handleUserRole" />
          </vue-loadmore>
        </div>
        <div class="gs-member-tbl" v-if="member_tab_active === 2">
          <member-table :member-list="adminList"
                        :table-type="'GROUP_SETTING_MEMBERS'" @remove-from-group="removeFromGroup"
                        @handle-user-role="handleUserRole" />
        </div>
      </div>
      <add-group-member-modal />
      <notifications></notifications>
    </div>
  </div>
</template>
<script>

import $ from "jquery";
import GroupService from "../../../../store/services/GroupService";
import CategoryService from "../../../../store/services/CategoryService";
import {helpers} from "../../../../helper/helpers";
import {TYPE_OPTIONS} from "../../../../const/const";
import AddGroupMemberModal from "../../../Components/Modal/AddGroupMemberModal";
import Notifications from "../../../../components/NotificationPlugin/Notifications";
import MemberTable from "../MemberTable";

export default {
  components: {AddGroupMemberModal, Notifications, MemberTable},
  data() {
    return {
      form: {
        name: null,
        description: null,
        join_policy: 1,
        category_id: null,
        type: 0,
      },
      groupImageDefault: '/img/group/group_image_low.jpg',
      categoryActive: 1,
      group: {},
      typeOption: {},
      isLoad: false,
      groupId: this.$route.params.group_id,
      tabSetting: this.$route.params.tab,
      joinPolicyOptions: [
        {
          id: 1,
          title: 'Admin duyệt quyền tham gia nhóm',
        },
        {
          id: 2,
          title: 'Duyệt tự động',
        }
      ],
      category_params: {
        page: 1,
        per_page: 10,
        search_text: "",
        parent_id: 1
      },
      category_last_page: 1,
      categoryModel: null,
      categorySet: new Set(),
      categoryOptions: [],
      showError: {
        name: false,
        category: false
      },
      sortType: 'desc',
      listAccepted: [],
      listDecline: [],
      requestMembers: [],
      member_tab_active: 1,
      memberData: {
        searchMemberInput: "",
        memberList: [],
        memberPage: 1,
        finished: false,
        lastPage: 1
      },
      adminList: [],
      loadingText: 'Đang tải',
    };
  },

  watch: {
    '$route.params.group_id'(to, from) {
      this.groupId = this.$route.params.group_id;
      this.refreshData();
      this.getGroup();
      this.getRequestMembers();
      this.getAdminList();
    },
    '$route.params.tab'(newV) {
      this.tabSetting = newV;
    }
  },

  computed: {
    hasNextPageForCategory() {
      return this.category_params.page < this.category_last_page;
    },
    headerName() {
      let headerName;
      switch (this.tabSetting) {
        case 'setting':
          headerName = 'Thiết lập nhóm';
          break;
        case 'request-member':
          headerName = 'Phê duyệt thành viên';
          break;
        case 'members':
          headerName = 'Quản lý thành viên';
          break;
      }
      return headerName;
    }
  },

  methods: {
    changeCategory(type) {
      this.$router.push("/group-setting/" + this.groupId + '/' + type);
    },
    redirectToGroupDetail() {
      this.$router.push("/group/" + this.groupId);
    },
    onSearchCategory(search) {
      this.category_params = {
        page: 1,
        per_page: 10,
      };
      this.category_params.search_text = search;
      this.category_last_page = 1;
      this.categorySet = new Set();
      this.getCategories({keyword: search});
    },
    getGroup() {
      // this.isLoad = true;
      GroupService.getGroup({groupId: this.groupId}).then(res => {
        this.isLoad = false;
        if (res && res.data) {
          this.group = res.data;
          // if (res.data.is_admin) {
          //   this.$router.push("/group/" + this.groupId);
          // }
          this.form.name = res.data.name;
          this.form.description = res.data.description;
          this.form.category_id = res.data.category ? res.data.category.id : null;
          this.form.join_policy = res.data.join_policy;
          for (let i = 0; i < TYPE_OPTIONS.length; i++) {
            if (TYPE_OPTIONS[i].id === res.data.type) {
              this.typeOption = TYPE_OPTIONS[i];
            }
          }
          this.categoryModel = res.data.category;
          this.form.type = res.data.type;
        }
      })
    },
    getCategories(params = {}) {
      params.page = this.category_params.page;
      CategoryService.getCategory(params).then(res => {
        if (res && res.data) {
          res.data.forEach(item => this.categorySet.add(item));
          this.categoryOptions = Array.from(this.categorySet);
          this.category_last_page = res.meta.last_page;
        }
      });
    },
    reachedEndOfListForCategory(reached) {
      if (reached) {
        this.category_params.page++;
        this.getWorkProcess({keyword: this.category_params.search_text});
      }
    },
    updateGroup() {
      if (!this.validateForm()) {
        return;
      }
      if (this.categoryModel && this.categoryModel.id) {
        this.form.category_id = this.categoryModel.id;
      }
      let data = this.form;
      this.isLoad = true;
      GroupService.updateGroup(this.groupId, data).then(res => {
        this.isLoad = false;
        if (res && res.status) {
          this.$store.dispatch("alerts/success", 'Bạn cập nhật nhóm thành công.');
        } else {
          let html = ''
          if (res.data) {
            for (let i = 0; i < res.data.length; i++) {
              html += '<p>' + res.data[i][0] + '</p>';
            }
          }
          this.$store.dispatch("alerts/error", 'Bạn cập nhật nhóm không thành công. Nguyên nhân : ' + res.message + html);
        }
      });
    },
    validateForm() {
      let isValid = true;
      if (helpers.isEmpty(this.form.name)) {
        isValid = false;
        this.showError.name = true;
      }

      if (this.typeOption && this.typeOption === 0) {
        if (!this.categoryModel) {
          isValid = false;
          this.showError.category = true;
        }
      }
      return isValid;
    },
    async getRequestMembers() {
      let param = {
        page: 1,
        per_page: 500,
        member_type: 1,
        groupId: this.groupId,
      }
      GroupService.getMembers(param).then(res => {
        if (res && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].approved = false;
            res.data[i].accept = false;
            this.requestMembers.push(res.data[i]);
          }
          ;
        }
      });
    },
    redirectToProfile(id) {
      this.$router.push(this.getProfileLink(id));
    },
    getProfileLink(id) {
      return '/profile/' + id;
    },
    approveRequest(e) {
      let accept = e.accept;
      let user;
      for (let i = 0; i < this.requestMembers.length; i++) {
        if (this.requestMembers[i].id === e.userId) {
          user = this.requestMembers[i];
          break;
        }
      }
      let params = {
        approve: accept ? 1 : 0
      };
      let message = (accept ? 'Đã chấp nhận ' : 'Đã từ chối ') + user.fullname;
      GroupService.approveJoinGroupRequest(this.groupId, user.id, params).then(res => {
        if (res && res.status) {
          this.$store.dispatch("alerts/success", message);
          user.accept = accept;
          user.approved = true;
        } else {
          this.$store.dispatch("alerts/error", 'Lỗi hệ thống');
        }
      });
    },
    changeMemberTab(tab) {
      this.member_tab_active = tab;
    },
    async getMembers(isSearch) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (isSearch) {
          this.memberData.memberPage = 1;
          this.memberData.lastPage = 1;
          this.memberData.memberList = [];
        }

        let param = {
          page: this.memberData.memberPage,
          per_page: 30,
          member_type: 2,
          keyword: this.memberData.searchMemberInput,
          groupId: this.groupId,
        }
        GroupService.getMembers(param).then(res => {
          if (res && res.data && res.data.length > 0) {
            this.memberData.lastPage = res.meta.last_page
            this.memberData.memberList = this.memberData.memberList.concat(res.data);
            this.memberData.totalMember = res.meta.total;
          }
        });
      }, 800);
    },
    async getAdminList() {
      let params = {
        page: 1,
        per_page: 50,
        member_type: 2,
        groupId: this.groupId,
        is_admin: true
      }
      GroupService.getMembers(params).then(res => {
        if (res && res.data) {
          this.adminList = res.data;
        }
      });
    },
    onRefreshForMember(done) {
      this.memberData.memberList = [];
      this.memberData.memberPage = 1;
      this.memberData.finished = false;
      this.getMembers();
      done();
    },
    onLoadForMember(done) {
      if (this.memberData.memberPage < this.memberData.lastPage) {
        this.memberData.memberPage++
        this.getMembers();
      } else {
        this.memberData.finished = true;
      }
      done();
    },
    refreshData() {
      this.typeOption = {};
      this.memberData = {
        searchMemberInput: "",
        memberList: [],
        memberPage: 1,
        finished: false,
        lastPage: 1
      };
      this.showError = {
        name: false,
        category: false
      };
    },
    handleUserRole(e) {
      let isAdmin = e.isAdmin;
      let user;
      for (let i = 0; i < this.memberData.memberList.length; i++) {
        if (this.memberData.memberList[i].id === e.userId) {
          user = this.memberData.memberList[i];
          break;
        }
      }

      if (!user) {
        for (let i = 0; i < this.adminList.length; i++) {
          if (this.adminList[i].id === e.userId) {
            user = this.adminList[i];
            break;
          }
        }
      }

      let newAdminStatus = !isAdmin;
      let params = {
        is_admin: newAdminStatus
      };
      let message = newAdminStatus ? 'Đã đặt quản trị viên thành công  ' : 'Đã hủy quyền quản trị viên thành công ';
      GroupService.handleAdminRole(this.groupId, user.id, params).then(res => {
        if (res && res.status) {
          this.$store.dispatch("alerts/success", message);
          user.is_admin = newAdminStatus ? 1 : 0;
          if (newAdminStatus) {
            this.adminList.unshift(user);
          } else {
            for (let i = 0; i < this.adminList.length; i++) {
              if (this.adminList[i].id == user.id) {
                this.adminList.splice(i, 1);
              }
            }
          }
        } else {
          this.$store.dispatch("alerts/error", 'Lỗi hệ thống');
        }
      });
    },
    showAddMemberModal() {
      this.$modal.show('add-group-member-modal', {groupId: this.groupId});
    },
    removeFromGroup(e) {
      let user;
      let removeUserIndex;
      for (let i = 0; i < this.memberData.memberList.length; i++) {
        if (this.memberData.memberList[i].id === e.userId) {
          user = this.memberData.memberList[i];
          removeUserIndex = i;
          break;
        }
      }

      if (!user) {
        for (let i = 0; i < this.adminList.length; i++) {
          if (this.adminList[i].id === e.userId) {
            user = this.adminList[i];
            break;
          }
        }
      }

      let message = 'Bạn đã xóa ' +  user.fullname + ' khỏi nhóm';
      GroupService.removeMember(this.groupId, user.id).then(res => {
        if (res && res.status) {
          this.$store.dispatch("alerts/success", message);
          this.memberData.memberList.splice(removeUserIndex, 1);
          for (let i = 0; i < this.adminList.length; i++) {
            if (this.adminList[i].id == user.id) {
              this.adminList.splice(i, 1);
            }
          }
        } else {
          this.$store.dispatch("alerts/error", 'Đã có lỗi, vui lòng liên hệ bộ phận IT!');
        }
      });
    },
  },
  mounted() {
    this.getCategories();
    this.getGroup();
    this.getRequestMembers();
    this.getAdminList();
    this.getMembers(false);
    $(".gs-more-btn").click(function (event) {
    });
    $(".gs-member-more-btn").click(function (event) {
    });
    localStorage.setItem('router_name', this.$route.name)
  },
};
</script>
<style lang="scss">
.group-setting-container {
  width: 100%;
  height: 100%;

  .gs-left-main {
    width: 250px;
    height: 100%;
    padding: 15px 10px 0 10px;
    float: left;
    position: fixed;
    top: 0;
    bottom: 0;
    background: #FFFFFF;
    border-right: 1px solid #EEE;

    .md-list-item-content {
      padding-left: 0 !important;
    }

    .gs-info {
      margin-bottom: 25px;
    }

    .gs-close-btn {
      margin-right: 10px !important;
    }

    .gs-category {
      margin-top: 10px;
      border-radius: 4px;
      width: 220px;
      height: 38px;
      background-color: #EDEFF5;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
  }

  .gs-right-main {
    width: calc(100% - 250px);
    height: auto;
    float: right;
    position: relative;
    overflow-y: auto;

    .gs-group {
      margin-top: 20px;
      width: 100%;
      min-height: 100px;

      textarea {
        height: auto !important;
      }

      .vs__actions {
        .vs__clear {
          display: none !important;
        }
      }
    }

    .gs-save-btn {
      width: 125px;
      height: 32px;
      margin-top: 35px;
    }

    .gs-type-group {
      height: 65px;
      background: #FFFFFF;
      border: 1px solid #EDEFF5;
      border-radius: 4px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      padding-left: 10px;
    }

    .gs-request-accept {
      background-color: #EDFAD0;
      border-radius: 4px;
      padding: 4px;
    }

    .gs-request-decline {
      background-color: #FAECEC;
      border-radius: 4px;
      padding: 4px;
    }

    .gs-member-more-btn {
      width: 30px;
      height: 30px;
      background-color: #F9FAFC !important;
      border: 1px solid #D8DAE5;

      i {
        color: #424347 !important
      }
    }
    .title-main {
      display: flex;
      justify-content: space-between;
      .md-list-item {
        display: flex;
        align-items: center;
      }
    }

    .search-file-container {
      display: -ms-flexbox; /* IE10 */
      display: flex;
      width: 237px;
      height: 40px;
      border: 1px solid #EDEFF5;
      border-radius: 4px;
      background-color: white;

      .input-search-field {
        width: 100%;
        outline: none;
        border: none !important;
        background-color: transparent;
      }

      .search-icon {
        padding: 10px 0px 10px 0px;
        min-width: 35px;
        text-align: center;
        background-color: transparent;
        color: black !important;
      }
    }
    .add-member-btn, .more-setting-btn {
      background: #F9FAFC !important;
      border: 1px solid #D8DAE5  !important;
      border-radius: 4px !important;
      color: #424347 !important;
      margin-left: 15px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      .md-icon {
        color: #424347 !important;
      }

      .md-ripple {
        padding: 10px 15px;
        border-radius: 3px;
      }

      .md-button-content {
        text-transform: none !important;
      }
    }
  }

  .gs-category-active {
    background: #FCAF17 !important;
    color: #FFFFFF !important;
  }

  .gs-content-main {
    width: 100%;
    padding: 25px 50px 40px 20px;

    .gs-member-tbl {
      margin-top: 15px;
    }

    .gs-member-tab {
      padding-left: 0;
      margin-top: 35px;
      z-index: 5;

      li {
        z-index: 4;
      }
    }
  }

  .more-btn-wrapper {
    position: relative;

    .more-dropdown {
      z-index: 6;
    }
  }

  .vuejs-loadmore-finished-text {
    display: none;
  }

  .vuejs-loadmore-wrap {
    z-index: 1;
  }
  .gs-setting-form__main {
    width: 800px;
    padding: 5px 25px 20px 25px;
    background-color: white;
    border-radius: 10px;
    border-collapse: collapse;
  }
  .gs-header {
    padding: 20px;
    background-color: white;
  }
  .vuejs-refresh-head {
    display: none;
  }
}

body {
  height: 100%;
}
</style>
