import {
    BRANCH_GROUP_TYPE,
    CLANDESTINE_GROUP_TYPE,
    CLANDESTINE_GROUP_TYPE_NAME,
    COMPANY_WALL_GROUP_TYPE,
    PRIVATE_GROUP_TYPE,
    PRIVATE_GROUP_TYPE_NAME,
    WORK_GROUP_TYPE,
    WORK_GROUP_TYPE_NAME
} from '../const/const';
import moment from "moment";

export const helpers = {
    getGroupTypeNameAndColor: (type) => {
        let groupData = {};
        switch (type) {
            case WORK_GROUP_TYPE:
                groupData.typeName = WORK_GROUP_TYPE_NAME;
                groupData.classColor = 'work-group-name-color';
                break;
            case COMPANY_WALL_GROUP_TYPE:
            case BRANCH_GROUP_TYPE:
            case PRIVATE_GROUP_TYPE:
                groupData.typeName = PRIVATE_GROUP_TYPE_NAME;
                groupData.classColor = 'private-group-name-color';
                break;
            case CLANDESTINE_GROUP_TYPE:
                groupData.typeName = CLANDESTINE_GROUP_TYPE_NAME;
                groupData.classColor = 'clandestine-group-name-color';
                break;
        }
        return groupData;
    },

    fromNow(date) {
        return moment(date).locale("vi").fromNow();
    },

    createSlug(str) {
        str = this.convertViToEn(str);
        str = str.trim().toLowerCase();
        str = str.replace('/[^a-z0-9-]/', '-');
        str = str.replace('/-+/', "_");
        return str;
    },

    convertViToEn(str, checkNumber = false) {
        str = str.replace(" ", "_");
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");

        if (checkNumber) {
            str = str.replace(/0/g, "");
            str = str.replace(/1/g, "");
            str = str.replace(/2/g, "");
            str = str.replace(/3/g, "");
            str = str.replace(/4/g, "");
            str = str.replace(/5/g, "");
            str = str.replace(/6/g, "");
            str = str.replace(/7/g, "");
            str = str.replace(/8/g, "");
            str = str.replace(/9/g, "");
        }

        return str;
    },

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    isEmpty(text) {
        if (text) {
            if (text !== '') {
                return false;
            }
        }
        return true;
    },
    goToProfile(userId) {
        window.open('/profile/' + userId, '_blank');
    },

    getMessageTime(item) {
        return moment(item.created_at).format('HH:mm');
    },

    checkMessageInBlock(currentMess, nextMess) {
        if (this.nextMess == null) return false;

        const senderNext = nextMess.sender;
        if (senderNext && senderNext.id !== currentMess.sender.id) return false;

        const current = moment(currentMess.created_at);
        const next = moment(nextMess.created_at);
        const offset = next.diff(current, 'minutes');
        return offset < 2;
    },

    checkShowSenderName(currentMess, prevMess) {
        if (prevMess == null) return true;
        if (currentMess.sender.id !== this.user.id) {
            return currentMess.sender.id !== prevMess.sender.id || this.checkShowTime(currentMess, index);
        }
        return false;
    },
    checkShowTime(currentMess, prevMess) {
        if (prevMess == null) {
            return !moment(currentMess.created_at).isSame(moment(this.prevMess.created_at), "days");
        } else {
            return true;
        }
    },

    sumEmoji(emojiList) {
        return Object.keys(emojiList).reduce(function (previous, key) {
            return previous + emojiList[key];
        }, 0);
    },

    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },

    getFileType(type){
        let icon = '';
        switch (type) {
            case 'xlsx':
                icon = '/img/chat/icon-excel.svg';
                break;
            case 'docx':
                icon = '/img/chat/icon-word.svg';
                break;
            case 'video':
                icon = '/img/chat/icon-video.svg';
                break;
            case 'png':
            case 'img':
                icon = '/img/chat/icon-photo.svg';
                break;
            case 'pdf':
                icon = '/img/chat/icon-pdf.svg';
                break;
            default:
                icon = '/img/chat/icon-file-empty.svg';
                break;
        }
        return icon;
    }
};
