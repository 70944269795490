<template>
  <div>
    <modal name="group-member-list-modal"
           :click-to-close="false"
           width="90%"
           height="90%"
           class="group-member-list-modal modal-custom group-member-list-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">{{groupMemberType === 'MEMBERS' ? 'Thành viên' : 'Yêu cầu phê duyệt'}}</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('group-member-list-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <div style="width: 100%; height: 100%; overflow-y: auto">
        <div class="member-list-tbl" v-if="groupMemberType === 'MEMBERS'">
          <vue-loadmore
              :on-loadmore="onLoadForMember"
              :load-offset="400"
              :finished="memberData.finished"
              :loading-text="'Đang tải'"
              :finished-text="''">
            <member-table :member-list="memberData.memberList" :table-type="'GROUP_SETTING_MEMBERS'" @remove-from-group="removeFromGroup" @handle-user-role="handleUserRole" />
          </vue-loadmore>
        </div>
        <div class="member-list-tbl" v-else>
          <member-table v-if="requestMembers.length !== 0" :member-list="requestMembers"
                        :table-type="'GROUP_SETTING_REQUEST_MEM'" @approve-request="approveRequest" />
        </div>
      </div>

    </modal>
  </div>
</template>

<script>

import GroupService from "../../../../store/services/GroupService";
import MemberTable from "../../../Workplace/Pages/MemberTable";

export default {
  components: {MemberTable},
  name: 'group-member-list-modal',
  computed: {},
  mounted() {
  },
  data() {
    return {
      memberData: {
        searchMemberInput: "",
        memberList: [],
        memberPage: 1,
        finished: false,
        lastPage: 1,
      },
      requestMembers: [],
      groupMemberType: 'MEMBERS',  // or REQUEST_MEMBER
      groupId: null,
    };
  },
  methods: {
    beforeOpened(event) {
      this.refreshDataFilter();
      this.groupId = event.params.groupId;
      this.groupMemberType = event.params.groupMemberType;
      if (this.groupMemberType === 'MEMBERS') {
        this.getMembers();
      } else {
        this.getRequestMembers();
      }
    },

    beforeClosed(event) {
    },

    onRefreshForMember(done) {
      this.memberData.memberList = [];
      this.memberData.memberPage = 1;
      this.memberData.finished = false;
      this.getMembers();
      done();
    },
    onLoadForMember(done) {
      if (this.memberData.memberPage < this.memberData.lastPage) {
        this.memberData.memberPage++
        this.getMembers();
      } else {
        this.memberData.finished = true;
      }
      done();
    },
    refreshDataFilter() {
      this.memberData = {
        searchMemberInput: "",
        memberList: [],
        memberPage: 1,
        finished: false,
        lastPage: 1,
      };
      this.requestMembers = [];
    },
    async getMembers(isSearch) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (isSearch) {
          this.memberData.memberPage = 1;
          this.memberData.lastPage = 1;
          this.memberData.memberList = [];
        }

        let param = {
          page: this.memberData.memberPage,
          per_page: 25,
          member_type: 2,
          keyword: this.memberData.searchMemberInput,
          groupId: this.groupId,
        }
        GroupService.getMembers(param).then(res => {
          if (res && res.data && res.data.length > 0) {
            this.memberData.lastPage = res.meta.last_page
            this.memberData.memberList = this.memberData.memberList.concat(res.data);
            this.memberData.totalMember = res.meta.total;
          }
        });
      }, 800);
    },
    async getRequestMembers() {
      let param = {
        page: 1,
        per_page: 500,
        member_type: 1,
        groupId: this.groupId,
      }
      GroupService.getMembers(param).then(res => {
        if (res && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].approved = false;
            res.data[i].accept = false;
            this.requestMembers.push(res.data[i]);
          }
          ;
        }
      });
    },
    approveRequest(e) {
      let accept = e.accept;
      let user;
      for (let i = 0; i < this.requestMembers.length; i++) {
        if (this.requestMembers[i].id === e.userId) {
          user = this.requestMembers[i];
          break;
        }
      }
      let params = {
        approve: accept ? 1 : 0
      };
      let message = (accept ? 'Đã chấp nhận yêu cầu của ' : 'Đã từ chối yêu cầu của ') + user.fullname;
      GroupService.approveJoinGroupRequest(this.groupId, user.id, params).then(res => {
        if (res && res.status) {
          this.$store.dispatch("alerts/success", message);
          user.accept = accept;
          user.approved = true;
        } else {
          this.$store.dispatch("alerts/error", 'Lỗi hệ thống');
        }
      });
      this.$emit('approve-request', {groupId: this.groupId, accept: accept});
    },

    removeFromGroup(e) {
      let user;
      let removeIndex = 0;
      for (let i = 0; i < this.memberData.memberList.length; i++) {
        if (this.memberData.memberList[i].id === e.userId) {
          user = this.memberData.memberList[i];
          removeIndex = i;
          break;
        }
      }
      GroupService.removeMember(this.groupId, user.id).then(res => {
        if (res && res.status) {
          this.memberData.memberList.splice(removeIndex, 1);
          let message = 'Bạn đã xóa ' +  user.fullname + ' khỏi nhóm';
          this.$store.dispatch("alerts/success", message);
          this.$emit('remove-from-group', {groupId: this.groupId});
        } else {
          this.$store.dispatch("alerts/error", res.message);
        }
      });
    },

    handleUserRole(e) {
      let isAdmin = e.isAdmin;
      let user;
      for (let i = 0; i < this.memberData.memberList.length; i++) {
        if (this.memberData.memberList[i].id === e.userId) {
          user = this.memberData.memberList[i];
          break;
        }
      }

      let newAdminStatus = !isAdmin;
      let params = {
        is_admin: newAdminStatus
      };
      let message = newAdminStatus ? 'Đã đặt quản trị viên thành công  ' : 'Đã hủy quyền quản trị viên thành công ';
      GroupService.handleAdminRole(this.groupId, user.id, params).then(res => {
        if (res && res.status) {
          this.$store.dispatch("alerts/success", message);
          user.is_admin = newAdminStatus ? 1 : 0;
          if (newAdminStatus) {
            this.adminList.unshift(user);
          } else {
            for (let i = 0; i < this.adminList.length; i++) {
              if (this.adminList[i].id == user.id) {
                this.adminList.splice(i, 1);
              }
            }
          }
        } else {
          this.$store.dispatch("alerts/error", 'Lỗi hệ thống');
        }
      });
    },

  }

}
</script>

<style lang="scss">
.group-member-list-modal {
  .member-list-tbl {
    overflow: auto;
    padding: 10px;
  }
}
</style>
