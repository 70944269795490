import BaseService from "./BaseService";

export default class ProfileService extends BaseService {
    static async updateProfileImage(params = {}) {
        const res = await this.post(`/profile/wallpaper`, params);
        return res.data;
    }

    static async getProfile(params = {}) {
        const res = await this.get(`/users/` + params.user_id);
        return res.data;
    }

    static async getFiles(userId, params = {}) {
        const res = await this.get(`/users/` + userId + '/files', params);
        return res.data;
    }
    static async updateProfile(params = {}) {
        const res = await this.post(`/update-profile`, params);
        return res.data;
    }
    static async changePassword(params = {}) {
        const res = await this.post(`/change-password`, params);
        return res.data;
    }
    static async updateUserProfile(params = {}, user_id) {
        const res = await this.post(`/profile/update-other-account/${user_id}`, params);
        return res.data;
    }
}
