<template>
  <div>
    <div class="wrapper-image"
         :class="(currentMess.files.length >= 1 && currentMess.files.length <= 5) ? 'wrapper-image' + currentMess.files.length : 'wrapper-image5'">
      <md-card-media class="card-media" v-for="(item, indexFile) in currentMess.files"
                     :style="setSizeImage(currentMess.files)" :key="indexFile" v-if="indexFile < 5">
        <span @click="showFileSlideShow(currentMess.files, indexFile)">
          <img class="cursor-pointer media-image"
            :class="{'border-none-sticker': currentMess.system_note && JSON.parse(currentMess.system_note).sticker}"
            :src="item.thumbnail.medium_thumb">
          <span class="hd-quality"
                v-if="item.width >= 1920 || item.height >=1080">HD</span>
          <div class="countFile" v-if="indexFile == 4 && currentMess.files.length - 5 > 0">
            <span class="totalCount">+ {{ currentMess.files.length - 5 }}</span>
          </div>
        </span>
      </md-card-media>
    </div>
    <div class="emojis" v-if="displayEmoji"
         @click="$modal.show('reaction-message-detail-modal', {message_id : currentMess.id})">
      <div v-for="(emoji_icon, icon) in currentMess.reaction_stats" v-if="emoji_icon > 0"
           :key="icon" class="emoji-wrapper">
        <span class="mr-2px text-300-medium neutral_3">{{ emoji_icon }}</span>
        <div :class="'reaction-' + icon" class="emoji_icon"></div>
      </div>
    </div>
    <div class="content-display-timestamp mt-5px mr-10px"
         v-if="!isMessageInBlock">
      <span>{{ messageTime }}</span>
    </div>
  </div>
</template>

<script>
  import {helpers} from "../../../../../helper/helpers";
  export default {
    name: "image-chat-content",
    components: {},
    props: {
      displayName: {
        type: String,
        default: "",
      },
      currentMess: {
        type: Object,
        default: () => {
          return {};
        }
      },
      nextMess: {
        type: Object,
        default: null
      }
    },
    computed: {
      displayEmoji() {
        return helpers.sumEmoji(this.currentMess.reaction_stats) > 0
      },
      messageTime() {
        return helpers.getMessageTime(this.currentMess)
      },
      isMessageInBlock() {
        return helpers.checkMessageInBlock(this.currentMess, this.nextMess)
      }
    },
    methods: {
      showFileSlideShow(files, index) {
        this.$modal.show('slide-show-modal', {items: files, activeId: index});
      },

      setSizeImage(image){

        if(typeof image == 'undefined' || (typeof image !== 'undefined' && image.length > 1)){
          return {};
        }

        // set size for message stickter
        if(this.currentMess.system_note && JSON.parse(this.currentMess.system_note).sticker){
          return {
            'width' :  '100px',
            'height' : '100px'
          };
        }

        const max_width = 450;
        const max_height = 450;

        const width_image = image[0].width;
        const height_image = image[0].height;

        // giá trị set cho ảnh
        let renderWidth, renderHeight;
        // tỷ lệ gốc của ảnh
        let imageRatio = height_image/width_image
        let maxRatio = max_height/max_width

        //TH1
        if(width_image <= max_width && height_image <= max_height){
          return {
            'width' :  `${width_image}px`,
            'height' : `${height_image}px`
          };
        }
        //TH2
        if (imageRatio > 1.5) {
          renderWidth = max_width/imageRatio;
          return {
            'width' :  `${renderWidth}px`,
            'height' : `${max_height}px`,
          };
        }
        // TH2
        else if (width_image > max_width && height_image > max_height) {
          if (imageRatio <= maxRatio) {
            renderWidth = max_width;
            // tính tỷ lệ bo
            let tlb = width_image / renderWidth;
            renderHeight = parseInt(height_image / tlb);
          } else {
            renderHeight = max_height;
            // tính tỷ lệ bo
            let tlb = height_image / renderHeight;
            renderWidth = parseInt(width_image / tlb);
          }

          return {
            'width' : `${renderWidth}px`,
            'height' : `${renderHeight}px`
          };
        }
        //TH3
        else if (width_image > max_width && height_image < max_height){
          renderWidth = max_width;
          // tính tỷ lệ bo
          let tlb = width_image / max_width;
          renderHeight = parseInt(height_image / tlb);

          return {
            'width' : `${renderWidth}px`,
            'height' : `${renderHeight}px`
          };
        }
        // TH4:
        else if (width_image < max_width && height_image > max_height){
          renderHeight = max_height;
          // tính tỷ lệ bo
          let tlb = height_image / renderHeight;
          renderWidth = parseInt(width_image / tlb);

          return {
            'width' :  `${renderWidth}px`,
            'height' : `${renderHeight}px`
          };
        }

        return {};

      },
    }
  };
</script>
<style lang="scss">
.border-none-sticker{
  border: 0 !important;
}
</style>