<template>
  <table class="ml-member-tbl">
    <thead>
    <tr>
      <th class="text-400-semibold ml-table-cell group_name">Tên nhóm</th>
      <th class="text-400-semibold ml-table-cell w_min_160">Admin</th>
      <th class="text-400-semibold ml-table-cell w_min_160">Chat cộng đồng</th>
      <th class="text-400-semibold ml-table-cell">Thành viên</th>
      <th class="text-400-semibold ml-table-cell w_min_120">Yêu cầu<br>phê duyệt</th>
      <th class="text-400-semibold ml-table-cell w_min_120">Trạng thái</th>
      <th class="ml-table-cell">Hành động</th>
    </tr>
    </thead>
    <tbody class="ml-member-tbody">
    <tr v-for="member in memberList" :key="member.id">
      <td class="gs-mem-avt ml-table-cell">
        <md-list-item>
          <md-avatar>
            <image-template :class="'group-avatar'" :size="'small'" :image="member.avatar_url" :thumbnail="member.thumbnail"/>
          </md-avatar>
          <div class="md-list-item-text">
            <router-link :to="getGroupDetailUrl(member.id)" class="member-name">
              <span class="text-400-medium neutral_1 cursor-pointer hover-link text-2-line">{{ member.name }}</span>
              <div class="text-300-default gs-admin-flag" v-if="tableType !== 'MEMBERS' && member.is_admin && member.is_admin === 1">Admin
              </div>
            </router-link>
            <!--                    <span class="text-300-default neutral_2">Người tạo nhóm</span>-->
          </div>
        </md-list-item>
      </td>
      <td class="text-400-medium ml-table-cell">
        <div v-for="admin in member.admins" :key="'admin_id' + admin.id">
          <a :href="'/profile/' + admin.id" target="_blank">{{admin.fullname}}</a>
        </div>
      </td>
      <td class="text-400-medium ml-table-cell">
        {{member.chat_group ? member.chat_group.name : '-'}}
      </td>
      <td class="text-400-medium ml-table-cell cursor-pointer hover-link"
          @click="$modal.show('group-member-list-modal', {groupId: member.id, groupMemberType: 'MEMBERS'})">
        {{member.member_count}}
      </td>
      <td class="text-400-medium ml-table-cell cursor-pointer hover-link"
          @click="$modal.show('group-member-list-modal', {groupId: member.id, groupMemberType: 'REQUEST_MEMBERS'})">
        {{member.request_count}}
      </td>
      <td class="text-400-medium ml-table-cell w_min_120">
        <span class="text-300-default ml-mem-status" :class="member.status == 1 ? 'active' : 'non-active'">
          {{member.status == 1 ? 'Hoạt động' : 'Không hoạt động'}}
        </span>
      </td>

      <td class="text-400-medium ml-table-cell" style="text-align: center">
        <g-s-more-member-btn :table-type="'GROUP_LIST'" @add-member="$modal.show('add-group-member-modal', {groupId: member.id})"/>
      </td>

    </tr>
    </tbody>
  </table>
</template>

<script>
import ImageTemplate from "../../../../components/ImageTemplate";
import GSMoreMemberBtn from "../Group/GSMoreMemberBtn";

export default {
  name: "GroupTable",
  components: {
    ImageTemplate,
    GSMoreMemberBtn,

  },
  props: {
    memberList: {
      type: Array,
      default: () => [],
    },
    tableType: {
      type: String,
      default: 'MEMBERS',
    }
  },
  methods: {
    getGroupDetailUrl(id) {
      return '/group/' + id;
    },
    approveRequest(accept, userId) {
      this.$emit("approve-request", {accept: accept, userId: userId});
    },
    handleUserRole(isAdmin, userId) {
      this.$emit("handle-user-role", {isAdmin: isAdmin, userId: userId});
    },
    removeFromGroup(userId) {
      this.$emit("remove-from-group", {userId: userId});
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return 'Hoạt động';
        case 0:
          return 'Vô hiệu hóa';
        default:
          return '';
      }
    }
  }
}
</script>


<style lang="scss">
  .ml-member-tbl{
    padding: 10px;
    .group-name{
      max-width: 600px;
    }
  }
  .w_min_160 {
    min-width: 160px;
  }
</style>