<template>
  <div style="height: 100%; overflow-y: auto">
    <vue-element-loading :active="show" is-full-screen/>
    <div class="group-list-container py-20px">
      <template v-if="$route.params.group_type == null">
        <div v-if="companyGroups.length !== 0">
          <div class="group-title">
            <div class="heading-600">Tổ chức<span class="text-400-default">・{{companyGroupTotal}} nhóm</span></div>
          </div>
          <div class="w_100 d-flex">
            <div class="group-wrapper">
              <div v-for="(groupItem, i) in companyGroups" :key="i">
                <group-item :group-item="groupItem" :is-work-group="true"></group-item>
              </div>
            </div>
            <button class="view-more-btn cursor-pointer" @click="$router.push('/groups/company')">
              <md-icon>arrow_forward</md-icon>
            </button>
          </div>
        </div>
        <div v-if="workGroups.length !== 0">
          <div class="group-title">
            <div class="heading-600">Nhóm làm việc<span class="text-400-default">・{{workGroupTotal}} nhóm</span></div>
          </div>
          <div class="w_100 d-flex">
            <div class="group-wrapper">
              <div v-for="(groupItem, i) in workGroups" :key="i">
                <group-item :group-item="groupItem" :is-work-group="true"></group-item>
              </div>
            </div>
            <button class="view-more-btn cursor-pointer"  @click="$router.push('/groups/work')">
              <md-icon>arrow_forward</md-icon>
            </button>
          </div>
        </div>
        <div v-if="adminGroups.length !== 0">
          <div class="group-title">
            <div class="heading-600">Nhóm mà bạn đang là quản trị viên<span class="text-400-default">・{{adminGroupTotal}} nhóm</span></div>
          </div>
          <div class="w_100 d-flex">
            <div class="group-wrapper">
              <div v-for="(groupItem, i) in adminGroups" :key="i">
                <group-item :group-item="groupItem" :is-work-group="false"></group-item>
              </div>
            </div>
            <button class="view-more-btn cursor-pointer"  @click="$router.push('/groups/joined?isAdmin=true')">
              <md-icon>arrow_forward</md-icon>
            </button>
          </div>
        </div>
        <div v-if="joinedGroups.length !== 0">
          <div class="group-title">
            <div class="heading-600">
              Nhóm mà bạn đang tham gia ・{{joinedGroupTotal}} nhóm
            </div>
            <div class="text-400-default mt-5px">
              Chỉ bài viết của nhóm bạn theo dõi mới hiển thị trên Bảng tin.
            </div>
          </div>
          <div class="w_100 d-flex">
            <div class="group-wrapper">
              <div v-for="(groupItem, i) in joinedGroups" :key="i">
                <group-item :group-item="groupItem" :is-work-group="false"></group-item>
              </div>
            </div>
            <button class="view-more-btn cursor-pointer heading-300" @click="$router.push('/groups/joined')">
              Xem thêm
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <group-list/>
      </template>
    </div>
  </div>
</template>

<script>
import GroupService from "@/store/services/GroupService";
import GroupItem from "./GroupItem";
import GroupList from "./GroupList";

export default {
  components: {GroupItem, GroupList},

  data() {
    return {
      companyGroups: [],
      companyGroupTotal: 0,
      workGroups: [],
      workGroupTotal: 0,
      joinedGroups: [],
      joinedGroupTotal: 0,
      adminGroups: [],
      adminGroupTotal: 0,
      groupId: '',
      show: true,
    }
  },

  mounted() {
    this.getGroups();
  },

  methods: {
    // $v: undefined,
    getGroups() {
      let params = {
        per_page: 50
      };
      GroupService.getGroupOverview(params).then(res => {
        this.show = false;
        if (res && res.data.length !== 0) {
          this.companyGroups = res.data.company.items.slice(0, 3);
          this.companyGroupTotal = res.data.company.total;
          this.joinedGroups = res.data.other.items.slice(0, 3);
          this.joinedGroupTotal = res.data.other.total;
          this.workGroups = res.data.working.items.slice(0, 3);
          this.workGroupTotal = res.data.working.total;
          this.adminGroups = res.data.admin.items.slice(0, 3);
          this.adminGroupTotal = res.data.admin.total;
        }
      });
    },
  }
};

</script>


<style lang="scss">
.md-toolbar {
  height: auto !important;
  background-color: white !important;
}

.group-list-header {
  width: 100%;
  background-color: white;
}

.main-panel > .content {
  padding: 0;
}

.md-toolbar {
  border: none;
  .md-toolbar-row {
    padding-left: 0px;
    justify-content: space-between;

    .add-group-btn {
      border: 1px solid #D8DAE5;
      border-radius: 4px;
      width: 125px;
      height: 40px;

      .d-flex {
        align-items: center;
      }
    }

    .logo {
      width: 50px;
      height: 50px;
    }
  }
}

.group-list-container {
  width: 800px;
  margin: auto;

  .view-more-btn {
    border-radius: 30px;
    border: 1px solid rgba(167, 159, 159, 0.93);
    height: 50px;
    width: 50px;
    margin-top: 120px;
  }
}
</style>