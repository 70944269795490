<template>
  <div class="notfound-page-container ">
    <div class="header">
      <md-list-item>
        <md-avatar>
            <img src="/img/toolbar/logo_toolbar.svg" alt="">
        </md-avatar>
        <div class="md-list-item-text">
          <span class="heading-600 primary_y500">AZA GROUP</span>
          <span class="text-400-default neutral_2">
            Liên tục tiến lên - Không ngừng vươn xa - Vô địch
          </span>
        </div>
      </md-list-item>
    </div>
    <div class="body mt-20px">
      <div class="content-wrapper">
        <div class="content">
          <div class="heading-800 secondary_b400">
            Trang không được tìm thấy
          </div>
          <div class="mt-20px" style="align-content: center">
            Thật tiếc! Trang của bạn yêu cầu không tồn tại
          </div>
          <div style="align-content: center">
            hoặc không có quyền truy cập.
          </div>
          <button class="bt-active heading-300 mt-40px cursor-pointer" @click="$router.push('/new-feeds')">Quay về bảng tin</button>
        </div>
      </div>
      <div class="img">
      </div>
    </div>
    <div class="footer text-400-default neutral_2">
      <span>Copyright © {{currentYear}} AZA</span>
    </div>
  </div>
</template>
<script>

export default  {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>
<style lang="scss">
 .notfound-page-container {
    padding: 20px 20% 0 20%;
   .header {
     .md-avatar {
       width: 75px;
       height: 100px;
     }
   }
   .body {
     width: 100%;
     height: 270px;
     display: flex;
     .content-wrapper {
       width: 50%;
     }
     .content {
       width: 80%;
       height: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
       .bt-active {
         width: 155px;
         height: 32px;
         display: flex;
         justify-content: center;
         align-items: center;
         background-color: #FFD700;
         border-radius: 4px;
         border: none;
       }
     }
     .img {
       width: 50%;
       height: 100%;
       background: url("/img/404_background.png");
     }
   }
   .footer {
     position: fixed;
     left: 0;
     bottom: 0;
     width: 100%;
     height: 50px;
     text-align: center;
     border-top: 1px solid #D8DAE5;
     justify-content: center;
     display: flex;
     align-items: center;
   }
 }
</style>
