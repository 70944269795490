var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isCategory)?[_c('h3',{staticClass:"title-sidebar"},[_vm._v("Thư viện kiến thức")]),_c('div',{staticClass:"content-sidebar"},[_c('sidebar-item',{attrs:{"link":{
                      name: 'Thư viện kiến thức',
                      path: '/category-home',
                      image: '/img/sidebar/right/workplace/home.svg',
                      is_group: false,
                    }}}),[_c('h3',{staticClass:"subtitle-sidebar text-500-medium"},[_vm._v("Hạng mục")]),([1,2,119,141,845,921,920,869].includes(_vm.user.id))?_c('div',[_c('li',{},[_c('a',{staticClass:"nav-link is_group",on:{"click":function($event){return _vm.$modal.show('create-category-modal', {categoryParent:null})}}},[_vm._m(0),_c('p',{staticClass:"text-400-semibold secondary_g400"},[_vm._v(" Tạo hạng mục ")])])])]):_vm._e(),_vm._l((_vm.categories),function(category){return _c('sidebar-item',{key:category.id,attrs:{"link":{
                      name: category.title,
                      path: '/category/' + category.id,
                      icon: category.icon ? category.icon : 'star',
                      is_group: false,
                      is_category: true,
                      color: category.color,
                      category: category
                    }}})})]],2)]:[_c('h3',{staticClass:"title-sidebar text-nowrap heading-600",on:{"click":function($event){_vm.isCategory=false}}},[_c('md-icon',{staticClass:"arrow_back cursor-pointer"},[_vm._v("arrow_back")]),_vm._v(" Chi tiết hạng mục ")],1),_c('div',{staticClass:"content-sidebar"},[_c('sidebar-item',{attrs:{"link":{
                      name: _vm.chosenCategory.title,
                      path: '/category/' + _vm.chosenCategory.id,
                      icon: _vm.chosenCategory.icon ? _vm.chosenCategory.icon : 'star',
                      is_category: true,
                      color: _vm.chosenCategory.color,
                      category: _vm.chosenCategory
                    }}}),_c('h3',{staticClass:"subtitle-sidebar text-500-medium"},[_vm._v("Hạng mục con")]),_vm._l((_vm.chosenCategory.subcategories),function(sudCategory){return _c('sidebar-item',{key:sudCategory.id,attrs:{"link":{
                      name: sudCategory.title,
                      path: '/category/'+sudCategory.id,
                      icon: sudCategory.icon ? sudCategory.icon : 'star',
                      is_category: true,
                      is_sub_category: true,
                      color: sudCategory.color,
                      category: sudCategory
                    }}})}),(_vm.chosenCategory.id && [1,2,119,141,845,921,920,869].includes(_vm.user.id))?_c('div',[_c('li',[_c('a',{staticClass:"nav-link is_group",on:{"click":function($event){return _vm.$modal.show('create-category-modal', {categoryParent:_vm.chosenCategory})}}},[_vm._m(1),_c('p',{staticClass:"text-400-semibold secondary_g400"},[_vm._v("Tạo hạng mục con")])])])]):_vm._e()],2)]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"md-icon md-icon-font md-theme-default"},[_c('div',{staticClass:"md-image md-theme-default",staticStyle:{"height":"auto","width":"auto","min-width":"auto"}},[_c('img',{attrs:{"src":"/img/sidebar/right/workplace/add.svg"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"md-icon md-icon-font md-theme-default"},[_c('div',{staticClass:"md-image md-theme-default",staticStyle:{"height":"auto","width":"auto","min-width":"auto"}},[_c('img',{attrs:{"src":"/img/sidebar/right/workplace/add.svg"}})])])}]

export { render, staticRenderFns }