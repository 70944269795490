import BaseService from "./BaseService";

export default class ChatService extends BaseService {

    static async getChatGroups(params = {}) {
        const res = await this.get(`/chat/groups`, params);
        return res.data;
    }

    static async getChatBotGroups(params = {}) {
        const res = await this.get(`/chat/groups/search`, params);
        return res.data;
    }

    static async createGroupChat(params = {}) {
        const res = await this.post(`/chat/groups`, params);
        return res.data;
    }

    static async createMessageChat(params = {}) {
        const res = await this.post(`/chat/groups/message`, params);
        return res.data;
    }

    static async getMessageList(params = {}) {
        const res = await this.get(`/chat/groups/message`, params);
        return res.data;
    }

    static async createReadGroupChat(params = {}) {
        const res = await this.post(`/chat/groups/read`, params);
        return res.data;
    }

    static async getUnreadGroupChat() {
        const res = await this.get(`/chat/groups/unread-count`);
        return res.data;
    }

    static async getMembersGroup(params = {}) {
        const res = await this.get(`/chat/groups/members`, params);
        return res.data;
    }

    static async leaveGroupChat(params = {}) {
        const res = await this.post(`/chat/groups/leave`, params);
        return res.data;
    }

    static async updateNameGroupChat(params = {}) {
        const res = await this.post(`/chat/groups/name`, params);
        return res.data;
    }

    static async addMemberGroupChat(params = {}) {
        const res = await this.post(`/chat/groups/add-member`, params);
        return res.data;
    }

    static async updateAvatarGroup(params = {}) {
        const res = await this.post(`/chat/groups/avatar`, params);
        return res.data;
    }

    static async getChatConvesations(params = {}) {
        const res = await this.get(`/chat/groups/search`, params);
        return res.data;
    }
    
    static async checkChatExits(params = {}) {
        const res = await this.post(`/chat/groups/check-group-chat`, params);
        return res.data;
    }    
    
    static async notificationConvensation(params = {}) {
        const res = await this.post(`/chat/groups/${params.group_id}/stage`, params);
        return res.data;
    }

    static async messageRecall(params = {}) {
        const res = await this.post(`/chat/groups/${params.chat_message_id}/recall`, params);
        return res.data;
    }
    
    static async reactionMessage(params = {}) {
        const res = await this.post(`/chat/messages/${params.message_id}/reaction`, params);
        return res.data;
    }

    static async getFilesGroupChat(params = {}) {
        const res = await this.get(`/chat/groups/${params.group_id}/files/${params.type}`, params);
        return res.data;
    }

    static async removeMemberChatGroup(params = {}) {
        const res = await this.post(`/chat/groups/remove-member`, params);
        return res.data;
    }

    static async getListReaction(params = {}) {
        const res = await this.get(`/chat/groups/list-reaction/${params.chat_message_id}`, params);
        return res.data;
    }
    
    static async getMessageOriginal(params = {}) {
        const res = await this.get(`/chat/messages/original-message`, params);
        return res.data;
    }
    
    static async getPinListMessage(params = {}) {
        const res = await this.get(`/chat/messages/pin-messages/${params.group_id}`, params);
        return res.data;
    }
    
    static async pinMessage(params = {}) {
        const res = await this.post(`/chat/messages/pin`, params);
        return res.data;
    }

    static async unPinMessage(params = {}) {
        const res = await this.post(`/chat/messages/unpin`, params);
        return res.data;
    }

    static async pinMessagePriority(params = {}) {
        const res = await this.post(`/chat/messages/pin-priority`, params);
        return res.data;
    }
    
    static async setMemberAdminChatGroup(params) {
        const res = await this.post(`/chat-group/${params.group_id}/admin`, params);
        return res.data;
    }

    static async editMessageChat(params = {}) {
        const res = await this.post(`/chat/groups/edit-message`, params);
        return res.data;
    }

    static async requestJoinGroup(params = {}) {
        const res = await this.post(`/chat-group/${params.group_id}/request-to-join/${params.user_id}`);
        return res.data;
    }

    static async getListRequestJoinChatGroup(params = {}) {
        const res = await this.get(`/chat-group/${params.group_id}/pending-users/`, params);
        return res.data;
    }

    static async browseRequestJoinChatGroup(params = {}) {
        const res = await this.post(`/chat-group/${params.group_id}/browse-request/${params.user_id}`, params);
        return res.data;
    }

    static async pinChatGroup(params = {}) {
        const res = await this.post(`/chat/groups/pin-chat-group`, params);
        return res.data;
    }

    static async getChatNotifications(params = {}) {
        const res = await this.get(`/chat-notification`, params);
        return res.data;
    }

    static async readChatNotification(params = {}) {
        const res = await this.post(`/chat-notification/${params.chat_notification_id}/read`);
        return res.data;
    }

    static async hiddenChatGroup(params = {}) {
        const res = await this.post(`/chat/groups/hidden-chat-group`, params);
        return res.data;
    }

    static async getMembersMention(params = {}) {
        const res = await this.get(`/chat/groups/mention-members`, params);
        return res.data;
    }

    static async searchMessageInGroup(params = {}) {
        const res = await this.get(`/chat/messages/search`, params);
        return res.data;
    }

    static async votePoll(params = {}) {
        const res = await this.post(`/polls/${params.poll_id}/vote`, params);
        return res.data;
    }

    static async addOptionPoll(poll_id, params = {}) {
        const res = await this.post(`/polls/${poll_id}/add-option`, params);
        return res.data;
    }

    static async createChatBotMessage(chatGroupId, params = {}) {
        const res = await this.post(`/chat-groups/${chatGroupId}/message-chat-bot`, params, 'ADMIN');
        return res.data;
    }

    static async getBranchesTeams(params = {}) {
        const res = await this.get(`/teams`, params);
        return res.data;
    }

    static async getPackages(params = {}) {
        const res = await this.get(`/stickers/packages`, params);
        return res.data;
    }

    static async userTyping(params = {}) {
        const res = await this.get(`/chat/groups/${params.group_id}/user-typing`, params);
        return res.data;
    }

    static async deleteReaction(params = {}) {
        const res = await this.post(`/chat/messages/delete/reaction`, params);
        return res.data;
    }
}
