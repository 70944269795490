<template>
  <div class="mt-10px p-relative">
    <template v-if="mediaObject.mediaList.length === 1">
      <div class="image-content-wrapper1">
        <div class="wrapper-image wrapper-image1">
          <md-card-media class="card-media" v-for="(media, index) in mediaObject.mediaList" :key="index">
            <template v-if="media.extension !== 'mp4' && media.extension !== 'avi'">
              <img :src="isPostModal ? media.path : media.thumbnail.medium_thumb" @click="redirectToPostDetail(media)">
            </template>
            <template v-else>
              <div>
                <video class="video" :id="media.id"
                       :poster="media.thumbnail.medium_thumb">
                  <source :src="media.path">
                  Sorry, your browser doesn't support embedded videos.
                </video>
                <div class="video-overlay" :class="'overlay'+ media.id" :data-id="media.id">
                  <div class="play-button material-symbols-outlined">play_circle</div>
                  <div class="replay-button" style="display: none">Xem lại</div>
                </div>
              </div>
            </template>
          </md-card-media>
        </div>
      </div>
    </template>
    <template v-else>
      <div :class="{'image-content-wrapper2': mediaObject.mediaList.length !== 1}"
           v-if="mediaObject.mediaList.length > 0">
        <div v-if="mediaObject.mediaList.length > 0" class="wrapper-image" :class="{
    'wrapper-image2': mediaObject.mediaList.length ===2,
    'wrapper-image3': mediaObject.mediaList.length ===3,
    'wrapper-image4': mediaObject.mediaList.length ===4,
    'wrapper-image5': mediaObject.mediaList.length > 4}">
          <md-card-media class="card-media" v-for="(media, index) in mediaObject.mediaList" :key="index">
            <div v-if="index === 4 && mediaObject.media_count && mediaObject.media_count > 5"
                 class="total-image-other-wrapper" @click="redirectToPostDetail(media)">
            </div>
            <span v-if="index === 4 && mediaObject.media_count && mediaObject.media_count > 5" class="total-image-other"
                  @click="redirectToPostDetail(media)">
         +{{ mediaObject.media_count - 4 }}
       </span>
            <template v-if="media.extension !== 'mp4' && media.extension !== 'avi'">
              <img :src="isPostModal ? media.path : media.thumbnail.medium_thumb" @click="redirectToPostDetail(media)">
            </template>
            <template v-else>
              <div>
                <video class="video" :id="media.id"
                       :poster="media.thumbnail.medium_thumb">
                  <source :src="media.path">
                  Sorry, your browser doesn't support embedded videos.
                </video>
                <div class="video-overlay-album" @click="redirectToPostDetail(media)">
                  <div class="play-button material-symbols-outlined">play_circle</div>
                </div>
              </div>
            </template>
          </md-card-media>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "image-content",
  components: {},
  props: {
    isPostModal: {
      type: Boolean,
      default: false,
    },
    mediaObject: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mounted() {
    $(document).ready(function () {
      $('.video-overlay').on('click', function () {
        var id = $(this).data("id");
        $('#' + id).trigger('play');
        $('#' + id).attr('controls',true);
      });

      $('.video').on('ended', function () {
        var overlayId = '.overlay' +$(this).attr("id");
        $(overlayId).show();
        $(overlayId).children('.replay-button').show();
        $(overlayId).children('.play-button').hide();
        $(this).attr('controls',false);
      });

      $('.video').on('play', function () {
        var overlayId = '.overlay' +$(this).attr("id");
        $(overlayId).hide();
      });
    })
  },
  methods: {
    redirectToPostDetail(media) {
      this.$modal.show("post-detail-modal", {singlePostId: media.single_post_id ? media.single_post_id : this.mediaObject.post_id});
    },
    getLink(singlePostId) {
      return '/post/image/' + singlePostId
    },
  }
};
</script>

<style lang="scss" scoped>
.image-content-wrapper2 {
  height: 600px;
}

.wrapper-image {
  height: 100%;
  cursor: pointer;
}

.wrapper-image1 {
  .card-media {
    max-height: 600px;
    background: black;
    text-align: center;
    position: relative;

    video {
      //max-height: 520px;
      max-width: 100%;
      min-height: 476px;
      max-height: 600px;
      position: relative;
      width: auto;
      height: 100%;
      box-sizing: border-box;
      margin: auto;
      object-fit: contain;
    }

    img {
      margin: auto;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.wrapper-image2, .wrapper-image3, .wrapper-image4, .wrapper-image5 {
  .card-media {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    display: inline-block;
  }
}

.wrapper-image2 {
  .card-media:nth-child(1), .card-media:nth-child(2) {
    padding-bottom: 1px;
  }

  .card-media {
    height: 50%;
    width: 100%;
  }
}

.wrapper-image3 {
  .card-media:nth-child(1) {
    width: 100%;
    height: 60%;
  }

  .card-media:nth-child(2), .card-media:nth-child(3) {
    height: 40%;
    width: 50%;
  }

  .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3) {
    padding-bottom: 1px;
    padding-right: 1px;
  }
}

.wrapper-image4 {
  .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3), .card-media:nth-child(4) {
    width: 50%;
    height: 50%;
  }

  .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3), .card-media:nth-child(4) {
    padding-bottom: 1px;
    padding-right: 1px;
  }
}

.wrapper-image5 {
  .card-media:nth-child(1), .card-media:nth-child(2) {
    width: 50%;
    height: 60%;
  }

  .card-media:nth-child(3), .card-media:nth-child(4), .card-media:nth-child(5) {
    width: calc(100% / 3);
    height: 40%;
  }

  .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3), .card-media:nth-child(4), .card-media:nth-child(5) {
    padding-bottom: 1px;
    padding-right: 1px;
  }

  .card-media:nth-child(5) {
    position: relative;

    .total-image-other-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.5;
      display: flex;
    }

    .total-image-other {
      position: absolute;
      top: 50%;
      align-self: center;
      text-align: center;
      width: 100%;
      color: white;
      font-size: 2rem;
      transform: translateY(-50%);
    }
  }
}

.gg-play-button {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px
}

.gg-play-button::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 6px;
  left: 9px
}
</style>
