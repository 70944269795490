<template>
  <div>
    <modal name="add-user-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="create-group-modal modal-custom add-user-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal" v-if="type == TYPE_ADD_GROUP_MEMBER.CREATE_GROUP">Tạo nhóm chat</span>
          <span class="neutral_1 heading-500 text-header-modal" v-else>Thêm thành viên</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('add-user-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content class="p-0px">
        <div style="width: 100%; height: auto">
          <div class="md-gutter mb-15px" v-if="type == TYPE_ADD_GROUP_MEMBER.CREATE_GROUP">
            <input style="height: 40px;width: 100%;" type="text" placeholder="Tên nhóm chat"
                   class="form-control input-custom" required name="name"
                   v-model="forms.name">
          </div>

          <div class="cs-tab-menu" v-if="type === TYPE_ADD_GROUP_MEMBER.CREATE_GROUP">
            <div class="text-400-medium cursor-pointer" :class="{active: tabActive == 1}" @click="chosenTab(1)">Thành
              viên
            </div>
            <div class="text-400-medium cursor-pointer" :class="{active: tabActive == 2}" @click="chosenTab(2)">Phòng
              ban
            </div>
            <div class="text-400-medium cursor-pointer" :class="{active: tabActive == 3}" @click="chosenTab(3)">Chức vụ
            </div>
          </div>
          <div>
            <div class="input-auto-complete">
              <div class="item-select" v-for="(item, index) in listItems" :key="item.id">
                <span v-if="tabActive === 1">{{ item.fullname }}</span>
                <span v-if="tabActive === 2">{{ item.name }}</span>
                <span v-if="tabActive === 3">{{ item.name }}</span>
                <span class="close-icon" @click="removeItem(item, index)"><md-icon>close</md-icon></span>
              </div>
              <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group" v-model="search">
            </div>

            <div class="content py-15px">
              <template v-if="tabActive == 1">
                <div v-for="member in memberList" :key="member.id">
                  <md-list-item class="cursor-pointer user-detail">
                    <md-avatar>
                      <img class="avatar" :src="member.thumbnail.small_thumb || '/img/profile/avatar.png'">
                    </md-avatar>
                    <div class="md-list-item-text">
                      <div class="text-400-medium mb-10px fullname">{{ member.fullname }}</div>
                      <div class="detail-info" v-if="member.agency">
                        <span class="material-icons">schema</span>
                        <span class="text-2-line text-info-name">
                        <span>{{ member.agency.name }}</span>
                        <span class="ml-5px text-300-default" v-if="member.team">></span>
                        <span class="ml-5px text-300-default" v-if="member.team">{{ member.team.name }}</span>
                    </span>
                      </div>
                      <div class="detail-info" v-else>
                        <span class="material-icons">schema</span>
                        <span class="text-2-line text-info-name">AZA GROUP</span>
                      </div>
                      <div class="detail-info" v-if="member.position">
                        <span class="material-icons">account_box</span>
                        <span class="text-info-name">{{ member.position.name }}</span>
                      </div>
                      <div class="detail-info">
                        <span class="material-icons">email</span>
                        <span class="text-info-name">{{ member.email }}</span>
                      </div>
                    </div>
                    <md-checkbox v-model="items" class="text-right mr-10px" :value="member.id"/>
                  </md-list-item>
                </div>
              </template>
              <template v-if="tabActive == 2">
                <div v-if="departmentListFilter.length > 0">
                  <div v-for="department in departmentListFilter" :key="department.id">
                    <tree-view-item :model="department" :isModal="true" :items="items"
                                    @handleUpdateItem="handleUpdateItem"></tree-view-item>
                  </div>
                </div>
              </template>
              <template v-if="tabActive == 3">
                <div v-for="position in positionListFilter" :key="position.id" class="position-list">
                  <md-list-item class="cursor-pointer">
                    <md-icon class="mr-5px">account_box</md-icon>
                    <span class="md-list-item-text">{{ position.name }}</span>
                    <md-checkbox v-model="items" class="text-right" :value="position.id"/>
                  </md-list-item>
                </div>
              </template>
            </div>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions class="p-15px w_100 text-400-medium">
        <button v-if="type == TYPE_ADD_GROUP_MEMBER.CREATE_GROUP" type="submit" class="create-post-btn btn-active md-button w_100"
                :class="{'disabled': (items.length == 0 || forms.name == '')}"
                @click="createChat()">Tạo Nhóm Chat
        </button>
        <button v-if="type == TYPE_ADD_GROUP_MEMBER.UPDATE_GROUP" type="submit" class="create-post-btn btn-active md-button w_100"
                :class="{'disabled': (items.length == 0)}"
                @click="addMoreMember()">Thêm thành viên
        </button>
      </md-dialog-actions>

    </modal>
  </div>
</template>

<script>

import EmployeeService from "../../../store/services/EmployeeService";
import SettingService from "../../../store/services/SettingService";
import {GROUP_CHAT_TYPE, CREATE_POST_LEVEL, MODEL_TYPE, POST_TYPE, TYPE_ADD_GROUP_MEMBER} from "../../../const/const";
import PostService from "../../../store/services/PostService";
import ChatService from "../../../store/services/ChatService";
import TreeViewItem from "../../Workplace/Pages/TreeViewItem";

export default {
  components: {TreeViewItem},
  name: 'add-user-modal',
  computed: {},
  mounted() {
    this.getEmployees(this.member_params);
    this.getPosition(this.position_params);
    if (this.$store.state.companyStructure.departmentTree.length == 0) {
      this.getDepartmentTree(true);
    } else {
      this.departmentList = this.$store.state.companyStructure.departmentTree;
      this.departmentListFilter = this.$store.state.companyStructure.departmentTree;
    }
  },
  watch: {
    items(newValue, oldValue) {
      if (this.tabActive === 1) {
        this.listItems = this.memberListFilter.filter(item => newValue.includes(item.id));
      }

      if (this.tabActive === 2) {
        var listItems = [];
        let it = this.departmentList.filter(item => newValue.includes(item.id));
        if (it.length > 0) {
          listItems = listItems.concat(it);
        }

        for (let i = 0; i < this.departmentList.length; i++) {
          let itc = this.departmentList[i].children.filter(item => newValue.includes(item.id));
          if (itc.length > 0) {
            listItems = listItems.concat(itc);
          }

          // if (newValue.includes(this.departmentList[i]['id'])) {
          //   for (let x = 0; x < this.departmentList[i].children.length; x++) {
          //     listItems.push(this.departmentList[i].children[x]);
          //   }
          // }
        }

        const unique = [...new Map(listItems.map((m) => [m.id, m])).values()];
        this.listItems = unique;
      }

      if (this.tabActive === 3) {
        this.listItems = this.positionList.filter(item => newValue.includes(item.id));
      }
    },
    search(newValue, oldValue) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(async () => {
        if (this.tabActive === 1) {
          this.member_params.keyword = newValue;
          this.getEmployees(this.member_params);
        }
        if (this.tabActive === 2) {
          this.departmentListFilter = this.departmentList.filter((elm) => elm.name.toUpperCase().trim().includes(newValue.trim().toUpperCase()));
        }
        if (this.tabActive === 3) {
          this.positionListFilter = this.positionList.filter((elm) => elm.name.toUpperCase().trim().includes(newValue.trim().toUpperCase()));
        }
      }, 500);
    }
  },
  data() {
    return {
      tabActive: 1,
      member_params: {
        page: 1,
        per_page: 20,
        keyword: "",
        status: 1
      },
      position_params: {
        keyword: ""
      },
      member_last_page: 1,
      memberList: [],
      memberListFilter: [],
      positionList: [],
      positionListFilter: [],
      departmentList: [],
      departmentListFilter: [],
      items: [],
      listItems: [],
      forms: {
        name: '',
        model_ids: [],
        model_type: GROUP_CHAT_TYPE.USER,
        group_id : this.$route.params.messenger_id
      },
      search: '',
      TYPE_ADD_GROUP_MEMBER,
      type: TYPE_ADD_GROUP_MEMBER.CREATE_GROUP,
      group_update_id : null,
      time: null
    };
  },
  methods: {
    beforeOpened(event) {
      this.forms.name = ''
      this.forms.model_ids = [];
      this.items = [];
      this.type = event.params.type;
      this.group_update_id = event.params.group_id;
      this.getEmployees(this.member_params);
    },

    beforeClosed(event) {
    },

    async getEmployees(params = {}) {
      params.page = this.member_params.page;
      await EmployeeService.getEmployees(params).then(res => {
        if (res && res.data) {
          this.memberList = res.data
          this.memberListFilter = this.memberListFilter.concat(this.memberList);
          const unique = [...new Map(this.memberListFilter.map((m) => [m.id, m])).values()];
          this.memberListFilter = unique;
          this.member_last_page = res.meta.last_page;
        }
      });
    },

    async getPosition(params = {}) {
      await SettingService.getPositions(params).then(res => {
        if (res && res.data) {
          this.positionList = res.data;
          this.positionListFilter = res.data;
        }
      });
    },

    chosenTab(tab) {
      this.tabActive = tab;
      this.items = [];
      this.search = '';
    },

    removeItem(item, index) {
      this.items.splice(index, 1);
    },

    async createChat() {
      this.forms.model_type = GROUP_CHAT_TYPE.USER

      if (this.tabActive === 2) {
        this.forms.model_type = GROUP_CHAT_TYPE.DEPARTMENT
      }

      if (this.tabActive === 3) {
        this.forms.model_type = GROUP_CHAT_TYPE.POSITION
      }

      const formData = new FormData();

      formData.append("name", this.forms.name);
      formData.append("model_type", this.forms.model_type);

      for (let i = 0; i < this.listItems.length; i++) {
        formData.append("model_ids[]", this.listItems[i]['id']);
      }

      await ChatService.createGroupChat(formData).then(res => {
        this.show = false;
        if (res.status) {
          this.$store.dispatch("alerts/success", 'Bạn tạo nhóm chat thành công.');
          this.$store.dispatch("chat/getChatGroups");
          this.$modal.hide('add-user-modal')
          this.$router.push("/messenger/" + res.data.id);
        } else {
          this.$store.dispatch("alerts/error", 'Bạn tạo nhóm chat không thành công. Nguyên nhân: ' + res.message);
        }
      });
    },

    async addMoreMember() {
      this.forms.model_type = GROUP_CHAT_TYPE.USER

      if (this.tabActive === 2) {
        this.forms.model_type = GROUP_CHAT_TYPE.DEPARTMENT
      }

      if (this.tabActive === 3) {
        this.forms.model_type = GROUP_CHAT_TYPE.POSITION
      }

      const formData = new FormData();

      if(this.type === TYPE_ADD_GROUP_MEMBER.UPDATE_GROUP){
        formData.append("group_id", this.group_update_id);
      }

      formData.append("model_type", this.forms.model_type);

      for (let i = 0; i < this.listItems.length; i++) {
        formData.append("model_ids[]", this.listItems[i]['id']);
      }


      await ChatService.addMemberGroupChat(formData).then(res => {
        this.show = false;
        if (res.status) {
          this.$store.dispatch("alerts/success", 'Thêm thành viên thành công.');
          this.$modal.hide('add-user-modal')
        } else {
          this.$store.dispatch("alerts/error", 'Thêm thành viên không thành công. Nguyên nhân: ' + res.message);
        }
      });
    },

    async getDepartmentTree() {
      await SettingService.getDepartmentTree({keyword: this.keyword}).then(res => {
        if (res && res.data) {
          this.departmentList = res.data;
          this.departmentListFilter = res.data;
          this.$store.commit("updateDepartmentTree", res.data);
        }
      });
    },

    handleUpdateItem(items) {
      this.items = items;
    }

  }

}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
}
.md-checkbox .md-checkbox-container .md-ripple {
  overflow: hidden;
}
.add-user-modal {
  .md-list-item-content{
    min-height: auto;
  }
  .md-button{
    font-size: inherit;
  }
  .md-ripple {
    padding: 6px 10px !important;
  }
  .content {
    height: 350px;
    overflow-y: scroll;
  }

  .md-list-item:focus {
    background-color: rgb(241, 242, 244);
    border-radius: 8px;

    .check-icon {
      width: 20px;
      opacity: 1;
    }
  }

  .check-icon {
    width: 20px;
    opacity: 0;
    color: rgb(48, 169, 96);
  }

  .input-auto-complete {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border: none;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px 12px;
    min-height: auto !important;
    border-bottom: 1px solid #F0F0F0;

    .item-select {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 24px;
      margin: 5px 5px 0 0;
      background-color: rgb(236, 247, 231);
      border: none;
      border-radius: 6px;
      box-sizing: content-box;
      padding: 2px 8px;
      outline: 0px;
      font-weight: 500;
      overflow: hidden;
      color: rgb(48, 169, 96);
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
      width: fit-content;

      .close-icon {
        border-radius: 50%;
        margin-left: 2px;
        width: auto;
        height: auto;
        background: transparent;
        i {
          font-size: 18px !important;
        }
      }
    }

    .input-search-field {
      font-size: 14px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      width: 0px;
      min-width: 30px;
      -webkit-box-flex: 1;
      flex-grow: 1;
      border: 0px;
      margin: 0px;
      outline: 0px;
    }
  }

  .create-post-btn {
    width: 100% !important;
  }
}


</style>
