<template>
  <div class="group-info-wrapper">
    <div class="group-info-container">
      <div class="info-text">
        <div class="heading-600 mb-10px text-2-line">{{ group.name }}</div>
        <div class="detail-info">
          <div class="content-left">
            <span class="material-icons">security</span>
          </div>
          <span :class="typeNameAndColor.classColor">{{typeNameAndColor.typeName}}</span>
        </div>
        <div class="detail-info">
          <div class="content-left">
            <span class="material-icons">group</span>
          </div>
          <span>{{ group.member_count }} thành viên</span>
        </div>
        <div class="text-300-default mt-10px" v-if="group.members">
          <div>
            <div class="avatar-wrapper" v-for="groupMem in group.members" :key="groupMem.id">
              <img :src="groupMem.avatar_url">
            </div>
          </div>
        </div>
      </div>
      <div class="info-image">
        <img :src="group.avatar_url || '/img/group/group_image_low.jpg'">
      </div>
    </div>
  </div>
</template>

<script>
import {helpers} from "../../../helper/helpers";

export default {
  props: {
    group: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    typeNameAndColor() {
      return helpers.getGroupTypeNameAndColor(this.group.type);
    }
  }
};
</script>

<style lang="css"></style>
