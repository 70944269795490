import axios from 'axios';
// import Api from '../config/Api';

const domain = process.env.VUE_APP_API_BASE_URL + '/v1';
const adminDomain = process.env.VUE_APP_ADMIN_API_BASE_URL;

export default class BaseService {
    /*constructor() {
        this.setAuth();
    }

    setAuth() {
        axios.interceptors.request.use(function (config) {
            console.log('axios.interceptors');
            const access_token = localStorage.getItem("access_token");
            const XsrfToken = localStorage.getItem("X_XSRF_TOKEN");

            if (access_token) {
                config.headers.Authorization = `Bearer ${access_token}`;
                config.headers.X_XSRF_TOKEN = XsrfToken;
            }

            return config;
        });
    }*/

    static async getByUrl(uri, params = {} , apiType = 'USER') {
        try {
            return await axios.get(uri, {params: params});
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async get(uri, params = {} , apiType = 'USER') {
        try {
            return await axios.get(this.getUrl(uri, apiType), {params: params});
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async post(uri, params = {} , apiType = 'USER') {
        try {
            return await axios.post(this.getUrl(uri, apiType), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async put(uri, params = {} , apiType = 'USER') {
        try {
            return await axios.put(this.getUrl(uri, apiType), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async patch(uri, params = {} , apiType = 'USER') {
        try {
            return await axios.patch(this.getUrl(uri, apiType), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async show(uri) {
        try {
            return await axios.get(this.getUrl(uri));
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async delete(uri) {
        try {
            return await axios.delete(this.getUrl(uri));
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static getUrl(uri, apiType) {
        let domainUrl = domain;
        if (apiType === 'ADMIN') {
            domainUrl = adminDomain;
        }
        return domainUrl + uri;
    }

    static errorMsg(e) {
        if (e.response.status === 401 || e.response.status === 422) {
            window.app.$store.dispatch("redirectToLogin");
            return;
        }
        else if (e.response.status === 403) {
            window.app.$router.push({name: "Error page"});
        }
        return {data: e.response.data};
    }
}
