<template class="group-detail__container">
  <div class="md-layout group-detail-wrapper">
    <vue-element-loading :active="isLoad" is-full-screen/>
    <div class="w_100 group-head">
      <md-card class="p-0px m-0px border-radius-0px">
        <div class="wallpaper">
          <vue-element-loading :active="changeWallpaperLoading" spinner="bar-fade-scale"/>
          <div class="update-wallpaper-main" @click="openPopupChangeWallpaper">
            <img src="/img/group/image-default-icon-white.svg" alt="">
            <div class="heading-300">Cập nhật ảnh bìa</div>
            <input type="file" accept="image/jpg, image/png, image/jpeg" hidden id="update-wallpaper-btn"
                   @change="changeWallpaper">
          </div>
          <img class="logo cursor-pointer" @click="viewWallpaper"
               :src="group.avatar && group.avatar.url ? group.avatar.url : '/img/group/group_wallpaper.jpg'">
        </div>
        <md-card-header style="position: relative" class="group-header">
          <md-list-item class="group-header-info">
            <div class="md-list-item-text">
              <span class="group-name">{{ group.name }}</span>
              <p class="neutral_2">
                <span class="text-400-default" :class="group.classColor">{{ group.typeName }}</span>
                <span>・</span>
                <span class="text-400-default"> {{ group.member_count }} thành viên </span>
              </p>
            </div>
            <template>
              <md-button 
                class="md-raised add-member-btn neutral_2" 
                @click="$modal.show('create-chat-social-modal', {groupId : $route.params.group_id})"
                v-if="group.chat_group_id == null && group.is_admin == 1"
              >
                <md-icon class="mr-5px">chat</md-icon>
                Tạo đoạn chat cộng đồng
              </md-button>
              <md-button 
                class="md-raised add-member-btn neutral_2" 
                @click="goToChatSocial(group.chat_group_id)"
                v-if="group.chat_group_id !== null"
              >
                <md-icon class="mr-5px">chat</md-icon>
                Chat cộng đồng
              </md-button>
            </template>
            <template v-if="memberStatus == 1">
              <md-button class="md-raised add-member-btn neutral_2" @click="showAddMemberModal">
                <img class="cursor-pointer bookmark pr-15px neutral_4" src="/img/group/add_user.svg">
                Thêm người
              </md-button>
            </template>
            <template v-if="memberStatus == 2">
              <md-button class="disabled add-member-btn neutral_2">
                Chờ phê duyệt
              </md-button>
            </template>
            <template v-if="memberStatus == 3">
              <template v-if="group.join_policy != 2">
                <md-button class="md-raised add-member-btn neutral_2 btn-active" @click="requestMember()">
                  Gửi yêu cầu tham gia nhóm
                </md-button>
              </template>
              <template v-if="group.join_policy == 2">
                <md-button class="md-raised add-member-btn neutral_2 btn-active" @click="requestMember()">
                  Tham gia nhóm
                </md-button>
              </template>
            </template>
            <div v-if="tab_active != 6" class="ml-10px more-btn-wrapper">
              <md-button id="more-setting-btn" v-on-clickaway="onClickMoreBtnAway" @click="toggleGroupMoreBtn"
                         class="md-raised more-setting-btn">
                <md-icon class="cursor-pointer neutral_2">more_horiz</md-icon>
              </md-button>
              <div class="more-dropdown" v-show="moreBtnActive">
                <md-list class="p-0px" style="z-index: 1000">
                  <md-list-item v-on:click="redirectToGroupSetting" v-if="group.is_admin == true">
                    <md-icon class="group-more-icon">settings</md-icon>
                    <div class="md-list-item-text">
                      <div class="text-400-medium">Cài đặt nhóm</div>
                    </div>
                  </md-list-item>
                  <md-list-item v-on:click="openNotificationSettingPopup">
                    <md-icon class="group-more-icon">notifications</md-icon>
                    <div class="md-list-item-text">
                      <div class="text-400-medium">Cài đặt thông báo</div>
                    </div>
                  </md-list-item>
                  <md-divider v-if="group.is_admin == true"></md-divider>
                  <md-list-item v-on:click="handlePinGroup"
                                v-if="group.type != 0 && group.type != 3 && group.type != 4">
                    <md-icon class="group-more-icon">push_pin</md-icon>
                    <div class="md-list-item-text">
                      <div class="text-400-medium">{{ group.is_pin == 1 ? 'Bỏ ghim nhóm' : 'Ghim nhóm' }}</div>
                      <div class="text-300-default neutral_2 mt-5px" v-if="group.is_pin != 1">Ghim vào lỗi tắt</div>
                    </div>
                  </md-list-item>
                  <md-divider v-if="group.type != 0 && group.type != 3 && group.type != 4"></md-divider>
                  <md-list-item v-on:click="showConfirmOutGroupModal">
                    <md-icon class="group-more-icon">logout</md-icon>
                    <div class="md-list-item-text">
                      <span class="text-400-medium">Rời khỏi nhóm</span>
                    </div>
                  </md-list-item>
                </md-list>
              </div>
            </div>
          </md-list-item>

        </md-card-header>
        <ul class="nav nav-tabs tab-custom">
          <template v-if="group && memberStatus == 1">
            <li class="cursor-pointer" :class="{'active':tab_active === 1}">
              <router-link class="text-400-medium" :to="getGroupTabLink('')">Bảng tin</router-link>
            </li>
<!--            <li class="cursor-pointer" :class="{'active':tab_active === 2}">-->
<!--              <router-link class="text-400-medium" :to="getGroupTabLink('notification')">Thông báo</router-link>-->
<!--            </li>-->
            <li v-if="group.type === 0" class="cursor-pointer" :class="{'active':tab_active === 5}">
              <router-link class="text-400-medium" :to="getGroupTabLink('work_process')">Quy trình làm việc
              </router-link>
            </li>
            <li class="cursor-pointer" :class="{'active':tab_active === 3}">
              <router-link class="text-400-medium" :to="getGroupTabLink('members')">Thành viên</router-link>
            </li>
            <li class="cursor-pointer" :class="{'active':tab_active === 4}">
              <router-link class="text-400-medium" :to="getGroupTabLink('files')">File đa phương tiện</router-link>
            </li>
          </template>
          <li v-if="group && memberStatus != 1" class="cursor-pointer" :class="{'active':tab_active === 6}">
            <router-link class="text-400-medium" :to="getGroupTabLink('information')">Thông tin nhóm</router-link>
          </li>
        </ul>
      </md-card>
    </div>

    <div class="group-content w_100">
      <template v-if="tab_active === 1">
        <div class="md-layout" style="padding: 15px;">
          <div class="md-layout-item" style="padding: 0; z-index: 0">
            <template v-if="postId == undefined && group.id">
              <create-post />
            </template>
            <div class="loading-holder-post post-layout" v-if="loadPageHolder">
              <div class="container-metadata">
                <div class="loader-shimmer loading-image-holder">
                </div>
                <div class="metadata-content">
                    <p class="loading-holder text-1-line loader-shimmer"></p>
                    <p class="loading-holder text-2-line loader-shimmer"></p>
                    <p class="loading-holder text-1-line loader-shimmer"></p>
                </div>
              </div>
            </div>
            <template v-if="postId != undefined">
              <post-layout :post=postDetail :isPost="true"></post-layout>
            </template>
            <template v-else>
              <vue-loadmore
                  :on-loadmore="onLoad"
                  :finished="finished"
                  :loading-text="loadingText"
                  :finished-text="finishedText">
                <div v-for="post of postGroup" :key="post.post.id" class="new-feed-item" :data-id="post.post.id"
                     :data-read="post.post.is_read">
                  <post-layout :post=post :isGroup="true"></post-layout>
                </div>
              </vue-loadmore>
            </template>
          </div>
          <div class="company-info-section">
            <group-info :description="group.description" :data="{type:'group',id:groupId}" :image-list="topImageList"></group-info>
          </div>
        </div>
      </template>
      <template v-if="tab_active === 2">
        <div class="text-center">
          <img class="p-15px" src="/img/group/under_construction.svg" style="width: 236px">
          <p> Tính năng đang phát triển</p>
        </div>
      </template>
      <template v-if="tab_active === 3">
        <div class="w_100 group-tab-content">
          <div class="tab-content-main background_neutral_white">
            <div class="title-main">
              <div class="heading-700 mt-20px">Thành viên</div>
              <div class="search-file-container">
                <img class="search-icon" src="/img/search-icon.svg">
                <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group"
                       v-model="memberData.searchMemberInput" @keyup="searchForTabMember">
              </div>
            </div>

            <div class="admin-list w_100">
              <div class="heading-500 neutral_2">
                Quản trị viên ({{ memberData.totalAdmin }})
              </div>
              <md-list class="md-double-line">
                <md-list-item v-for="admin in memberData.adminMemberList" :key="admin">
                  <md-avatar>
                    <router-link :to="getProfileLink(admin.id)">
                      <image-template :size="'small'" :image="admin.avatar_url" :thumbnail="admin.thumbnail"/>
                    </router-link>
                  </md-avatar>
                  <div class="md-list-item-text">
                    <router-link :to="getProfileLink(admin.id)" class="member-name">
                      <span class=" text-400-medium neutral_1 cursor-pointer hover-link">{{ admin.fullname }}</span>
                    </router-link>
                    <span class="text-300-default neutral_2" v-if="!admin.added_by || admin.id === admin.added_by.id">Người tạo nhóm</span>
                    <span class="text-300-default neutral_2" v-else>
                      Được thêm bởi
                      <span @click="redirectToProfile(admin.added_by.id)"
                            class="text-300-medium cursor-pointer hover-link">{{ admin.added_by.fullname }}</span>
                      vào ngày
                      <span class="text-300-medium">{{ admin.added_at }}</span>
                    </span>
                  </div>
                </md-list-item>
              </md-list>
            </div>
            <div class="member-list w_100">
              <div class="heading-500 neutral_2">
                Thành viên ({{ memberData.totalMember }})
              </div>

              <vue-loadmore
                  :on-loadmore="onLoadForMember"
                  :finished="memberData.finished"
                  :loading-text="loadingText"
                  :finished-text="''">
                <md-list-item v-for="member in memberData.normalMemberList" :key="member">
                  <md-avatar>
                    <router-link :to="getProfileLink(member.id)">
                      <image-template :size="'small'" :image="member.avatar_url" :thumbnail="member.thumbnail"/>
                    </router-link>
                  </md-avatar>
                  <div class="md-list-item-text">
                    <router-link :to="getProfileLink(member.id)" class="member-name">
                      <span class="text-400-medium neutral_1 cursor-pointer hover-link">{{ member.fullname }}</span>
                    </router-link>
                    <span class="text-300-default neutral_2" v-if="member.added_by">
                      Được thêm bởi
                      <span @click="redirectToProfile(member.added_by.id)"
                            class="text-300-medium  cursor-pointer hover-link">{{
                          member.added_by.fullname ? member.added_by.fullname : ''
                        }}</span>
                      vào ngày
                      <span class="text-300-medium">{{ member.added_at }}</span>
                    </span>
                  </div>
                </md-list-item>
              </vue-loadmore>

            </div>
          </div>
        </div>
      </template>
      <template v-if="tab_active === 4">
        <div class="w_100 group-tab-content">
          <div class="tab-content-main background_neutral_white">
            <div class="title-main">
              <div class="heading-700 mt-20px">File</div>
              <div class="search-file-container" v-show="file_media_active === 1">
                <img class="search-icon" src="/img/search-icon.svg">
                <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group"
                       v-model="fileData.searchFileInput" @keyup="getFiles(true)">
              </div>
            </div>

            <div class="group-tab-content-tablayout">
              <ul class="nav nav-tabs tab-custom file-media-tab">
                <li class="cursor-pointer"
                    :class="{'active text-400-semibold' :file_media_active === 1, 'text-400-medium': file_media_active !== 1}"
                    @click="changeFileMediaTab('files')">
                  <a>Tất cả file</a></li>
                <li class="cursor-pointer"
                    :class="{'active text-400-semibold':file_media_active === 2, 'text-400-medium': file_media_active !== 2}"
                    @click="changeFileMediaTab('image')">
                  <a>Ảnh/Video</a></li>
              </ul>
            </div>

            <div class="file-list-main w_100" v-if="file_media_active === 1">
              <p v-if="fileData.fileList.length === 0" class="heading-400 text-center">Không có file nào</p>

              <vue-loadmore
                  :on-refresh="onRefreshForFile"
                  :on-loadmore="onLoadForFile"
                  :finished="fileData.fileFinished"
                  :loading-text="loadingText"
                  :finished-text="''">
                <file-content :files="fileData.fileList"></file-content>
              </vue-loadmore>
            </div>

            <div class="image-list-main w_100" v-if="file_media_active === 2">
              <p v-if="fileData.fileImageList.length === 0" class="heading-400 text-center">Không có file nào</p>
              <vue-loadmore
                  :on-refresh="onRefreshForFileImage"
                  :on-loadmore="onLoadForFileImage"
                  :finished="fileData.fileImageFinished"
                  :loading-text="loadingText"
                  :finished-text="''">
                <div class="image-list-content">
                  <div class="image-item cursor-pointer" v-for="item in fileData.fileImageList" :key="item" @click="redirectToPostDetail(item)">
                    <image-template :height="'180px'" :class="'image-content'" :size="'medium'" :image="item.path" :thumbnail="item.thumbnail"/>
                  </div>
                </div>
              </vue-loadmore>
            </div>
          </div>
        </div>
      </template>
      <template v-if="tab_active === 5">
        <div class="md-layout" style="padding: 15px;">
          <div class="company-info-section work_process">
            <md-card class="group-info-block background_neutral_white">
              <md-list-item class="p-0px" @click="clickCategory(category)">
                <div class="md-ripples">
                  <md-avatar class="logo-toolbar"
                             :style="{'background-color': category.color ? category.color : '#EDEFF5' }"
                             style="border: 1px solid #D8DAE5">
                    <span class="material-icons">{{ category.icon ? category.icon : 'star' }}</span>
                  </md-avatar>
                  <div class="md-list-item-text ml-10px">
                    <span class="title-sidebar text-nowrap heading-400">{{ category.title }}</span>
                  </div>
                </div>

              </md-list-item>
              <h3 class="subtitle-sidebar text-500-medium">Hạng mục con</h3>
              <template v-if="categories">
                <div v-for="cat in categories" :key="cat.id">
                  <md-list-item class="p-0px cursor-pointer" v-bind:class="chosenCategory.id === cat.id ? 'active' : ''">
                    <template v-if="cat.subcategories && cat.subcategories.length > 0">
                      <md-icon class="cursor-pointer m-0px group-category-next"
                               :class="'group_navigate_next_'+cat.id" :data-id="cat.id"
                               style="margin: 3px 0px 0px 0px !important;">navigate_next
                      </md-icon>
                      <md-icon class="cursor-pointer m-0px group-category-next display-none"
                               :class="'group_keyboard_arrow_down_'+cat.id" :data-id="cat.id"
                               style="margin: 3px 0px 0px 0px !important;">keyboard_arrow_down
                      </md-icon>
                    </template>
                    <div class="md-ripples" @click="clickCategory(cat)">
                      <md-avatar class="logo-toolbar logo-group"
                                 :style="{'background-color': cat.color ? cat.color : '#EDEFF5' }"
                                 >
                        <!--                        <span class="material-icons">{{ cat.icon ? cat.icon : 'star' }}</span>-->
                      </md-avatar>
                      <div class="md-list-item-text ml-10px">
                        <span class="title-sidebar text-nowrap heading-400 ">{{ cat.title }}</span>
                      </div>
                    </div>
                  </md-list-item>
                  <div style="margin-left: 36px" class="display-none"
                       :class="'group_category_'+cat.id">
                    <md-list-item class="p-0px cursor-pointer"
                                  v-bind:class="chosenCategory.id === ca.id ? 'active' : ''"
                                  v-for="ca in cat.subcategories" :key="ca.id" @click="clickCategory(ca)">
                      <div class="md-ripples">
                        <md-avatar class="logo-toolbar logo-group"
                                   :style="{'background-color': ca.color ? ca.color : '#EDEFF5' }"
                                   style="border: 1px solid #D8DAE5">
                          <!--                        <span class="material-icons">{{ ca.icon ? ca.icon : 'star' }}</span>-->
                        </md-avatar>
                        <div class="md-list-item-text ml-10px">
                          <span class="title-sidebar text-nowrap heading-400 ">{{ ca.title }}</span>
                        </div>
                      </div>

                    </md-list-item>

                  </div>
                </div>
              </template>
            </md-card>
          </div>
          <div class="md-layout-item ml-15px" style="padding: 0">
            <md-card class="md-layout-item background_neutral_white" style="padding: 0">
              <div class="mt-20px m-15px" v-if="chosenCategory">
                <md-list-item class="header-category ml-0px">
                  <div class="md-list-item-text">
                    <div class="heading-600 neutral_1 text-center">{{ chosenCategory.title }}</div>
                    <span class="mt-10px neutral_2 text-center">{{ chosenCategory.description }}</span>
                    <div class="line"></div>
                  </div>
                </md-list-item>
                <div v-if="chosenCategory" class="text-html my-15px" v-html="chosenCategory.content"></div>
                <template v-if="medias && medias.length > 0">
                  <div class="text-400-semibold">Tài liệu đính kèm</div>
                  <div class="file-list-main post-file w_100 mt-10px">
                    <file-content :files="medias"></file-content>
                  </div>
                </template>
              </div>
            </md-card>
          </div>
        </div>
      </template>
      <template v-if="tab_active === 6">
        <div class="md-layout p-15px">
          <div class="md-layout-item p-0px">
            <div class="post-layout">
              <md-card class="p-15px mb-20px about" v-if="memberStatus == 2" style="background-color: #3B9EC9; color: white">
                <div>
                  <md-icon style="color: white">info</md-icon>
                  <span class="text-400-medium">Vui lòng nhắn tin tới các Admin của nhóm để được phê duyệt vào nhóm</span>
                </div>
              </md-card>
              <md-card class="p-15px about">
                <div class="heading-600 mt-15px">Thông tin nhóm</div>
                <hr class="mt-15px">
                <div class="my-10px" v-if="group.description">{{ group.description }}</div>
                <md-list-item>
                  <md-avatar>
                    <img src="/img/group/group_type.svg">
                  </md-avatar>

                  <div class="md-list-item-text">
                    <span class="text-500-medium mb-5px" :class="group.classColor">{{ group.typeName }}</span>
                    <p>Nhóm làm việc và báo cáo hằng ngày của từng phòng ban chuyên biệt.
                    </p>
                  </div>
                </md-list-item>
                <md-list-item>
                  <md-avatar>
                    <img src="/img/group/group_created_at.svg">
                  </md-avatar>

                  <div class="md-list-item-text">
                    <span class="text-500-medium mb-5px">Ngày tạo nhóm</span>
                    <p>{{ group.created_at }}</p>
                  </div>
                </md-list-item>
              </md-card>
              <md-card class="mt-30px p-15px about">
                <div class="heading-600 mt-15px">Quản trị viên</div>
                <hr class="mt-15px">
                <div class="mt-20px admin-user-list__container">
                  <div class="admin-user-item__main" v-for="admin in memberData.adminMemberList" :key="'admin-info' + admin.id">
                    <group-admin-info :user-info="admin" />
                    <hr>
                  </div>
                </div>
              </md-card>
            </div>
          </div>
          <div class="company-info-section">
            <group-info :description="group.description" :data="{type:'group',id:groupId}"></group-info>
          </div>
        </div>
      </template>
    </div>
    <update-post-modal @update-post="updatePostData" />
    <notification-setting-modal @toggle-notification="toggleNotification"/>
    <create-chat-social-modal/>
    <update-comment-modal @update-comment="updateCommentData" />
  </div>
</template>
<script>
import $ from "jquery";
import CreatePost from "../../../Components/Post/UserCreatePost/createPost";
import PostLayout from "../../../Layout/Newfeed/PostLayout";
import AddGroupMemberModal from "@/pages/Components/Modal/AddGroupMemberModal";
import FileContent from "@/pages/Components/Post/Content/FileContent";
import GroupService from "@/store/services/GroupService";

import {helpers} from "../../../../helper/helpers";
import PostService from "../../../../store/services/PostService";
import GroupInfo from "../../../Components/RightBar/GroupInfo";
import {mixin as clickaway} from 'vue-clickaway';
import UpdatePostModal from "../../../Components/Modal/UpdatePostModal";
import UpdateCommentModal from "../../../Components/Modal/UpdateCommentModal";
import NotificationSettingModal from "../../../Components/Modal/NotificationSettingModal";
import CreateChatSocialModal from "../../../Components/Modal/CreateChatSocialModal";
import moment from "moment";
import CategoryService from "../../../../store/services/CategoryService";
import ImageTemplate from "../../../../components/ImageTemplate";
import GroupAdminInfo from "./GroupAdminInfo";

const POST_TAB = 1;
const NOTIFICATION_TAB = 2;
const MEMBER_TAB = 3;
const FILE_TAB = 4;
const WORK_PROCESS_TAB = 5;
const INFORMATION_TAB = 6;
export default {
  mixins: [clickaway],
  components: {
    GroupAdminInfo,
    GroupInfo,
    FileContent,
    PostLayout,
    CreatePost,
    UpdatePostModal,
    UpdateCommentModal,
    NotificationSettingModal,
    ImageTemplate,
    CreateChatSocialModal
  },
  watch: {
    '$route.params.group_id'(to, from) {
      this.loadPageHolder = true;
      this.groupId = this.$route.params.group_id;
      this.postId = this.$route.params.post_id;
      this.params = {
        page: 1,
        per_page: 10,
        group_id: this.$route.params.group_id
      };
      this.getGroup();
      this.resetGroupPage();
      this.getPosts();
    },
    '$route.params.group_tab'(to, from) {
      let groupTab = this.$route.params.group_tab;
      if (groupTab) {
        switch (groupTab) {
          case "members":
            this.tab_active = MEMBER_TAB;
            if (this.memberData.normalMemberList.length === 0) {
              this.getMembers();
              this.getAdminList();
            }
            break;
          case "files":
            this.tab_active = FILE_TAB;
            if (this.fileData.fileList.length === 0) {
              this.getFiles();
            }
            if (this.fileData.fileImageList.length === 0) {
              this.getImageVideo();
            }
            break;
          case "work_process":
            this.tab_active = WORK_PROCESS_TAB;
            this.getCategories();
            break;
          case "notification":
            this.tab_active = NOTIFICATION_TAB;
            break;
          case "information":
            this.tab_active = INFORMATION_TAB;
            break;
          case "post":
            this.tab_active = POST_TAB;
            this.getPost();
            break;
        }
      } else {
        this.tab_active = POST_TAB;
        this.getTopImage();
        this.getPost();
        this.getPosts();
      }
    },

    '$route.params.post_id'(to, from) {
      this.loadPageHolder = false;
      this.postId = this.$route.params.post_id;
      this.getPost();
      this.userReadPost(this.postId);
    },

    '$store.state.group.groupData'(newV){
      this.group = newV;
    },
    '$store.state.post.changePostFlag'(newV){
      if (newV == 1 || newV == 2) {
        this.getPosts();
        this.$store.dispatch("changePostFlag", 0);
      }
    },

    '$store.state.notificationSetting.settingModel'(newV) {
      this.toggleNotification(newV);
    },

    '$route.query.mediaTab'(newV) {
      if (newV && newV === 'image') {
        this.file_media_active = 2;
      } else if (newV && newV === 'files') {
        this.file_media_active = 1;
      }
    }
  },
  created(){
    this.loadPageHolder = true;
  },
  async mounted() {
    this.$store.dispatch("changePostFlag", 0);
    await this.getGroup();
    localStorage.setItem('link', this.$route.fullPath);

    $(document).on("click", ".group-category-next", (event) => {
      const categoryId = event.target.getAttribute("data-id");
      this.showHideCategory(categoryId);
      event.stopPropagation();
      event.stopImmediatePropagation();
    });

    window.addEventListener("scroll", (e) => {
      let pageYOffset = window.pageYOffset;
      let totalItemHeight = 600
      for (const item of $(".new-feed-item")) {
        totalItemHeight += item.offsetHeight
        let id = item.dataset.id;
        let isRead = item.dataset.read
        if (totalItemHeight <= pageYOffset && (isRead == 'false' || !isRead) && this.postIdsIsRead.indexOf(id) === -1) {
          this.userReadPost(id);
          this.postIdsIsRead.push(id);
        }
      }
    })
    if (this.$route.query.mediaTab === 'image') {
      this.file_media_active = 2;
    }
  },

  data() {
    return {
      tab_active: 6,
      file_media_active: 1,
      fileData: {
        searchFileInput: "",
        fileList: [],
        fileImageList: [],
        filePage: 1,
        fileImagePage: 1,
        fileLastPage: 1,
        fileImageLastPage: 1,
        fileFinished: false,
        fileImageFinished: false,
      },
      memberData: {
        searchMemberInput: "",
        adminMemberList: [],
        normalMemberList: [],
        memberPage: 1,
        totalMember: 0,
        totalAdmin: 0,
        finished: false,
        lastPage: 1
      },
      workProcess: {},
      groupId: this.$route.params.group_id,
      postId: this.$route.params.post_id,
      group: {},
      postGroup: [],
      postDetail: {},
      changeWallpaperLoading: false,
      params: {
        page: 1,
        per_page: 10,
        group_id: this.$route.params.group_id
      },
      finished: false,
      last_page: 1,
      loadingText: 'Đang tải',
      finishedText: 'Không còn bài viết mới nào.',
      moreBtnActive: false,
      isLoad: false,
      memberStatus: 3,   // 1: Đã tham gia, 2: Đang request, 3: Chưa tham gia
      category: {},
      chosenCategory: {},
      categories: [],
      medias: [],
      postIdsIsRead: [],
      topImageList: [],
      loadPageHolder: false
    };
  },

  methods: {
    changeTab(tab) {
      this.tab_active = tab;
    },
    changeFileMediaTab(tab) {
      this.$router.push({path: this.$route.fullPath, query: {mediaTab: tab} });
    },
    showAddMemberModal() {
      this.$modal.show('add-group-member-modal', {groupId: this.groupId});
    },
    async getFiles(isSearch) {
      if (this.timer1) {
        clearTimeout(this.timer1);
        this.timer1 = null;
      }
      this.timer1 = setTimeout(() => {
        if (isSearch) {
          this.fileData.filePage = 1;
          this.fileData.fileList = [];
        }

        let param = {
          page: this.fileData.filePage,
          per_page: 10,
          file_type: 1,
          keyword: this.fileData.searchFileInput,
          groupId: this.groupId
        }
        GroupService.getFiles(param).then(res => {
          if (res && res.data) {
            this.fileData.fileLastPage = res.meta.last_page
            this.fileData.fileList.push.apply(this.fileData.fileList, res.data);
          }
        });
      }, 800);
    },
    async getImageVideo() {
      let param = {
        page: this.fileData.fileImagePage,
        per_page: 20,
        file_type: 2,
        groupId: this.groupId
      }
      await GroupService.getFiles(param).then(res => {
        if (res && res.data) {
          this.fileData.fileImageLastPage = res.meta.last_page
          this.fileData.fileImageList.push.apply(this.fileData.fileImageList, res.data);
        }
      });
    },
    async getWorkProcessByCurrentGroup() {
      await GroupService.getWorkProcess({groupId: this.groupId}).then(res => {
        if (res && res.data) {
          this.workProcess = res.data;
        }
      });
    },
    async getGroup() {
      this.isLoad = true;
      const routerName = localStorage.getItem('router_name');
      if(routerName !== 'PostDetail'){
        await GroupService.getGroup({groupId: this.groupId}).then(res => {
          if (res && res.data) {
            this.group = res.data
            this.memberStatus = this.group.member_status ?? 1;
            let typeNameAndColor = helpers.getGroupTypeNameAndColor(this.group.type);
            this.group.typeName = typeNameAndColor.typeName;
            this.group.classColor = typeNameAndColor.classColor;
            this.$store.commit("updateGroupData", this.group);
          }
        });
      } else {
        this.group = this.$store.state.group.groupData;
        this.memberStatus = this.group.member_status ?? 1;
      }
      this.isLoad = false;
      if (this.memberStatus != 1) {
        await this.getAdminList();
        this.tab_active = INFORMATION_TAB;
      }
      if (this.group.type == 0 && this.group.category_id !== null) {
        this.getCategories();
      }

      if (this.memberStatus == 1) {
        let groupTab = this.$route.params.group_tab;
        if (groupTab) {
          switch (groupTab) {
            case "members":
              this.tab_active = MEMBER_TAB;
              this.getMembers();
              this.getAdminList();
              break;
            case "files":
              this.tab_active = FILE_TAB;
              this.getFiles();
              this.getImageVideo();
              break;
            case "work_process":
              this.tab_active = WORK_PROCESS_TAB;
              this.group = this.$store.state.group.groupData;
              this.getCategories();
              break;
            case "notification":
              this.tab_active = NOTIFICATION_TAB;
              break;
            case "infomation":
              this.tab_active = INFORMATION_TAB;
              break;
            case "post":
              this.tab_active = POST_TAB;
              if (this.postId !== undefined) {
                this.getPost();
                this.userReadPost(this.postId);
              }
              break;
          }
        } else {
          this.tab_active = POST_TAB;
          if (this.postId !== undefined) {
            this.getPost();
            this.userReadPost(this.postId);
          } else {
            this.getTopImage();
            this.getPosts();
          }
        }
      }
    },
    async getPosts(loadMore = false) {
      const routerName = localStorage.getItem('router_name');
      if(routerName !== 'PostDetail'){
        await PostService.getPosts(this.params).then(res => {
          this.show = false;
          if (res) {
            this.loadPageHolder = false;
            this.isLoad = false;
            this.last_page = res.meta.last_page
            if (loadMore) {
              this.postGroup = this.postGroup.concat(res.data)
            } else {
              this.postGroup = res.data;
            }
            this.$store.commit("updatePostGroup", this.postGroup);
          }
        });
      } else {
        this.isLoad = false;
        this.postGroup = this.$store.state.group.postGroup;
      }
      this.loadPageHolder = false;
      localStorage.removeItem('router_name');
    },
    async getPost(loadMore = false) {
      this.show = false;
      await PostService.getPost({post_id: this.postId}).then(res => {
        if (res) {
          this.postDetail = res.data
          this.isLoad = false;
        }
      });
      this.userReadPost(this.postId)
    },
    openPopupChangeWallpaper() {
      document.getElementById("update-wallpaper-btn").click();
    },
    changeWallpaper(e) {
      this.changeWallpaperLoading = true;
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("type", 2);
      formData.append("groupId", this.groupId);
      GroupService.updateWallpaper(formData).then(res => {
        this.changeWallpaperLoading = false;
        if (res && res.status && res.data) {
          this.group.avatar.url = res.data.avatar;
          this.$store.dispatch("alerts/success", 'Bạn thay ảnh bìa thành công.');
        } else {
          this.$store.dispatch("alerts/error", 'Thay ảnh thất bại.');
        }
      });
    },
    async getAdminList() {
      if (this.adminTimer) {
        clearTimeout(this.adminTimer);
        this.adminTimer = null;
      }
      this.adminTimer = setTimeout(() => {
        this.memberData.memberPage = 1;
        this.memberData.adminMemberList = [];
        let param = {
          page: 1,
          per_page: 50,
          member_type: 2,
          keyword: this.memberData.searchMemberInput,
          groupId: this.groupId,
          is_admin: true
        }
        GroupService.getMembers(param).then(res => {
          if (res && res.data && res.data.length > 0) {
            this.memberData.adminMemberList = res.data;
            this.memberData.totalAdmin = res.meta.total;
          }
        });
      }, 800);
    },
    searchForTabMember() {
      this.getMembers(true);
      this.getAdminList();
    },
    async getMembers(isSearch) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (isSearch) {
          this.memberData.memberPage = 1;
          this.memberData.lastPage = 1;
          this.memberData.normalMemberList = [];
        }

        let param = {
          page: this.memberData.memberPage,
          per_page: 30,
          member_type: 2,
          keyword: this.memberData.searchMemberInput,
          groupId: this.groupId,
          is_admin: false
        }
        GroupService.getMembers(param).then(res => {
          if (res && res.data && res.data.length > 0) {
            this.memberData.lastPage = res.meta.last_page
            this.memberData.normalMemberList = this.memberData.normalMemberList.concat(res.data);
            this.memberData.totalMember = res.meta.total;
          }
        });
      }, 800);
    },
    onRefresh(done) {
      this.postGroup = [];
      this.params.page = 1;
      this.finished = false;
      this.getPosts();
      done();
    },
    onLoad(done) {
      if (this.params.page < this.last_page) {
        this.params.page++
        this.getPosts(true);
      } else {
        this.finished = true;
      }
      done();
    },
    onRefreshForMember(done) {
      this.memberData.normalMemberList = [];
      this.memberData.memberPage = 1;
      this.memberData.finished = false;
      this.getMembers();
      done();
    },
    onLoadForMember(done) {
      if (this.memberData.memberPage < this.memberData.lastPage) {
        this.memberData.memberPage++
        this.getMembers();
      } else {
        this.memberData.finished = true;
      }
      done();
    },

    onRefreshForFile(done) {
      this.fileData.fileList = [];
      this.fileData.filePage = 1;
      this.fileData.fileFinished = false;
      this.getFiles();
      done();
    },

    onLoadForFile(done) {
      if (this.fileData.filePage < this.fileData.fileLastPage) {
        this.fileData.filePage++
        this.getFiles();
      } else {
        this.fileData.fileFinished = true;
      }
      done();
    },

    onRefreshForFileImage(done) {
      this.fileData.fileImageList = [];
      this.fileData.fileImageLastPage = 1;
      this.fileData.fileImageFinished = false;
      this.getImageVideo();
      done();
    },

    onLoadForFileImage(done) {
      if (this.fileData.fileImagePage < this.fileData.fileImageLastPage) {
        this.fileData.fileImagePage++
        this.getImageVideo();
      } else {
        this.fileData.fileImageFinished = true;
      }
      done();
    },

    redirectToProfile(id) {
      this.$router.push(this.getProfileLink(id));
    },
    getProfileLink(id) {
      return '/profile/' + id;
    },
    getGroupLink(id) {
      return '/group/' + id;
    },
    showConfirmOutGroupModal() {
      this.$modal.show('dialog', {
        title: 'Rời khỏi nhóm?',
        text: 'Bạn có muốn rời khỏi nhóm không?',
        buttons: [
          {
            title: 'Hủy',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Đồng ý',
            handler: () => {
              this.outGroup();
            }
          }
        ]
      })
    },
    outGroup() {
      GroupService.outGroup({groupId: this.groupId}).then(res => {
        if (res && res.status) {
          this.$router.push("/new-feeds");
        }
        this.$modal.hide('dialog')
      });
    },
    handlePinGroup() {
      let isPin = this.group.is_pin == 1 ? 0 : 1;
      let message = this.group.is_pin == 1 ? 'Bỏ ghim nhóm thành công' : 'Ghim nhóm thành công';
      GroupService.pinGroup({groupId: this.groupId, is_pin: isPin}).then(res => {
        this.$store.dispatch("getGroups");
        this.group.is_pin = isPin;
        this.moreBtnActive = false;
        this.$store.dispatch("alerts/success", message);
      });
    },
    toggleGroupMoreBtn() {
      this.moreBtnActive = !this.moreBtnActive;
    },
    onClickMoreBtnAway() {
      this.moreBtnActive = false;
    },
    resetGroupPage() {
      this.tab_active = 1;
      this.file_media_active = 1;
      this.fileData = {
        searchFileInput: "",
        fileList: [],
        fileImageList: [],
        filePage: 1,
        fileImagePage: 1,
        fileLastPage: 1,
        fileImageLastPage: 1,
        fileFinished: false,
        fileImageFinished: false,
      };
      this.memberData = {
        searchMemberInput: "",
        adminMemberList: [],
        normalMemberList: [],
        memberPage: 1,
        totalMember: 0,
        totalAdmin: 0,
        finished: false,
        lastPage: 1
      };
      this.workProcess = {};
      this.groupId = this.$route.params.group_id;
      this.group = [];
      this.postGroup = [];
      this.params = {
        page: 1,
        per_page: 10,
        group_id: this.$route.params.group_id
      };
      this.finished = false;
      this.last_page = 1;

    },
    getGroupTabLink(tab) {
      return "/group/" + this.groupId + "/" + tab;
    },
    async requestMember() {
      await GroupService.requestMember({groupId: this.groupId}).then(res => {
        if (res && res.status) {
          this.memberStatus = 2
          this.$store.dispatch("alerts/success", 'Bạn tạo gửi yêu cầu thành công.');
        }
      });
      if(this.group.join_policy == 2){
        window.location.reload();
      }
    },
    updatePostData(e) {
      for (let i = 0; i < this.postGroup.length; i++) {
        if (this.postGroup[i].post.id === e.id) {
          this.postGroup[i].post = e;
        }
      }
    },
    updateCommentData(e) {
      let postParentId = e.postParentId;
      let commentData = e.commentData;
      for (let i = 0; i < this.postGroup.length; i++) {
        if (this.postGroup[i].post.id === postParentId) {
          for (let j = 0; j < this.postGroup[i].comments.length; j++) {
            if (this.postGroup[i].comments[j].id === commentData.id) {
              this.postGroup[i].comments[j] = commentData;
            }
          }
        }
      }
    },
    fromNow(date) {
      return moment(date).format('DD-MM-YYYY hh:mm:ss');
    },

    getCategories() {
      CategoryService.getCategories().then(res => {
        if (res && res.status) {
          for (var x = 0; x < res.data.categories.length; x++) {
            let categories = res.data.categories[x]['subcategories'];
            for (var i = 0; i < categories.length; i++) {
              if (categories[i]['id'] == this.group.category_id) {
                this.category = categories[i];
                this.chosenCategory = categories[i];
                this.categories = categories[i]['subcategories'];
                break;
              }
            }
          }
          this.getDetailCategory(this.chosenCategory.id)
        }
      });
    },
    clickCategory(category) {
      this.chosenCategory = category
      this.getDetailCategory(category.id)
      this.showHideCategory(category.id)
    },
    getDetailCategory(categoryId){
      CategoryService.getDetailCategory({category_id: categoryId}).then(res => {
        if (res) {
          this.medias = res.data.files;
        }
      });
    },
    redirectToGroupSetting() {
      this.$router.push("/group-setting/" + this.group.id + '/setting');
    },
    convertUrlContent(content) {
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      return content.replace(urlRegex, function (url) {
        return '<a href="' + url + '">' + url + '</a>';
      });
    },
    viewWallpaper() {
      if (this.group.avatar.post_id) {
        this.$router.push('/post/image/' + this.group.avatar.post_id);
      } else {
        return;
      }
    },
    userReadPost(postId) {
      PostService.userReadPost(postId).then(res => {
        this.loadPageHolder = false;
      });
    },
    showHideCategory(categoryId){
      const ele = $(".group_category_" + categoryId);
      const isNone = ele.hasClass('display-none');
      const arrowNext = $(".group_navigate_next_" + categoryId);
      const arrowDown = $(".group_keyboard_arrow_down_" + categoryId);

      if (isNone) {
        ele.removeClass('display-none').addClass('display-block');
        arrowNext.removeClass('display-block').addClass('display-none')
        arrowDown.removeClass('display-none').addClass('display-block')
      } else {
        ele.removeClass('display-block').addClass('display-none');
        arrowNext.removeClass('display-none').addClass('display-block')
        arrowDown.removeClass('display-block').addClass('display-none')
      }
    },
    redirectToPostDetail(media) {
      if(media.single_post_id || this.mediaObject.post_id){
        this.$store.dispatch('refreshPostTotalPage');
        this.$store.state.post.previousDetail = 2;
        let link = media.single_post_id ? this.getLink(media.single_post_id) : this.getLink(this.mediaObject.post_id);
        this.$router.push(link);
      }
    },
    getLink(singlePostId) {
      return '/post/image/' + singlePostId
    },
    openNotificationSettingPopup() {
      this.$modal.show('notification-setting-modal', {groupId:this.groupId, enable_notification: this.group.enable_notification});
    },
    toggleNotification(e) {
      if (!e) {
        return;
      }
      if (e.groupId) {
        this.group.enable_notification = e.enableNotification;
      } else {
        for (let i = 0; i < this.postGroup.length; i++) {
          if (this.postGroup[i].post.id === e.postId) {
            this.postGroup[i].post.enable_notification = e.enableNotification;
            break;
          }
        }
      }
    },
    async getTopImage() {
      let param = {
        per_page: 6,
        file_type: 2,
        groupId: this.groupId
      }
      await GroupService.getFiles(param).then(res => {
        if (res && res.data) {
          this.topImageList = res.data;
        }
      });
    },
    goToChatSocial(chat_id) {
      this.$store.commit('chat/updateChatGroups', this.group);
      this.group.id = chat_id;
      this.group.type = 'GROUP';
      this.$store.commit('chat/updateChatGroupSelected', this.group);
      this.$store.dispatch("chat/getChatGroups");
      this.$router.push("/messenger/" + chat_id);
    }
  }
};
</script>
<style lang="scss">
.group-head {
  //position: fixed;
  //z-index: 100;
}

.group-header {
  .md-ripple {
    height: 40px;
  }

  .group-name {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .more-btn-wrapper {
    position: relative;

    .more-dropdown {
      z-index: 5;
      position: absolute;
      width: 250px;
      right: 0px;
      top: 50px;
      box-shadow: 0px 4px 20px 0px rgb(52 73 94 / 20%);
      border-radius: 5px;
    }

    .md-list-item {
      margin-left: 0px !important;

      .md-ripple {
        height: auto;
      }
    }

    .group-more-icon {
      margin-right: 10px !important;
    }
  }
}

.group-content {
  //margin-top: 330px;
}

.group-header-info {
  .md-list-item-default {
    overflow: inherit !important;
  }
}

.member-list {
  .vuejs-loadmore-finished-text {
    display: none !important;
  }
}
.active .md-ripples {
  border: 1px solid #FCAF17 !important;
  background-color: #FFE973 !important;
  .logo-group{
    background-color: #FCAF17;
    border: 1px solid #FCAF17;
  }
}
.work_process {

  .md-list-item .md-list-item-container .md-ripples {
    border: 1px solid #EDEFF5;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding .4s cubic-bezier(.25, .8, .25, 1);
    will-change: padding;
  }

  .md-list-item .md-list-item-container .md-ripple {
    padding: 5px !important;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding .4s cubic-bezier(.25, .8, .25, 1);
    will-change: padding;
  }

  .logo-group {
    border-radius: 0px !important;
    width: 20px !important;
    min-width: 20px !important;
    margin: 0px;
    height: 38px;
    border: 1px solid #D8DAE5
  }

  .logo-toolbar {
    border-radius: 0px;
    width: 50px;
    margin: 0px;
  }
}
.header-category {
  .md-list-item-content {
    padding: 0px !important;
    .md-list-item-text{
      align-items: center;
    }
  }
  .line {
    width: 60px;
    height: 20px;
    border-bottom: 3px solid #D8DAE5;
  }
}

.post-file .md-list-item-container {
  font-size: 14px;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
}

.file-list-main {
  .md-ripple > span {
    overflow: inherit;
    position: inherit;
  }
}

.admin-user-list__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .admin-user-item__main {
    width: 49%;
  }
}
.loading-holder-post{
    .container-metadata{
      text-decoration: none;
      font-weight: normal;
      font-size: 15px;
      border: 1px solid rgba(0, 0, 0, 0.1803921569);
      position: relative;
      overflow: hidden;
      background-color: #fff;
      border-radius: 12px;
      margin-top: 2px;
      padding: 5px;
      .loading-image-holder{
        min-height: 100px;
        margin: 5px;
      }
    }
    .loader-shimmer{
      animation-duration: 2.2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer-holder;
      animation-timing-function: linear;
      background: #ddd;
      background: linear-gradient(to right, #f7f6f6 8%, #efefef 18%, #F6F6F6 33%);
      background-size: 1200px 100%;
      color: transparent;
      height: 20px;
      margin: 5px;
      img{
        display: none;
      }
    }
}

@-webkit-keyframes shimmer-holder {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer-holder {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}
</style>
