var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"title-sidebar"},[_vm._v("Trang chủ")]),_c('div',{staticClass:"content-sidebar"},[_c('sidebar-item',{attrs:{"link":{
                      name: 'Bảng tin',
                      path: '/new-feeds',
                      image: '/img/sidebar/right/workplace/home.svg',
                      is_group: false,
                    }}}),_c('sidebar-item',{attrs:{"link":{
                      name: 'Nhóm',
                      path: '/groups',
                      image: '/img/sidebar/right/workplace/group.svg',
                      is_group: false,
                    }}}),_vm._m(0),(_vm.$store.state.group.groups.companyWallGroups.length > 0 || _vm.$store.state.group.groups.branchGroups.length > 0)?_c('h3',{staticClass:"subtitle-sidebar text-500-medium"},[_vm._v(" Tổ chức")]):_vm._e(),_vm._l((_vm.$store.state.group.groups.companyWallGroups),function(group){return _c('sidebar-item',{key:'cwg' + group.id,attrs:{"link":{
                      name: group.name,
                      path: _vm.getPath(group.id),
                      image: group.avatar_url ? group.avatar_url : _vm.groupImageDefault,
                      is_group: true,
                    }}})}),_vm._l((_vm.$store.state.group.groups.branchGroups),function(group){return _c('sidebar-item',{key:'bg' + group.id,attrs:{"link":{
                      name: group.name,
                      path: _vm.getPath(group.id),
                      image: group.avatar_url ? group.avatar_url : _vm.groupImageDefault,
                      is_group: true,
                    }}})}),(_vm.$store.state.group.groups.workGroups.length > 0)?_c('h3',{staticClass:"subtitle-sidebar text-500-medium"},[_vm._v("Nhóm làm việc")]):_vm._e(),_vm._l((_vm.$store.state.group.groups.workGroups),function(group){return _c('sidebar-item',{key:'wg' + group.id,attrs:{"link":{
                      name: group.name,
                      path: _vm.getPath(group.id),
                      image: group.avatar_url ? group.avatar_url : _vm.groupImageDefault,
                      is_group: true,
                    }}})}),(_vm.$store.state.group.groups.pinnedGroups.length > 0)?_c('h3',{staticClass:"subtitle-sidebar text-500-medium"},[_vm._v("Nhóm đã ghim")]):_vm._e(),_vm._l((_vm.$store.state.group.groups.pinnedGroups),function(group){return _c('sidebar-item',{key:'pg' + group.id,attrs:{"link":{
                      name: group.name,
                      path: _vm.getPath(group.id),
                      image: group.avatar_url ? group.avatar_url : _vm.groupImageDefault,
                      is_group: true,
                    }}})}),_c('div',[_c('li',{},[_c('a',{staticClass:"nav-link is_group",on:{"click":function($event){return _vm.openDialog()}}},[_vm._m(1),_c('p',{staticClass:"text-400-semibold secondary_g400"},[_vm._v(" Tạo nhóm ")])])])])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('li',{},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://azagroup.asia/report","target":"_blank"}},[_c('i',{staticClass:"md-icon md-icon-font md-theme-default",staticStyle:{"color":"#474D66"}},[_c('span',{staticClass:"material-symbols-outlined"},[_vm._v("wysiwyg")])]),_c('p',{staticClass:"text-400-semibold"},[_vm._v(" Trang làm việc ")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"md-icon md-icon-font md-theme-default"},[_c('div',{staticClass:"md-image md-theme-default",staticStyle:{"height":"auto","width":"auto","min-width":"auto"}},[_c('img',{attrs:{"src":"/img/sidebar/right/workplace/add.svg"}})])])}]

export { render, staticRenderFns }