<template>
  <div>
    <modal name="share-message-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="create-group-modal modal-custom share-message-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Chuyển tiếp</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('share-message-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content class="p-0px">
        <div style="width: 100%; height: auto">
          <div class="input-auto-complete">
            <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group" v-model="search">
          </div>
          <div class="content py-15px custom-scroll-bar">
              <div v-for="(convensation, index) in memberList" :key="convensation.group_id" :set="model = convensation.model">
                <md-list-item class="conversation cursor-pointer" 
                            :class="convensation.type == 'GROUP'? 'group-type' : 'user-detail'"
                            >
                <md-avatar>
                  <img v-if="convensation.type == 'GROUP'" class="avatar"
                       :src="model.avatar || '/img/group/user-group.svg'">
                  <img v-else class="avatar"
                       :src="model.thumbnail ? model.thumbnail.small_thumb || '/img/profile/avatar.png' : '/img/profile/avatar.png'">
                </md-avatar>

                <div class="md-list-item-text" v-if="convensation.type == 'USER'">
                  <div class="text-400-medium mb-10px fullname">{{ model.full_name }}</div>
                  <div class="detail-info" v-if="model.parent_department">
                    <span class="material-icons">schema</span>
                    <span class="text-2-line text-info-name">
                      <span>{{ model.parent_department }}</span>
                      <span class="ml-5px text-300-default" v-if="model.department">></span>
                      <span class="ml-5px text-300-default" v-if="model.department">{{ model.department }}</span>
                  </span>
                  </div>
                  <div class="detail-info" v-else>
                    <span class="material-icons">schema</span>
                    <span class="text-2-line text-info-name">AZA GROUP</span>
                  </div>
                  <div class="detail-info" v-if="model.position">
                    <span class="material-icons">account_box</span>
                    <span class="text-info-name">{{ model.position }}</span>
                  </div>
                  <div class="detail-info">
                    <span class="material-icons">email</span>
                    <span class="text-info-name">{{ model.email }}</span>
                  </div>
                </div>
                <div class="md-list-item-text" v-else>
                  <div class="text-400-medium title">{{ model.name }}</div>
                  <div class="" v-if="convensation.type == 'GROUP'">
                    <span class="text-300-default">{{ convensation.model.member_count }} thành viên</span>
                  </div>
                </div>
                <button class="button-share-message" :class="{send_success: convensation.share_status_text}" @click="shareMessageToUser(convensation, index)">
                  {{ messageStatus(convensation.share_status_text) }}
                  </button>
              </md-list-item>
                </div>
            </div>
        </div>
      </md-card-content>
      <md-dialog-actions class="button-footer text-400-medium dialog-action-content">
        <label for="">Nội dung chuyển tiếp</label>
        <div class="content-preview-share">
          <span class="message-text">{{ getText() }}</span>
        </div>
      </md-dialog-actions>
      <md-dialog-actions class="button-footer text-400-medium dialog-action-footer">
        <!-- <button type="submit" class="btn-active md-button cursor-pointer" @click="() => $modal.hide('share-message-modal')">
          Đồng bộ
        </button> -->
        <button type="submit" class="md-button btn-stroke" @click="() => $modal.hide('share-message-modal')">
          hủy
        </button>
      </md-dialog-actions>

    </modal>
  </div>
</template>

<script>

import EmployeeService from "../../../store/services/EmployeeService";
import SettingService from "../../../store/services/SettingService";
import {GROUP_CHAT_TYPE, CREATE_POST_LEVEL, MODEL_TYPE, POST_TYPE, TYPE_ADD_GROUP_MEMBER, MESSAGE_CHAT_TYPE} from "../../../const/const";
import PostService from "../../../store/services/PostService";
import ChatService from "../../../store/services/ChatService";
// import TreeViewItem from "../../Workplace/Pages/TreeViewItem";

export default {
  // components: {TreeViewItem},
  name: 'share-message-modal',
  computed: {},
  mounted() {
    this.getListChatGoup(this.member_params);
  },
  watch: {
    search(newValue, oldValue) {
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
      this.timer = setTimeout(() => {
        this.member_params.keyword = newValue;
        this.getListChatGoup(this.member_params);
      }, 500);
    }
  },
  data() {
    return {
      member_params: {
        page: 1,
        per_page: 20,
        keyword: "",
        status: 1
      },
      position_params: {
        keyword: ""
      },
      member_last_page: 1,
      memberList: [],
      search: '',
      TYPE_ADD_GROUP_MEMBER,
      message: {},
      timer: null,
      form: {
        group_id: null,
        message: '',
        type: MESSAGE_CHAT_TYPE.TEXT,
        file_ids: [],
        fcm_token: localStorage.getItem("cmf_token"),
        mentions: [],
        reply_to_msg_id: null,
        message_id: null, //id edit message,
        forward_msg_id: null
      }
    };
  },
  methods: {
    beforeOpened(event) {
      this.getListChatGoup(this.member_params);
      this.message = event.params.message;
      
      let fileList = [];
      if(this.message.files.length > 0){
        this.message.files.map((file) => fileList.push(file.id));
      }

      this.form = {
          group_id: null,
          message: this.message.text,
          type: this.message.type,
          file_ids: fileList,
          fcm_token: localStorage.getItem("cmf_token"),
          mentions: this.message.mentions ?? [],
          reply_to_msg_id: null,
          message_id: null, //id edit message,
          forward_msg_id: this.message.id
      }
    },

    beforeClosed(event) {
    },

    getListChatGoup(params) {
      params.page = this.member_params.page;
      ChatService.getChatConvesations(params).then(res => {
        if (res && res.data) {
          this.memberList = res.data
          this.member_last_page = res.meta.last_page;
        }
      });
    },

    async shareMessageToUser(group, index){
      // TODO
      // this.$emit('share-message', {user : user, message :this.message});

      let chatGroups = this.$store.state.chat.chatGroups;
      this.$set(this.memberList[index], 'share_status_text', 'loading');

      this.form.group_id = group.group_id;
      await ChatService.createMessageChat(this.form).then(res => {
        if (res.status) {
          this.$set(this.memberList[index], 'share_status_text', 'sent');
          chatGroups.map((item) => {
                if (item.id == group.group_id) {
                  item.last_message = res.data
                }
              }
          )
          this.$store.commit('chat/updateChatGroups', chatGroups);
        }else{
          let name = group.model.type === 'USER' ? group.model.full_name : group.model.name;
          this.$set(this.memberList[index], 'share_status_text', null);
          this.$store.dispatch("alerts/error", 'Chuyển tiếp tin nhắn không thành công tới: ' + name);
        }
      });
    },

    getText(){
      let text = '';
      switch (this.message.type) {
        case 'FILE':
          text = 'Tập tin'
          break;
        case 'VIDEO':
          text = 'Video'
        break;
        default:
          text = this.message.text
          break;
      }
      return text;
      // this.message.text.
    },

    messageStatus(type){
      let text = ''
      switch (type) {
        case 'loading':
          text = 'Đang gửi'
          break;
        case 'sent':
          text = 'Đã gửi'
          break;
        default:
          text = 'Gửi'
          break;
      }

      return text;
    }

  }

}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
}
.md-checkbox .md-checkbox-container .md-ripple {
  overflow: hidden;
}
.share-message-modal {
  .dialog-action-footer{
    border-top: unset;
  }
  .dialog-action-content{
    border-top: 2px solid #D8DAE5;
    text-align: left;
    padding: 5px 15px 15px !important;
    line-height: 30px;

    label{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #101840;
    }
  }
  .md-list-item-content{
    min-height: auto;
  }
  .md-button{
    font-size: inherit;
  }
  .md-ripple {
    padding: 6px 10px !important;
  }
  .content {
    height: 350px;
    overflow-y: scroll;
  }

  .md-list-item:focus {
    background-color: rgb(241, 242, 244);
    border-radius: 8px;

    .check-icon {
      width: 20px;
      opacity: 1;
    }
  }

  .check-icon {
    width: 20px;
    opacity: 0;
    color: rgb(48, 169, 96);
  }

  .input-auto-complete {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border: none;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px 12px;
    min-height: auto !important;

    .item-select {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 24px;
      margin: 5px 5px 0 0;
      background-color: rgb(236, 247, 231);
      border: none;
      border-radius: 6px;
      box-sizing: content-box;
      padding: 2px 8px;
      outline: 0px;
      font-weight: 500;
      overflow: hidden;
      color: rgb(48, 169, 96);
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
      width: fit-content;

      .close-icon {
        border-radius: 50%;
        margin-left: 2px;
        width: auto;
        height: auto;
        background: transparent;
        i {
          font-size: 18px !important;
        }
      }
    }

    .input-search-field {
      width: 100% !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 12px;
      gap: 8px;
      height: 32px;
      background: #EDEFF5;
      border: 1px solid rgba(104, 113, 130, 0.16);
      border-radius: 4px;
    }
  }

  .create-post-btn {
    width: 100% !important;
  }

  .button-share-message {
    &:hover{
      cursor: pointer;
    }
    background: #376BFB;
    border-radius: 4px;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    padding: 4px 12px;
    margin-right: 6px;
    font-family: inherit;
  }
  .content-preview-share {
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    background: #EDEFF5;
    border-radius: 8px;
    padding: 6px 10px;
    .message-text{
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .send_success{
    background: #D8DAE5;
    color: #8A8EA0;
  }
}


</style>
