<template class="group-detail__container">
  <div class="md-layout group-detail-wrapper">
    <div class="w_100 group-head">
      <md-card class="p-0px m-0px border-radius-0px">
        <div class="wallpaper">
          <vue-element-loading :active="changeWallpaperLoading" spinner="bar-fade-scale"/>
          <div v-if="isMyProfile" class="update-wallpaper-main" @click="openPopupChangeImage('wallpaper')">
            <img src="/img/group/image-default-icon-white.svg" alt="">
            <div class="heading-300">Cập nhật ảnh bìa</div>
          </div>
          <img class="logo cursor-pointer" @click="viewProfileImage('wallpaper')"
               :src="profile.wallpaper.url ? profile.wallpaper.url: '/img/profile/wallpaper.jpg'">
        </div>
        <md-card-header style="position: relative" class="group-header">
            <div class="user-avatar-main">
              <div class="user-avatar-wrapper">
                <vue-element-loading :active="changeAvatarLoading" spinner="bar-fade-scale"/>
                <img class="user-avatar" :src="profile.avatar.url? profile.avatar.url: '/img/profile/avatar.png'" alt="" @click="viewProfileImage('avatar')">
                <div class="change-avatar-btn cursor-pointer" v-if="isMyProfile" @click="openPopupChangeImage('avatar')">
                  <md-icon>photo_camera</md-icon>
                </div>
              </div>
            </div>
          <md-list-item>
            <div class="avatar-content"></div>
            <div class="md-list-item-text pl-20px">
              <span class="group-name">{{profile.fullname}}</span>
              <p class="neutral_2">
                @{{profile.username}}
              </p>
            </div>
            <md-button v-if="!isMyProfile" class="neutral_1 text-400-medium message" @click="goToMessager(profile)">
              <md-icon class="mr-5px">chat</md-icon>
              Nhắn tin
            </md-button>
          </md-list-item>
        </md-card-header>
        <div class="groupTabs">
          <ul class="nav nav-tabs tab-custom">
            <li class="cursor-pointer" :class="{'active':tab_active === 1}" @click="$router.push('/profile/' + user_id)"><a
                class="text-400-medium">Dòng thời gian</a></li>
                <li class="cursor-pointer" :class="{'active':tab_active === 2}" @click="$router.push('/profile/' + user_id + '/about')"><a
                class="text-400-medium">Giới thiệu</a></li>
            <li class="cursor-pointer" :class="{'active':tab_active === 3}" @click="$router.push('/profile/' + user_id + '/files')"><a
                class="text-400-medium">Ảnh/Video</a></li>
          </ul>
        </div>
      </md-card>
    </div>

    <div class="group-content w_100">
      <template v-if="tab_active === 1">
        <div class="md-layout" style="padding: 15px;">
          <div class="md-layout-item" style="padding: 0">
            <create-post :isGroup="true" v-if="isMyProfile"></create-post>
                  <vue-loadmore
                      :on-loadmore="onLoad"
                      :finished="finished"
                      :loading-text="loadingText"
                      :finished-text="finishedText">
                    <div v-if="postUser.length > 0">
                      <div v-for="post of postUser" :key="post.id">
                        <post-layout :post=post :isGroup="true"></post-layout>
                      </div>
                    </div>
                    <div v-else-if="isMyProfile" class="post-layout">
                      <md-card class="p-15px">
                        <img src="/img/post/content/no_post_placeholder.jpg">
                      </md-card>
                    </div>
                    <div v-else class="post-layout">
                      <md-card>
                        <p class="p-15px text-500-default">Chưa có hoạt động nào</p>
                      </md-card>
                    </div>
                  </vue-loadmore>
          </div>
          <div class="company-info-section">
            <group-info :data="{type:'user',id:user_id, profile: profile}" :image-list="imageListForRightBar" />
          </div>
        </div>
      </template>
      <!-- About -->
      <template v-if="tab_active === 2">
        <div class="profile-about">
          <div class="profile-information">
            <div class="profile__head">
              <div class="profile__head__title">
                  <div class="profile__head__icon">
                    <md-icon>person</md-icon>
                  </div><span>Thông tin cá nhân</span>
                </div>
              <div class="profile__edit" v-if="isMyProfile" @click="showModalUpdate('infomation')">
                <md-icon>edit</md-icon>
                <span>Chỉnh sửa</span>
              </div>
            </div>
            <div class="profile__box">
              <div class="profile-form">
                <label class="profile-form__label">Họ và tên</label>
                <div class="profile-form__info">{{ profile.fullname }}</div>
              </div>
              <div class="profile-form">
                <label class="profile-form__label">Tên hiển thị</label>
                <div class="profile-form__info" v-if="profile.display_name">{{ profile.display_name}}</div>
                <div class="profile-form__info__not-update" v-else><md-icon>info</md-icon>Chưa cập nhật</div>
              </div>
              <div class="profile-form">
                <label class="profile-form__label">Số điện thoại</label>
                <div class="profile-form__info" v-if="profile.phone">{{ profile.phone }}</div>
                <div class="profile-form__info__not-update" v-else><md-icon>info</md-icon>Chưa cập nhật</div>
              </div>
              <div class="profile-form">
                <label class="profile-form__label">Ngày sinh</label>
                <div class="profile-form__info" v-if="profile.birthday">{{ profile.birthday }}</div>
                <div class="profile-form__info__not-update" v-else><md-icon>info</md-icon>Chưa cập nhật</div>
              </div>
              <div class="form-group profile-form">
                <label class="profile-form__label">Địa chỉ</label>
                <div class="profile-form__info" v-if="profile.address">{{ profile.address }}</div>
                <div class="profile-form__info__not-update" v-else><md-icon>info</md-icon>Chưa cập nhật</div>
              </div>
            </div>
          </div>
          <div class="profile-contact">
            <div class="profile__head">
              <div class="profile__head__title">
                <div class="profile__head__icon">
                  <md-icon>mail</md-icon>
                </div><span>Thông tin liên hệ</span>
              </div>
              <div class="profile__edit" v-if="isMyProfile" @click="showModalUpdate('email')">
                <md-icon>edit</md-icon>
                <span>Chỉnh sửa</span>
              </div>
            </div>
            <div class="profile__box">
              <div class="profile-form">
                <label class="profile-form__label">Email</label>
                <div class="profile-form__info" v-if="profile.email">{{ profile.email }}</div>
                <div class="not-update" v-else>Chưa cập nhật</div>
              </div>
              <div class="profile-form">
                <label class="profile-form__label">Đường dẫn</label>
                <div class="profile-form__info">{{ currentUrl }}</div>
              </div>
            </div>
          </div>
          <div class="profile-works">
            <div class="profile__head">
              <div class="profile__head__title">
                <div class="profile__head__icon">
                  <md-icon>business_center</md-icon>
                </div>
                <span>Công việc</span>
              </div>
            </div>
            <div class="profile__box">

              <div class="profile-form">
                <label class="profile-form__label">Tổ chức</label>
                <div class="profile-form__info">AZA Group</div>
              </div>
              <div class="profile-form" v-if="profile.department">
                <label class="profile-form__label">Phòng ban</label>
                <div class="profile-form__info" v-if="profile.parent_departement">{{ profile.parent_departement.name }} > {{profile.department.name}}</div>
                <div class="profile-form__info" v-else>{{profile.department.name}}</div>
              </div>
              <div class="profile-form">
                <label class="profile-form__label">Chức vụ</label>
                <div class="profile-form__info" >{{ profile.position.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- /About -->
      <template v-if="tab_active === 3">
        <div class="w_100 group-tab-content">
          <div class="tab-content-main background_neutral_white">
            <div class="image-list-main w_100">
              <p v-if="fileData.fileImageList.length === 0" class="text-500-default text-center">Không có ảnh nào</p>
              <vue-loadmore
                  :on-refresh="onRefreshForFileImage"
                  :on-loadmore="onLoadForFileImage"
                  :finished="fileData.fileImageFinished"
                  :loading-text="loadingText"
                  :finished-text="''">
                <div class="image-list-content">
                  <div class="image-item cursor-pointer" v-for="item in fileData.fileImageList" :key="item" @click="redirectToPostDetail(item)">
                    <image-template :height="'180px'" :class="'image-content'" :size="'medium'" :image="item.path" :thumbnail="item.thumbnail"/>
                  </div>
                </div>
              </vue-loadmore>
            </div>
          </div>
        </div>
      </template>
    </div>
    <input type="file" ref="fileupload" accept="image/jpg, image/png, image/jpeg" hidden id="update-wallpaper-btn"
           @change="changeWallpaper">
    <update-comment-modal @update-comment="updateCommentData"></update-comment-modal>
    <update-profile-modal  @update-profile="updateProfile($event)"></update-profile-modal>
  </div>
</template>
<script>


import CreatePost from "../../Components/Post/UserCreatePost/createPost";
import PostLayout from "../../Layout/Newfeed/PostLayout";
import PostService from "../../../store/services/PostService";
import ProfileService from "../../../store/services/ProfileService";
import GroupInfo from "../../Components/RightBar/GroupInfo";
import UpdateCommentModal from "../../Components/Modal/UpdateCommentModal";
import {GROUP_CHAT_TYPE} from "../../../const/const";
import ChatService from "../../../store/services/ChatService";
import moment from "moment";
import ImageTemplate from "../../../components/ImageTemplate";
import UpdateProfileModal from '../../Components/Modal/UpdateProfileModal'

export default {
  components: {PostLayout, CreatePost,GroupInfo, UpdateCommentModal, ImageTemplate, UpdateProfileModal},
  created() {
    window.addEventListener('wheel', this.handleScroll);
    this.getPosts();
    this.getProfile();
    this.getImageVideo();
    this.currentUrl = window.location.origin + '/profile/' + this.$route.params.user_id;
  },
  mounted() {
    if (this.$route.params.tab && this.$route.params.tab === 'files') {
      this.tab_active = 3;
    }else if(this.$route.params.tab && this.$route.params.tab === 'about'){
      this.tab_active = 2;
    }
  },
  computed: {
    imageListForRightBar() {
      return this.fileData.fileImageList.slice(0, 6);
    }
  },
  watch: {
    '$route.params.user_id'(to, from) {
      this.isMyProfile = parseInt(this.$route.params.user_id) === parseInt(this.$store.state.auth.user.id);
      this.user_id = this.$route.params.user_id;
      this.params = {
        user_id: this.$route.params.user_id,
        page: 1,
      };
      this.getProfile();
      this.getPosts();
      this.getImageVideo(true);
    },
    '$store.state.post.changePostFlag'(newV){
      if (newV == 1 || newV == 2) {
        this.getPosts();
        this.$store.dispatch("changePostFlag", 0);
      }
    },
    '$route.params.tab'(newV) {
      if (this.$route.params.tab === 'files') {
        this.tab_active = 3;
      }else if (this.$route.params.tab === 'about') {
        this.tab_active = 2;
      } else {
        this.tab_active = 1;
      }
    }
  },

  data() {
    return {
      tab_active: 1,
      user_id: this.$route.params.user_id,
      postUser: [],
      profile: {},
      isMyProfile: parseInt(this.$route.params.user_id) === parseInt(this.$store.state.auth.user.id),
      changeWallpaperLoading: false,
      changeAvatarLoading: false,
      changeImageType: null,
      description: 'test',
      finished: false,
      last_page: 1,
      loadingText: 'Đang tải',
      finishedText: 'Không còn bài viết mới nào.',
      params: {
        page: 1,
        user_id: this.$route.params.user_id
      },
      fileData: {
        searchFileInput: "",
        fileList: [],
        fileImageList: [],
        filePage: 1,
        fileImagePage: 1,
        fileLastPage: 1,
        fileImageLastPage: 1,
        fileFinished: false,
        fileImageFinished: false,
      },
      currentUrl : null
    };
  },

  methods: {
    changeTab(tab) {
      this.tab_active = tab;
    },

    async getPosts(loadMore = false) {
      this.show = false;
      await PostService.getPosts(this.params).then(res => {
        if (res) {
          this.isLoad = false;
          this.last_page = res.meta.last_page
          if (loadMore) {
            this.postUser = this.postGroup.concat(res.data)
          } else {
            this.postUser = res.data;
          }
        }
      });
    },

    onRefresh(done) {
      this.postGroup = [];
      this.params.page = 1;
      this.finished = false;
      this.getPosts();
      done();
    },

    onLoad(done) {
      if (this.params.page < this.last_page) {
        this.params.page++
        this.getPosts(true);
      } else {
        this.finished = true;
      }
      done();
    },

    async getProfile() {
      await ProfileService.getProfile({user_id: this.user_id}).then(res => {
        if (res) {
          this.profile = res.data;
        }
      });
    },

    openPopupChangeImage(type){
      if (!this.isMyProfile) {
        return;
      }
      this.changeImageType = type;
      document.getElementById("update-wallpaper-btn").click();
    },

    viewProfileImage(type) {
      if (type === 'avatar') {
        if (this.profile.avatar.post_id) {
          this.$router.push('/post/image/' + this.profile.avatar.post_id);
        } else if (this.profile.avatar.url) {
          this.$modal.show('show-comment-image', {imgUrl: this.profile.avatar.url});
        }
      } else {
        if (this.profile.wallpaper.post_id) {
          this.$router.push('/post/image/' + this.profile.avatar.post_id);
        } else if (this.profile.wallpaper.url) {
          this.$modal.show('show-comment-image', {imgUrl: this.profile.wallpaper.url});
        }
      }
    },

    changeWallpaper(e) {
      let type;
      let message;
      if (this.changeImageType === 'wallpaper') {
        this.changeWallpaperLoading = true;
        type = 2;
        message = 'Bạn thay ảnh bìa thành công.';
      } else {
        this.changeAvatarLoading = true;
        type = 1
        message = 'Bạn thay ảnh cá nhân thành công.';
      }
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("type", type);
      ProfileService.updateProfileImage(formData).then(res => {
        this.changeWallpaperLoading = false;
        this.changeAvatarLoading = false;
        if (res && res.status && res.data) {
          if (this.changeImageType === 'wallpaper') {
            this.profile.wallpaper.url = res.data.avatar;
          } else {
            this.profile.avatar.url = res.data.avatar;
            let user = this.$store.state.auth.user;
            user.avatar_url = res.data.avatar;
            user.thumbnail = {
              small_thumb: res.data.avatar,
              medium_thumb: res.data.avatar
            };
            this.$store.commit("updateUser", user);
          }
          this.$store.dispatch("alerts/success", message);
        } else {
          this.$store.dispatch("alerts/error", 'Thay ảnh thất bại.');
        }
      });
      const input = this.$refs.fileupload;
      input.type = 'text';
      input.type = 'file';
    },

    async getImageVideo(isRefresh = false) {
      if (isRefresh) {
        this.fileData.fileImagePage = 1;
        this.fileData.fileImageList = [];
      }
      let param = {
        page: this.fileData.fileImagePage,
        per_page: 20,
      }
      await ProfileService.getFiles(this.user_id, param).then(res => {
        if (res && res.data) {
          this.fileData.fileImageLastPage = res.meta.last_page
          this.fileData.fileImageList.push.apply(this.fileData.fileImageList, res.data);
        }
      });
    },

    onRefreshForFileImage(done) {
      this.fileData.fileImageList = [];
      this.fileData.fileImageLastPage = 1;
      this.fileData.fileImageFinished = false;
      this.getImageVideo();
      done();
    },

    onLoadForFileImage(done) {
      if (this.fileData.fileImagePage < this.fileData.fileImageLastPage) {
        this.fileData.fileImagePage++
        this.getImageVideo();
      } else {
        this.fileData.fileImageFinished = true;
      }
      done();
    },
    updateCommentData(e) {
      let postParentId = e.postParentId;
      let commentData = e.commentData;
      for (let i = 0; i < this.postUser.length; i++) {
        if (this.postUser[i].post.id === postParentId) {
          for (let j = 0; j < this.postUser[i].comments.length; j++) {
            if (this.postUser[i].comments[j].id === commentData.id) {
              this.postUser[i].comments[j] = commentData;
            }
          }
        }
      }
    },
    redirectToPostDetail(media) {
      if(media.single_post_id || this.mediaObject.post_id){
        this.$store.dispatch('refreshPostTotalPage');
        this.$store.state.post.previousDetail = 2;
        let link = media.single_post_id ? this.getLink(media.single_post_id) : this.getLink(this.mediaObject.post_id);
        this.$router.push(link);
      }
    },
    getLink(singlePostId) {
      return '/post/image/' + singlePostId
    },

    async goToMessager(member){

      //================CHECK-EXITS-GROUP-CHAT===============//
      let isChatExits = await this.checkGroupExits(member.id);

      if(isChatExits){
        //================CREATE-GROUP-CHAT===============//
        const formData = new FormData();

        //ADD VALUE
        formData.append("name", member.fullname);
        formData.append("model_type", GROUP_CHAT_TYPE.USER);
        formData.append("model_ids[]", member.id);

        // POST CHAT IS GROUP ALREADY EXIST
        await ChatService.createGroupChat(formData).then(res => {
            if (res.status) {
              // UPDATE STATE & GO TO MESSAGER
              delete res.data.isCreateChat;
              this.$store.commit('chat/updateChatGroups', res.data);
              this.$store.commit('chat/updateChatGroupSelected', res.data);
              this.$store.dispatch("chat/getChatGroups");
              this.$router.push("/messenger/" + res.data.id);
            } else {
              this.$store.dispatch("alerts/error", 'Bạn tạo cuộc trò chuyện không thành công. Nguyên nhân: ' + res.message);
            }
        });
      }else{
        const selectedChatGroup = {
          avatar: member.avatar_url,
          created_at: moment().format(),
          name: member.fullname,
          last_message: {},
          updated_at : moment().format(),
          user_id: member.id,
          isCreateChat: true,
          type: GROUP_CHAT_TYPE.USER
        };
        
         // UPDATE STATE & GO TO MESSAGER
        this.$store.commit('chat/updateChatGroupSelected', selectedChatGroup);
        this.$router.push("/messenger/new" + moment());
      }
      
    },

    async checkGroupExits(user_id){
      let status = false;
      
      await ChatService.checkChatExits({user_id : user_id}).then(res => {
        if(res.status){
          status = res.data.status;
        }
      });

      return status;
    },

    showModalUpdate(type){
      this.$modal.show('update-profile-modal', {
        fields: this.profile,
        type: type
      });

    },
    
    async updateProfile(event){

      const formData = new FormData();
      formData.append("fullname", this.profile.fullname);
      formData.append("email", event.email || this.profile.email);
      formData.append("display_name", event.display_name ||this.profile.display_name);
      formData.append("phone", event.phone ||this.profile.phone);
      formData.append("address", event.address || this.profile.address);
      formData.append("gender", this.profile.gender);

      let date = this.profile.birthday;
      let modifyDate = false;
      if(event.birthday && event.birthday !== ''){
        let dateTime = new Date(event.birthday);
        date = moment(dateTime).format('yyyy-MM-DD');
        modifyDate = true;
      }
      formData.append("birthday", date);

      await ProfileService.updateProfile(formData).then(res => {
        if (res.status) {
          this.$store.dispatch("alerts/success", 'Cập nhật thông tin thành công');
          this.$modal.hide('update-profile-modal');
          //update new data
          for (var key in event) {
            if (event.hasOwnProperty(key) && this.profile[key] !== event[key]) {
              if(key === 'birthday' && modifyDate){
                event[key] = moment(event[key]).format('DD-MM-yyyy');
              }
              this.profile[key] = event[key];
            }
          }
        }else{
          this.$store.dispatch("alerts/error", res.message);
        }
      });
      
    }
  }
};
</script>
<style lang="scss">
.groupTabs{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.group-head {
  //position: fixed;
  //z-index: 100;
}

.group-header {
  .md-ripple {
    height: 40px;
  }

  .group-name {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .message .md-ripple {
    background: #f59350;
    padding: 6px 15px;
  }
}
.aza-Icon * {
    fill: inherit!important;
}
.group-content {
  .profile-about {
    width: 100%;
    max-width: 750px;
    border-radius: 4px;
    padding: 10px 15px 0 15px;
    color: #375464;
    font-size: 1rem;
    margin: 0 auto 60px;
    .profile-information {
      margin-top: 0;
      align-items: flex-start;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .profile__head{
        border-left: 4px solid #0050b3;
      }
      .profile__head__icon {
          background: #e0eeff;
        .md-icon {
          color: #0050b3;
        }
      }
    }
    .profile__head {
      align-items: center;
      border-top-left-radius: 4px;
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 8px 12px 8px 12px;
      position: relative;
      width: 100%;
      border-bottom: 1px solid #e1dcdc;
      .profile__head__title {
        align-items: center;
        display: flex;
        .profile__head__icon {
          border-radius: 4px;
          padding: 3px;
          .md-icon {
            font-size: 16px !important;
          }
        }
      }
    }
    .profile__head__title span {
        font-weight: 600;
        margin-left: 10px;
    }
    .profile__edit{
      display: flex;
      border-radius: 4px;
      padding: 2px 10px;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: rgba(58, 182, 123, 0.18);
      }
      span {
        color: #3ab67b;
        font-size: 14px;
        font-weight: 600;
      }
      .md-icon {
        font-size: 16px !important;
        color: #3ab67b;
      }
    }
    .profile__box {
      font-size: 14px;
      padding: 16px;
      width: 100%;
      .profile-form {
        align-items: center;
        display: flex;
        margin: 0;
        padding: 4px 0;
      }
      .profile-form__label {
        color: #666768;
        font-weight: 400;
        margin: 0;
        overflow: hidden;
        padding: 0 15px 0 0;
        text-overflow: ellipsis;
        width: 160px;
      }
      .profile-form__info {
        color: #18202a;
        flex: 1 1;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .profile-form:nth-child(n+2) {
        margin-top: 10px;
      }
      .profile-form__login-account {
        align-items: center;
        display: flex;
      }
    }
    .profile-contact{
      .profile__head {
        border-left: 4px solid #ffa940;
        .profile__head__icon {
          background: #fff4e6;
          .md-icon {
            color: #ff9b00;
          }
        }
      }
    }
    .profile-works{
      .profile__head {
        .profile__head__icon {
          background: #ebf9f1;
          .md-icon {
            color: rgb(58 182 123);;
          }
        }
      }
      .profile__head {
        border-left: 4px solid rgb(58 182 123);;
      }
    }
  }
  .profile-form__info__not-update{
    display: flex;
    align-items: center;
    font-size: 14px;
    .md-icon {
      font-size: 18px !important;
      margin-right: 2px;
      color: red;
    }
  }
  .profile-about .profile-contact, .profile-about .profile-information, .profile-about .profile-works {
    align-items: flex-start;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
}
}

.avatar-content {
  width: 120px;
}

.user-avatar-main {
  position: absolute;
  width: 120px;
  height: 120px;
  top: -60px;
  left: 35px;
  display: flex;
  cursor: pointer;
  z-index: 6;
  .user-avatar-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .user-avatar {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      border-radius: 50%;
    }
    .change-avatar-btn {
      position: absolute;
      bottom: -6px;
      right: -6px;
      background-color: #EDEFF5;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
.company-info-section {
  .user-info-wrapper {
    width: 100% !important;
  }
}
</style>
