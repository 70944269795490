<template>
  <div class="sticker-wrapper" v-click-outside="closeBoxSticker">
    <div class="button-show-sticker footer-icon upload-file" title="Sticker" @click="toogleBoxSticker">
      <svg v-if="colour" width="20px" height="20px" fill="#28526e" xmlns="http://www.w3.org/2000/svg" class="aza-Icon" role="img" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 7.087A6.087 6.087 0 0 1 7.087 1h9.826A6.087 6.087 0 0 1 23 7.087v5.478c0 .146-.003.29-.009.435h-6.513a4.48 4.48 0 0 0-4.226 2.992 4.152 4.152 0 0 1-2.103-.422 3.458 3.458 0 0 1-1.322-1.132 1 1 0 0 0-1.654 1.124 5.455 5.455 0 0 0 2.082 1.798A6.15 6.15 0 0 0 12 18v5H7.087A6.087 6.087 0 0 1 1 16.913V7.087ZM9 9c0 1.105-.672 2-1.5 2S6 10.105 6 9s.672-2 1.5-2S9 7.895 9 9Zm7.5 2c.828 0 1.5-.895 1.5-2s-.672-2-1.5-2-1.5.895-1.5 2 .672 2 1.5 2Z" fill="#18202A"></path><path d="M16.478 15h6.236A10.445 10.445 0 0 1 14 22.902v-5.424A2.478 2.478 0 0 1 16.478 15Z" fill="#18202A"></path></svg>
      <svg v-else width="20px" height="20px" fill="#939393" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 17.228A4.228 4.228 0 0 1 16.228 13h5.522v1.5h-5.522a2.728 2.728 0 0 0-2.728 2.728v4.522H12v-4.522Z" fill="#939393"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M16.913 2.5H7.087A4.587 4.587 0 0 0 2.5 7.087v9.826A4.587 4.587 0 0 0 7.087 21.5h5.478c4.935 0 8.935-4 8.935-8.935V7.087A4.587 4.587 0 0 0 16.913 2.5ZM7.087 1A6.087 6.087 0 0 0 1 7.087v9.826A6.087 6.087 0 0 0 7.087 23h5.478C18.328 23 23 18.328 23 12.565V7.087A6.087 6.087 0 0 0 16.913 1H7.087Z" fill="#939393"></path><path d="M9 9c0 1.105-.672 2-1.5 2S6 10.105 6 9s.672-2 1.5-2S9 7.895 9 9ZM18 9c0 1.105-.672 2-1.5 2S15 10.105 15 9s.672-2 1.5-2 1.5.895 1.5 2ZM12.114 16.248a4.24 4.24 0 0 0-.114.98v.522c-.918 0-1.825-.21-2.633-.614a5.206 5.206 0 0 1-1.987-1.714.75.75 0 0 1 1.24-.844c.338.498.824.92 1.418 1.216a4.402 4.402 0 0 0 2.076.454Z" fill="#939393"></path></svg>
    </div>

    <div class="container-stickers" v-if="isOpen">
      <!-- <div class="sticker-head">
        <div class="warehouse-store head-sticker" @click="$modal.show('sticker-manage-modal', {tab: 1})">
          <img src="/img/chat/icon/store-shop.svg" alt="" />
          <span>Kho sticker</span>
        </div>
        <div class="collection-management head-sticker" @click="$modal.show('sticker-manage-modal', {tab: 2})">
          <img src="/img/chat/icon/gear-setting.svg" alt="" />
          <span>Quản lý bộ sưu tập</span>
        </div>
      </div> -->
      <div class="list-sticker custom-scroll-bar">
        <div class="item-sticker" v-for="(sitcker, index) in stickers" :key="index" @click="sendSticker(sitcker)">
          <!-- <img :src="sitcker.thumbnail.small_thumb" alt=""> -->
          <img :src="sitcker.image_url">
        </div>
      </div>
      <div class="sticker-package">
        <div class="package" v-for="(item, index) in packages" :key="index" @click="getStickers(index)"
             :class="item.isActive ? '' : 'display-none'">
          <a>
            <img :src="item.image_url || item.stickers[0].image_url">
          </a>
        </div>
        <!-- <div class="package">
          <a>
            <img src="https://gapo-work-files.statics.pancake.vn/sticker/origin/d2967f12-c969-44a7-94e3-ba539af56a19.png" alt="">
          </a>
        </div>
        <div class="package">
          <a>
            <img src="https://gapo-image.s3-ap-southeast-1.amazonaws.com/images/c8deb4e2-879b-4da4-a337-35f268d47e1a.png" alt="">
          </a>
        </div> -->
        <div class="button-prev-next" v-if="packages.length > 5">
          <span class="material-symbols-outlined prev cursor-pointer" @click="prevPackage">chevron_left</span>
          <span class="material-symbols-outlined next cursor-pointer" @click="nextPackage">chevron_right</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatService from "@/store/services/ChatService";
import $ from "jquery";

export default {
  name: "sticker",
  props: {
    autoLoad: {
      type: Boolean,
      default: true
    },
    colour: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
  },
  created(){
    if(this.autoLoad){
      this.getPackages();
    }
  },
  data(){
    return {
      stickers: [],
      isOpen: false,
      packages: [],
      positionActive: 5
    }
  },
  watch: {
    'isOpen'(val){
      // khi sử dụng ở post comment bị chèn title lên hộp hiển thị sticker
      $('.post-header-wrapper').css('z-index', val ? 1 : 4);
    }
  },
  methods: {
    toogleBoxSticker(){
      this.isOpen = !this.isOpen;
      if(!this.autoLoad){
        this.getPackages();
      }
    },

    closeBoxSticker(){
      this.isOpen = false;
    },

    prevPackage(){
      let current_position = this.positionActive;
      if(this.positionActive == 5) {
        current_position = this.positionActive = this.packages.length - this.positionActive;
      }

      this.positionActive = this.packages.length * 2 - current_position;
      let isReset = false;
      if(current_position > this.packages.length){
        isReset = true;
        this.positionActive = 5;
      }
      let items = this.packages.map((item, index) => {
          item.isActive = false;
          if((index > current_position - 1 && index < this.positionActive) || (isReset && index < this.positionActive)){
            item.isActive = true;
          }
          return item;
      });
      
      this.packages = items;
    },

    nextPackage(){
      let current_position = this.positionActive;
      this.positionActive = this.positionActive * 2;
      let isReset = false;
      if(current_position > this.packages.length){
        isReset = true;
        this.positionActive = 5;
      }
      let items = this.packages.map((item, index) => {
          item.isActive = false;
          if((index > current_position - 1 && index < this.positionActive) || (isReset && index < this.positionActive)){
            item.isActive = true;
          }
          return item;
      });
      
      this.packages = items;
    },

    async getPackages(){
      const formData = new FormData();
      formData.append("per_page", 60);
      formData.append("user_id", this.$store.state.auth.user.id);
      formData.append("page", 1);
      await ChatService.getPackages(formData).then(res => {
        if(res.data){
          let data = res.data.filter(function(item) {
            if (item.stickers.length > 0) {
              return true;
            }
            return false;
          }).map(function(item, index) { 
            item.isActive = index < 5;
            return item;
          });
          this.packages = data;
          this.stickers = this.packages[0].stickers ?? [];
        }
      });
    },

    getStickers(index){
      this.stickers = this.packages[index].stickers ?? [];
    },

    sendSticker(sticker){
      this.$emit('send-sticker', sticker);
      this.isOpen = false;
    }
  }
}
</script>

<style lang="scss">
.sticker-wrapper{
  position: relative;
  z-index: 100;
  .ic-sticker {

  }
  .button-show-sticker {
    svg {
      fill: #0ebd59;
    }
  }
  .container-stickers{
    position: absolute;
    bottom: 40px;
    left: -65px;
    width: 350px;
    height: auto;
    background: #fff;
    border: 1px solid #dadde1;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 2px 2px 5px #dadde1;
    .sticker-head{
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 10px 5px;
      font-size: 14px;
      border-bottom: 1px solid #dadde1;
      .head-sticker {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 50%;
      }
    }
    .list-sticker{
      display: flex;
      flex-wrap: wrap;
      place-content: flex-start;
      align-items: center;
      overflow-y: scroll;
      height: 300px;
      padding: 5px;
      margin: 5px 0;
      .item-sticker {
        &:hover{
          cursor: pointer;
          background: #dbe3ff;
        }
        width: 80px;
        height: 80px;
        padding: 5px;
        border-radius: 8px;
        overflow: hidden;
      }
    }
    .sticker-package {
      display: flex;
      justify-content: center;
      grid-gap: 5px;
      border-top: 1px solid #d5d2d26e;
      max-width: 350px;
      overflow: hidden;
      padding: 5px 0;
      position: relative;
      min-height: 50px;
      .package {
        &:hover{
          cursor: pointer;
          background: #dbe3ff;
          border-radius: 5px;
        }
        width: 40px;
        height: 40px;
      }
      .button-prev-next{
        .next{
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          right: 0;
        }
        .prev{
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 20px;
        }
      }
    }
  }
  .custom-scrollbar-x {
  overflow-x: auto;
  white-space: nowrap;
  width: 400px; /* Chiều rộng của phần tử chứa */
}

.custom-scrollbar-x::-webkit-scrollbar {
  height: 8px; /* Độ cao của thanh cuộn */
  background-color: #dddddd; /* Màu nền của thanh cuộn */
}

.custom-scrollbar-x::-webkit-scrollbar-thumb {
  background-color: #888888; /* Màu sắc của phần thumb */
}

.custom-scrollbar-x::-webkit-scrollbar-thumb:hover {
  background-color: #555555; /* Màu sắc của phần thumb khi di chuột qua */
}
}
</style>