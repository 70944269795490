<template>
  <div class="container-modal-update-rule">
    <modal name="update-rule-group"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="modal-custom update-rule-group"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
    <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="heading-500 text-header-modal">Cập nhật nội quy</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('update-rule-group')">close</span>
        </md-dialog-title>
      </md-card>
    <md-card-content>
      <template v-if="isShow">
        <div class="show-rule-content" v-html="content"></div>
      </template>
      <ckeditor v-model="content" :config="editorConfig" v-else></ckeditor>
    </md-card-content>
    <md-dialog-actions class="p-15px w_100 text-400-medium">
        <button type="submit" class="create-post-btn btn-active md-button w_100" v-if="!isShow" @click="updateRule">Cập nhật</button>
        <button type="submit" class="btn-stroke md-button heading-300" @click="$modal.hide('update-rule-group')">Đóng</button>
      </md-dialog-actions>
  </modal>
  </div>
</template>

<script>
export default {
  name: "update-rule-group",

  data() {
    return {
      content: '',
      editorConfig: {
        toolbar: [
          ['Format', 'Font', 'FontSize', 'Bold','Italic','Strike'],
          ['NumberedList','BulletedList'],
        ]
      },
      isShow: false
    }
  },

  methods: {
    beforeOpened(event){
      this.isShow = false;
      if(event.params && event.params.content){
        this.isShow = true;
        this.content = event.params.content;
      }else{
        this.content = this.$store.state.chat.chatGroupSelected.description;
      }
    },

    beforeClosed(){

    },

    updateRule(){
      this.$emit('update-rule', this.content);
    }
  }
}
</script>

<style lang="scss">
.container-modal-update-rule{
  .md-card-content{
    min-height: 375px;
  }
  .show-rule-content{
    padding: 0 20px;
  }
  textarea{
    width: 100%;
    height: 300px;
    border: 1px solid #dbdbdb;
    resize: none;
    padding: 5px;
  }
}
</style>