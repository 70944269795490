<template>
  <div class="container-library">
    <vs-tabs>
      <vs-tab class="library-tab" label="Ảnh" @click="showLibrary('IMAGE')">
        <vue-element-loading :active="loading" />
        <md-content class="md-scrollbar scroll-listmember">
          <vue-loadmore :on-loadmore="onLoad" :finished="finished" :loading-text="'Đang tải'" :finished-text="'Hết'">
            <div class="con-example-images scrollbar-library2">
              <div class="lists-image-by-date" :key="index" v-for="(item, index) in files">
                <div class="date">{{ getTimeCreated(index, 'LL') }}</div>
                <div class="grid-container ">
                  <div class="list-file" :key="index_img" v-for="(img, index_img) in item"
                    @click="showFileSlideShow(item, index_img)">
                    <img class="cursor-pointer" :src="img.media_file.thumbnail.medium_thumb" alt="aza" />
                  </div>
                </div>
              </div>
              <div class="not-found-data" v-if="Object.keys(this.files).length == 0">
                <p class="text-center">Không có dữ liệu</p>
              </div>
            </div>
          </vue-loadmore>
        </md-content>
      </vs-tab>
      <vs-tab label="Video" class="library-tab" @click="showLibrary('VIDEO')">
        <vue-element-loading :active="loading" />
        <md-content class="md-scrollbar scroll-listmember">
          <vue-loadmore :on-loadmore="onLoad" :finished="finished" :loading-text="'Đang tải'" :finished-text="'Hết'">
            <div class="con-example-images">
              <div class="lists-video-by-date" :key="index" v-for="(items, index) in files">
                <div class="date">{{ getTimeCreated(index, 'LL') }}</div>
                <div class="grid-container ">
                  <div class="grid-video grid-item" :key="index_video" v-for="(video, index_video) in items">
                    <video style="margin: auto" class="video pb-5px cursor-pointer"
                      @click="showFileSlideShow(video, index_video, true)">
                      <source :src="video.media_file.path">
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  </div>
                </div>
              </div>
              <div class="not-found-data" v-if="(Object.keys(files).length == 0)">
                <p class="text-center">Không có dữ liệu</p>
              </div>
            </div>
          </vue-loadmore>
        </md-content>
      </vs-tab>
      <vs-tab label="Tập tin" class="library-tab" @click="showLibrary('FILE')">
        <vue-element-loading :active="loading" />
        <md-content class="md-scrollbar scroll-listmember">
          <vue-loadmore :on-loadmore="onLoad" :finished="finished" :loading-text="'Đang tải'" :finished-text="'Hết'">
            <div class="con-example-images">
              <div class="lists-file-by-date" :key="index" v-for="(file, index) in files">
                <div class="date">{{ getTimeCreated(index, 'LL') }}</div>
                <div class="itemFile" :key="index" v-for="(file_item, index) in file">
                  <div class="file-thumb">
                    <!-- <span class="material-symbols-outlined primary_y500">description</span> -->
                   <img class="file-icon" :src="getIconByType(file_item.media_file.extension)">
                  </div>
                  <div class="message text-300-default md-list-item-text" v-popover:tooltip.top="'click to dowload'"
                    @click="dowloadFile(file_item.media_file.url)">
                    <span class="neutral_7 text-400-medium">{{ file_item.media_file.name }}</span>
                    <div class="mt-5px neutral_2 text-300-default">
                      <span class="text-1-line">{{ bytesToSize(file_item.media_file.file_size) }} {{
                        getTimeCreated(file.created_at, 'LT')
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="not-found-data" v-if="(Object.keys(files).length == 0)">
                <p class="text-center">Không có dữ liệu</p>
              </div>
            </div>
          </vue-loadmore>
        </md-content>
      </vs-tab>
      <vs-tab label="Liên kết" @click="showLibrary('LINK')" class="library-tab">
        <vue-element-loading :active="loading" />
        <md-content class="md-scrollbar scroll-listmember">
          <vue-loadmore :on-loadmore="onLoad" :finished="finished" :loading-text="'Đang tải'" :finished-text="'Hết'">
            <div class="con-example-images container-linkmetadata">
              <div class="link-collection">
                <div class="link-collection-item" :key="date" v-for="(links, date) in linkCollections">
                  <span class="time-label">{{ getTimeCreated(date, 'LL') }}</span>
                  <div class="link-item" :key="link.id" v-for="link in links">
                    <div class="link-item__wrapper">
                      <div class="thumb-wrapper" @click="goToLink(link.link_metadata.url)">
                        <img alt="thumb" :src="link.link_metadata.image || '/img/link.png'" class="thumb-wrapper__img">
                      </div>
                      <div class="link-info" @click="goToLink(link.link_metadata.url)">
                        <span class="link-title">{{ link.link_metadata.title }}</span>
                        <div class="link-source"><a :href='link.link_metadata.url'>{{ link.link_metadata.url }}</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="not-found-data" v-if="(Object.keys(this.linkCollections)).length == 0">
                <p class="text-center">Không có dữ liệu</p>
              </div>
            </div>
          </vue-loadmore>
        </md-content>
      </vs-tab>
    </vs-tabs>
  
  
  </div>
</template>

<script>

import GroupService from "../../../../../store/services/GroupService";
import moment from "moment";
import {helpers} from "../../../../../helper/helpers";

export default {
  name: "Library",
  data() {
    return {
      hover: 'none',
      form: {
        page: 1,
        per_page: 30,
        group_id: this.$route.params.messenger_id,
        type: 'IMAGE',
        order_by: 'created_at',
        order: 'desc'
      },
      last_page: 1,
      finished: false,
      loading : false,
      linkCollections : [],
      files: [],
      tabActive: 'IMAGE'
    }
  },
  mounted() {
    this.showLibrary();
  },
  methods : {
    goToLink(link){
      window.open(link, '_blank');
    },

    async showLibrary(library = 'IMAGE', isLoadMore = false) {
      if(this.tabActive !== library){
        this.last_page = 1;
        this.form.page = 1;
      }

      this.tabActive = library;
      this.loading = !isLoadMore;
      this.form.type = library;
      if(library === 'LINK'){
        await GroupService.getLinkMetaDataChatGroup(this.form).then(res => {
          if (res.data) {
            this.last_page = res.meta.last_page;
            if(isLoadMore){
              let data = this.groupByDate(res.data)
              this.linkCollections = {...this.linkCollections, ...data};
            }else{
              this.linkCollections = this.groupByDate(res.data);
            }
          }
        });
      }else{
        await GroupService.getFileChatGroup(this.form).then(res => {
          if (res.data) {
            this.last_page = res.meta.last_page;
            if(isLoadMore){
              let data = this.groupByDate(res.data)
              this.files = {...this.files, ...data};
            }else{
              this.files = this.groupByDate(res.data);
            }
          }
        });
      }
      this.loading = false;
    },

    groupByDate(data){
      return data.reduce((collections, item) => {
        item.created_at = moment(item.created_at).format('YYYY-MM-DD');
        const group = (collections[item.created_at] || []);
        group.push(item);
        collections[item.created_at] = group;
        return collections;
      }, {});
    },

    checkShowTime(item, index, dataList) {
      if (index !== 0) {
        const pattern = "DD/MM/YYY";
        const itemTime = this.getTimeCreated(item.created_at, pattern);
        const itemPreTime = this.getTimeCreated(dataList[index - 1]['created_at'], pattern);

        if (itemTime !== itemPreTime) {
          return true;
        }

        return false;

      } else {
        return true;
      }
    },

    getTimeCreated(time, format) {
      return moment(time).locale('vi').format(format);
    },

    bytesToSize(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },

    showFileSlideShow(files, active, isVideo = false) {
      this.$modal.show('slide-show-modal', {
        items: files,
        activeId: active,
        isVideo : isVideo,
        isLibrary : true
      });
    },

    dowloadFile(item) {
      window.open(item);
    },

    onLoad(done) {
      if (this.form.page < this.last_page) {
        this.form.page++
        this.showLibrary(this.tabActive, true);
      } else {
        this.finished = true;
      }
      done();
    },

    getIconByType(type){
      return helpers.getFileType(type)
    }
  }
}
</script>

<style lang="scss">
.neutral_7 {
  color: #5a5f66;;
}
.library-tab{
  min-height: 40px;
  margin-top: 20px;
  padding: 0;
  .date {
    margin: 4px 0;
    font-weight: 600;
    font-size: 14px;
  }

  .tab-images{
    .vs-image--img{
      background-size: 100%;
      background-color: #0000000d;
      background-repeat: no-repeat;
    }
  }
  .lists-file-by-date{
    .md-list-item-content{
      display: unset;
    }
    .itemFile{
      border-bottom: 1px solid rgba(127, 123, 123, 0.12);
      padding: 6px;
      display: flex;
      align-items: center;
      .file-thumb{
        span {
          margin-right: 5px;
          font-size: 28px !important;
        }
        .file-icon{
          width: 30px;
          margin-right: 10px;
        }
      }
      &:hover{
        cursor: pointer;
        background: #eff0f5a3;
      }
    }
  }
  .vs-tabs--content {
    padding: 0;
  }
  .grid-container {
    display: grid;
    grid-auto-rows: minmax(min-content,max-content);
    grid-template-columns: repeat(3,minmax(0,1fr));
    padding: 6px 0;
    grid-gap: 6px;
    .list-file{
      img{
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100px;
        border: 1px solid #dddddd;
        border-radius: 8px;
      }
    }
  }
  .grid-item {
    background-color: rgb(209 209 209 / 80%);
    border: 1px solid rgb(0 0 0 / 6%);
    font-size: 30px;
    text-align: center;
    border-radius: 5px;
    &:hover{
      cursor: pointer;
    }
  }
  .scrollbar_item{
    height: 600px;
    overflow: hidden;
    overflow-y: scroll !important;
  }
  .sidebar-workplace {
    .workplace-icon {
      border: 1px solid transparent;
    }
    .active .workplace-icon {
      background-color: #FFE973 !important;
      border: 1px solid #FCAF17;
    }
  }
  .container-linkmetadata{
    display: block;
    font-weight: 600;
    font-size: 13px;
    margin: 10px 0;
    .link-item {
      padding: 0.5rem 0;
      width: calc(100% - 6px);
      display: flex;
      margin-left: 4px;
      align-items: center;
      justify-content: space-between;
      .link-item__wrapper {
        align-items: center;
        display: flex;
        justify-content: space-around;
        padding: 0 0.5rem;
        width: 100%;
      }
      .thumb-wrapper {
        align-items: center;
        background: #091f340a;
        border-radius: 8px;
        display: flex;
        height: 48px;
        justify-content: center;
        position: relative;
        width: 48px;
        .thumb-wrapper__img {
          border-radius: 8px;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
      .link-info {
        cursor: pointer;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        margin: 0 8px;
        min-width: 0;
        .link-title {
          -webkit-line-clamp: 1;
          line-clamp: 3;
          font-weight: 600;
          margin: 0;
          overflow: hidden;
          padding: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .link-source {
          -webkit-line-clamp: 1;
          line-clamp: 3;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .listActions{
      &:focus{
        .list-action > .list-action{
          display: block;
        }
      }
    }
    .list-action {
      display: none;
      width: max-content;
      position: absolute;
      right: 0;
      top: 17px;
      border: 1px solid var(--aza-color-sliver);
      border-radius: 5px;
      z-index: 10;
      background: #fff;
      font-size: 14px;
      padding: 5px 10px;
      line-height: 25px;
    }
  }
}
.container-library {
    box-shadow: none !important;
    .con-ul-tabs {
      .ul-tabs {
        box-shadow: none !important;
        border-bottom: 1px solid #e9e9e9;
      }
    }
    // .con-slot-tabs {
    //   height: calc(100vh - 170px);
    //   overflow-y: scroll !important;
    // }
    .con-slot-tabs::-webkit-scrollbar {
      width: 7px;
    }
    .con-slot-tabs::-webkit-scrollbar-thumb {
      background: rgba(136, 136, 136, 0.4784313725);
      border-radius: 5px;
      margin: 7px;
      border-left: 2px white solid;
    }
    .con-slot-tabs::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .scroll-listmember{
      height: calc(100vh - 185px);
      overflow-y: auto;
    }
  }
</style>