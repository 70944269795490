<template>
  <div class="wiki__container">
    <vue-element-loading :active="isLoading" spinner="bar-fade-scale"/>
    <h3 class="title-sidebar">AZA WIKI</h3>
    <div class="content">
      <sidebar-item
          :link="{
                        name: 'Aza Wiki',
                        path: '/wiki-home',
                        image: '/img/sidebar/right/workplace/home.svg',
                        is_group: false,
                      }"
      />
      <div class="cs-department cs-position-list">
        <div class="wiki-category" v-for="item in category.subcategories" :key="'wiki_id_' + item.id">
          <div class="text-400-semibold d-flex-center wiki-category-1">
            <md-icon>expand_more</md-icon>
            <span>{{item.title}}</span>
          </div>
          <div class="text-400-medium wiki-sub-category"
               v-for="subItem in item.subcategories" :key="'sub_wiki_id_' + subItem.id"
               :class="{active: category_id == subItem.id}"
               @click="clickSubCategory(subItem)">
            <img src="/img/dot_circle.svg" alt="">
            <span class="ml-10px text-2-line">{{subItem.title}}</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import CategoryService from "../../../../store/services/CategoryService";

export default {
  name: "category-sidebar",
  watch: {
    '$route.params.category_id'(newV) {
      this.category_id = newV;
    }
  },
  created() {
    this.getCategories();
  },
  mounted() {
  },
  data() {
    return {
      category: {},
      isLoading: false,
      category_id: this.$route.params.category_id
    }
  },
  methods: {
    getPath(id) {
      return '/group/' + id;
    },

    fromNow(date) {
      return moment(date).locale("vi").fromNow();
    },

    getCategories() {
      this.isLoading = true;
      CategoryService.getCategories().then(res => {
        if (res && res.status) {
          for (let i = 0; i < res.data.categories.length; i++) {
            if (res.data.categories[i].id === 19) {
              this.category = res.data.categories[i];
              break;
            }
          }
        }
        this.isLoading = false;
      });
    },
    clickSubCategory(link) {
      this.$store.commit("updateCategory", link);
      this.$router.push("/wiki/" + link.id);
    }
  },
};
</script>
<style lang="scss">
.wiki__container {
  .content {
    padding: 0 10px;
  }
  .wiki-category {
    .wiki-category-1{
      background: #f0f0f0;
    }
    .md-icon {
      opacity: 1 !important;
    }
    .wiki-sub-category.active {
      background-color: #FFF1A6 !important;
    }
    .wiki-sub-category {
      min-height: 35px;
      cursor: pointer;
      display: flex;
      padding: 5px 0 5px 18px;
      align-items: center;
      margin-top: 1px;
      margin-bottom: 1px;
      &:hover {
        background-color: #FFF1A6 !important;
      }
    }
  }
}

</style>

