<template>
  <div class="message-sender-reply">
    <div class="text-300-default neutral_2">
    <span v-if="mess.reply_to_msg.sender.id === mess.sender.id">
      <span class="text-300-semibold">{{mess.sender.id === user.id ? "Bạn" : mess.sender.name }}</span> đã trả lời chính mình
    </span>
      <span v-else>
      <span class="text-300-semibold">{{mess.sender.id === user.id ? "Bạn" : mess.sender.name }}</span>
      đã trả lời
      <span
          class="text-300-semibold">{{mess.reply_to_msg.sender.id === user.id ? "bạn" :mess.reply_to_msg.sender.name}}</span>
    </span>
    </div>
    <div class="replyMessageSender">
      <template v-if="mess.reply_to_msg.type === MESSAGE_CHAT_TYPE.FILE">
        <div class="file-reply">
          <md-icon>description</md-icon> {{ mess.reply_to_msg.files[0].name }}
        </div>
      </template>
      <template v-else-if="mess.reply_to_msg.type === MESSAGE_CHAT_TYPE.IMAGE">
        <!-- <span><md-icon>image</md-icon> Hình ảnh</span> -->
        <span class="preview-image">
          <img :src="mess.reply_to_msg.files[0].thumbnail.small_thumb" alt="file">
        </span>
      </template>
      <template v-else-if="mess.reply_to_msg.type === MESSAGE_CHAT_TYPE.VIDEO">
        <span><md-icon>videocam</md-icon> Video</span>
      </template>
      <template v-else>
        <div class="text-1-line">{{mess.reply_to_msg.text}}</div>
      </template>
    </div>
  </div>
</template>
<script>
  import {MESSAGE_CHAT_TYPE} from "../../../../../const/const";
  export default {
    data() {
      return {
        MESSAGE_CHAT_TYPE
      }
    },
    name: "reply-chat-content",
    components: {},
    props: {
      displayName: {
        type: String,
        default: "",
      },
      mess: {
        type: Object,
        default: () => {
          return {};
        }
      },
      user: {
        type: Object,
        default: () => {
          return {};
        }
      },
    },
  }
</script>

<style lang="scss">
  .replyMessageSender {
    &:hover {
      cursor: pointer;
    }
    span {
      display: flex;
      align-items: center;
      .md-icon {
        margin: 0 5px 0 0;
      }
    }
    background: #e2e2e2;
    color: #3d3d3d;
    padding: 5px 10px 12px;
    border-radius: 8px;
    margin-bottom: -8px;
    max-width: 400px;
    max-height: 150px;
    border: 1px solid #d4d4d4;
    .preview-image{
      justify-content: center;
      img {
        max-height: 100px;
        opacity: 0.7;
      }
    }
  }
</style>

