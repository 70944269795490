import Vue from "vue";
import axios from "axios";
// Import Bootstrap and BootstrapVue CSS files (order is important)

// Plugins
import App from "./App.vue";
// import Chartist from "chartist";
import VueAxios from "vue-axios";
import DashboardPlugin from "./material-dashboard";
import Popover from 'vue-js-popover';
import "vue-select/dist/vue-select.css";
import VueJSModal from 'vue-js-modal';
import VueClipboard from 'vue-clipboard2';
import CKEditor from 'ckeditor4-vue';
import VueElementLoading from 'vue-element-loading';
import VEmojiPicker from 'v-emoji-picker';
import VueLoadmore, {locale} from 'vuejs-loadmore';
import Multiselect from 'vue-multiselect';
import VueObserveVisibility from 'vue-observe-visibility';
import 'vue-datetime/dist/vue-datetime.css';
import {Settings} from 'luxon';
import $ from "jquery";
import {highlightTextarea} from "jquery-highlighttextarea";
import FloatingVue from 'floating-vue'

Vue.use(FloatingVue)
import 'floating-vue/dist/style.css';

Settings.defaultLocale = 'vi';

//import library vuesax
import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css'; //Vuesax styles
Vue.use(Vuesax, {
    theme: {
        colors: {
            primary: '#FCAF17',
            success: 'rgb(23, 201, 100)',
            danger: 'rgb(242, 19, 93)',
            warning: 'rgb(255, 130, 0)',
            dark: 'rgb(36, 33, 69)'
        }
    }
});

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Popover, {tooltip: true});
Vue.use(VueAxios, axios);
Vue.use(VueJSModal, {dialog: true, dynamic: true, injectModalsContainer: true});
Vue.use(VueJSModal);
Vue.use(VueClipboard);
Vue.use(CKEditor);
Vue.component('VueElementLoading', VueElementLoading);
Vue.component('multiselect', Multiselect);
Vue.config.productionTip = false;
Vue.use(VEmojiPicker);
Vue.use(VueLoadmore, {
    lang: 'en-US'
});
Vue.use(VueObserveVisibility);
import io from 'socket.io-client';

// router & store setup
import router from "./router";
import store from "./store";
import NotificationService from "./store/services/NotificationService";
import {helpers} from "./helper/helpers";
import moment from "moment";
import Cookies from "js-cookie";

let ws = io('wss://socket.azagroup.asia/', {
    transports: ['websocket'],
});

let accessTokenLocal = localStorage.getItem("access_token");
let userIdLocal = localStorage.getItem("user_id");
let accessTokenCookie = helpers.getCookie("access_token");
let userIdCookie = helpers.getCookie("access_token");

let accessToken = accessTokenLocal;
let userId = userIdLocal;
if (accessTokenLocal == null) {
    accessToken = accessTokenCookie;
    localStorage.setItem("access_token", accessTokenCookie);
}

if (userIdLocal == null) {
    userId = userIdCookie;
    localStorage.setItem("user_id", userIdCookie);
}

const vuex = localStorage.getItem("vuex");

if (accessToken !== null && accessToken !== '') {
    let token = accessToken.replace('%', '|');
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    setTimeout(() => {
        let fcmToken = localStorage.getItem("cmf_token");
        let sendCmfToken = localStorage.getItem("send_cmf_token");
        if (fcmToken != null && sendCmfToken != 'true') {
            NotificationService.postFcmToken({token: fcmToken}).then(res => {
                localStorage.setItem("send_cmf_token", true);
            });
        }
    }, 1000);
    let authUserValid = false;
    if (vuex && JSON.parse(vuex).hasOwnProperty("auth")) {
        let auth = JSON.parse(vuex).auth;
        if (!isEmpty(auth.user)) authUserValid = true;
    }

    if(!authUserValid ){
        window.isAuthenticated = false;
        Cookies.remove('access_token');
        Cookies.remove('user_id');
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_id");
        axios.defaults.headers.common.Authorization = ``;
    } else {
        startWebsocket(token);
    }
}

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

function startWebsocket(token) {
    if (vuex) {
        let user = JSON.parse(vuex).auth.user;

        if (user && user.id) {
            const messageTopic = 'message_' + user.id;
            const messageReactionEmojiTopic = 'message_reaction_emoji_' + user.id;
            const modifyInformationChatTopic = 'modify_information_chat_' + user.id;
            const systemTopic = 'system_' + user.id;
            const userTypingTopic = 'user_typing_' + user.id;
            const userRecall = 'user_recall_' + user.id;
            const dataLogin = JSON.stringify({
                action: "login",
                token: `${token}`,
                user_id: `${user.id}`
            });


            ws.on('connect', () => {
                console.log('login socket connect ' + moment().format('yyyy-MM-DD HH:mm:ss'));
                ws.emit('connect_socket', dataLogin);
            });

            ws.on('connect_socket', (message) => {
                let mess = JSON.parse(message);

                if (mess.action == 'login' && mess.status) {
                    console.log('socket connect ' + moment().format('yyyy-MM-DD HH:mm:ss'));
                    ws.emit(messageTopic, dataLogin);
                    ws.emit(messageReactionEmojiTopic, dataLogin);
                    ws.emit(modifyInformationChatTopic, dataLogin);
                    ws.emit(systemTopic, dataLogin);
                    ws.emit(userTypingTopic, dataLogin);
                    ws.emit(userRecall, dataLogin);
                }
            });

            ws.on(messageTopic, (message) => {
                console.log('socket message messageTopic ' + moment().format('yyyy-MM-DD HH:mm:ss'));
                if (app) {
                    let mess = JSON.parse(message);
                    let fcmToken = localStorage.getItem("cmf_token");
                    // logic old mess.sender && mess.sender.id && user.id !== mess.sender.id
                    if (mess.sender && mess.sender.id) {
                        app.$store.dispatch("chat/dataSocket", mess);
                    }

                    if (mess.sender && mess.sender.id && user.id === mess.sender.id) {
                        if (fcmToken != mess.fcm_token) {
                            app.$store.dispatch("chat/dataSocket", mess);
                        }
                    }
                }
            });

            ws.on(messageReactionEmojiTopic, (message) => {
                console.log('socket message messageReactionEmojiTopic ' + moment().format('yyyy-MM-DD HH:mm:ss'));
                if (app) {
                    let chatGroupSelected = JSON.parse(vuex).chat.chatGroupSelected;
                    let mess = JSON.parse(message);

                    if (typeof mess.action === 'undefined' || mess.chat_group_id === chatGroupSelected.id) {
                        app.$store.dispatch("chat/dataReactionEmoji", mess);
                    }
                }
            });

            ws.on(modifyInformationChatTopic, (message) => {
                console.log('socket message modifyInformationChatTopic ' + moment().format('yyyy-MM-DD HH:mm:ss'));

                if (app) {
                    let chatGroupSelected = JSON.parse(vuex).chat.chatGroupSelected;
                    let mess = JSON.parse(message);

                    if ((mess.data && mess.data.group_id === chatGroupSelected.id && mess.data.sender && mess.data.sender.id === user.id)
                        || (mess.type === 'update_message' && mess.data.group_id === chatGroupSelected.id)
                        || (['PIN_CHAT_GROUP', 'set_role_admin'].includes(mess.type))
                    ) {
                        app.$store.dispatch("chat/modifyMessageSocket", mess);
                    }

                }
            });

            ws.on(systemTopic, (message) => {
                console.log('socket message systemTopic ' + moment().format('yyyy-MM-DD HH:mm:ss'));
                let mess = JSON.parse(message);
                if (mess.is_banned && mess.is_banned === true) {
                    window.isAuthenticated = false;
                    Cookies.remove('access_token');
                    Cookies.remove('user_id');
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("user_id");
                    axios.defaults.headers.common.Authorization = ``;
                    window.location.reload();
                }
            });

            ws.on(userTypingTopic, (message) => {
                console.log('socket message userTypingTopic ' + moment().format('yyyy-MM-DD HH:mm:ss'));
                if (app) {
                    let mess = JSON.parse(message);
                    if(user.id !== mess.data.user_typing.id){
                        console.log(mess.data.user_typing.id);
                        app.$store.dispatch("chat/dataUserTyping", mess.data);
                    }
                }
            });

            ws.on(userRecall, (message) => {
                console.log('socket message userRecall ' + moment().format('yyyy-MM-DD HH:mm:ss'));
                if (app) {
                    let mess = JSON.parse(message);
                    app.$store.dispatch("chat/dataUserRecall", mess.data);
                }
            });

            ws.on('disconnect', () => {
                console.log('socket disconnect ' + moment().format('yyyy-MM-DD HH:mm:ss'));
                startWebsocket(token);
                if (app) {
                    app.$store.commit('chat/updateReconnectSocket', true);
                }
            });

            ws.on("close", (message) => {
                // console.log('socket close' + moment());
                startWebsocket(token);
            });
        }
    }
}

window.addEventListener("load", (event) => {
    app.$store.commit('chat/updateCacheChatMessages', {});
});

window.addEventListener("storage", storageListener);

function storageListener() {
    let firebaseMessage = localStorage.getItem("firebase_message");
    if (app && firebaseMessage != null) {
        app.$store.dispatch("alerts/firebaseNotification", JSON.parse(firebaseMessage));
    }
}

localStorage.setItem("create_post_modal_open", false);

const app = new Vue({
    router: router,
    store: store,
    ws: ws,
    el: "#app",
    render: h => h(App)
});

store.$app = app;
window.app = app;