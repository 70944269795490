<template>
  <div class="update-profile-modal">
    <modal name="update-profile-modal"
           :click-to-close="false"
           width="700px"
           height="auto"
           class="select-group-modal modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale"/>
      <md-card class="modal-header-custom">
        <template>
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal">Chỉnh sửa thông tin</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('update-profile-modal')">close</span>
          </md-dialog-title>
        </template>
      </md-card>

      <md-card-content>
        <md-list class="nav p-0px">
          <template v-if="type === 'all'">
            <div class="mb-10px">
              <label class="required heading-400 neutral_2">Họ và tên</label>
              <input type="text" placeholder="Nhập tên hiển thị" required class="form-control form-info" name="fullname"
                     v-model="profile.fullname">
            </div>
          </template>
          <template v-if="type === 'email'">
            <div>
              <label class="required heading-400 neutral_2">Email</label>
              <input type="text" placeholder="Nhập email" required class="form-control form-info" name="email"
                     v-model="profile.email">
            </div>
          </template>
          <template v-if="type === 'all' || type === 'infomation'">
            <div class="mb-10px">
              <label class="required heading-400 neutral_2">Tên hiển thị</label>
              <input type="text" placeholder="Nhập tên hiển thị" required class="form-control form-info" name="display_name"
                     v-model="profile.display_name">
            </div>
            <div class="mb-10px" v-if="profile.phone">
              <label class="required heading-400 neutral_2">Số điện thoại</label>
              <input type="text" placeholder="Nhập số điện thoại" required class="form-control form-info" name="phone"
                     v-model="profile.phone">
            </div>
            <div class="birthday-field mb-10px" v-if="profile.birthday">
              <label class="required heading-400 neutral_2">Ngày sinh</label>
              <datetime type="date form-control"
                        format="dd/MM/yyyy" v-model="profile.birthday" :max-datetime="maxDate"
                        title="Nhập ngày sinh">dd/MM/yyyy
              </datetime>
            </div>
            <div class="mb-10px" v-if="profile.address">
              <label class="required heading-400 neutral_2">Địa chỉ</label>
              <input type="text" placeholder="Nhập địa chỉ" required class="form-control form-info" name="address"
                     v-model="profile.address">
            </div>
          </template>
        </md-list>
      </md-card-content>
      <md-dialog-actions class="p-15px w_100">
        <button type="submit" class="btn-active md-button text-400-medium neutral_1"
                @click="updateProfile()">Cập nhật
        </button>
        <button class="btn-stroke md-button text-400-medium " @click="$modal.hide('update-profile-modal')">Huỷ</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
    import {Datetime} from 'vue-datetime';

    export default {
  name: 'update-profile-modal',
  components: {datetime: Datetime},
  mounted() {
  },
  destroyed() {
  },
  created() {
  },

  data() {
    return {
      profile: {},
      type: '',
      show: false,
      showDialogEmoji: false,
      postParentId: null,
      maxDate: new Date().toJSON(),
    }
  },
  methods: {
    beforeOpened(event) {
      this.profile = {...event.params.fields};
      if (this.profile.birthday == null || this.profile.birthday == '') {
        this.profile.birthday = new Date().toJSON();
      } else {
        const [day, month, year] = this.profile.birthday.split('-');
        let date = new Date(+year, +month - 1, +day);
        this.profile.birthday = date.toJSON();
      }

      // this.profile = event.params.fields;
      this.type = event.params.type;
    },

    updateProfile(){
      this.$emit("update-profile", this.profile);
    },

    beforeClosed(event){
    }
  },
}

</script>

<style lang="scss">
  .birthday-field{
    .vdatetime-input{
      height: 36px;
      padding: 7px 0;
      font-size: 14px;
      line-height: 1.5;
      width: 100%;
      border: 1px solid #EDEFF5;
      border-radius: 4px;
      background-image: none;
      padding-left: 10px;
      margin-top: 6px;
    }
  }
  .form-info {
    width: 100%;
    border: 1px solid #EDEFF5;
    border-radius: 4px;
    background-image: none;
    padding-left: 10px;
    margin-top: 6px;
  }
</style>
