import Swal from "sweetalert2";

const state = {};

const mutations = {};

const actions = {
  success({commit, dispatch}, message) {
    window.app.$notify({
      timeout: 5000,
      message: message,
      horizontalAlign: "right",
      verticalAlign: "top",
      icon: "add_alert",
      type: "success",
      isNoti: true,
    });
  },

  error({commit, dispatch}, message) {
    window.app.$notify({
      timeout: 7000,
      message: message,
      horizontalAlign: "right",
      verticalAlign: "top",
      icon: "add_alert",
      type: "warning",
      isNoti: true,
    });
  },

  firebaseNotification({commit, dispatch}, firebaseContent) {
    window.app.$notify({
      timeout: 7000,
      message: '',
      horizontalAlign: "right",
      verticalAlign: "bottom",
      type: "info",
      isFirebaseNoti: true,
      firebaseNotiContent: firebaseContent
    });
  },

  chatNoti({commit, dispatch}, chatContent) {
    window.app.$notify({
      timeout: 7000,
      message: '',
      horizontalAlign: "right",
      verticalAlign: "bottom",
      type: "info",
      isChatNoti: true,
      chatContent: chatContent
    });
  },

  chatNotiReaction({commit, dispatch}, chatContent) {
    window.app.$notify({
      timeout: 7000,
      message: '',
      horizontalAlign: "right",
      verticalAlign: "bottom",
      type: "info",
      isChatNotiReaction: true,
      chatContent: chatContent
    });
  }
};

const getters = {};

const alerts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default alerts;