<template>
  <div>
    <modal name="add-group-member-modal"
           :click-to-close="false"
           width='600px'
           height="auto"
           class="modal-custom add-group-member-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 modal-title">Thêm thành viên</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('add-group-member-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content>
          <div class="input-auto-complete">
            <div class="item-select" v-for="(item, index) in listItems" :key="item.id">
              <span>{{ item.fullname }}</span>
              <span class="close-icon" @click="removeItem(item, index)"><md-icon>close</md-icon></span>
            </div>
            <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group" v-model="member_params.keyword" @keyup="getEmployees">
          </div>
          <div class="content py-15px">
            <div v-for="member in memberList" :key="member.id">
              <md-list-item class="cursor-pointer user-detail">
                <md-avatar>
                  <image-template class="avatar" :size="'small'" :image="member.avatar_url" :thumbnail="member.thumbnail"/>
                </md-avatar>
                <div class="md-list-item-text">
                  <div class="text-400-medium mb-10px fullname">{{ member.fullname }}</div>
                  <div class="detail-info" v-if="member.agency">
                    <span class="material-icons">schema</span>
                    <span class="text-2-line text-info-name">
                        <span>{{ member.agency.name }}</span>
                        <span class="ml-5px text-300-default" v-if="member.team">></span>
                        <span class="ml-5px text-300-default" v-if="member.team">{{ member.team.name }}</span>
                    </span>
                  </div>
                  <div class="detail-info" v-else>
                    <span class="material-icons">schema</span>
                    <span class="text-2-line text-info-name">AZA GROUP</span>
                  </div>
                  <div class="detail-info" v-if="member.position">
                    <span class="material-icons">account_box</span>
                    <span class="text-info-name">{{ member.position.name }}</span>
                  </div>
                  <div class="detail-info">
                    <span class="material-icons">email</span>
                    <span class="text-info-name">{{ member.email }}</span>
                  </div>
                </div>
                <md-checkbox v-model="items" class="text-right mr-10px" :value="member.id"/>
              </md-list-item>
            </div>
          </div>
          <div class="md-gutter pt-15px pb-15px group-link-main">
            <label class="text-400-medium neutral_3">Chia sẻ liên kết</label>
            <div class="ml-15px group-link">
              <span class="text-300-default">{{currentUrl}}</span>
              <popover name="coppy" style="width: auto; padding: 4px 8px">Đã copy</popover>
              <img v-popover.top="{name: 'coppy'}" v-on:mouseleave="onMouseleave($event)" v-clipboard:copy="''+currentUrl" class="ml-15px" src="/img/coppy-link.svg" alt="">
              <span id="close-popover"></span>
            </div>
          </div>
        </md-card-content>

        <md-dialog-actions>
          <button type="submit" class="btn-active md-button heading-300" @click="save">
            <vue-element-loading :active="addMemberLoading" spinner="bar-fade-scale"/>
            Thêm
          </button>
          <button class="btn-stroke md-button heading-300" @click="$modal.hide('add-group-member-modal')">Huỷ</button>
        </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import EmployeeService from "../../../store/services/EmployeeService";
import GroupService from "../../../store/services/GroupService";
import ImageTemplate from "../../../components/ImageTemplate";

export default {
  name: 'add-group-member-modal',
  components: {
    ImageTemplate
  },
  computed: {
    hasNextPage() {
      return this.params.page < this.last_page;
    }
  },
  created() {
    this.getEmployees();
  },
  mounted() {
    this.currentUrl = window.location.origin + '/group/' + this.groupId;
  },

  watch: {
    groupId(newValue, oldValue) {
      this.currentUrl = window.location.origin + '/group/' + newValue;
    },
    items(newValue, oldValue) {
      this.listItems = this.memberListFilter.filter(item => newValue.includes(item.id));
    }
  },
  data: () => ({
    currentUrl: "hello",
    isLoading: false,
    params: {
      page: 1,
      per_page: 10,
    },
    last_page:1,
    search_text: "",
    addMemberLoading: false,
    member_params: {
      page: 1,
      per_page: 20,
      keyword: ""
    },
    position_params: {
      keyword: ""
    },
    member_last_page: 1,
    memberList: [],
    memberListFilter: [],
    items: [],
    listItems: [],
    groupId: null,
    timer: null
  }),

  methods: {
    save() {
      this.addMemberLoading = true;
      let memberIds = this.items;
      this.addMembers({groupId: this.groupId, members: memberIds});
    },

    beforeOpened(event) {
      this.items = [];
      this.groupId = event.params.groupId;
      // this.$store.commit("updateModalIsOpen", true);
    },
    beforeClosed(event){
      // this.$store.commit("updateModalIsOpen", false);
    },

    async addMembers(data) {
      await GroupService.addMembers(data).then(res => {
        if (res && res.status) {
          this.$store.dispatch("alerts/success", 'Bạn thêm thành viên thành công.');
          this.$modal.hide('add-group-member-modal');
          window.location.reload();
        } else {
          this.$store.dispatch("alerts/error", 'Bạn thêm thành viên không thành công.');
        }
      });
    },

    async getEmployees(params = {}) {

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(async () => {
        params = this.member_params;
        await EmployeeService.getEmployees(params).then(res => {
          if (res && res.data) {
            this.memberList = res.data
            this.memberListFilter = this.memberListFilter.concat(this.memberList);
            const unique = [...new Map(this.memberListFilter.map((m) => [m.id, m])).values()];
            this.memberListFilter = unique;
            this.member_last_page = res.meta.last_page;
          }
        });
      }, 500);
    },

    onMouseleave(e) {
      document.getElementById("close-popover").click();
    },

    reachedEndOfList(reached) {
      if (reached) {
        this.params.page++;
        this.getEmployees({keyword: this.search_text});
      }
    },
    removeItem(item, index) {
      this.items.splice(index, 1);
    },
  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
}

.group-link-main {
  display: flex;
  align-items: center;
}

.group-link {
  background: #EDEFF5;
  border-radius: 16px;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}

.md-dialog-actions {
  button {
    width: 100px !important;
    text-transform: none !important;
  }
}

.multiselect__content {
  height: 220px;
}

.add-group-member-modal {
  .md-list-item-content{
    min-height: auto;
  }
  .md-button{
    font-size: inherit;
  }
  .md-ripple {
    padding: 6px 10px !important;
  }
  .content {
    height: 350px;
    overflow-y: scroll;
  }

  .md-list-item:focus {
    background-color: rgb(241, 242, 244);
    border-radius: 8px;

    .check-icon {
      width: 20px;
      opacity: 1;
    }
  }

  .check-icon {
    width: 20px;
    opacity: 0;
    color: rgb(48, 169, 96);
  }

  .input-auto-complete {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border: none;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px 12px;
    min-height: auto !important;
    border-bottom: 1px solid #F0F0F0;

    .item-select {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 24px;
      margin: 5px 5px 0 0;
      background-color: rgb(236, 247, 231);
      border: none;
      border-radius: 6px;
      box-sizing: content-box;
      padding: 2px 8px;
      outline: 0px;
      font-weight: 500;
      overflow: hidden;
      color: rgb(48, 169, 96);
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
      width: fit-content;

      .close-icon {
        border-radius: 50%;
        margin-left: 2px;
        width: auto;
        height: auto;
        background: transparent;
        i {
          font-size: 18px !important;
        }
      }
    }

    .input-search-field {
      font-size: 14px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      width: 0px;
      min-width: 30px;
      -webkit-box-flex: 1;
      flex-grow: 1;
      border: 0px;
      margin: 0px;
      outline: 0px;
    }
  }

  .create-post-btn {
    width: 100% !important;
  }

  .md-dialog {
    .md-dialog-container {
      width: 515px !important;
    }
  }
  .md-checkbox .md-checkbox-container .md-ripple {
    overflow: hidden;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
