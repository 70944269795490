import BaseService from "./BaseService";

export default class PackageStickerService extends BaseService {

    static async getChatGroups(params = {}) {
        const res = await this.get(`/chat/groups`, params);
        return res.data;
    }

    static async createPackage(params = {}) {
        const res = await this.post(`/stickers/packages/add-with-stickers`, params);
        return res.data;
    }

    static async deletePackage(params = {}) {
        const res = await this.post(`/stickers/packages/${params.id}/delete`, params);
        return res.data;
    }
}
