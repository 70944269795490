<template>
  <div class="taglist-message">
    <div class="tag-message-type"
      v-if="mess.category"
      :class="{
          'active-normal' : mess.category == 'DEFAULT',
          'active-report' : mess.category == 'REPORT',
          'active-bonus' : mess.category == 'REWARD',
          'active-discipline' : mess.category == 'DISCIPLINE'
        }">
      <span>{{ categoryMessage[mess.category] }}</span>
    </div>
    <div class="taglist tag-list-date" v-if="mess.date">
      <span>{{ mess.date }}</span>
    </div>
    <div class="taglist tag-list-team" v-if="mess.team">
      <span>{{ mess.team }}</span>
    </div>
    <div class="taglist tag-list-place" v-if="mess.place">
      <span>{{ mess.place }}</span>
    </div>
    <div class="taglist" v-for="(other, index) in mess.others" :key="index">
      <span>{{ other }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "tag-chat-content",
    data() {
      return {
        categoryMessage: {
        'DEFAULT': 'KỶ LỤC MỚI',
        'REPORT': 'BÁO CÁO',
        'REWARD': 'KHEN THƯỞNG',
        'DISCIPLINE': 'KỶ LUẬT',
      }
      }
    },
    props: {
      mess: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    computed(){
    },
    methods: {

    }
  }
</script>
<style lang="scss">
.taglist-message {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  font-size: 13px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  .taglist {
    background: #a3eeff;
    border-radius: 5px;
    padding: 3px 6px;
    color: #343434;
  }
  .tag-message-type {
    padding: 3px 6px;
    color: #fff;
    border-radius: 2px;
  }
  .active-normal{
      background: #18a619;
    }
    .active-report{
      background: #376BFB;
    }
    .active-bonus{
      background: #FF7B00;
    }
    .active-discipline{
      background: #D14343;
    }
}
</style>