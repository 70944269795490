<template>
  <div class="sticker-manager__wrapper custom-scroll-bar-2">
    <!--    <vue-element-loading :active="isLoad" is-full-screen/>-->
    <div class="button-add-packages">
      <button class="btn-package cursor-pointer" @click="$modal.show('add-sticker-modal')">
        <md-icon>add</md-icon>
        Thêm mới gói sticker
      </button>
    </div>
    <div class="list-packages" style="height: calc(100% - 35px); overflow: auto;">
      <vue-loadmore
          :on-loadmore="getPackages"
          :finished="loading"
          :loading-text="'Đang tải'"
          :finished-text="'Hết'">
        <div class="packages-container">
        <div class="containers" v-for="(list, index) in packageList" :key="index">
          <div class="package-card-title d-flex-between mb-10px">
            <div class="title-package">{{ list.title }}</div>
            <div class="status-install">
              <!-- <div class="action-icon edit-package cursor-pointer">
                <img src="/img/chat/icon/edit.svg" alt="edit" />
                <span>Chỉnh sửa</span>
              </div> -->
              <div class="action-icon delete-package cursor-pointer" @click="deletePackage(list, index)">
                <img src="/img/chat/icon/delete.svg" alt="delete" />
                <span>Xóa</span>
              </div>
            </div>
          </div>
          <div class="package d-flex-between">
            <div class="image-avatar">
              <img :src="list.image_url || list.stickers[0].image_url">
            </div>
            <div class="list-package-demo">
              <div class="item-package" v-for="(sticker, index) in list.stickers" :key="sticker.id" v-if="index < 10">
                <img :src="sticker.image_url">
              </div>
            </div>
          </div>
        </div>
      </div>
      </vue-loadmore>
    </div>
    <add-sticker-modal @create_package="createdPackage($event)"></add-sticker-modal>
  </div>
</template>

<script>

import ChatService from "../../../../store/services/ChatService";
import PostService from "../../../../store/services/PostService";
import AddStickerModal from "../../../Components/Modal/AddStickerModal";
import PackageStickerService from '../../../../store/services/PackageStickerService';
export default {

  components: {
    AddStickerModal
  },

  data() {
    return {
      packageList: [],
      loading: false
    }
  },

  computed: {
  },

  created() {
    this.getPackages();
  },

  mounted() {
  },

  methods: {
    async getPackages(){
      this.loading = true;
      const formData = new FormData();
      formData.append("per_page", 60);
      formData.append("user_id", this.$store.state.auth.user.id);
      formData.append("page", 1);
      await ChatService.getPackages(formData).then(res => {
        if(res.data){
          let data = res.data.filter(function(item) {
            if (item.stickers.length > 0) {
              return true;
            }
            return false;
          }).map(function(item) { return item});
          this.packageList = data;
        }
      });
      this.loading = false;
    },

    deletePackage(item, index){
    let data = {
      id: item.id
    };

    this.$modal.show('dialog', {
        title: 'Xóa bộ sưu tập ' + item.title,
        text: 'Bạn có chắc chắn muốn xóa không?',
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Đồng ý',
            handler: () => {
              PackageStickerService.deletePackage(data).then(res => {
                if (res.status) {
                  this.packageList.splice(index, 1);
                }
              });
              this.$modal.hide('dialog')
            }
          }
        ]
      });
  },
  createdPackage(data){
    this.packageList.unshift(data);
  }
  },

  watch: {
  }
};

</script>


<style lang="scss">
.sticker-manager__wrapper{
  .button-add-packages{
    display: flex;
    justify-content: end;
    padding: 25px;
    .btn-package{
      background: #376BFB;
      border-radius: 4px;
      border: none;
      color: #fff;
      padding: 4px 12px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: 14px;
      font-family: 'Inter';
      font-style: normal;
      .md-icon{
        color: #FFFFFF;
      }
    }
  }
  .list-packages{
    .packages-container{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 5px;
        align-items: center;
        padding: 0 25px;
      }
      .containers{
        background: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
        border-radius: 12px;
        height: auto;
        min-height: 172px;
        margin-bottom: 12px;
        margin-right: 12px;
        padding: 10px;
        .image-avatar {
          width: 84px;
          height: 84px;
          margin-right: 8px;
          min-width: 84px;
        }
        .list-package-demo {
          display: flex;
          flex-wrap: wrap;
          margin-right: -4px;
          .item-package {
            width: 44px;
            height: 44px;
            border-radius: 8px;
            overflow: hidden;
            margin: 0px 4px 4px 0px;
          }
        }
        .status-install {
          color: rgb(77, 77, 77);
          border-radius: 4px;
          padding: 0px 4px;
          height: 24px;
          display: flex;
          border: none;
          align-items: center;
          justify-content: center;
          line-height: 16px;
          grid-gap: 10px;
          .action-icon{
            display: flex;
            justify-content: space-between;
            align-items: center;
            grid-gap: 5px;
          }
          .edit-package {
            background: #5CA222;
            border-radius: 2px;
            color: #fff;
            font-size: 12px;
            padding: 3px 10px;
          }
          .delete-package{
            border-radius: 2px;
            color: #fff;
            font-size: 12px;
            padding: 3px 10px;
            background: #FF5959;
          }
        }
        .uninstall{
          background: rgb(231, 231, 231);
        }
        .installed{
          background: rgb(48, 169, 96);
          color: #ffffff;
        }
      }
    }
  }
</style>