export const GROUP_TYPE = {
    //0: Nhóm làm việc - 1: Nhóm kín - 2: Nhóm bí mật - 3: Tường công ty - 4: Group
    WORK_GROUP: 0,
    PRIVATE_GROUP: 1,
    SECRET_GROUP: 2,
    COMPANY_WALL_GROUP: 3,
    BRANCH_GROUP: 4,
    TEAM_GROUP: 5,
    PINNED_GROUP: 111,
};

export const POST_TYPE = {
    TEXT: 'TEXT',                   //Bài viết chỉ có text
    IMAGE: 'IMAGE',                 //Bài viết có chứa 1 ảnh
    ALBUM: 'ALBUM',                 //Bài viết chứa nhiều ảnh
    SUB_ALBUM: 'SUB_ALBUM',         //Bài viết con cho từng ảnh trong bài viết ALBUM
    FILE: 'FILE',                   //Bài viết chứa file
    CREATE_GROUP: 'CREATE_GROUP',   //Bài viết khi tạo 1 group
    UPDATE_GROUP_AVATAR: 'UPDATE_GROUP_AVATAR',   //Bài viết đã thay đổi ảnh bìa của nhóm
    VIDEO: 'VIDEO',   //VIDEO
    POLL: 'POLL',
    STICKER: 'STICKER' 
};

export const CREATE_POST_LEVEL = {
    POST: '1',
    COMMENT: '2',
    REPLY_COMMENT: '3',
};

export const MODEL_TYPE = {
    USER: '1',
    GROUP: '2',
};

export const WORK_GROUP_TYPE = 0;
export const COMPANY_WALL_GROUP_TYPE = 3;
export const BRANCH_GROUP_TYPE = 4;
export const PRIVATE_GROUP_TYPE = 1;
export const CLANDESTINE_GROUP_TYPE = 2;
export const PRIVATE_GROUP_TYPE_NAME = 'Nhóm kín';
export const WORK_GROUP_TYPE_NAME = 'Nhóm làm việc';
export const CLANDESTINE_GROUP_TYPE_NAME = 'Nhóm bí mật';
export const COMPANY_WALL_GROUP_TYPE_NAME = 'Tường công ty';
export const BRANCH_GROUP_TYPE_NAME = 'Chi nhánh';
export const TEAM_GROUP_TYPE_NAME = 'Team';
export const TEAM_GROUP_TYPE = 5;

export const NOTIFICATION_TYPE = {
    ADD_MEMBER: 'ADD_MEMBER',
    CREATE_POST: 'CREATE_POST',
    LIKE_POST: 'LIKE_POST',
    COMMENT_POST: 'COMMENT_POST',
};

export const CHAT_NOTIFICATION_TYPE = {
    ACTION: 'ACTION',
    MENTION: 'MENTION',
    REPLY: 'REPLY',
};

export const TYPE_OPTIONS = [
    {
        id: 0,
        title: 'Nhóm làm việc',
        description: 'Nhóm làm việc và báo cáo hằng ngày của từng phòng ban chuyên biệt'
    },
    {
        id: 1,
        title: 'Nhóm Kín',
        description: 'Bất kỳ ai trong Công ty cũng có thể tìm được nhóm và xem thành viên nhóm. Chỉ thành viên có thể xem bài viết.'
    },
    {
        id: 2,
        title: 'Nhóm Bí mật',
        description: 'Chỉ thành viên mới tìm được nhóm, xem thành viên và những gì họ đăng.'
    }
];

export const GROUP_CHAT_TYPE = {
    USER: 'USER',
    BOT: 'BOT',
    POSITION: 'POSITION',
    DEPARTMENT: 'DEPARTMENT',
};

//SYSTEM|TEXT|IMAGE|VIDEO|FILE
export const MESSAGE_CHAT_TYPE = {
    SYSTEM: 'SYSTEM',
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    FILE: 'FILE',
};

//   - CREATE_GROUP: tạo nhóm
//   - CHANGE_GROUP_AVATAR: đổi avatar nhóm
//   - CHANGE_GROUP_NAME: đổi tên nhóm
//   - CREATE_USER_CHAT: chat 1-1
export const TEXT_CHAT_SYSTEM = {
    CREATE_GROUP: 'CREATE_GROUP',
    CHANGE_GROUP_AVATAR: 'CHANGE_GROUP_AVATAR',
    CHANGE_GROUP_NAME: 'CHANGE_GROUP_NAME',
    CREATE_USER_CHAT: 'CREATE_USER_CHAT',
    LEAVE_GROUP: 'LEAVE_GROUP',
    REMOVE_USER: 'REMOVE_USER',
    ADD_USER_GROUP: 'ADD_USER_GROUP',
    PIN_MESSAGE: 'PIN_MESSAGE',
    UNPIN_MESSAGE: 'UNPIN_MESSAGE'
};

export const TYPE_ADD_GROUP_MEMBER = {
    CREATE_GROUP: 'CREATE_GROUP',
    UPDATE_GROUP: 'UPDATE_GROUP',
};

export const NOTIFICATION_CHAT = {
    ON: 'ON',
    OFF: 'OFF',
};

export const BACKGROUND_IMAGE_LIST = [
    "https://image-1.gapo.vn/images/866ad07a-6d89-4233-a1f8-e085eed97ec9.png",
    "https://image-1.gapo.vn/images/fb009ca1-d40d-4722-a082-6b097f60768e.png",
    "https://image-1.gapo.vn/images/5ba07c21-8d05-465a-903f-5c83f916102a.png",
    "https://image-1.gapo.vn/images/ad2fd0fa-2cde-4ee5-a3c6-2eb5a7954734.png",
    "https://image-1.gapo.vn/images/8af29e58-2606-43d2-a033-02a00704ef3c.png",
    "https://image-1.gapo.vn/images/7ec797da-2859-4631-a9aa-2aa06ac1d874.png",
    "https://image-1.gapo.vn/images/c3bc9bab-c68d-4348-8fe1-5846599d922a.png",
    "https://image-1.gapo.vn/images/71f108e7-1da6-439c-9288-35036984a38e.png",
    "https://image-1.gapo.vn/images/06a0f48e-5d06-48ab-a974-048141e8c0cd.png",
    "https://image-1.gapo.vn/images/5c73fca4-16a8-4ba1-9f62-e10031fc1221.png",
    "https://image-1.gapo.vn/images/89639ea8-7f0f-4259-b539-70f528dad526.png",
    "https://image-1.gapo.vn/images/b0568fbd-cbf1-455e-8adf-ca89874d1326.png",
    "https://image-1.gapo.vn/images/46932665-7732-4719-ab85-5ebea99b9aed.png",
    "https://image-1.gapo.vn/images/5c41fa86-9915-4f2a-a33d-b88a84491892.png",
    "https://image-1.gapo.vn/images/b1a0a601-bd98-4b9c-913c-ff986fb18668.png",
    "https://image-1.gapo.vn/images/866ad07a-6d89-4233-a1f8-e085eed97ec9.png",
    "https://image-1.gapo.vn/images/fb009ca1-d40d-4722-a082-6b097f60768e.png",
    "https://image-1.gapo.vn/images/5ba07c21-8d05-465a-903f-5c83f916102a.png",
    "https://image-1.gapo.vn/images/ad2fd0fa-2cde-4ee5-a3c6-2eb5a7954734.png",
    "https://image-1.gapo.vn/images/8af29e58-2606-43d2-a033-02a00704ef3c.png",
    "https://image-1.gapo.vn/images/7ec797da-2859-4631-a9aa-2aa06ac1d874.png",
    "https://image-1.gapo.vn/images/c3bc9bab-c68d-4348-8fe1-5846599d922a.png",
    "https://image-1.gapo.vn/images/71f108e7-1da6-439c-9288-35036984a38e.png",
    "https://image-1.gapo.vn/images/06a0f48e-5d06-48ab-a974-048141e8c0cd.png",
    "https://image-1.gapo.vn/images/5c73fca4-16a8-4ba1-9f62-e10031fc1221.png",
    "https://image-1.gapo.vn/images/89639ea8-7f0f-4259-b539-70f528dad526.png",
];