<template>
  <div class="group-item">
    <div class="group-info">
      <router-link :to="getGroupLink(groupData.id)">
        <img :src="groupData.avatar_url ? groupData.avatar_url : groupImageDefault"
             class="group-img cursor-pointer" alt="">
      </router-link>
      <div class="w_100 text-center">
        <router-link :to="getGroupLink(groupData.id)">
          <p class="heading-500 group-name neutral_1 cursor-pointer hover-link">{{ groupData.name }}</p>
        </router-link>
        <span class="text-300-default group-member">
                <span>{{ groupData.total }} thành viên</span>
              </span>
        <div class="member-wrapper">
          <div class="avatar-wrapper cursor-pointer" v-for="member in groupData.members" :key="member.id + 'a'">
            <img :src="member.avatar_url || '/img/profile/avatar.png'">
          </div>
          <div class="avatar-wrapper cursor-pointer" v-if="groupData.total > 4" @click="redirectToGroupMembers">
            <img :src="'/img/icon-member-more.svg'">
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button v-if="!isWorkGroup" @click="showConfirmModal(groupData.name, groupData.id)"><img src="/img/out-group-icon.svg" alt="">
        </button>
        <button @click="toggleNotification(this.groupData)">
          <md-icon :class="{active: groupData.enable_notification === 'ON'}">notifications</md-icon>
        </button>
        <button v-if="!isWorkGroup" :class="{'icon-pinned' : groupData.is_pin === 1}" @click="handlePinGroup(groupData)"><img
            class="pin-img" :src="groupData.is_pin === 0 ? hasNotPinIcon : pinnedIcon" alt="">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import GroupService from "@/store/services/GroupService";
import {GROUP_TYPE} from "../../../../const/const";

export default {
  name: "group-item",
  data() {
    return {
      groupImageDefault: '/img/group/group_image_low.jpg',
      pinnedIcon: '/img/group/pinned_icon.svg',
      hasNotPinIcon: '/img/group/ic_unpin.svg',
      groupData: this.groupItem,
    }
  },
  props: {
    groupItem: {
      type: Object,
      default: () => {},
    },
    isWorkGroup: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handlePinGroup(groupItem) {
      let changePin = groupItem.is_pin === 0 ? 1 : 0;
      groupItem.is_pin = changePin;
      GroupService.pinGroup({groupId: groupItem.id, is_pin: changePin}).then(res => {
        this.$store.dispatch("getGroups");
        if (!changePin) {
          this.$store.dispatch("alerts/success", 'Bạn bỏ ghim nhóm ' + groupItem.name + ' thành công.');
        } else {
          this.$store.dispatch("alerts/success", 'Bạn ghim nhóm ' + groupItem.name + ' thành công.');
        }
      });
    },
    getGroupLink(id) {
      return '/group/' + id;
    },
    showConfirmModal(groupName, groupId) {
      this.$modal.show('dialog', {
        title: 'Rời khỏi?',
        text: 'Bạn có muốn rời khỏi nhóm ' + groupName + ' không?',
        buttons: [
          {
            title: 'Hủy',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Rời khỏi nhóm',
            handler: () => {
              outGroup(groupId);
            }
          }
        ]
      })
    },
    redirectToGroupMembers() {
      this.$router.push("/group/" + this.groupItem.id + "/members");
    },
    toggleNotification(group) {
      let newStage = group.enable_notification === 'ON' ? 'OFF' : 'ON';
      GroupService.toggleNotification(group.id, {stage: newStage}).then(res => {;
        if (res.status) {
          group.enable_notification = newStage;
        }
      });
    }
  }
};

function outGroup(groupId) {
  GroupService.outGroup({groupId: groupId}).then(res => {
    if (res && res.status) {
      window.location.reload();
    }
  });
}

</script>


<style lang="scss">
  .button-wrapper {
    .md-icon.active {
      color: #ffa940;
    }
  }
</style>