<template>
  <div>
    <div class="sideBarHeader">
      <h3 class="title-sidebar">Thông báo</h3>
      <div class="createMessage cursor-pointer">
        <button ref="readAllBtn" class="cursor-pointer" v-popover:foo
                aria-haspopup="true" aria-expanded="false" id="react-aria2936681137-126" style="user-select: none;">
          <svg width="24" height="24" fill="var(--spectrum-global-color-contentSecondary)" xmlns="http://www.w3.org/2000/svg" class="gapo-Icon" role="img" viewBox="0 0 24 24"><path d="M7 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM21 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" fill="#26282C"></path></svg>
        </button>
        <popover name="foo" class="text-400-medium">
          <div class="createChat createMessagePrivate cursor-pointer p-10px" @click="readAllNotification">
            <span>Đánh dấu tất cả là đã đọc</span>
          </div>
        </popover>
      </div>
    </div>
    <div class="cs-tab-menu pl-15px pr-15px mb-15px">
      <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 1 }" @click="chosenTab(1)">Tất cả</div>
      <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 2 }" @click="chosenTab(2)">Chưa đọc</div>
    </div>
    <div class="content-sidebar" style="height: 93vh; overflow: auto; padding-left: 5px !important;">
      <vue-element-loading :active="isLoading" spinner="bar-fade-scale"/>
      <vue-loadmore
          :on-loadmore="onLoad"
          :finished="finished"
          :loading-text="'Đang tải'"
          :finished-text="'Hết'">
        <div class="notification" v-for="notification of notifications" :key="notification.id">
          <div @click="onClickNotification(notification)">
            <router-link :to="notification.url"
                         :class="{'is_read': notification.is_read }"
                         class="cursor-pointer">
              <md-list-item class="p-0px m-0px">
                <div class="p-relative read-badge">
                  <md-avatar>

                    <img class="avatar" :src="!(notification.image instanceof Object) ? notification.image : '/img/profile/avatar.png'">
                  </md-avatar>
                  <md-badge v-if="!notification.is_read" class="md-primary p-absolute"></md-badge>
                </div>
                <div class="message text-300-default">
                  <template v-if="notification.type == NOTIFICATION_TYPE.ADD_MEMBER">Bạn đã được <span
                      class="text-300-medium">{{ detectData(notification.data).user.name }}</span>
                    thêm vào <span class="text-300-medium">{{
                        detectData(notification.data).group ? detectData(notification.data).group.name : ''
                      }}</span>
                  </template>
                  <template v-if="notification.type == NOTIFICATION_TYPE.CREATE_POST">
                    <span class="text-300-medium">{{ detectData(notification.data).user.name }}</span> đã đăng bài
                    vào <span class="text-300-medium">{{
                      detectData(notification.data).group ? detectData(notification.data).group.name : ''
                    }}</span>
                  </template>
                  <template v-if="notification.type == NOTIFICATION_TYPE.LIKE_POST">
                    <span class="text-300-medium">{{ detectData(notification.data).user.name }}</span>
                    đã thích bài viết của bạn trong <span
                      class="text-300-medium">{{
                      detectData(notification.data).group ? detectData(notification.data).group.name : ''
                    }}</span>
                  </template>
                  <template v-if="notification.type == NOTIFICATION_TYPE.COMMENT_POST">
                    <template
                        v-if="detectData(notification.data).created_by && detectData(notification.data).created_by.id != user.id">
                      <span class="text-300-medium">{{ detectData(notification.data).user.name }}</span> đã bình
                      luận bài viết của
                      <span class="text-300-medium">{{ detectData(notification.data).created_by.name }}</span>
                      trong
                      <span class="text-300-medium">{{
                          detectData(notification.data).group ? detectData(notification.data).group.name : ''
                        }}</span>
                    </template>
                    <template v-else>
                      <span class="text-300-medium">{{ detectData(notification.data).user.name }}</span> đã bình
                      luận bài viết của bạn trong
                      <span
                          class="text-300-medium">{{
                          detectData(notification.data).group ? detectData(notification.data).group.name : ''
                        }}</span>
                    </template>
                  </template>
                  <div class="create-time mt-5px text-200-default">{{ fromNow(notification.created_at) }}</div>
                </div>
              </md-list-item>
            </router-link>
          </div>
        </div>
      </vue-loadmore>
    </div>
  </div>
</template>
<script>
import {NOTIFICATION_TYPE} from "../../../../const/const";
import NotificationService from "../../../../store/services/NotificationService";
import moment from "moment";

export default {
  name: "notification-sidebar",
  mounted() {
    window.addEventListener("storage", this.storageListener);
  },
  created() {
    this.getUnreadCount()
    this.getNotifications()
  },
  destroyed() {
    window.removeEventListener("storage", this.storageListener);
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      unCountRead: 0,
      notifications: [],
      finished: false,
      params: {
        page: 1,
        per_page: 20,
      },
      last_page: 1,
      NOTIFICATION_TYPE: NOTIFICATION_TYPE,
      tabActive: 1,
      isLoading: false,
    }
  },
  methods: {
    getUnreadCount() {
      NotificationService.getUnreadCount().then(res => {
        if (res.data) {
          this.unCountRead = res.data.count;
          this.$store.commit("updateUnCountRead", this.unCountRead)
        }
      });
    },

    getNotifications(loadMore = false) {
      this.isLoading = true;
      let params = {...this.params};
      if (this.tabActive === 2) {
        params.has_read = 0;
      }
      NotificationService.getNotifications(params).then(res => {
        this.isLoading = false;
        if (res) {
          this.last_page = res.meta.last_page
          if (loadMore) {
            this.notifications = this.notifications.concat(res.data)
          } else {
            this.notifications = res.data;
          }
        }
      });
    },

    onRefresh(done) {
      this.notifications = [];
      this.params.page = 1;
      this.finished = false;
      this.getNotifications();
      done();
    },

    onLoad(done) {
      if (this.params.page < this.last_page) {
        this.params.page++
        this.getNotifications(true);
      } else {
        this.finished = true;
      }
      done();
    },

    fromNow(date) {
      return moment(date).locale("vi").fromNow();
    },

    detectData(data) {
      let jsonData = JSON.parse(data);
      return jsonData
    },

    onClickNotification(notification) {
      if (!notification.is_read) {
        NotificationService.readNotifications(notification.id).then(res => {
        });
        notification.is_read = true;
        this.unCountRead--
        this.$store.commit("updateUnCountRead", this.unCountRead);
      }
    },

    storageListener() {
      let firebaseMessage = JSON.parse(localStorage.getItem("firebase_message"));
      let createCategory = JSON.parse(localStorage.getItem("create_category"));
      if (firebaseMessage != null) {
        firebaseMessage.is_read = false
        firebaseMessage.id = this.notifications['0'].id + 1;
        this.unCountRead++;
        this.$store.commit("updateUnCountRead", this.unCountRead);
        this.notifications = [firebaseMessage].concat(this.notifications);
        localStorage.removeItem("firebase_message")
      }
      if (createCategory != null) {
        this.isCategory = true
        this.categories = createCategory
        localStorage.removeItem("create_category")
        this.getCategories()
      }
    },
    chosenTab(tab) {
      this.refreshParams();
      this.tabActive = tab;
      this.getNotifications();
    },
    readAllNotification() {
      NotificationService.readAllNotification().then(res => {
        if (res) {
          this.refreshParams();
          this.getNotifications();
          this.$store.commit("updateUnCountRead", 0);
          this.$refs.readAllBtn.click();
        }
      });
    },
    refreshParams() {
      this.params = {
        page: 1,
        per_page: 20,
      };
    }
  },
};
</script>
<style lang="scss">
.notification {
  .create-time {
    color: #0066ff;
    font-weight: 500;
  }

  .is_read {
    .message {
      opacity: 0.8;
      white-space: break-spaces;

      .create-time {
        color: #1f1f1f;
        font-weight: 400;
      }
    }
  }

  .md-list-item-content {
    align-items: start;
    padding: 5px !important;

    .read-badge {
      margin-right: 5px;

      .md-badge {
        top: inherit !important;
        width: 10px;
        height: 10px;
        background-color: #0066ff !important;
        position: absolute !important;
      }
    }

    .md-list-item-text {
      white-space: initial;
    }

    .message {
      white-space: break-spaces;
    }
  }

  .router-link-active {
    li {
      background-color: #FFEB7D !important;
    }

    .message {
      color: black;
    }
  }
}
</style>
