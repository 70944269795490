import BaseService from "./BaseService";

export default class EmployeeService extends BaseService {
    static async getEmployees(params = {}) {
        const res = await this.get(`/employees`, params);
        return res.data;
    }

    static async getUsers(params = {}) {
        const res = await this.get(`/users/votes`, params);
        return res.data;
    }

    // async create(params = {}) {
    //     const res = await this.post(`/api/v1/users`, params);
    //     return res.data;
    // }
    //
    // async update(id, params = {}) {
    //     const res = await this.post(`/api/v1/users/${id}`, params);
    //     return res.data;
    // }
    //
    // async show(id, params = {}) {
    //     const res = await this.get(`/api/v1/users/${id}`, params);
    //     return res.data;
    // }
    //
    // async destroy(id) {
    //     const res = await this.delete(`/api/v1/users/${id}`);
    //     return res.data;
    // }
}
