<template>
  <div class="righ-bar">
    <md-card class="group-info-block background_neutral_white">
      <div>
        <div class="title text-400-medium">
          Giới thiệu
        </div>
        <template v-if="data.type == 'user'">
          <div class="user-info-container">
            <div class="info-text neutral_1">
              <div class="detail-info" v-if="data.profile.parent_departement">
                <span class="material-icons">schema</span>
                <span class="text-400-semibold">{{data.profile.parent_departement.name}} > {{ data.profile.department.name }}</span>
              </div>
              <div class="detail-info" v-else>
                <span class="material-icons">schema</span>
                <span class="text-400-semibold">{{ data.profile.department.name }}</span>
              </div>
              <div class="detail-info" v-if="data.profile.position">
                <span class="material-icons">account_box</span>
                <span class="text-400-semibold">{{ data.profile.position.name }}</span>
              </div>
              <div class="detail-info">
                <span class="material-icons">email</span>
                <span class="">{{ data.profile.email }}</span>
              </div>
              <div class="detail-info">
                <span class="material-icons">cake</span>
                <span class="">{{ data.profile.birthday }}</span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="neutral_2 text-400-default">{{ description ? description : "Chưa có mô tả" }}</div>
        </template>
      </div>
    </md-card>

    <md-card class="group-info-block background_neutral_white" v-if="imageList.length !== 0">
      <div>
        <div class="title text-400-medium">
          Ảnh/Video
        </div>
        <div class="image-list__container">
          <div class="image-item cursor-pointer" v-for="(item, index) in imageList" :key="'image-item' + index"
               @click="redirectToPostDetail(item)">
            <image-template :size="'medium'" :image="item.path" :thumbnail="item.thumbnail"/>
          </div>
        </div>

        <button class="image-view-more-btn mt-10px text-400-medium cursor-pointer" @click="redirectToImageList">Xem tất
          cả
        </button>
      </div>
    </md-card>

    <md-card class="group-info-block background_neutral_white">
      <div>
        <div class="title text-400-medium">
          Chia sẻ liên kết
        </div>
        <div class="group-link">
          <span class="text-300-default">{{ currentUrl }}</span>
          <img v-popover.top="{name: 'coppy'}" v-on:mouseleave="onMouseleave($event)" v-clipboard:copy="''+currentUrl"
               class="ml-15px" src="/img/coppy-link.svg" alt="">
        </div>
        <span id="close-popover2"></span>
        <popover class="custom-popover-coppy" name="coppy" style="width: auto; padding: 4px 8px">Đã copy</popover>
      </div>
    </md-card>
  </div>
</template>

<script>

    import ImageTemplate from "../../../components/ImageTemplate";

    export default {
  name: "group-info",
  components: {
    ImageTemplate
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  props: {
    description: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: () => {
      },
    },
    imageList: {
      type: Array,
      default: () => []
    }
  },

  created() {
  },

  watch: {
    data(newValue, oldValue) {
      if (this.data.type == 'group') {
        this.currentUrl = window.location.origin + '/group/' + newValue.id;
      }
      if (this.data.type == 'user') {
        this.currentUrl = window.location.origin + '/profile/' + newValue.id;
      }
    }
  },

  mounted() {
    if (this.data.type == 'group') {
      this.currentUrl = window.location.origin + '/group/' + this.data.id;
    }
    if (this.data.type == 'user') {
      this.currentUrl = window.location.origin + '/profile/' + this.data.id;
    }
  },

  methods: {
    onMouseleave(e) {
      document.getElementById("close-popover2").click();
    },
    redirectToPostDetail(media) {
      if(media.single_post_id){
        this.$store.dispatch('refreshPostTotalPage');
        this.$store.state.post.previousDetail = 2;
        let link = this.getLink(media.single_post_id);
        this.$router.push(link);
      }
    },
    getLink(singlePostId) {
      return '/post/image/' + singlePostId
    },
    redirectToImageList() {
      if (this.data.type === 'group') {
        this.$router.push('/group/' + this.data.id + '/files?mediaTab=image');
      } else if (this.data.type === 'user') {
        this.$router.push('/profile/' + this.data.id + '/files');
      }
    }
  }
};
</script>

<style lang="scss">
.group-info-block {
  padding: 20px 15px;

  .title {
    margin-bottom: 15px;
    font-weight: 500;
  }
}

.group-link {
  background: #EDEFF5;
  border-radius: 16px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;

  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}

.custom-popover-coppy {
  left: 240px !important;
  top: 15px !important;
}

.group-info-block {
  z-index: unset !important;
}
.righ-bar {
  .user-info-container {
    display: flex;
    width: 100%;
    .detail-info {
      display: flex;
      margin-bottom: 8px;
      font-size: 14px;

      .material-icons {
        font-size: 20px;
        color: #747474;
        margin-right: 10px;
      }
    }
  }

  .image-list__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .image-item {
      width: 32%;
      height: 90px;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 1px solid #d6d6d6;
        border-radius: 4px;
      }
    }
  }
  .image-view-more-btn {
    background: #e2e2e2;
    border-radius: 2px;
    padding: 8px;
    width: 100%;
    border: none;
    &:hover {
      background: #bfbfbf;
    }
  }
}

</style>
