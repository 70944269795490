<template>
  <div>
    <div class="video-message" v-for="(media, indexVideo) in currentMess.files"
         :key="indexVideo">
      <div class="video-content">
        <video class="video" :id="media.id"
               :poster="media.thumbnail.medium_thumb">
          <source :src="media.path">
          Sorry, your browser doesn't support embedded videos.
        </video>
        <div class="video-overlay" :class="'overlay'+ media.id" :data-id="media.id">
          <div class="play-button material-symbols-outlined">play_circle</div>
          <div class="replay-button" style="display: none">Xem lại</div>
        </div>
      </div>
    </div>
    <div class="emojis" v-if="displayEmoji"
         @click="$modal.show('reaction-message-detail-modal', {message_id : currentMess.id})">
      <div v-for="(emoji_icon, icon) in currentMess.reaction_stats" v-if="emoji_icon > 0"
           :key="icon" class="emoji-wrapper">
        <span class="mr-2px text-300-medium neutral_3">{{ emoji_icon }}</span>
        <div :class="'reaction-' + icon" class="emoji_icon"></div>
      </div>
    </div>
    <div class="content-display-timestamp mt-5px mr-10px"
         v-if="!isMessageInBlock">
      <span>{{ messageTime }}</span>
    </div>
  </div>
</template>

<script>
  import {helpers} from "../../../../../helper/helpers";

  export default {
    name: "video-chat-content",
    components: {},
    props: {
      displayName: {
        type: String,
        default: "",
      },
      currentMess: {
        type: Object,
        default: () => {
          return {};
        }
      },
      nextMess: {
        type: Object,
        default: null
      }
    },
    mounted() {
      $(document).ready(function () {
        $('.video-overlay').on('click', function () {
          var id = $(this).data("id");
          $('#' + id).trigger('play');
          $('#' + id).attr('controls',true);
        });

        $('.video').on('ended', function () {
          var overlayId = '.overlay' +$(this).attr("id");
          $(overlayId).show();
          $(overlayId).children('.replay-button').show();
          $(overlayId).children('.play-button').hide();
          $(this).attr('controls',false);
        });

        $('.video').on('play', function () {
          var overlayId = '.overlay' +$(this).attr("id");
          $(overlayId).hide();
        });
      })
    },
    computed: {
      displayEmoji() {
        return helpers.sumEmoji(this.currentMess.reaction_stats) > 0
      },
      messageTime() {
        return helpers.getMessageTime(this.currentMess)
      },
      isMessageInBlock() {
        return helpers.checkMessageInBlock(this.currentMess, this.nextMess)
      }
    },
    methods: {
      dowloadFile(item) {
        window.open(item);
      },
      bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
      },
    }
  }
</script>