<template>
  <div class="rule-container">
    <button class="btn-active md-button" @click="$modal.show('update-rule-group')" v-if="is_admin">Thêm/Chỉnh sửa</button>
    <md-content v-if="content" class="content-rule mt-15px" v-html="content"></md-content>
    <div class="text-400-default text-center neutral_2 mt-15px" v-else>
      Không có mô tả nào
    </div>
    <update-rule-group @update-rule="updateGroup"></update-rule-group>
  </div>
</template>

<script>
import UpdateRuleGroup from "../../../../Components/Modal/UpdateRuleGroup";
import GroupService from "../../../../../store/services/GroupService";

  export default {
    name: "tag-chat-content",
    components: {
      UpdateRuleGroup
    },
    data() {
      return {
        content: "",
        descriptionGroup: {
          content: '',
          type: '',
          name: '',
          category_id: '',
          join_policy: '',
        },
        is_admin: false
      }
    },
    props: {
    },
    mounted(){
      this.content = this.$store.state.chat.chatGroupSelected.description;
      this.is_admin = this.$store.state.chat.chatGroupSelected.is_admin === 1;
    },
    methods: {
      updateGroup(rule_content){
        let selected = this.$store.state.chat.chatGroupSelected;
        this.descriptionGroup.content = rule_content;
        this.descriptionGroup = {
          content: rule_content,
          type: 0,
          name: selected.name,
          // category_id: selected.name,
          join_policy: selected.settings.join_policy,
        }
        GroupService.updateGroup(selected.id, this.descriptionGroup).then(res => {
          console.log(res);
          if (res.status) {

            let chatGroups = this.$store.state.chat.chatGroups;
            chatGroups.map((item) => {
                  if (item.id == this.$route.params.messenger_id) {
                    item.description = rule_content;
                    this.$store.commit('chat/updateChatGroupSelected', item);
                  }
                }
            );

            this.$store.commit('chat/updateChatGroups', chatGroups);
            this.content = rule_content;
            this.$modal.hide('update-rule-group');
            this.$store.dispatch("alerts/success", 'Cập nhật nội quy thành công');
          }else{
            this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra!');
          }
        });
    },
    }
  }
</script>
<style lang="scss">
.rule-container{
  .md-button{
    width: 100%;
    padding: 10px;
    background-color: #30A960 !important;
  }
  .content-rule{
  line-height: 2;
  margin: 10px 0 10px;
  p {
    margin: 0;
  }
  ol{
    padding-left: 15px;
  }
  ul{
    // padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
    li{
      position: relative;
      padding-left: 15px;
      // margin: 5px;
      line-height: 20px;
      &::before{
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: black;
      }
    }
  }
  s {
    text-decoration: line-through;
  }
  }
  .update-rule-group{
    .handle-button-group-name{
      margin-top: 10px;
      float: right;
      grid-gap: 10px;
      display: flex;
    }
    .button-rule{
      border: 1px solid #dbdbdb;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
}
</style>