<template>
  <div class="pb-30px message-sidebar__container">
    <div class="sideBarHeader">
      <h3 class="title-sidebar">Trò chuyện</h3>
      <div class="createMessage cursor-pointer">
        <button class="cursor-pointer" v-popover:tooltip.bottom="'Soạn tin nhắn'" v-popover:foo
                aria-haspopup="true" aria-expanded="false" id="react-aria2936681137-126" style="user-select: none;">
          <svg
              width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 23H2C1.73478 23 1.48043 22.8946 1.29289 22.7071C1.10536 22.5196 1 22.2652 1 22V5C1 4.73478 1.10536 4.48043 1.29289 4.29289C1.48043 4.10536 1.73478 4 2 4H9C9.55229 4 10 4.44772 10 5C10 5.55228 9.55228 6 9 6H3V21H18V15C18 14.4477 18.4477 14 19 14C19.5523 14 20 14.4477 20 15V22C20 22.2652 19.8946 22.5196 19.7071 22.7071C19.5196 22.8946 19.2652 23 19 23Z"
                fill="#30A960"></path>
            <path
                d="M18.7931 1.70685L17.2071 3.29289C16.8166 3.68342 16.8166 4.31658 17.2071 4.70711L19.2068 6.7068C19.5973 7.09733 20.2305 7.09733 20.621 6.7068L22.2071 5.12076C22.5976 4.73024 22.5976 4.09708 22.2071 3.70655L20.2074 1.70685C19.8168 1.31633 19.1837 1.31633 18.7931 1.70685Z"
                fill="#30A960"></path>
            <path
                d="M16.2921 5.70711C15.9016 5.31658 15.2684 5.31658 14.8779 5.70711L8.12497 12.46L6.7342 16.6323C6.6039 17.0232 6.97577 17.3951 7.36665 17.2648L11.539 15.874L18.2919 9.12111C18.6824 8.73058 18.6824 8.09742 18.2919 7.70689L16.2921 5.70711Z"
                fill="#30A960"></path>
          </svg>
        </button>
        <popover name="foo" class="text-400-medium">
          <div class="createChat createMessagePrivate cursor-pointer p-10px" @click="$modal.show('member-list-modal')">
            <span>Tạo tin nhắn riêng</span>
          </div>
          <div class="createChat createGroupChat cursor-pointer p-10px" @click="$modal.show('add-user-modal')">
            <span>Tạo nhóm chat</span>
          </div>
          <div class="createChat createGroupChat cursor-pointer p-10px"
               @click="$modal.show('request-join-chat-group-modal')">
            <span>Yêu cầu vào nhóm chat</span>
          </div>
        </popover>
      </div>
    </div>
    <div>
      <div class="convensations">
        <div class="input-container text-center">
          <input class="input-search-field py-20px" autocomplete="off" @focus="getConvensation()"
                 @input="getConvensation()"
                 v-model="search_convensation" type="text" placeholder="Tìm kiếm cuộc trò chuyện" name="search-group"/>
          <span class="button-close-convensation" v-if="convensations.active" @click="refreshDone">Đóng</span>
        </div>
        <template v-if="convensations.active">
          <div class="cs-tab-menu pl-15px pr-15px mb-15px">
            <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 5 }" @click="chosenTab(5)">Tất
              cả
            </div>
            <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 6 }" @click="chosenTab(6)">Nhóm
            </div>
            <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 7 }" @click="chosenTab(7)">Cá
              nhân
            </div>

          </div>
          <div class="dropDownConvensation">
            <div class="text-400-default header">{{ search_convensation ? 'Kết quả tìm kiếm' : 'Kết quả tìm kiếm gần đây' }}<span v-if="!!search_convensation"> cho "<span
                class="text-400-medium">{{ search_convensation }}</span>"</span></div>
            <div class="listConvensations" v-if="convensations.items.length > 0">
              <md-list-item class="conversation cursor-pointer" @click="goToGroupChat(convensation)"
                            :class="convensation.type == 'GROUP'? 'group-type' : 'user-detail'"
                            :key="convensation.group_id" v-for="convensation in convensations.items"
                            :set="model = convensation.model">
                <md-avatar>
                  <img v-if="convensation.type == 'GROUP'" class="avatar"
                       :src="convensation.model.avatar || '/img/group/user-group.svg'">
                  <img v-else class="avatar"
                       :src="model.thumbnail && model.thumbnail.small_thumb || '/img/profile/avatar.png'">
                </md-avatar>

                <div class="md-list-item-text" v-if="convensation.type == 'USER'">
                  <div class="text-400-medium mb-10px fullname">{{ model.full_name }}</div>
                  <div class="detail-info" v-if="model.team.agency">
                    <span class="material-icons">schema</span>
                    <span class="text-2-line text-info-name">
                      <span>{{ model.team.agency }}</span>
                      <span class="ml-5px text-300-default" v-if="model.team.department">></span>
                      <span class="ml-5px text-300-default" v-if="model.team.department">{{ model.team.department }}</span>
                  </span>
                  </div>
                  <div class="detail-info" v-else>
                    <span class="material-icons">schema</span>
                    <span class="text-2-line text-info-name">AZA GROUP</span>
                  </div>
                  <div class="detail-info" v-if="model.team.team">
                    <span class="material-icons">group</span>
                    <span class="text-info-name">{{ model.team.team }}</span>
                  </div>
                  <div class="detail-info" v-if="model.position">
                    <span class="material-icons">account_box</span>
                    <span class="text-info-name">{{ model.position }}</span>
                  </div>
                </div>
                <div class="md-list-item-text" v-else>
                  <div class="text-400-medium title">{{ model.name }}</div>
                  <div class="" v-if="convensation.type == 'GROUP'">
                    <span class="text-300-default">{{ convensation.model.member_count }} thành viên</span>
                  </div>
                </div>
              </md-list-item>
            </div>

            <div v-if="(convensations.loading || convensations.items.length == 0)">
              <div class="text-400-default px-15px py-10px">{{
                  (convensations.items.length === 0 && form.keyword !== '') && !convensations.loading ? 'Không tìm thấy kết quả' : ''
                }}
              </div>
              <vue-element-loading class="py-20px" :active="convensations.loading"/>
            </div>
          </div>
        </template>
      </div>
      <template v-if="!convensations.active">
        <div class="cs-tab-menu pl-15px pr-15px mb-15px">
          <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 1 }" @click="chosenTab(1)">Tất cả
          </div>
          <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 4 }" @click="chosenTab(4)">Group
          </div>
          <!--        <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 2 }" @click="chosenTab(2)">Chưa đọc</div>-->
          <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 3 }" @click="chosenTab(3)">Hoạt
            động
          </div>

        </div>
        <div v-if="tabActive !== 3">
          <div v-if="chatGroups.length > 0" style="height: calc(100vh - 174px); overflow: auto;">
            <vue-loadmore
                :on-loadmore="onLoad"
                :finished="finished"
                :loading-text="'Đang tải'"
                :finished-text="'Hết'">
              <div class="message-item p-relative" v-for="(item, index) in chatGroups" :key="item.id">
                <router-link @click.native="goToChatgroup(item)" :to="getPath(item.id)" class="cursor-pointer">
                  <md-list-item class="p-0px m-0px">
                    <div class="p-relative read-badge">
                      <md-avatar>
                        <image-template class="avatar" :size="'small'" :thumbnail="item.thumbnail"/>
                      </md-avatar>
                    </div>
                    <div class="message text-300-default md-list-item-text">
                      <div class="message-name" v-bind:class="item.type === 'BOT' ? 'bot-message-container' : ''">
                        <span class="name_user"
                              v-bind:class="item.unread_count > 0 ? 'neutral_2 text-400-bold' : 'neutral_2 text-400-default'">{{
                            item.name
                          }}</span>
                        <div class="bot-message" v-if="item.type === 'BOT'">
                          <span class="verified"><svg width="16px" height="16px"
                                                      fill="var(--spectrum-global-color-informativePrimary)"
                                                      xmlns="http://www.w3.org/2000/svg" class="gapo-Icon" role="img"
                                                      viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd"
                                                                                d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm4.724-13.31a1 1 0 1 0-1.448-1.38l-4.951 5.199-1.566-1.827a1 1 0 0 0-1.518 1.302l2.285 2.667a1 1 0 0 0 1.484.039l5.714-6Z"
                                                                                fill="#18202A"></path></svg></span>
                          <div class="bot-chat" style="display: inline-flex; align-items: center;"><span
                              class="bot-name">Bot</span></div>
                        </div>
                      </div>
                      <div class=""
                           v-bind:class="item.unread_count > 0 ? 'neutral_2 text-300-bold' : 'neutral_3 text-300-default'">
                        <span class="text-1-line wb-break-all">{{ getName(item) }}</span>
                      </div>
                    </div>
                    <div class="message-time"
                         v-bind:class="item.unread_count > 0 ? 'neutral_2 text-200-medium' : 'neutral_3 text-200-default'">
                      <span class="notifications_chat material-symbols-rounded" v-if="isNoty(item.noty)">notifications_off</span>
                      <span class="notifications_chat" v-if="item.pin_at">
                        <svg width="16px" height="16px" fill="#4a65ff" xmlns="http://www.w3.org/2000/svg"
                             class="aza-Icon" role="img" viewBox="0 0 24 24">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M22 8.001v-.002a.997.997 0 0 0-.293-.706l-.983-.984a.94.94 0 0 0-.033-.032L17.723 3.31a.93.93 0 0 0-.032-.033l-.984-.983a1 1 0 1 0-1.414 1.414l.055.055L5.53 9.117l-.824-.824a1 1 0 0 0-1.414 1.414L8.086 14.5l-3.793 3.793a1 1 0 1 0 1.414 1.414L9.5 15.914l4.793 4.793a1 1 0 0 0 1.414-1.414l-.824-.824 5.355-9.817.055.055A1 1 0 0 0 22 8.001Z"
                                fill="#18202A">
                          </path>
                        </svg>
                      </span>
                      <span class="pl-2x pt-2x">{{
                          fromNow(item.last_message ? item.last_message.created_at : item.created_at)
                        }}</span>
                    </div>
                    <md-badge v-if="item.unread_count > 0" :class="'noty_' + item.noty"
                              :md-content="item.unread_count < 100 ? item.unread_count : '99+'"></md-badge>
                  </md-list-item>
                </router-link>

                <template v-if="item.type !== 'BOT'">
                  <div class="list-option-sidebar">
                    <div class="option-chat-group">
                    <div class="options">
                      <div class="button-more" @click="showOption(item.id)">
                        <md-icon class="cursor-pointer neutral_2 gs-more-btn">more_horiz</md-icon>
                      </div>
                      <transition name="fade">
                        <div class="list-option text-400-default" v-show="option == item.id">
                          <div class="pin-convensation option" @click="pinChatGroup(item, index)">
                            {{ item.pin_at ? 'Bỏ ghim' : 'Ghim' }} nhóm chat
                          </div>
                          <div class="hide-convensation option" @click="hiddenChatGroup(item.id)">Ẩn cuộc trò chuyện</div>
                          <div class="hide-convensation option" @click="notificationChange(item.noty)" :class="item.noty">
                            {{ item.noty === 'ON' ? 'Tắt' : 'Bật' }} thông báo
                          </div>
                        </div>
                      </transition>
                    </div>
                    </div>
                  </div>
                </template>
              </div>
            </vue-loadmore>
          </div>
          <div v-else class="w_100">
            <img src="/img/chat/no_message.png" class="img-no-message">
            <p class="text-500-medium neutral_3 text-center">
              {{ tabActive == 1 ? "Hãy bắt đầu trò chuyện" : "Bạn đã đọc hết tin nhắn" }}</p>
          </div>
        </div>
        <div v-else class="content-sidebar chat-notification__wrapper"
             style="height: 80vh; overflow: auto; padding: 0 0 0 5px !important;">
          <vue-element-loading :active="loadingNotification" spinner="bar-fade-scale"/>
          <vue-loadmore
              :on-loadmore="onLoadForNotification"
              :finished="notificationPaging.finished"
              :loading-text="'Đang tải'"
              :finished-text="'Hết'">
            <div class="activity" v-for="notification in notifications" :key="'chat_notification_' + notification.id">
              <div @click="readNotification(notification)" :class="notification.is_read ? '' : 'unread'">
                <div @click="redirectToMessage(notification)"
                     class="cursor-pointer">
                  <md-list-item class="p-0px m-0px">
                    <div class="p-relative read-badge">
                      <md-avatar>
                        <image-template class="avatar" :size="'small'" :image="'/img/profile/avatar.png'"
                                        :thumbnail="notification.reaction_user.thumbnail"/>
                      </md-avatar>
                      <div v-if="notification.type == CHAT_NOTIFICATION_TYPE.ACTION" class="reaction"
                           :class="getReactionIconClass(notification)"></div>
                      <div v-else-if="notification.type == CHAT_NOTIFICATION_TYPE.MENTION" class="mention">
                        <md-icon>alternate_email</md-icon>
                      </div>
                      <div v-else-if="notification.type == CHAT_NOTIFICATION_TYPE.REPLY" class="reply">
                        <md-icon>reply</md-icon>
                      </div>
                    </div>
                    <div class="message text-300-default" :class="notification.is_read ? 'neutral-3' : 'neutral-1'">
                      <div class="d-flex" :class="notification.is_read ? 'text-300-medium' : 'text-300-bold'">
                        <span class="w_100 title">
                          {{ notification.reaction_user.fullname }} {{ getActionTextForNotification(notification) }}</span>
                      </div>
                      <div class="mt-2px w_95 ellipsis">{{ notification.message.text }}</div>
                      <div class="w_95 ellipsis" v-if="notification.chat_group">Nhóm:
                        {{ notification.chat_group.name }}
                      </div>
                      <div class="w_95 ellipsis" v-else>
                        {{ 'Trò chuyện với ' + getNameForUser(notification.reaction_user) }}
                      </div>
                      <div class="mt-2px text-200-default"
                           :class="notification.is_read ? 'neutral-3' : 'secondary_b400'">
                        {{ fromNow(notification.created_at) }}
                      </div>
                    </div>
                  </md-list-item>
                </div>
              </div>
            </div>
          </vue-loadmore>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ChatService from "../../../../store/services/ChatService";
import {
  MESSAGE_CHAT_TYPE,
  TEXT_CHAT_SYSTEM,
  TYPE_ADD_GROUP_MEMBER,
  NOTIFICATION_CHAT,
  CHAT_NOTIFICATION_TYPE
} from "../../../../const/const";
import ImageTemplate from "../../../../components/ImageTemplate";

export default {
  name: "message-sidebar",
  components: {
    ImageTemplate
  },
  watch: {
    '$store.state.chat.chatGroupSelected.last_message.created_at'() {
      let data = this.$store.state.chat.chatGroups;
      data.sort(function (a, b) {
        if (a.pin_at || b.pin_at) {
          return new Date(b?.pin_at) - new Date(a?.pin_at);
        }
        return new Date(b?.last_message?.created_at || b?.created_at) - new Date(a?.last_message?.created_at || a?.created_at);
      });
      this.$store.commit('chat/updateChatGroups', data);
    },
    '$store.state.chat.dataSocket'(newV) {
      let chatGroups = this.$store.state.chat.chatGroups;
      let isGroupInData = chatGroups.filter((item) => {
            return item.id == newV.group_id;
          }
      )

      if (isGroupInData.length == 0) {
        this.getChatGroups();
      } else {
        chatGroups.map((item) => {
          if (item.id == newV.group_id) {
            item.unread_count += newV.sender.id === this.user.id ? 0 : 1;
            item.last_message = newV
          }
        })
      }

      chatGroups.sort(function (a, b) {
        if (a.pin_at || b.pin_at) {
          return new Date(b?.pin_at) - new Date(a?.pin_at);
        }
        return new Date(b?.last_message?.created_at || b?.created_at) - new Date(a?.last_message?.created_at || a?.created_at);
      });

      this.$store.commit('chat/updateChatGroups', chatGroups);
      this.$store.dispatch('chat/getUnreadGroupChat');
    },
    '$store.state.chat.chatGroups.length'() {
      this.chatGroups = this.$store.state.chat.chatGroups;
    },
    '$store.state.chat.modifyMessageSocket'(newV) {

      if (newV.type == "update_avatar") {
        this.getChatGroups(this.page != 1);
      }

      if (newV.type === "PIN_CHAT_GROUP") {
        this.getChatGroups(this.page != 1, true);
      }
    },
    '$store.state.chat.reconnectSocket'() {
      this.$store.dispatch('chat/getUnreadGroupChat');
    },
    isOnLine(newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        if (this.router_name === 'messenger') {
          // console.log('isOnLine and have call api get message');
          this.getChatGroups();
          this.$store.dispatch('chat/getUnreadGroupChat');
        }
      }
    },
  },
  async created() {
    if(!this.$store.state.chat.chatGroupSelected.isCreateChat){
      await this.getChatGroups();
      if (this.chatGroups.length > 0 && !this.$store.state.chat.chatGroupSelected.id) {
        this.$router.push("/messenger/" + this.chatGroups[0]['id']);
      } else {
        this.$router.push("/messenger/" + this.$store.state.chat.chatGroupSelected['id']);
      }
    }
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      router_name: this.$route.name,
      chatGroups: this.$store.state.chat.chatGroups,
      tabActive: 1,
      TYPE_ADD_GROUP_MEMBER,
      search_convensation: '',
      time: null,
      form: {
        keyword: '',
        page: 1,
        per_page: 20,
      },
      convensations: {
        active: false,
        items: [],
        loading: false,
        notfound: false
      },
      finished: false,
      page: 1,
      last_page: 1,
      option: null,
      loadingNotification: false,
      notificationPaging: {
        page: 1,
        last_page: 1,
        finished: false,
      },
      notifications: [],
      CHAT_NOTIFICATION_TYPE: CHAT_NOTIFICATION_TYPE,
      chatGroupStatus: 'ALL',
      isOnLine: window.navigator.onLine,
      intervalid: null
    }
  },
  mounted() {
    setInterval(() => {
      this.isOnLine = window.navigator.onLine;
    }, 1000);

    if(this.$route.name === 'messenger'){
      this.autoGetDate();
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalid)
  },
  methods: {
    autoGetDate(){
      if(this.intervalid){
        clearInterval(this.intervalid)
        this.intervalid = null;
      }
      this.intervalid = setInterval(() => {
        this.chatGroups.sort(function (a, b) {
          if (a.pin_at || b.pin_at) {
            return new Date(b?.pin_at) - new Date(a?.pin_at);
          }
          return new Date(b?.last_message?.created_at || b?.created_at) - new Date(a?.last_message?.created_at || a?.created_at);
        });
      }, 60000);
    },
    getPath(id) {
      return '/messenger/' + id;
    },

    goToChatgroup(item) {
      delete item.isCreateChat;
      delete item.create_new_message;
      // if (item.id === this.$route.params.messenger_id) {
        this.createReadGroupChat();
      // }

      const params = {
        'page': 1,
        'per_page': 20,
        'group_id': item.id
      };

      ChatService.getChatGroups(params).then(res => {
        if (res.data) {
          item = res.data[0];
        }
      });
      this.$store.commit("chat/updateChatGroupSelected", item);
    },

    fromNow(date) {
      return moment(date).locale("vi").fromNow(true).replace("một", "1");
    },

    chosenTab(tab) {
      //search chat
      this.tabActive = tab;
      if ([5, 6, 7].includes(tab)) {
        this.getConvensation();
        return;
      }

      this.refreshPaging();

      if (this.tabActive === 3) {
        this.getChatNotifications();
      } else {
        this.getChatGroups()
      }
    },

    async getChatGroups(isLoadMore = false, isPin = false) {
      let params = {
        'page': isLoadMore ? this.page : 1,
        'per_page': 20,
      };
      
      if(isPin && isLoadMore){
        params.page = 1;
        params.per_page = this.page * 20;
      }

      if (this.tabActive === 4) {
        params.type = "COMMUNITY";
      }
      await ChatService.getChatGroups(params).then(res => {
        if (res.data) {
          this.last_page = res.meta.last_page;
          let data = res.data;
          data.sort(function (a, b) {
            if (a.pin_at || b.pin_at) {
              return new Date(b?.pin_at) - new Date(a?.pin_at);
            }
            return new Date(b?.last_message?.created_at) - new Date(a?.last_message?.created_at);
          });

          if (isLoadMore && !isPin) {
            this.chatGroups = this.chatGroups.concat(data);
            this.$store.commit('chat/updateChatGroups', this.chatGroups);
          } else {
            this.chatGroups = data;
            this.$store.commit('chat/updateChatGroups', data);
          }

          const chatGroupSelected = this.$store.state.chat.chatGroupSelected;
          if(typeof chatGroupSelected.id === 'undefined'){
            this.$store.commit("chat/updateChatGroupSelected", this.chatGroups[0]);
          }
          
        } else {
          this.chatGroups = [];
        }
      });
    },
    refreshDone() {
      this.tabActive = 1
      this.convensations.items = [];
      this.convensations.active = false;
      this.search_convensation = '';
    },

    handleConvensation() {
      this.convensations.active = !this.convensations.active;
    },

    getConvensation() {
      // this.tabActive = 5; //Tất cả
      this.convensations.active = true;
      this.convensations.loading = true;
      this.convensations.items = [];

      if (this.tabActive == 7) {
        this.form.type = 'USER';
      } else if (this.tabActive == 6) {
        this.form.type = 'GROUP';
      } else {
        this.tabActive = 5;
        delete this.form.type;
      }

      if (this.search_convensation !== '') {
        // check typing end
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }

        this.timer = setTimeout(() => {
          this.form.keyword = this.search_convensation;
          this.getListChatGoup();
        }, 500);

      } else {
        this.form.keyword = '';
        this.getListChatGoup();
      }
    },

    getListChatGoup() {
      if(this.search_convensation === '' && this.$store.state.chat.searchConversation[this.tabActive]){
        let searchConversation = this.$store.state.chat.searchConversation[this.tabActive];
        searchConversation.sort(function (a, b) {
            return new Date(b?.model.updated_at) - new Date(a?.model.updated_at);
          });
        this.convensations.items = searchConversation;
        this.convensations.loading = false;
        return;
      }
      // searchConversation
      ChatService.getChatConvesations(this.form).then(res => {
        let data = res.data;
        if(!this.$store.state.chat.searchConversation == false && this.search_convensation === ''){
          this.updateSearchConvensation(res.data);
        }
        this.convensations.items = data;
        this.convensations.loading = false;
      });
    },

    async goToGroupChat(item) {
      const params = {
        'page': 1,
        'per_page': 20,
        'group_id': item.group_id
      };
      item = item.model
      let group_id = item.group_id;
      await ChatService.getChatGroups(params).then(res => {
        if (res.data) {
          item = res.data[0];
          group_id = res.data[0].id
        }
      });

      this.$store.commit("chat/updateChatGroupSelected", item);
      this.$router.push("/messenger/" + group_id);
      if(this.$store.state.chat.searchConversation[this.tabActive]){
        let searchGroup = this.$store.state.chat.searchConversation[this.tabActive];
        searchGroup.map((group) => {
          if(group.group_id == group_id){
            group.model.updated_at = moment().format();
          }
        });
        this.updateSearchConvensation(searchGroup);
      }
      this.refreshDone();
      this.updateUnreadMessageToGroupChat();
    },

    getName(item) {
      if (item.type === "BOT") return item.last_message !== null ? item.last_message.text : '' ;
      const last_message = item.last_message;
      if (last_message == null || last_message.sender == null) return "Tin nhắn lỗi";
      let name = '';
      const isMyLastMessage = last_message.sender.id == this.user.id;
      const lastMessageUsername = isMyLastMessage ? "Bạn" : last_message.sender.name;

      switch (last_message.delete_status) {
        case 2:
          return lastMessageUsername + ' đã thu hồi một tin nhắn';
        case 1:
          return lastMessageUsername + ' đã xóa một tin nhắn';
      }

      if (last_message.text == TEXT_CHAT_SYSTEM.CREATE_USER_CHAT) {
        return "Hãy bắt đầu cuộc trò chuyện";
      }
      if (!isMyLastMessage && item.type === 'USER' && last_message.type == MESSAGE_CHAT_TYPE.TEXT) {
        return last_message.text
      }

      name = lastMessageUsername;

      if(last_message.system_note && JSON.parse(last_message.system_note).sticker){
        return name += ' đã gửi một nhãn dãn';
      }

      switch (last_message.type) {
        case MESSAGE_CHAT_TYPE.SYSTEM:
          name += this.getTextTypeSystem(item.last_message.text);
          break;
        case MESSAGE_CHAT_TYPE.FILE:
          name += " đã tải lên " + last_message.files.length + " file"
          break;
        case MESSAGE_CHAT_TYPE.IMAGE:
          name += " đã tải lên " + last_message.files.length + " ảnh"
          break;
        case MESSAGE_CHAT_TYPE.VIDEO:
          name += " đã tải lên " + last_message.files.length + " video"
          break;
        default:
          if(last_message.poll.length){
            name += ' đã tạo bình chọn';
          }else if (item.last_message.mentions.length
              && this.isMentionInMessage(last_message.sender.id, last_message.mentions, this.user.id)) {
            name = last_message.sender.name + ' đã đề cập đến bạn. '
          } else {
            name += ": " + last_message.text.replace(/(<([^>]+)>)/gi, "");
          }
          break;
      }
      return name;
    },

    isMentionInMessage(sender_id, mention_list, user_id) {
      const mention = mention_list.filter(function (ele) {
        return ele.mention_user_id == user_id
      });
      return mention.length
    },

    getTextTypeSystem(last_message) {
      let text = '';
      switch (last_message) {
        case TEXT_CHAT_SYSTEM.CREATE_GROUP:
          text = ' đã tạo nhóm';
          break;
        case TEXT_CHAT_SYSTEM.CHANGE_GROUP_AVATAR:
          text = ' đã đổi ảnh đại diện của nhóm';
          break;
        case TEXT_CHAT_SYSTEM.CHANGE_GROUP_NAME:
          text = ' đã đổi tên nhóm';
          break;
        case TEXT_CHAT_SYSTEM.LEAVE_GROUP:
          text = ' đã rời khỏi nhóm';
          break;
        case TEXT_CHAT_SYSTEM.REMOVE_USER:
          text = ' đã xóa thành viên khỏi nhóm';
          break;
        case TEXT_CHAT_SYSTEM.PIN_MESSAGE:
          text = ` đã ghim tin nhắn`;
          break;
        case TEXT_CHAT_SYSTEM.UNPIN_MESSAGE:
          text = ` đã bỏ ghim tin nhắn`;
          break;
        case TEXT_CHAT_SYSTEM.ADD_USER_GROUP:
          text += ' đã thêm thành viên';
          break;
        default:
          text = '';
          break;
      }

      return text;
    },

    isNoty(noty) {
      return noty === NOTIFICATION_CHAT.OFF;
    },

    async createReadGroupChat() {
      await ChatService.createReadGroupChat({group_id: this.$route.params.messenger_id}).then(res => {
        if (res.status) {
          this.updateUnreadMessageToGroupChat()
          this.$store.dispatch('chat/getUnreadGroupChat');
        }
      });
    },

    updateUnreadMessageToGroupChat() {
      let chatGroupSelected = this.$store.state.chat.chatGroupSelected;
      let chatGroups = this.$store.state.chat.chatGroups;

      chatGroups.map((item) => {
            if (item.id === chatGroupSelected.id) {
              item.unread_count = 0
            }
          }
      )
      document.title = 'Aza Social';
      this.$store.commit('chat/updateChatGroups', chatGroups);
    },

    onLoad(done) {
      if (this.page < this.last_page) {
        this.page++
        this.getChatGroups(true);
      } else {
        this.finished = true;
      }
      done();
    },

    showOption(id) {
      this.option = id == this.option ? false : id;
    },

    async pinChatGroup(group, index) {
      this.option = null;
      const params = {
        'group_id': group.id,
        'type': group.pin_at == null ? 'PIN' : 'UNPIN'
      }
      await ChatService.pinChatGroup(params).then(res => {
        if (!res.status) {
          this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại');
        }
      });
    },

    getChatNotifications(isLoadMore = false) {
      this.loadingNotification = true;
      ChatService.getChatNotifications({page: this.notificationPaging.page, per_page: 15}).then(res => {
        if (res.data) {
          if (isLoadMore) {
            this.notifications = this.notifications.concat(res.data);
          } else {
            this.notifications = res.data;
          }
          this.notificationPaging.last_page = res.meta.last_page;
        }
        this.loadingNotification = false;
      });
    },

    onLoadForNotification(done) {
      if (this.notificationPaging.page < this.notificationPaging.last_page) {
        this.notificationPaging.page++
        this.getChatNotifications(true);
      } else {
        this.notificationPaging.finished = true;
      }
      done();
    },

    getActionTextForNotification(notification) {
      let text = '';
      switch (notification.type) {
        case CHAT_NOTIFICATION_TYPE.ACTION:
          const total = notification.reaction.total
          if (notification.chat_group && total > 1) {
            text = `+${total - 1} phản ứng`;
          } else {
            text = 'đã phản ứng';
          }
          break;
        case CHAT_NOTIFICATION_TYPE.REPLY:
          text = 'đã trả lời tin nhắn của bạn';
          break;
        case CHAT_NOTIFICATION_TYPE.MENTION:
          text = 'đã đề cập đến bạn';
          break;
      }

      return text;
    },

    getReactionIconClass(notification) {
      switch (notification.reaction.current) {
        case 'like':
          return 'reaction-like';
        case 'love':
          return 'reaction-love';
        case 'care':
          return 'reaction-care';
        case 'haha':
          return 'reaction-haha';
        case 'wow':
          return 'reaction-wow';
        case 'sad':
          return 'reaction-sad';
        case 'angry':
          return 'reaction-angry';
        default:
          return '';
      }
    },

    getNameForUser(user) {
      if (user.display_name) {
        return user.display_name;
      } else {
        return user.fullname;
      }
    },

    readNotification(notification) {
      if (notification.is_read) {
        return;
      }
      ChatService.readChatNotification({chat_notification_id: notification.id}).then(res => {
        if (res.status) {
          notification.is_read = true;
        }
      });
    },

    redirectToMessage(group) {
      const params = {
        'page': 1,
        'per_page': 20,
        'group_id': group.chat_group.id
      };

      ChatService.getChatGroups(params).then(res => {
        if (res.data) {
          this.$store.commit("chat/updateChatGroupSelected", res.data[0]);
          res.data[0].scroll_to_message_id = group.message.id;

          if (this.$route.params.messenger_id != res.data[0].id) {
            this.$router.push('/messenger/' + res.data[0].id);
          }
        }
      });
    },
    refreshPaging() {
      this.page = 1;
      this.last_page = 1;
      this.notificationPaging = {
        page: 1,
        last_page: 1,
        finished: false,
      };
    },

    hiddenChatGroup(group_id) {
      this.option = null;
      this.$modal.show('dialog', {
        title: 'Ẩn cuộc trò chuyện',
        text: 'Trò chuyện sẽ được ẩn khỏi danh sách tin nhắn và sẽ hiển thị lên khi có tin nhắn mới hoặc khi bạn tìm kiếm cuộc trò chuyện.',
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Có',
            handler: () => {
              ChatService.hiddenChatGroup({group_id: group_id}).then(res => {
                if (res.status) {
                  this.chatGroups = this.chatGroups.filter(item => item.id != group_id);
                  this.$modal.hide('dialog');
                }
              });
            }
          }
        ]
      })
    },

    updateChatGroupPinAt() {
      let data = this.chatGroups;
      data.pin_at = moment().format();
      data.unshift(data.splice(index, 1)[0]);
      this.chatGroups = data;
      this.$store.commit('chat/updateChatGroups', this.chatGroups);
    },

    updateSearchConvensation(data){
      switch (this.tabActive) {
        case 5:
          this.$store.commit('chat/updateSearchConvensation', {...this.$store.state.chat.searchConversation, 5: data});
          break;
        case 6:
        this.$store.commit('chat/updateSearchConvensation', {...this.$store.state.chat.searchConversation, 6: data});
          break;
        case 7:
        this.$store.commit('chat/updateSearchConvensation', {...this.$store.state.chat.searchConversation, 7: data});
          break;
      }
    },

    async notificationChange(notification){
      let params = {
        group_id : this.$store.state.chat.chatGroupSelected.id,
        stage : this.isNoty(notification) ? 'ON' : 'OFF'
      };
      
      await ChatService.notificationConvensation(params).then(res => {
        if(res.status){
          let chatGroups = this.$store.state.chat.chatGroups;
          chatGroups.map((item) => {
              if (item.id == this.$route.params.messenger_id) {
                item.noty = res.data.stage
              }
            }
          );
          this.$store.commit('chat/updateChatGroups', chatGroups);
          this.option = null;
        }else{
          this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra! Vui lòng thử lại');
        }
      })
    },
  },
};
</script>
<style lang="scss">
.sideBarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .createMessage {
    button {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: initial;
      border: none;
      border-radius: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 100%;
      padding: 16px 4px 12px;
      margin-right: 15px;
    }
  }
}

.img-no-message {
  width: 80px;
  display: block;
  margin: 50px auto 15px;
}

.message-item {
  margin-bottom: 2px;

  &:hover {
    .option-chat-group {
      display: block;
    }
  }

  .active .md-list-item {
    background-color: #dcefff;
  }

  .message-time {
    position: absolute;
    top: 6px;
    right: 4px;
    display: flex;
    padding: 0 2px;

    .notifications_chat {
      font-size: 15px;
      color: #9c9c9c;
    }
  }

  .md-badge {
    height: 16px;
    width: auto;
    padding: 3px;
    min-width: 16px;
    border-radius: 8px;
    margin-top: 22px !important;
  }

  .create-time {
    color: #0066ff;
    font-weight: 500;
  }

  .is_read {
    .message {
      opacity: 0.8;
      white-space: break-spaces;

      .create-time {
        color: #1f1f1f;
        font-weight: 400;
      }
    }
  }

  .md-list-item-content {
    align-items: start;
    padding: 5px !important;

    .read-badge {
      margin-right: 5px;

      .md-badge {
        top: inherit !important;
        width: 10px;
        height: 10px;
        background-color: #0066ff !important;
        position: absolute !important;
      }
    }

    .md-list-item-text {
      white-space: initial;
    }

    .message {
      white-space: break-spaces;
    }
  }

  .message-name {
    display: flex;
  }

  .name_user {
    display: inline-block;
    width: max-content !important;
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin: 3px 0 3px;
  }

  .bot-message-container {
    .name_user {
      max-width: 125px;
    }
  }

  .bot-message {
    width: 60px;
    display: flex;
    align-items: center;

    svg {
      path {
        fill: #1a99f4;
      }
    }

    .bot-name {
      border: 1px solid #1a99f4;
      border-radius: 4px;
      color: #1a99f4;
      font-size: 12px;
      font-weight: 600;
      height: 16px;
      line-height: 14px;
      padding: 0 4px;
    }
  }

  .router-link-active {
    li {
      background-color: #FFEB7D !important;
    }

    .message {
      color: black;
    }
  }

  .option-chat-group-notification{
    right: 25px !important;
    .options{
      &:hover{
        cursor: pointer
      }
      position: relative;
      width: 30px;
      height: 30px;
      .button-more{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .material-symbols-rounded{
          font-size: 20px;
        }
      }
    }
  }
  .option-chat-group {
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.0509803922);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: none;

    .button-more {
      &:focus {
        & + .list-option {
          display: block;
        }
      }
    }

    .list-option {
      position: absolute;
      right: 0;
      bottom: -100px;
      width: max-content;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.0509803922);
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
      border-radius: 4px;
      z-index: 10;

      .option {
        padding: 6px 10px;

        &:hover {
          cursor: pointer;
          background: #e4e5e6;
          color: #000;
        }
      }
    }
  }
}

.input-container .search-icon {
  padding: 10px !important;
  min-width: 50px !important;
  text-align: center !important;
  background-color: transparent !important;
  color: black !important;
}

.convensations {
  .input-container {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5px;
    margin: 0 10px 15px;
    background: none;

    .input-search-field {
      background-color: #EDEFF5;
      border-radius: 5px;
    }
  }

  .button-close-convensation {
    &:hover {
      cursor: pointer;
    }

    border: 1px solid #E1E3EB;
    font-size: 13px;
    padding: 6px 15px;
    border-radius: 5px;
    background: #F9FAFC;
    font-weight: 500;
  }

  .scroll-bar {
    overflow-y: scroll;
    height: 500px;
  }

  .dropDownConvensation {
    height: calc(100vh - 170px);
    overflow: auto;

    .header {
      padding: 0 15px 5px;
      color: #505050;
    }

    .listConvensations {
      padding: 0 0 20px 0;
      margin: 0;

      .md-list-item {
        margin: 0;
      }

      .group-type {
        padding: 0;

        .md-list-item-content {
          padding: 0 10px;

          .title {
            color: #0051cf;
          }
        }
      }
      .text-info-name{
        white-space: normal;
      }
    }

    .notFoundConvensation {
      margin: 10px;
    }
  }
}

.noty_OFF {
  background-color: #afafaf !important;
}

.message-sidebar__container {
  .chat-notification__wrapper {
    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .activity {
      margin-bottom: 1px;

      .unread {
        background: #f2f5ff;
      }
      .md-list-item-content {
        align-items: flex-start;
        padding: 5px 0;
      }
      .message {
        width: 205px !important;
        margin-left: 10px;
      }
      .title {
        white-space: pre-line;
      }
      .reaction {
        position: absolute;
        width: 25px;
        height: 25px;
        bottom: -8px;
        right: -5px;
        border: 3px solid white;
        border-radius: 50px;
      }

      .mention, .reply {
        position: absolute;
        bottom: -8px;
        right: -5px;
        border-radius: 50%;
        border: 3px solid white;
        background: #ff8600;
        width: 25px;
        height: 25px;
        padding: 3px;

        .md-icon {
          font-size: 15px !important;
          min-width: auto;
          min-height: auto;
          color: white;
          opacity: 1 !important;
          width: auto;
          height: auto;
          line-height: 1;
        }
      }

      .reply {
        background: #43b93f;
      }
    }

    .reaction-like {
      background: url("/img/facebook-reactions/like.svg") no-repeat;
    }

    .reaction-love {
      background: url("/img/facebook-reactions/love.svg") no-repeat;
    }

    .reaction-care {
      background: url("/img/facebook-reactions/care.svg") no-repeat;
    }

    .reaction-haha {
      background: url("/img/facebook-reactions/laughing.svg") no-repeat;
    }

    .reaction-wow {
      background: url("/img/facebook-reactions/soaked.svg") no-repeat;
    }

    .reaction-sad {
      background: url("/img/facebook-reactions/sad.svg") no-repeat;
    }

    .reaction-angry {
      background: url("/img/facebook-reactions/firework.svg") no-repeat;
      background-size: cover;
    }

    .is-read-flg {
      font-size: 20px !important;
    }
  }

  .aza-Icon * {
    fill: inherit !important;
  }
}
</style>
