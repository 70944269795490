<template>
  <div class="first-step-page-container ">
    <div class="content-wrapper">
      <h2>
        {{isChangeProfilePage ? 'Cập nhật hồ sơ cá nhân' : 'Yêu cầu đổi mật khẩu'}}
      </h2>
      <div class="tab-wrapper">
        <div :class="{active : user.last_changed_password_at !== null && user.last_changed_password_at != ''}">
          1. Đổi mật khẩu
        </div>
        <div :class="{active : user.profile_updated != 0}">
          2. Cập nhật hồ sơ
        </div >
      </div>
      <div>
        <span v-if="user.profile_updated == 0">
          Vui lòng đổi mật khẩu mới khác mật khẩu mặc định, phải bao gồm chữ hoa,
          <br>
          chữ thường, chữ số và ký tự đặc biệt
        </span>
        <span v-else>
          Vui lòng điền đầy đủ thông tin để công ty có thể
          <br>
          liên hệ với bạn khi cần thiết.
        </span>
        <div class="form-password" v-show="!isChangeProfilePage">
          <form action="" @submit.prevent="updatePassword">
            <div class="input-wrapper">
              <md-icon>lock</md-icon>
              <input :type="newPasswordType ? 'password': 'text'" v-model="newPassword" placeholder="Mật khẩu mới" required>
              <div class="cursor-pointer" @click="newPasswordType = !newPasswordType">
                <md-icon>{{newPasswordType ? 'visibility' : 'visibility_off'}}</md-icon>
              </div>
            </div>
            <div class="input-wrapper">
              <md-icon>lock</md-icon>
              <input :type="confirmPasswordType ? 'password': 'text'" v-model="confirmPassword" placeholder="Xác nhận mật khẩu" required>
              <div class="cursor-pointer" @click="confirmPasswordType = !confirmPasswordType">
                <md-icon>{{confirmPasswordType ? 'visibility' : 'visibility_off'}}</md-icon>
              </div>
            </div>
            <button class="change-pass-btn mt-40px">Đổi mật khẩu</button>
          </form>
        </div>
        <div class="form-profile" v-show="isChangeProfilePage">
          <div class="profile-wrapper">
            <div class="left-wrapper">
              <div class="avatar-wrapper">
                <img :src="user.avatar_url ? user.avatar_url : '/img/profile/avatar.png'" alt="">
              </div>
              <div class="btn-upload-wrapper mt-20px" @click="openUploadFile">
                <md-icon>file_upload</md-icon>
                <span>Tải ảnh đại diện</span>
                <input type="file" accept="image/*" id="file-upload" @change="onChangeFile">
              </div>
              <div style="color: white">
                <md-radio v-model="user.gender" :value="1">Nam</md-radio>
                <md-radio v-model="user.gender" :value="0">Nữ</md-radio>
              </div>
            </div>
            <form class="w_100" @submit.prevent="updateProfile()">
              <div class="right-wrapper">
                <div class="input-wrapper">
                  <md-icon>lock</md-icon>
                  <input type="text" placeholder="Họ và tên" v-model="user.fullname" required>
                </div>
                <div class="input-wrapper">
                  <md-icon>email</md-icon>
                  <input type="email" placeholder="Email" v-model="user.email" required>
                </div>
                <div class="input-wrapper">
                  <md-icon>phone</md-icon>
                  <input type="phone" placeholder="Số điện thoại" v-model="user.phone" required>
                </div>
                <div class="input-wrapper">
                  <md-icon>calendar_month</md-icon>
                  <datetime type="date" format="dd/MM/yyyy" v-model="user.birthday" :max-datetime="maxDate" title="Nhập ngày sinh">dd/MM/yyyy</datetime>
                </div>
                <div class="input-wrapper">
                  <md-icon>home</md-icon>
                  <input type="text" placeholder="Địa chỉ" v-model="user.address" required>
                </div>
              </div>

          <button class="change-pass-btn mt-40px">Hoàn thành</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <notifications></notifications>
  </div>
</template>
<script>

import {Datetime} from 'vue-datetime';
import moment from 'moment';
import ProfileService from "../../../store/services/ProfileService";
import Notifications from "../../../components/NotificationPlugin/Notifications";

export default  {
  components: {datetime: Datetime, Notifications},
  data() {
    return {
      newPasswordType: true,
      confirmPasswordType: true,
      newPassword: '',
      confirmPassword: '',
      user: new Object(),
      maxDate: new Date().toJSON(),
      avatarFile: null,
    }
  },
  computed: {
    isChangeProfilePage() {
      return (this.user.last_changed_password_at != null && this.user.last_changed_password_at != '');
    }
  },
  mounted() {
    this.getUser();
  },
  methods: {
    openUploadFile() {
      document.getElementById("file-upload").click();
    },
    async updateProfile() {
      if (this.avatarFile == null) {
        this.$store.dispatch("alerts/error", 'Bạn cần thay đổi avatar');
        return;
      }
      const formData = new FormData();
      formData.append("fullname", this.user.fullname);
      formData.append("email", this.user.email);
      formData.append("phone", this.user.phone);
      formData.append("address", this.user.address);
      formData.append("gender", this.user.gender);

      formData.append("image", this.avatarFile);
      let date = new Date(this.user.birthday);
      formData.append("birthday", moment(date).format('yyyy-MM-DD'));

      await ProfileService.updateProfile(formData).then(res => {
        this.show = false;
        if (res.status) {
          this.getUserProfile(this.user.id);
        }
      });
    },
    onChangeFile(e) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.user.avatar_url = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);
      this.avatarFile = e.target.files[0];
    },
    async getUserProfile(userId) {
      await ProfileService.getProfile({user_id: userId}).then(res => {
        if (res) {
          this.$store.commit("updateUser", res.data);
          this.$router.push("/new-feeds");
        }
      });
    },
    updatePassword() {
      const passRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!this.newPassword.match(passRegex)) {
        this.$store.dispatch("alerts/error", 'Trường Mật khẩu mới có định dạng không hợp lệ. Mật khẩu ít nhất 8 kí tự và phải có chữ hoa, chữ thường, chữ số và ký tự đặc biệt.');
      } else if (this.newPassword !== this.confirmPassword) {
        this.$store.dispatch("alerts/error", 'Trường Xác nhận mật khẩu và Mật khẩu mới phải giống nhau.');
      } else {
        ProfileService.changePassword({
          "new_password": this.newPassword
        }).then(res => {
          this.show = false;
          if (res.status) {
            this.$store.state.auth.user.last_changed_password_at = new Date().toJSON();
            this.user.last_changed_password_at = new Date().toJSON();
          }
        });
      }
    },
    getUser() {
      this.user = {...this.$store.state.auth.user};
      if (this.user.birthday == null || this.user.birthday == '') {
        this.user.birthday = new Date().toJSON();
      } else {
        const [day, month, year] = this.user.birthday.split('-');
        let date = new Date(+year, +month - 1, +day);
        this.user.birthday = date.toJSON();
      }
    }
  },
};
</script>
<style lang="scss">
 .first-step-page-container {
   background: url("/img/first-step/home.jpg") no-repeat center;
   width: 100%;
   height: 100%;
   color: white;
   font-size: 16px;
   font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
   display: flex;

   .content-wrapper {
     width: 1001px;
     margin: auto;
     padding: 40px;
     background-color: rgba(16, 15, 13, 0.48);
     text-align: center;
   }

   .tab-wrapper {
     display: flex;
     width: 300px;
     height: 35px;
     font-size: 14px;
     margin: 25px auto;
     div {
       background-color: white;
       position: relative;
       display: flex;
       align-items: center;
       height: 100%;
       flex-grow: 1;
       text-decoration: none;
       margin: auto;
       padding-left: 15.2px;
       padding-right: 0;
       color: #666;
     }
     div:first-child {
       border-top-left-radius: 6px;
       border-bottom-left-radius: 6px;
       border-right: 1px solid black;
     }
     div:last-child {
       border-top-right-radius: 6px;
       border-bottom-right-radius: 6px;
     }
     div.active {
       background-color: #9942E7;
       color: white;
     }
   }
   .form-password, .form-profile {
     width: 420px;
     margin: 20px auto;
     .input-wrapper {
       width: 100%;
       display: flex;
       align-items: center;
       background-color: white;
       border-radius: 6px;
       margin-top: 20px;
       height: 40px;
       input {
         border: none;
         height: 100%;
         width: 100%;
         background: transparent;
       }
       .md-icon {
         margin: 10px;
       }
     }
     .change-pass-btn {
       width: 100%;
       height: 40px;
       cursor: pointer;
       background-color: #28a745;
       border-radius: 6px;
       border: none;
       color: white;
       font-size: 16px;
     }
   }
   .form-profile {
     width: 600px;
     .profile-wrapper {
       display: flex;
       align-items: center;

       .left-wrapper {
         width: 45%;
         margin-right: 10%;
         .avatar-wrapper {
           height: 120px;
           width: 120px;
           margin: auto;
           border-radius: 50%;
           box-shadow: 1px 1px 15px -5px black;
           transition: all 0.3s ease;
           border: 2px solid white;
           float: initial !important;

           img {
             height: 100%;
             width: 100%;
             transition: all 0.3s ease;
             border-radius: 50%;
           }
         }
         .btn-upload-wrapper {
           display: flex;
           align-items: center;
           cursor: pointer;
           .md-icon {
             color: white;
           }
           #file-upload {
             display: none;
           }
         }
         .md-radio-label {
           color: white;
         }
       }

       .right-wrapper {
         width: 100%;
       }
     }
   }
 }
</style>
