<template>
  <div>
    <div class="text-has-background"
         :style="'background-image: url(' + Post.background.background_url + '); color: ' + Post.background.color"
         v-html="Post.html_content"
         v-if="Post.background" ></div>
    <div class="text-html" :id="'text-html-' + Post.id" v-html="convertUrlContent(Post.html_content)" v-else></div>
    <span class="cursor-pointer hover-link pl-15px" @click="handleViewMore" style="color: #0d47a1" v-if="isLargeText">{{showingFullText ? "...Thu gọn" : "...Xem thêm"}}</span>
    <div class="mt-15px">
      <post-layout v-if="postPreview" :post=postPreview :is-post-preview="true"></post-layout>
      <link-metadata v-else-if="Post.medias.length === 0 && Post.link_metadata" :link-metadata="JSON.parse(Post.link_metadata)" :action="'VIEW'" />
    </div>
  </div>
</template>

<script>
import LinkMetadata from "../../../Workplace/Pages/LinkMetadata";

export default {
  name: "text-content",
  components: {
    LinkMetadata,
    PostLayout: () => import("../../../Layout/Newfeed/PostLayout"),
  },
  data () {
    return {
      isLargeText: false,
      showingFullText: false
    }
  },
  props: {
    Post: {
      type: Object,
      default: () => {},
    },
    postPreview: {
      type: Object,
      default: null,
    }
  },
  mounted() {
    if (!this.Post.background) {
      let elm = 'text-html-' + this.Post.id;
      let offsetHeight = document.getElementById(elm).offsetHeight;
      // 20 chính là line-height của thẻ div
      if (offsetHeight / 20 > 20) {
        this.isLargeText = true;
        this.showingFullText = false;
        document.getElementById(elm).classList.add("text-ellipsis");
      }
    }
  },
  computed: {
  },
  methods: {
    handleViewMore() {
      let elm = 'text-html-' + this.Post.id;
      // showingFullText = true => đang mở cần thu lại, thu lại là thêm class text-ellipsis
      if (this.showingFullText) {
        document.getElementById(elm).classList.add("text-ellipsis");
      } else {
        document.getElementById(elm).classList.remove("text-ellipsis");
      }
      this.showingFullText = !this.showingFullText;
    },
    convertUrlContent(content) {
      return content
      // var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      // return content.replace(urlRegex, function(url) {
      //   return '<a href="' + url + '">' + url + '</a>';
      // });
    },
  }
};
</script>

<style lang="css">
.text-has-background {
  width: 100%;
  height: 365px;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 26px;
}
</style>
