<template>
  <div class="message-share">
    <div class="text-300-default neutral_2" :class="mess.forward_msg_id">
      <template v-if="user.id === mess.sender.id">
        <span class="text-300-default"><span class="text-300-semibold">Bạn</span> đã chuyển tiếp một tin nhắn</span>
      </template>
      <template v-else>
        <span class="text-300-default"><span class="text-300-semibold">{{ mess.sender.name }}</span> đã chuyển tiếp</span>
      </template>
    </div>
  </div>
</template>
<script>
  import {MESSAGE_CHAT_TYPE} from "../../../../../const/const";
  export default {
    data() {
      return {
        MESSAGE_CHAT_TYPE
      }
    },
    name: "share-message-content",
    components: {},
    props: {
      displayName: {
        type: String,
        default: "",
      },
      mess: {
        type: Object,
        default: () => {
          return {};
        }
      },
      user: {
        type: Object,
        default: () => {
          return {};
        }
      },
    },
    computed(){
    }
  }
</script>

<style lang="scss">
  .message-share {
    padding: 5px 0px 10px;
    border-radius: 8px;
    margin-bottom: -8px;
    max-width: 400px;
    margin-top: 5px;
  }
</style>

