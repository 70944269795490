<template>
  <div class="sticker-manager__wrapper">
    <modal name="add-sticker-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="modal-custom add-sticker-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
    <template v-if="tabActive == 1">
      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="heading-500 text-header-modal">Thêm mới gói sticker</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('add-sticker-modal')">close</span>
        </md-dialog-title>
      </md-card>
      <md-card-content>
        <div class="content-add-package">
          <div class="add-avatar-item" @click="uploadFile('package')"
               @change="onChangeUpload($event, 'package')">
            <img :src="form.thumbnails ? form.thumbnails.small_thumb : '/img/chat/add-image-default.png'" alt="">
            <input name="file" hidden accept="image/*" type="file" ref="image_package">
            <div class="error-upload" v-if="error.sticker_label">
              <span>{{ error.sticker_label }}</span>
            </div>
          </div>
          <div class="layout-form-add">
            <div class="form mt-5px">
              <div class="title required">Tên gói</div>
              <div class="entry-input">
                <input type="text" class="form-input" v-model="form.title" placeholder="Tên bộ sưu tập">
                <div class="validation-error" v-if="error.title">
                <span>{{ error.title }}</span>
              </div>
              </div>
            </div>
            <div class="form mt-15px">
              <div class="title required">Mô tả gói</div>
              <div class="entry-input">
                <input type="text" class="form-input" placeholder="Thêm mô tả..." v-model="form.description">
                <div class="validation-error" v-if="error.description">
                <span>{{ error.description }}</span>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-add-sticker d-flex-between">
          <div class="title-sticker">
            DANH SÁCH STICKER
          </div>
          <div class="button-add-sticker">
            <button class="add-sticker cursor-pointer" @click="switchTab(2)">Thêm mới sticker</button>
          </div>
        </div>
        <md-content class="md-scrollbar content-sticker">
          <div class="stickers">
            <div class="sticker cursor-pointer" v-for="(sticker, index) in stickers" :key="index">
              <div class="sticker-image">
                <img :src="sticker.thumbnails && sticker.thumbnails.small_thumb ? sticker.thumbnails.small_thumb :  'https://d1fee2zp1b6u2o.cloudfront.net/assets/media/packages/5/2d3b4e22-8869-4df4-be7f-dff26c5385fb.png'" alt="">
              </div>
              <div class="sticker-title">
                {{ sticker.title }}
              </div>
              <div class="sticker-tag">
                {{ sticker.tag }}
              </div>
              <div class="sticker-button">
                <!-- <span class="edit-sticker" title="Chỉnh sửa">
                  <img src="/img/chat/icon/edit.svg" alt="edit" />
                </span> -->
                <span class="delete-sticker" title="Xóa" @click="deleteSticker(index)">
                  <img src="/img/chat/icon/delete.svg" alt="delete" />
                </span>
              </div>
            </div>
          </div>
        </md-content>
      </md-card-content>
      <md-dialog-actions class="p-15px w_100 text-400-medium">
        <button type="submit" class="create-post-btn btn-active md-button w_100" @click="addPackage">Thêm</button>
        <button type="submit" class="btn-stroke md-button heading-300" @click="$modal.hide('add-sticker-modal')">Đóng</button>
      </md-dialog-actions>
    </template>
    <template v-else>
      <md-card class="modal-header-custom">
        <md-dialog-title class="d-flex-between">
          <div class="d-flex-between">
            <span class="material-icons cursor-pointer mr-10px"
                  @click="switchTab(1)">keyboard_backspace</span>
            <span class="neutral_1 heading-500 text-header-modal">Thêm mới sticker</span>
          </div>
          <span class="material-icons float-right cursor-pointer" @click="$modal.hide('add-sticker-modal')">close</span>
        </md-dialog-title>
      </md-card>
      <md-card-content>
        <md-content class="md-scrollbar container-wraper-stickers">
          <div class="content-add-package">
            <div class="add-avatar-item sticker-add-image cursor-pointer"
                 @click="uploadFile('sticker')"
                 @change="onChangeUpload($event, 'image')"
            >
              <img :src="avatar_sticker || '/img/chat/add-image-default.png'" alt="">
              <input name="file" hidden accept="image/*" type="file" ref="image_sticker">
              <div class="error-upload" v-if="error.sticker_label">
                <span>{{ error.sticker_label }}</span>
              </div>
            </div>
            <div class="layout-form-add">
              <div class="form mt-5px">
                <div class="title required">Tên gói</div>
                <div class="entry-input">
                  <input type="text" class="form-input required" v-model="sticker_form.title">
                  <div class="validation-error" v-if="error.title">
                    <span>{{ error.title }}</span>
                  </div>
                </div>
              </div>
              <div class="form mt-15px">
                <div class="title required">Mô tả</div>
                <div class="entry-input">
                  <input type="text" class="form-input" placeholder="Thêm mô tả..." v-model="sticker_form.description">
                  <div class="validation-error" v-if="error.description">
                    <span>{{ error.description }}</span>
                  </div>
                </div>
              </div>
              <div class="form mt-15px">
                <div class="title required">Gắn thẻ tìm kiếm</div>
                <div class="entry-input">
                  <md-chips @md-insert="insertOtherTag" v-model="sticker_form.tag" md-placeholder="Gắn thẻ tìm kiếm..."></md-chips>
                  <div class="validation-error" v-if="error.tag">
                    <span>{{ error.tag }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </md-content>
      </md-card-content>
      <md-dialog-actions class="p-15px w_100 text-400-medium">
        <button type="submit" class="create-post-btn btn-active md-button w_100" @click="addSticker">Thêm</button>
        <button type="submit" class="btn-stroke md-button heading-300" @click="switchTab(1)">Đóng</button>
      </md-dialog-actions>
    </template>
    </modal>
  </div>
</template>

<script>
 import ChatService from "../../../store/services/ChatService";
 import PostService from "../../../store/services/PostService";
 import PackageStickerService from '../../../store/services/PackageStickerService';

export default {
  name: 'add-sticker-modal',
  components: {
  },
  data() {
    return {
      stickers: [],
      sticker_form: {
        title: null,
        description: null,
        tag: [],
        order: 1,
        thumbnails: null,
        file_id: null
      },
      form: {
        title: null,
        description: null,
        total_download: 0,
        default: 1,
        order: 1,
        file_id: null,
        thumbnails: null,
        stickers: []
      },
      tabActive: 1,
      avatar_sticker: null,
      error: {
        sticker_label: '',
        title: null,
        description: null,
        tag: null
      },
      uploading: false,
      submiting: false
    }
  },
  props: {
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  watch: {
    'sticker_form.description'(newV){
      if(newV){
        this.error.description = null
      }
    },
    'sticker_form.title'(newV){
      if(newV){
        this.error.title = null
      }
    },
    'sticker_form.tag'(newV){
      if(newV.length){
        this.error.tag = null
      }
    },
    'form.description'(newV){
      if(newV){
        this.error.description = null
      }
    },
    'form.title'(newV){
      if(newV){
        this.error.title = null
      }
    },
  },
  methods: {
    beforeOpened(event){
      // this.tabActive = event.params.tab ?? 1;
      this.resetForms();
      this.stickers = [];
    },

    beforeClosed(){

    },

    switchTab(tab){
      this.tabActive = tab;
      if(tab !== 1){
        this.avatar_sticker = null;
          this.sticker_form = {
          title: null,
          description: null,
          tag: [],
          order: 1,
          thumbnails: null,
          file_id: null
        };
      }
      this.error = {
        sticker_label: null,
        title: null,
        description: null,
        tag: null
      }
    },

    insertOtherTag(tag){
    },

    uploadFile(type){
      if(type === 'package'){
        this.$refs.image_package.click();
      }else{
        this.$refs.image_sticker.click();
      }
    },

    async onChangeUpload(e, type) {
      let self = this;
      if(self.uploading) return;

      const formFile = new FormData();

      self.uploading = true;
      self.error.sticker_label = '';
      let fileInput = e.target;

      let filePath = fileInput.value;

      // Allowing file type
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;

      if (!allowedExtensions.exec(filePath)) {
        self.uploading = false;
        fileInput.value = '';
        self.error.sticker_label = 'File tải lên không hợp lệ: .jpg, .jpeg, .png, .gif';
        return false;
      } else {
        // Image preview
        if (fileInput.files && fileInput.files[0]) {
          const reader = new FileReader();
          formFile.append("files[]", fileInput.files[0]);

          reader.onload = function(e) {
            self.avatar_sticker = e.target.result;
          };

          await PostService.uploadFile(formFile).then(res => {
            if (res.status) {
              if (type === 'package') {
                self.form.thumbnails = res.data[0].thumbnail;
                self.form.file_id = res.data[0].file_id;
                self.avatar_package = res.data[0].thumbnail.medium_thumb;
              } else {
                self.avatar_sticker = res.data[0].thumbnail.medium_thumb;
                self.sticker_form.file_id = res.data[0].file_id;
                self.sticker_form.thumbnails = res.data[0].thumbnail;
              }

              self.error.sticker_label = '';
            }
          });

          reader.readAsDataURL(fileInput.files[0]);
          self.uploading = false;
          formFile.delete("files[]");
        }
      }
    },

    addSticker(){
      let is_not_valid = false;

      if(this.sticker_form.title === null){
        this.error.title = 'Trường này là bắt buộc';
        is_not_valid = true;
      }

      if(this.sticker_form.description === null){
        this.error.description = 'Trường này là bắt buộc';
        is_not_valid = true;
      }

      if(this.sticker_form.tag.length === 0){
        this.error.tag = 'Trường này là bắt buộc';
        is_not_valid = true;
      }

      if(this.avatar_sticker === null){
        this.error.sticker_label = 'Bạn cần đặt ảnh cho sticker'
        is_not_valid = true;
      }

      if(is_not_valid) return;

      this.sticker_form.tag = `[${this.sticker_form.tag.splice('').join()}]`;
      this.stickers.push(this.sticker_form);
      this.form.stickers.push(this.sticker_form);

      this.switchTab(1);

      //reset form add sticker
      this.sticker_form = {
        title: '',
        description: '',
        tag: [],
        order: 1,
        thumbnails: null,
        file_id: null
      };
      this.avatar_sticker = null;
    },

    async addPackage(){

      if(this.submiting) return;
     
      let is_not_valid = false;

      if(this.form.title === null){
        this.error.title = 'Trường này là bắt buộc';
        is_not_valid = true;
      }

      if(this.form.description === null){
        this.error.description = 'Trường này là bắt buộc';
        is_not_valid = true;
      }
      
      if(this.form.thumbnails === null){
        this.error.sticker_label = 'Bạn cần đặt ảnh cho bộ sưu tập'
        is_not_valid = true;
      }

      if(is_not_valid) return;

      this.submiting = true;

      await PackageStickerService.createPackage(this.form).then(res => {
          if(res.status){
            this.$store.dispatch("alerts/success", 'Bạn đã tạo thành công bộ sticker ' + res.data.title);
            this.$emit('create_package', res.data);
            this.resetForms();
            this.$modal.hide('add-sticker-modal');
          }else{
            this.$store.dispatch("alerts/error", 'Tạo không thành công bộ sticker ' + res.data.title);
          }
      });

      this.submiting = false;
    },

    deleteSticker(index){
      this.stickers.splice(index, 1);
    },

    resetForms(){
      this.sticker_form = {
        title: null,
        description: null,
        tag: [],
        order: 1,
        thumbnails: null,
        file_id: null
      };

      this.form = {
        title: null,
        description: null,
        total_download: 0,
        default: 1,
        order: 1,
        file_id: null,
        thumbnails: null,
        stickers: []
      };

      this.error = {
        sticker_label: '',
        title: null,
        description: null,
        tag: null
      };

      this.avatar_sticker = null;
    }
  },
}
</script>

<style lang="scss">
.sticker-manager__wrapper{
  .d-flex-between{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .form-input{
    border: 1px solid #EDEFF5;
    border-radius: 4px;
    background: #EDEFF5;
    width: 100%;
    height: 35px;
    padding: 5px;
    font-size: 13px;
    color: #101840;
  }
  .md-card-content{
    padding: 0;
    .entry-input {
    position: relative;
      .validation-error {
        position: absolute;
        color: red;
        font-size: 12px;
        bottom: -20px;
      }
    }
    .content-add-package{
      grid-template-columns: 25% 73%;
      display: grid;
      grid-gap: 20px;
      //align-items: center;
      padding: 18px 16px;
      .add-avatar-item {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
          width: 100%;
          height: 100%;
        }
        .error-upload {
          position: absolute;
          background: #ffffffcc;
          width: 100%;
          height: 100%;
          font-size: 15px;
          display: flex;
          justify-content: center;
          color: #f00;
          align-items: center;
          text-align: center;
          border-radius: 6px;
          word-break: break-word;
        }
      }
      .md-chips{
        margin: 0;
        padding: 0 5px;
        background: #EDEFF5;
        border-radius: 4px;
        .md-ripple {
          background: #FFE4CB;
          color: #101840;
          svg{
            fill: #101840 !important;
          }
        }
        input{
          border: 1px solid #EDEFF5;
          border-radius: 4px;
          background: #EDEFF5;
          width: 100%;
          height: 35px;
          padding: 5px;
          font-size: 13px;
          color: #101840;
        }
      }
    }
    .header-add-sticker{
      padding: 10px;
      border-top: 1px solid #D8DAE5;
      margin: 0 10px;
      .add-sticker{
        background: #FFD700;
        border-radius: 4px;
        border: none;
        padding: 8px 12px;
      }
    }


    .header-sticker{
      padding: 20px 10px;
      .management-package {
        background: #F1F2F4;
        padding: 10px 15px;
        border-radius: 10px;
        font-weight: 600;
      }
      .title-tab{
        margin: 0px 5px;
        display: inline-block;
        position: relative;
        width: 64px;
        text-align: center;
        height: 30px;
        a{
          &:hover{
            cursor: pointer;
          }
          color: #000000;
        }
        .activeTab {
          &::after{
            content: "";
            width: 100%;
            height: 4px;
            background: rgb(26, 26, 26);
            border-radius: 5px;
            position: absolute;
            bottom: 0px;
            left: calc(50% - 32px);
          }
        }
      }
      .package-title{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        grid-gap: 10px;
      }
    }
    .md-scrollbar{
      width: 100%;
      height: 500px;
      min-height: 400px;
      //overflow-x: auto !important;
      background: #FFFFFF;
      padding: 10px;
      .stickers{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        height: 480px;
        overflow: hidden;
        overflow-y: scroll;
        align-content: baseline;
        .sticker{
          background: rgb(255, 255, 255);
          border-radius: 12px;
          width: 150px;
          height: 172px;
          margin: auto;
          line-height: 25px;
          *{
            margin: auto;
            text-align: center;
          }
          .sticker-image{
            width: 74px;
            height: 74px;
          }
          .sticker-title {
            color: #000000;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }
          .sticker-tag {
            color: #52576F;
            font-size: 12px;
          }
          .sticker-button{
            .edit-sticker {
              background: #5CA222;
              border-radius: 2px;
              color: #fff;
              font-size: 12px;
              padding: 3px 10px 5px 10px;
              margin-right: 5px;
            }
            .delete-sticker{
              border-radius: 2px;
              color: #fff;
              font-size: 12px;
              padding: 3px 10px 5px 10px;
              background: #FF5959;
            }
          }
        }
      }
    }
    .container-wraper-stickers{
      padding: 0;
      .add-avatar-item{
        position: relative;
      }
    }
    .packages-container{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .containers{
        background: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
        border-radius: 12px;
        width: 320px;
        max-width: calc(50% - 16px);
        height: 172px;
        margin-bottom: 12px;
        margin-right: 12px;
        padding: 10px;
        .image-avatar {
          width: 84px;
          height: 84px;
          margin-right: 8px;
          min-width: 84px;
        }
        .list-package-demo {
          display: flex;
          flex-wrap: wrap;
          margin-right: -4px;
          .item-package {
            width: 44px;
            height: 44px;
            border-radius: 8px;
            overflow: hidden;
            margin: 0px 4px 4px 0px;
          }
        }
        .status-install {
          color: rgb(77, 77, 77);
          border-radius: 4px;
          padding: 0px 4px;
          height: 24px;
          display: flex;
          border: none;
          align-items: center;
          justify-content: center;
          line-height: 16px;
        }
        .uninstall{
          background: rgb(231, 231, 231);
        }
        .installed{
          background: rgb(48, 169, 96);
          color: #ffffff;
        }
      }
    }
  }
  .container-management-list{
    .md-scrollbar{
      padding: 0;
      background: unset;
    }
    .management-description {
      font-size: 12px;
      line-height: 16px;
      color: rgb(128, 128, 128);
      height: 30px;
      background: rgb(231, 231, 231);
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      margin-bottom: 8px;
    }
    .package-management {
      &:hover{
        background: rgb(245, 245, 245);
        border-radius: 8px;
      }
      width: 100%;
      padding: 6px 8px;
      background: rgb(255, 255, 255);
      .avatar-sticky {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
      .button-delete{
        .md-icon {
          color: red !important;
        }
      }
    }
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group {
    min-height: 20px;
    line-height: 35px;
  }
  .list-group-item {
    cursor: grab;
    padding: 0 10px;
  }
  .list-group-item i {
    cursor: pointer;
  }
}
</style>