<template>
  <md-card-header style="position: relative">
    <template v-if="$route.name != 'PostDetail'">
      <!-- <popover v-if="userPost" :name=userPopoverName class="header-popover user-popover">
        <user-info :user-post="userPost" />
      </popover> -->
      <div v-if="userPost" class="header-popover user-popover" v-show="userPopupName" v-on:mouseleave="onMouseleave($event)">
        <user-info :user-post="userInfo" />
      </div>
      <div v-if="group && !isGroup" v-show=groupPopupName class="header-popover group-popover" v-on:mouseleave="onMouseleave($event)">
        <group-info :group="group" />
      </div>
      <div class="tag-users__popover" v-if="usersTagged.length > 2">
        <div class="text-200-default" v-for="user in usersTagged.slice(2)" :key="user.id + 'tup'">
          {{ user.fullname }}
        </div>
      </div>
    </template>

    <md-list-item class="post-header-wrapper">
      <md-avatar>
        <router-link :to="getProfileLink(userPost.id)">
          <image-template :size="'small'" :image="userPost.avatar_url" :thumbnail="userPost.thumbnail"/>
        </router-link>
      </md-avatar>
      <div class="md-list-item-text">
        <div class="header-name" v-if="!isPost">
          <span class="text-400-semibold cursor-pointer hover-link userPopoverName" v-on:click="onClick('user', userPost.id)"
                v-on:mouseover="onMouseover($event)" v-on:mouseleave="onMouseleave($event)">{{ userPost.display_name ? userPost.display_name : userPost.fullname }}</span>
          <template v-if="$route.name != 'PostDetail'">
            <template v-if="!isPostPreview && !!postPreview">
              <template v-if="group && isGroup">
                <span class="neutral_2"> đã chia sẻ một
                  <span class="origin-link" v-on:click="openOriginPost(postPreview)">bài viết</span>.
                </span>
              </template>
              <template v-else>
                <span class="neutral_2"> đã chia sẻ một
                  <span class="origin-link" v-on:click="openOriginPost(postPreview)">bài viết</span> vào nhóm
                </span>
                <span v-if="group.name" class="text-400-semibold cursor-pointer hover-link groupPopoverName" v-on:click="onClick('group', group.id)"
                      v-on:mouseover="onMouseover($event)" v-on:mouseleave="onMouseleave($event)"
                      v-popover.top="{ name: groupPopoverName }">{{ group.name }}</span>
                <span class="neutral_2">.</span>
                <tag-user-view v-if="usersTagged.length !== 0 && group.name"
                               :users-tagged="usersTagged" @mouseleave-user-tagged="mouseleaveUserTagged" @mouseover-user-tagged="mouseoverUserTagged" />
                <tag-user-view v-else-if="usersTagged.length !== 0"
                               :users-tagged="usersTagged" @mouseleave-user-tagged="mouseleaveUserTagged" @mouseover-user-tagged="mouseoverUserTagged" />
              </template>
            </template>

            <template v-else-if="group && !isGroup">
              <template v-if="post.type === POST_TYPE.FILE">
                <span class="neutral_2"> đã tải {{ post.medias.length }} file lên nhóm: </span>
                <span class="text-400-semibold cursor-pointer hover-link" v-on:click="onClick('group', group.id)"
                      v-on:mouseover="onMouseover($event)" v-on:mouseleave="onMouseleave($event)"
                      v-popover.top="{ name: groupPopoverName }">{{ group.name }}</span>
                <tag-user-view v-if="usersTagged.length !== 0" :users-tagged="usersTagged" @mouseleave-user-tagged="mouseleaveUserTagged" @mouseover-user-tagged="mouseoverUserTagged" />
              </template>
              <template v-else-if="post.type === POST_TYPE.UPDATE_GROUP_AVATAR">
                <span class="neutral_2"> đã thay đổi ảnh bìa của nhóm: </span>
                <span class="text-400-semibold cursor-pointer hover-link" v-on:click="onClick('group', group.id)"
                      v-on:mouseover="onMouseover($event)" v-on:mouseleave="onMouseleave($event)"
                      v-popover.top="{ name: groupPopoverName }">{{ group.name }}</span>
              </template>
              <template v-else>
                <span v-if="group.name" class="neutral_2"> &#9654; </span>
                <span v-if="group.name" class="text-400-semibold cursor-pointer hover-link groupPopoverName" v-on:click="onClick('group', group.id)"
                      v-on:mouseover="onMouseover($event)" v-on:mouseleave="onMouseleave($event)"
                      v-popover.top="{ name: groupPopoverName }">{{ group.name }}</span>
                <tag-user-view v-if="usersTagged.length !== 0 && group.name"
                        :users-tagged="usersTagged" @mouseleave-user-tagged="mouseleaveUserTagged" @mouseover-user-tagged="mouseoverUserTagged" />
                <tag-user-view v-else-if="usersTagged.length !== 0"
                        :users-tagged="usersTagged" @mouseleave-user-tagged="mouseleaveUserTagged" @mouseover-user-tagged="mouseoverUserTagged" />
              </template>
            </template>

            <template v-else-if="group && isGroup">
              <template v-if="post.type === POST_TYPE.FILE">
                <span class="neutral_2"> đã tải lên {{ post.medias.length }} file.</span>
                <div v-if="usersTagged.length !== 0">
                  <tag-user-view :users-tagged="usersTagged" @mouseleave-user-tagged="mouseleaveUserTagged" @mouseover-user-tagged="mouseoverUserTagged" />
                </div>
              </template>
              <template v-else-if="post.type === POST_TYPE.CREATE_GROUP">
                <span class="neutral_2"> đã tạo nhóm </span>
                <span class="text-400-semibold">{{ group.name }}</span>
              </template>
              <template v-else-if="post.type === POST_TYPE.UPDATE_GROUP_AVATAR">
                <span class="neutral_2"> đã thay đổi ảnh bìa của nhóm.</span>
              </template>
            </template>
          </template>
        </div>
        <div class="" v-else>
          <span class="text-400-semibold cursor-pointer hover-link userPopoverName" v-on:click="onClick('user', userPost.id)"
                v-on:mouseover="onMouseover($event)" v-on:mouseleave="onMouseleave($event)"
                v-popover.top="{ name: userPopoverName}">{{ userPost.fullname }}</span>
          <span v-if="usersTagged.length !== 0">
            <tag-user-view :users-tagged="usersTagged" @mouseleave-user-tagged="mouseleaveUserTagged" @mouseover-user-tagged="mouseoverUserTagged" />
          </span>
        </div>
        <p class="neutral_2 text-300-default mt-3" style="margin-top: 4px">{{ fromNow(post.created_at) }}</p>
      </div>
      <div class="more-btn-wrapper menu-post" v-if="!isPostPreview">
        <template v-if="post">
          <img v-if="post.is_pin == 1" class="bookmark" src="/img/post/header/bookmark.svg">
        </template>
        <div @click="toggleMoreBtn" v-on-clickaway="onClickMoreBtnAway">
          <md-icon class="cursor-pointer">more_horiz</md-icon>
        </div>
        <div class="more-dropdown" v-show="moreBtnActive">
          <md-list class="p-0px">
            <md-list-item class="cursor-pointer" @click="copyPostUrl">
              <span class="md-list-item-text pl-15px">Sao chép link bài viết</span>
            </md-list-item>
            <md-list-item class="cursor-pointer" @click="showNotificationSetting">
              <span class="md-list-item-text pl-15px">Cài đặt thông báo</span>
            </md-list-item>
            <md-list-item class="cursor-pointer" @click="showUpdatePostModal" v-if="userPost.id === this.$store.state.auth.user.id">
              <span class="md-list-item-text pl-15px">Chỉnh sửa bài viết</span>
            </md-list-item>
            <md-list-item class="cursor-pointer" @click="showConfirmDeletePost" v-if="userPost.id === this.$store.state.auth.user.id">
              <span class="md-list-item-text pl-15px">Xóa bài viết</span>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </md-list-item>
  </md-card-header>
</template>

<script>
import moment from "moment";
import {GROUP_TYPE, POST_TYPE} from "../../../const/const";
import {helpers} from "../../../helper/helpers";
import { mixin as clickaway } from 'vue-clickaway';
import PostService from "../../../store/services/PostService";
import UserInfo from "../PopoverInfo/UserInfo";
import GroupInfo from "../PopoverInfo/GroupInfo";
import TagUserView from "./TagUserView";
import group from "../../../store/modules/group";
import $ from "jquery";
import ImageTemplate from "../../../components/ImageTemplate";

export default {
  components: {UserInfo, GroupInfo, TagUserView, ImageTemplate},
  mixins: [ clickaway ],
  name: "post-header",
  mounted() {
    this.typeNameAndColor = helpers.getGroupTypeNameAndColor(this.group.type);
  },
  props: {
    post: {
      type: Object,
      default: () => {
      },
    },
    userPost: {
      type: Object,
      default: () => {
      },
    },
    group: {
      type: Object,
      default: () => {
      },
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
    isPostDetail: {
      type: Boolean,
      default: false,
    },
    pollData: {
      type: Object,
      default: null
    },
    usersTagged: {
      type: Array,
      default: () => [],
    },
    isPostPreview: {
      type: Boolean,
      default: false,
    },
    postPreview: {
      type: Object,
      default: null,
    }
  },
  computed: {
    userPopoverName() {
      return this.post.id + '_' + this.userPost.id
    },
    groupPopoverName() {
      return this.post.id + '_'
    }
  },
  data() {
    return {
      GROUP_TYPE: GROUP_TYPE,
      POST_TYPE: POST_TYPE,
      isClick: false,
      moreBtnActive: false,
      typeNameAndColor: {},
      userPopupName : false,
      groupPopupName : false,
      userInfo: null,
    };
  },
  methods: {
    fromNow(date) {
      return moment(date).locale("vi").fromNow();
    },
    onMouseover(e) {
      this.userPopupName = e.target.classList.contains('userPopoverName');
      this.groupPopupName = e.target.classList.contains('groupPopoverName');
      if (this.userPopupName) {
        $('.user-popover').css("left", "0px");
        this.userInfo = this.userPost;
      }
    },
    mouseoverUserTagged(e) {
      // this.userPopupName = e.userPopupName;
      // let leftCss = e.offsetLeft;
      // if (e.offsetTop > 10) {
      //   let topCss = -157 + e.offsetTop;
      //   $('.user-popover').css("top", topCss + 'px');
      // } else {
      //   $('.user-popover').css("top", '-157px');
      // }
      // $('.user-popover').css("left", leftCss + 'px');
      // this.userInfo = e.userTagged;
    },
    mouseleaveUserTagged(e) {
      // this.userPopupName = e.userPopupName;
    },
    onMouseleave(e) {
      this.userPopupName = e.relatedTarget.classList.contains('user-info-wrapper');
      this.groupPopupName = e.relatedTarget.classList.contains('group-info-wrapper');
    },

    onClick(type, id) {
      if (this.isClick) {
        this.isClick = false;
        return;
      } else {
        if (type === 'group') {
          this.$router.push(this.getGroupLink(id));
        } else if (type === 'user') {
          this.$router.push(this.getProfileLink(id));
        }
      }
      return;
    },

    getProfileLink(id) {
      return '/profile/' + id;
    },

    getGroupLink(id) {
      return '/group/' + id;
    },

    openOriginPost(postPreview) {
      return this.$router.push('/group/' + postPreview.group.id + '/post/' + postPreview.post.id);
    },

    toggleMoreBtn() {
      this.moreBtnActive = !this.moreBtnActive;
    },
    onClickMoreBtnAway() {
      this.moreBtnActive = false;
    },
    showConfirmDeletePost() {
      this.$modal.show('dialog', {
        title: 'Xóa bài viết?',
        text: 'Bạn có chắc chắn muốn xóa bài viết này không?',
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              this.deletePost(this.post.id);
            }
          }
        ]
      })
    },
    deletePost(postId) {
      PostService.deletePost(postId).then(res => {
        if (res && res.status) {
          let lists = this.$store.state.post.posts.filter(x => {
            return x.post.id != postId;
          })
          this.$store.state.post.posts = lists;
        }
        this.$store.dispatch("changePostFlag", 2);
        this.$modal.hide('dialog')
      });
    },

    showUpdatePostModal() {
      this.$modal.show('update-post-modal',          {
            postData: this.post,
            userInfo: this.userPost,
            groupInfo: this.group,
            pollData: this.pollData
          });
      this.moreBtnActive = false;
    },
    copyPostUrl() {
      let url = window.location.origin;
      if (this.isPostDetail) {
        url += '/post/image/' + this.post.id;
      } else {
        if (this.isPost) {
          url += '/new-feeds/' + this.post.id;
        } else {
          if (this.group.id) {
            url += '/group/' + this.group.id + '/post/' + this.post.id;
          } else {
            url += '/new-feeds/' + this.post.id;
          }
        }
      }
      navigator.clipboard.writeText(url);
      this.$store.dispatch("alerts/success", 'Bạn đã sao chép link thành công');
      this.moreBtnActive = false;
    },
    showNotificationSetting() {
      this.$modal.show('notification-setting-modal', {postId:this.post.id, enable_notification: this.post.enable_notification});
    }
  }

};
</script>

<style lang="scss">
  .origin-link {
    display: inline !important;
    text-transform: initial;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #1446ff !important;
    }
  }
.triangle-post {
  width: 9px;
  margin: 0px 5px 3px 5px;
  width: auto !important;
}

.avatar {
  background-size: cover;
  width: 40px !important;
  height: 40px !important;
  object-fit: cover;
}

.header-popover {
  width: 371px !important;
  top: -157px;
  position: absolute;
  background: #fff;
  z-index: 10;
  border: 1px solid #00000038;
  height: 170px;
  box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 14%);
  .material-icons {
    font-size: 16px;
    color: #4b4b4b;
    margin-right: 4px;
    padding: 2px 0;
  }
}

.header-popover:before {
  bottom: 0px !important;
}

.user-popover {
  left: 0px;
}

.group-popover {
  left: auto !important;

  .group-info-wrapper {
    width: 360px;
    height: 177px;
    border-radius: 4px;
    padding: 10px;

    .group-info-container {
      display: flex;
      width: 100%;
    }

    .info-text {
      flex: 1;
    }

    .info-image {
      width: 100px;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .avatar-wrapper {
      width: 30px;
      height: 30px;
      float: left;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #F9FAFC;;
      }
    }

    .avatar-wrapper:nth-child(n+2) {
      margin-left: -8px;
    }
  }
}

.follow-button {
  width: 195px;
  height: 35px;
  background-color: #EDFAD0;
  border: none;
  border-radius: 4px;
}
.more-btn-wrapper.menu-comment {
  position: absolute;
  top: 20px;
  right: 28px;
  padding: 2px;
}
.more-btn-wrapper.menu-post {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
}
.more-btn-wrapper {
  background: transparent;

  img, i {
    width: 30px !important;
    height: 30px !important;
  }

  .bookmark {
    margin-right: 10px;
  }

  .more-dropdown {
    position: absolute;
    width: 250px;
    right: 0px;
    top: 40px;

    ul {
      border: 1px solid #D8DAE5;
      border-radius: 4px;
      li {
        z-index: 4;
      }
    }
  }

  .md-list-item {
    margin-left: 0px !important;
  }

  .md-list-item-text {
    position: inherit !important;
    color: #26272d;
    font-weight: 500;
    font-size: 14px;
  }

  .group-more-icon {
    margin-right: 10px !important;
  }
}

.post-header-wrapper {
  .md-list-item-default {
    overflow: visible;
  }
  .header-name{
    padding-right: 36px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    * {
      width: auto !important;
      white-space: pre-wrap;
    }
  }
}

.tag-users__popover {
  width: 135px;
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  display: none;
  div {
    color: white;
  }
}
</style>
