<template>
  <div class="p-relative postComment">
    <template>
      <!-- <popover v-if="comment.user" :name=userPopoverName class="header-popover user-popover">
        <user-info :user-post="comment.user" />
      </popover> -->
      <div  v-if="comment.user" class="header-popover user-popover" v-show="userPopupName" v-on:mouseleave="onMouseleave($event)">
        <user-info :user-post="comment.user" />
      </div>
    </template>
    <md-list-item class="post-another-comment">
      <md-avatar>
        <router-link :to="getProfileLink(comment.user.id)">
          <img class="avatar" :src="comment.user.thumbnail.small_thumb || '/img/profile/avatar.png'">
        </router-link>
      </md-avatar>
      <div class="md-list-item-text">
        <span class="text-300-semibold neutral_2 hover-link cursor-pointer userPopoverName" v-on:mouseover="onMouseover($event)" v-on:click="onClick('user', comment.user.id)"
               v-on:mouseleave="onMouseleave($event)" v-popover.top="{ name: userPopoverName}">{{ comment.user.display_name || comment.user.fullname }}</span>
        <p class="white-space-normal comment-content" :class="comment.mentions && comment.mentions.length > 0 ? 'mention-post' : ''" :id="'text-html-' + comment.id" v-html="convertUrlContent(comment)"></p>
        <span class="cursor-pointer hover-link pl-15px" @click="handleViewMore" style="color: #0d47a1" v-if="isLargeText">{{showingFullText ? "...Thu gọn" : "...Xem thêm"}}</span>
        <div class="comment-img-wrapper cursor-pointer comment-img-wrapper-sticker" style="display: block" v-if="comment.is_sticker">
          <img class="comment-img" :src="comment.thumbnail.small_thumb">
        </div>
        <div class="comment-img-wrapper cursor-pointer" style="display: flex" v-else-if="comment.thumbnail || comment.image" @click="showFullCommentImage">
          <img class="comment-img" :src="comment.image ? comment.image: comment.thumbnail.medium_thumb" alt="">
        </div>
      </div>
      <div class="md-icon more-btn-wrapper menu-comment" v-show="comment.user.id === this.$store.state.auth.user.id" @click="toggleMoreBtn" v-on-clickaway="onClickMoreBtnAway">
        <md-icon class="cursor-pointer comment-more-btn">more_horiz</md-icon>
        <div class="more-dropdown" v-show="activeDropdown">
          <md-list class="p-0px">
            <md-list-item class="cursor-pointer" @click="showUpdateCommentModal">
              <div class="md-list-item-text comment-more-option">Sửa bình luận</div>
            </md-list-item>
            <md-list-item class="cursor-pointer" @click="showConfirmDeleteComment">
              <div class="md-list-item-text comment-more-option">Xóa bình luận</div>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </md-list-item>
    <div class="sub-comment-wrapper">
      <div class="pl-15px sub-comment-action">
        <span class="p-relative">
          <span class="text-300-semibold neutral_3 cursor-pointer like-btn" :class="reactionCurrentColorClass" @click="actionLike(1, 'likeBtn')">{{reactionCurrentName}}</span>
          <span class="text-300-semibold neutral_3 cursor-pointer" @click="clickReplyCommentBtn">Phản hồi</span>
          <span class="text-300-default neutral_3 cursor-pointer">{{ fromNow(comment.created_at) }}</span>
          <div class="reaction-box background_neutral_white">
          <div class="reaction-icon reaction-like" data-reaction="1" @click="actionLike(1, 'reaction')">
            <label>Thích</label>
          </div>
          <div class="reaction-icon reaction-love" data-reaction="2" @click="actionLike(2, 'reaction')">
            <label>Yêu thích</label>
          </div>
          <div class="reaction-icon reaction-care" data-reaction="3" @click="actionLike(3, 'reaction')">
            <label>Thương thương</label>
          </div>
          <div class="reaction-icon reaction-haha" data-reaction="4" @click="actionLike(4, 'reaction')">
            <label>Haha</label>
          </div>
          <div class="reaction-icon reaction-wow" data-reaction="5" @click="actionLike(5, 'reaction')">
            <label>Wow</label>
          </div>
          <div class="reaction-icon reaction-sad" data-reaction="6" @click="actionLike(6, 'reaction')">
            <label>Buồn</label>
          </div>
          <div class="reaction-icon reaction-angry" data-reaction="7" @click="actionLike(7, 'reaction')">
            <label>Tuyệt vời</label>
          </div>
        </div>
        </span>
        <span class="neutral_2 like-count-wrap cursor-pointer" v-show="likeCounts > 0" @click="$modal.show('user-post-interactive-modal',
        {postId: comment.id,type: 'like'})">
          <span v-for="item in listReactionCurrentClass" :key="item" class="icon-like-count" :class="item"></span>
          <span class="ml-5px">{{ likeCounts }}</span>
        </span>
      </div>
      <div v-for="subComment in replyCommentList" :key="subComment.id">
        <post-sub-comment :comment="subComment" @remove-comment="removeComment">
        </post-sub-comment>
      </div>
      <div class="p-relative">
        <VEmojiPicker class="p-absolute emoji" v-if="showDialogEmoji" @select="onSelectEmoji" v-click-outside="hiddenDialogEmoji"/>
        <md-list-item class="post-comment" v-if="showReplyInput">
          <md-avatar>
            <img class="avatar" :src="user.thumbnail.small_thumb || '/img/profile/avatar.png'">
          </md-avatar>

          <div class="list-item-text p-relative">
            <md-field md-inline>
              <MentionChat
              @open="onOpen"
              @close="onClose"
              @apply="onApply"
              :group="group"
              class="mentionable">
              <textarea class="comment" placeholder="Viết phản hồi..." :ref='"input_" + comment.id' v-model="user_comment"
                        @click="hiddenDialogEmoji()"
                        @input="autoResizeTextarea"
                        @keydown="handleKeydown">
              </textarea>
            </MentionChat>
            </md-field>
            <div class="cmt-action-wrapper">
              <img class="comment-action" src="/img/post/footer/smile.png" @click="toogleDialogEmoji">
              <img class="comment-action ml-5px" src="/img/post/footer/ic_camera.svg" @click="openUploadCommentImage">
              <Sticker :autoLoad="false" class="ml-5px" @send-sticker="sendSticker" />
            </div>
            <input @change="onChangeCommentImage" type="file" :id="'imageUpload' + comment.id" accept="image/jpg, image/png, image/jpeg" hidden>
          </div>
        </md-list-item>
        <div class="comment-img-wrapper" :id="'commentImageWrapper' + comment.id">
          <img class="comment-img comment-image-preview" :id="'commentImage' + comment.id" src="/img/group/group_image_low.jpg" alt="">
          <div class="cancel-btn cursor-pointer" @click="removeCommentImage">
            <md-icon>cancel</md-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mixin as clickaway} from "vue-clickaway";
import PostService from "../../../store/services/PostService";
import PostSubComment from "./postSubComment";
import {CREATE_POST_LEVEL, POST_TYPE} from "../../../const/const";
import {VEmojiPicker} from 'v-emoji-picker';
import UserInfo from "../PopoverInfo/UserInfo";
import MentionChat from "../../Workplace/Pages/Messager/Mention";
import Sticker from '../../Workplace/Pages/Messager/Sticker';

export default {
  name: "post-comment",
  components: {PostSubComment, VEmojiPicker, UserInfo, MentionChat, Sticker},
  mixins: [ clickaway ],
  computed: {
    reactionCurrentName() {
      switch (this.reactionExisting) {
        case 2:
          return 'Yêu thích';
        case 3:
          return 'Thương thương';
        case 4:
          return 'Haha';
        case 5:
          return 'Wow';
        case 6:
          return 'Buồn';
        case 7:
          return 'Tuyệt vời';
        default:
          return 'Thích';
      }
    },
    reactionCurrentColorClass() {
      switch (this.reactionExisting) {
        case 1:
          return 'is_like';
        case 2:
          return 'is_love';
        case 3:
        case 4:
        case 5:
        case 6:
          return 'is_reaction_other'
        case 7:
          return 'is_angry';
        default:
          return '';
      }
    },
    reactionCurrentIconClass() {
      switch (this.reactionExisting) {
        case 2:
          return 'reaction-love';
        case 3:
          return 'reaction-care';
        case 4:
          return 'reaction-haha';
        case 5:
          return 'reaction-wow';
        case 6:
          return 'reaction-sad';
        case 7:
          return 'reaction-angry';
        default:
          return '';
      }
    },
    listReactionCurrentClass() {
      let reactionList = [];
      let reactionClassList = [];
      //Thuật toán lấy ra 3 reaction có số lượng lớn nhất
      for (const key in this.reactionTotal) {
        if (this.reactionTotal[key] > 0) {
          let reaction = {
            "type": key,
            "total": this.reactionTotal[key]
          };
          reactionList.push(reaction);
        }
      }
      for (let i = 0; i < reactionList.length - 1; i++) {
        for (let j = i + 1; j < reactionList.length; j++) {
          if (reactionList[i].total < reactionList[j].total) {
            let temp = reactionList[j];
            reactionList[j] = reactionList[i];
            reactionList[i] = temp;
          }
        }
      }
      for (let i = 0; i < reactionList.length; i++) {
        switch (reactionList[i].type) {
          case 'love':
            reactionClassList.push('reaction-love');
            break;
          case 'care':
            reactionClassList.push('reaction-care');
            break;
          case 'haha':
            reactionClassList.push('reaction-haha');
            break;
          case 'wow':
            reactionClassList.push('reaction-wow');
            break;
          case 'sad':
            reactionClassList.push('reaction-sad');
            break;
          case 'angry':
            reactionClassList.push('reaction-angry');
            break;
          default:
            reactionClassList.push('reaction-like');
        }
        if (reactionClassList.length == 3) {
          break;
        }
      }
      return reactionClassList;
    },
    userPopoverName() {
      return this.comment.id + '_' + this.comment.user.id
    },
  },
  watch: {
    'reactionCurrent': function (newVal, oldVal) {
      this.reactionExisting = newVal
    },
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      activeDropdown: false,
      showReplyInput: false,
      showDialogEmoji: false,
      user_comment: '',
      CREATE_POST_LEVEL: CREATE_POST_LEVEL,
      replyCommentList: this.comment.replies,
      likeCounts: 0,
      commentImage: null,
      commentImageUrl: "",
      reactionExisting: this.comment.reaction_current,
      reactionTotal: this.comment.reaction_stats,
      isLargeText: false,
      showingFullText: false,
      isClick: false,
      userPopupName : false,
      activeMention: false,
      mentions: []
    }
  },
  props: {
    comment: {
      type: Object,
      default: () => {
        return {};
      }
    },
    postId: null,
    group: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (this.replyCommentList && this.replyCommentList.length !== 0) {
      this.showReplyInput = true;
    };
    for (const key in this.reactionTotal) {
      this.likeCounts += this.reactionTotal[key];
    };
    let elm = 'text-html-' + this.comment.id;
    let textContentElm = document.getElementById(elm);
    if (textContentElm) {
      let offsetHeight = textContentElm.offsetHeight;
      // 20 chính là line-height của thẻ div
      if (offsetHeight / 20 > 20) {
        this.isLargeText = true;
        this.showingFullText = false;
        document.getElementById(elm).classList.add("text-ellipsis");
      };
    }
  },
  inject: {
    getPostId: {default: null},
  },
  methods: {
    getProfileLink(id) {
      return '/profile/' + id;
    },
    fromNow(date) {
      return moment(date).locale("vi").fromNow();
    },
    toggleMoreBtn() {
      this.activeDropdown = true;
    },
    onClickMoreBtnAway() {
      this.activeDropdown = false;
    },
    showConfirmDeleteComment() {
      this.$modal.show('dialog', {
        title: 'Xóa bình luận?',
        text: 'Bạn có chắc chắn muốn xóa bình luận này không?',
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              this.deleteComment();
            }
          }
        ]
      })
    },

    showUpdateCommentModal() {
      this.$modal.show('update-comment-modal', {commentData: this.comment, postParentId: this.getPostId});
    },

    deleteComment() {
      PostService.deletePost(this.comment.id).then(res => {
        if (res && res.status) {
          this.$emit('remove-comment', this.comment.id);
        }
        this.$modal.hide('dialog');
        this.activeDropdown = false;
      });
    },
    hiddenDialogEmoji() {
      this.showDialogEmoji = false
    },
    toogleDialogEmoji() {
      this.showDialogEmoji = !this.showDialogEmoji;
    },

    onSelectEmoji(emoji) {
      this.user_comment += emoji.data;
      try {
        this.$nextTick(function () {
          if(this.$refs["input_" + this.comment.id].$el){
            this.$refs["input_" + this.comment.id].$el.focus()
          }
        })
      } catch (e) {
      }
    },

    postComment(level) {
      if (this.user_comment !== '') {
        let data = {
          content: this.user_comment,
          created_at: moment(),
          id: 0,
          image: null,
          user: {...this.user},
        }

        let formData = new FormData();
        formData.append("html_content", this.user_comment);
        formData.append("type", POST_TYPE.TEXT);
        formData.append("parent_post_id", this.comment.id);
        formData.append("level", level);
        formData.append("model_type", 1);

        if(this.mentions.length > 0){
          for (let mention = 0; mention < this.mentions.length; mention++) {
            formData.append("mentions[]", JSON.stringify(this.mentions[mention]));
            formData.append("mention_type", 1);
          }
          data.mentions = this.mentions;
        }

        if (this.commentImage) {
          formData.append("files[]", this.commentImage);
          data.image = this.commentImageUrl;
        }

        PostService.createPost(formData).then(res => {
          if (res.status) {
            data.id = res.data.post_id;
            if(this.replyCommentList === undefined){
              this.replyCommentList = [data];
            }else{
              this.replyCommentList = this.replyCommentList.concat(data);
            }
          }
        });

        this.mentions = [];
        this.user_comment = '';
        this.removeCommentImage();
      }
    },
    clickReplyCommentBtn() {
      this.showReplyInput = true;
      try {
        this.$nextTick(function () {
          if(this.$refs["input_" + this.comment.id].$el){
            this.$refs["input_" + this.comment.id].$el.focus()
          }
        })
      } catch (e) {
      }
    },
    actionLike(type, action) {
      const isReact = this.reactionExisting === null;

      const params = {
        "type": type,
        "is_like": isReact,
        "post_id": this.comment.id,
      };

      if (action == 'likeBtn') {
        type = 1;
        if (isReact) {
          params.type = 1;
          this.likeCounts++;
          this.reactionTotal.like++;
          this.reactionExisting = 1;
        } else {
          params.type = this.reactionExisting;
          this.likeCounts--;
          this.decrementReactionTotal(this.reactionExisting);
          this.reactionExisting = null;
        }
      } else {
        params.is_like = true;
        if (isReact) {
          this.likeCounts++;
          this.increaseReactionTotal(type);
        } else {
          let oldReaction = this.reactionExisting;
          this.decrementReactionTotal(oldReaction);
          this.increaseReactionTotal(type);
        }
        this.reactionExisting = type;
      }

      PostService.likePost(params).then(res => {
      });
    },
    openUploadCommentImage() {
      let elmId = "imageUpload" + this.comment.id;
      document.getElementById(elmId).click();
    },
    onChangeCommentImage(e) {
      let cmtWrapper = "commentImageWrapper" + this.comment.id;
      let cmtImage = "commentImage" + this.comment.id;
      this.commentImage = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.commentImageUrl = reader.result;
        document.getElementById(cmtImage).src = this.commentImageUrl;
      };
      reader.readAsDataURL(e.target.files[0]);
      document.getElementById(cmtWrapper).style.display = 'block';

    },
    removeCommentImage() {
      let cmtWrapper = "commentImageWrapper" + this.comment.id;
      this.commentImage = null;
      document.getElementById(cmtWrapper).style.display = 'none';
      let imgUpload = "#imageUpload" + this.comment.id;
      document.querySelector(imgUpload).type = "text";
      document.querySelector(imgUpload).type = "file";
    },
    showFullCommentImage() {
      this.$modal.show('show-comment-image', {imgUrl: this.comment.image ?? this.comment.thumbnail.medium_thumb});
    },
    convertUrlContent(content) {

      let text = content.content;

      if(text){
        var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        text = text.replace(urlRegex, function(url) {
          return '<a href="' + url + '">' + url + '</a>';
        });
      }
      

      if (content.mentions && content.mentions.length > 0) {
        let dataReplace = [];
        let ids = []
        for (let i = 0; i < content.mentions.length; i++) {
          let offsetMention = content.mentions[i]['offset'];
          let lengthMention = content.mentions[i]['length'];
          let idMention = content.mentions[i]['user_id'];

          if(ids.includes(idMention) === false){
            let mentionText = text.substring(offsetMention, (offsetMention + lengthMention));
            dataReplace.push({
              'id': idMention,
              'text': mentionText
            });
            ids.push(idMention);
          }
        }

        for (let x = 0; x < dataReplace.length; x++) {

          if(dataReplace[x]['text'] === ''){
            continue;
          }
          
          if (dataReplace[x]['id'] != 0) {
            text = text.replaceAll(dataReplace[x]['text'], "<a target='_blank' href='profile/" + dataReplace[x]['id'] + "' ><span class='cursor-pointer hover-link user-tag'>" + dataReplace[x]['text'] + "</span></a>")
          } else {
            text = text.replaceAll(dataReplace[x]['text'], "<a href='javascript:;' ><span class='cursor-pointer hover-link user-tag'>" + dataReplace[x]['text'] + "</span></a>")
          }
        }
      }

      return text;
    },
    increaseReactionTotal(type) {
      switch (type) {
        case 1:
          this.reactionTotal.like++;
          break;
        case 2:
          this.reactionTotal.love++;
          break;
        case 3:
          this.reactionTotal.care++;
          break;
        case 4:
          this.reactionTotal.haha++;
          break;
        case 5:
          this.reactionTotal.wow++;
          break;
        case 6:
          this.reactionTotal.sad++;
          break;
        case 7:
          this.reactionTotal.angry++;
          break;
      }
    },
    decrementReactionTotal(type) {
      switch (type) {
        case 1:
          this.reactionTotal.like--;
          break;
        case 2:
          this.reactionTotal.love--;
          break;
        case 3:
          this.reactionTotal.care--;
          break;
        case 4:
          this.reactionTotal.haha--;
          break;
        case 5:
          this.reactionTotal.wow--;
          break;
        case 6:
          this.reactionTotal.sad--;
          break;
        case 7:
          this.reactionTotal.angry--;
          break;
      }
    },
    handleViewMore() {
      let elm = 'text-html-' + this.comment.id;
      // showingFullText = true => đang mở cần thu lại, thu lại là thêm class text-ellipsis
      if (this.showingFullText) {
        document.getElementById(elm).classList.add("text-ellipsis");
      } else {
        document.getElementById(elm).classList.remove("text-ellipsis");
      }
      this.showingFullText = !this.showingFullText;
    },
    removeComment(e) {
      let commentList = this.replyCommentList.filter(x => {
        return x.id != e;
      });
      this.replyCommentList = commentList;
    },
    onMouseover(e) {
      this.userPopupName = e.target.classList.contains('userPopoverName');;
      this.isClick = true;
      e.target.click();
    },
    onMouseleave(e) {
      this.userPopupName = e.relatedTarget.classList.contains('user-info-wrapper');
      this.isClick = true;
      e.target.click();
    },
    onClick(type, id) {
      if (this.isClick) {
        this.isClick = false;
        return;
      } else {
        if (type === 'group') {
          this.$router.push(this.getGroupLink(id));
        } else if (type === 'user') {
          this.$router.push(this.getProfileLink(id));
        }
      }
      return;
    },
    autoResizeTextarea(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    },
    handleKeydown(event) {
      if (event.key === 'Enter' && !event.shiftKey && !this.activeMention) {
        this.positionMention(event.target.value);
        this.postComment(CREATE_POST_LEVEL.REPLY_COMMENT);
        $('.highlightTextarea-container mark').css('background-color', '#FFF');
        event.preventDefault();
      }
    },
    onOpen(e){
      this.activeMention = true;
    },

    onClose (e) {
      this.activeMention = false;
    },

    onApply (item, key, replacedWith) {
      this.mentions.push({
        mention_user_id: item.value == 'All' ? 0 : item.id,
        user_id: item.value == 'All' ? 0 : item.id,
        off_set: this.user_comment.length - 1,
        offset: this.user_comment.length - 1,
        text: '@' + item.fullname,
        length: item.fullname.length + 1,
      });
      this.activeMention = false;
    },

    sendSticker(sticker){
      let data = {
          content: '',
          created_at: moment(),
          id: Date.now().toString(),
          image: null,
          user: {...this.user},
          is_sticker: true
        }
        let formData = new FormData();
        formData.append("html_content", '');
        formData.append("type", POST_TYPE.STICKER);
        formData.append("parent_post_id", this.comment.id);
        formData.append("level", CREATE_POST_LEVEL.REPLY_COMMENT); // level 2: Comment
        formData.append("model_type", 1);
        formData.append("file_ids[]", sticker.file_id);
        data.image = sticker.image_url;
        data.thumbnail = sticker.thumbnails;
        PostService.createPost(formData).then(res => {
          if (res.status) {
            data.id = res.data.post_id;
            if(this.replyCommentList === undefined){
              this.replyCommentList = [data];
            }else{
              this.replyCommentList = this.replyCommentList.concat(data);
            }
          }
        });
    },

    positionMention(inputText){
      for (let i = 0; i < this.mentions.length; i++) {
        let text = this.mentions[i]['text'];
        let offsetToInput = inputText.search(text)
        if (offsetToInput > -1){
          this.mentions[i]['off_set'] = offsetToInput;
          this.mentions[i]['offset'] = offsetToInput;
        }else{
          this.mentions.splice(i,1);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.file-size {
  display: inline-block;
  width: 70px;
  text-align: right;
  margin-right: 16px;
}

.post-file {
  z-index: 0 !important;
  .md-list-item-content {
    z-index: 0 !important;
  }
}

.sub-comment-wrapper {
  padding-left: 40px;
  .sub-comment-action {
    padding-top: 2px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    .p-relative {
      margin-left: 15px;
      span {
        margin: 0 8px;
      }
    }
  }
  .md-button-clean {
    overflow: inherit !important;
  }
  .emoji {
    bottom: 30px !important;
    left: 65px !important;
  }

  .like-count-wrap {
    display: inline-flex;
    align-items: center;

    .icon-like-count {
      width: 18px;
      height: 18px;
    }
  }
  .list-item-text{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: initial;
      line-height: 1.25em;
      white-space: nowrap;
      .comment{
        width: 100%;
      }
      .p-relative {
        overflow: initial !important;
      }
    }
}

.post-another-comment {
  .comment-img-wrapper {
    padding: 10px 0 0 0 !important;
  }
  .comment-img-wrapper-sticker{
    width: 100px !important;
    height: 100px !important;
  }
}

.sub-comment-action {
  .reaction-box {
    bottom: 16px !important;
  }
}
.postComment {
  .header-popover {
      top: -165px !important;
  }
  .highlightTextarea{
      .highlightTextarea-container{
          background: #fff !important;
          padding: 6px 15px 32px !important;
      }
  }
}
.mention-post{
  a{
    display: unset !important;
    .user-tag {
        font-size: 12px;
    }
  }
}
</style>
