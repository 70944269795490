<template>
  <div class="container-member-group container-search-chat">
    <div class="member-group-search flex-space-between container-input-search">
      <svg width="20px" height="20px"
           xmlns="http://www.w3.org/2000/svg" class="aza-Icon" role="img" viewBox="0 0 24 24">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.47 18.47a.75.75 0 0 1 1.06 0l2 2a.75.75 0 1 1-1.06 1.06l-2-2a.75.75 0 0 1 0-1.06ZM11 18.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm9-7.5a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              fill="#26282C"></path>
      </svg>
      <input name="search" @keyup.stop.prevent="onKeyupSearch($event)" type="text" placeholder="Tìm kiếm thành viên" autocomplete="off" class="input-search" v-model="searchMember">
    </div>
    <hr>
    <div class="add-member" v-if="role_add_user">
      <div class="addmore cursor-pointer centerx">
        <button class="text-400-semibold add-member-button flex-space-between cursor-pointer w_100" type="button"
          @click="$modal.show('add-user-modal', {type: TYPE_ADD_GROUP_MEMBER.UPDATE_GROUP, group_id : group_id})" >
          <div class="add-member-button__icon">
            <md-icon class="primary_y500">add</md-icon>
          </div>
          Thêm thành viên
          <div class="centerx">

            <!--            <vs-button @click="popupActive=true" color="primary" type="border">Open Default popup</vs-button>-->
            <!--            <vs-popup class="holamundo"  title="Thêm thành viên" :active.sync="popupActive">-->
            <!--              <p>-->
            <!--               thành viên-->
            <!--              </p>-->
            <!--            </vs-popup>-->

          </div>
          <div class="aza-Button--overlay"></div>
        </button>
      </div>
    </div>
    <!-- tab-list-member-with-admin -->
    <div class="cs-tab-menu mb-15px">
      <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 1 }" @click="chosenTab(1)">Thành viên</div>
      <div class="text-400-medium cursor-pointer" :class="{ active: tabActive == 2 }" @click="chosenTab(2)">Quản trị viên</div>
      <div v-if="isAdmin" class="text-400-medium cursor-pointer" :class="{ active: tabActive == 3 }" @click="chosenTab(3)">Phê duyệt</div>
    </div>
    <!-- tab-list-member-with-admin -->

    <div class="list-members">
        <div class="contents">
          <template>
            <md-content class="md-scrollbar scroll-listmember">
              <vue-element-loading :active="isLoading" spinner="bar-fade-scale"/>
              <vue-loadmore
                  :on-loadmore="onLoad"
                  :finished="finished"
                  :loading-text="'Đang tải'"
                  :finished-text="'Hết'">
              <div v-for="member in resultSearch" :key="member.id">
                <div class="member-action-list">
                  <md-list-item class="cursor-pointer chooseMember user-detail">
                      <md-avatar>
                        <img v-if="tabActive !== 3" class="avatar" :src="member.avatar.small_thumb || '/img/profile/avatar.png'">
                        <img v-else class="avatar" :src="member.thumbnail.small_thumb || '/img/profile/avatar.png'">
                      </md-avatar>
                      <div class="md-list-item-text">
                        <div class="text-400-medium mb-10px fullname">{{ member.fullname }}</div>
                        <div class="detail-info">
                          <span class="material-icons">schema</span>
                          <span class="text-info-name">
                              <span class="" v-if="member.parent_departement">{{ member.parent_departement.name }} > </span>
                              <span class="text-300-default" v-if="member.department">{{ member.department.name }}</span>
                              <span class="text-300-default" v-else>AZA GROUP</span>
                          </span>
                        </div>
                        <div class="detail-info" v-if="member.position">
                          <span class="material-icons">account_box</span>
                          <span class="text-info-name">{{ member.position.name }}</span>
                        </div>
                        <div class="detail-info">
                          <span class="material-icons">email</span>
                          <span class="text-info-name">{{ member.email }}</span>
                        </div>
                        <div class="detail-button-wrapper d-flex-space-between mt-10px" v-if="tabActive === 3">
                          <button class="btn-active text-300-semibold" @click="browseRequestJoinGroup(member.id, true)">
                            Đồng ý
                          </button>
                          <button class="btn-stroke text-300-medium" @click="browseRequestJoinGroup(member.id, false)">
                            Từ chối
                          </button>
                        </div>
                      </div>
                      <div class="listActions">
                        <div class="check-icon" v-on:click="dropDownAction(member.id)">
                          <span class="material-icons">more_horiz</span>
                        </div>
                      </div>
                    </md-list-item>
                  <div  v-click-outside="hideDropDown" v-if="dropDownId === member.id" class="contact-action-member" >
                    <div class="action" @click="goToProfile(member.id)">
                      <div class="icons"></div>
                      <div class="name">Xem trang cá nhân</div>
                    </div>
                    <div class="action" @click="setMemberAdmin(member)" v-if="member.id !== user.id && tabActive != 3 && role_add_user">
                      <div class="icons set-member"></div>
                      <div class="name">{{ textSetRole(member.id) }}</div>
                    </div>
                    <div class="action remove-member" v-if="isAdmin && member.id !== user.id && tabActive != 3" @click="removeMember(member)">
                      <div class="icons delete-user"></div>
                      <div class="name">Xóa khỏi nhóm</div>
                    </div>
                    <!-- <div class="action">
                      <div class="icons"></div>
                      <div class="name">Xóa và chặn khỏi nhóm</div>
                    </div> -->
                  </div>
                </div>
              </div>
              </vue-loadmore>
            </md-content>
          </template>
        </div>
    </div>
    <div class="modal-add-member">
<!--      <add-member-group-chat-modal :info_group="infoGroup" ></add-member-group-chat-modal>-->
    </div>
  </div>

</template>

<script>
  import {TYPE_ADD_GROUP_MEMBER} from "../../../../../const/const";
  import ChatService from "../../../../../store/services/ChatService";

  export default {
  name: "MemberGroup",
  props : {
    membersGroup : Array,
    infoGroup : Object,
    activeTab: {
        type: Number,
        default: 1,
      }
  },
  created() {
    // this.getMembersGroup();
  },
  mounted() {
    this.chosenTab(this.activeTab);
    this.getMembersGroup();

    let selected = this.$store.state.chat.chatGroupSelected;

    if(selected.is_admin == 1 || selected.settings.join_policy == 2){
      this.role_add_user = true;
    }

  },
  watch: {
    '$store.state.chat.modifyMessageSocket'(newV) {
      if(newV.type === "set_role_admin"){
        if(newV.data.user_id === this.user.id){
          this.$store.commit('chat/updateChatGroupSelected', {
            ...this.$store.state.chat.chatGroupSelected,
            is_admin: newV.data.is_admin ? 1 : 0
          });
          this.role_add_user = newV.data.is_admin;
        }
      }else if(newV.type === "add_member"){
        this.getMembersGroup();
      }
    },
    '$route.params.messenger_id'(to, from) {
      this.getMembersGroup();
    },
  },
  data() {
    return {
      popupActive:false,
      dropDownId : false,
      searchMember : '',
      resultSearch : [],
      TYPE_ADD_GROUP_MEMBER,
      role_add_user : false,
      user : this.$store.state.auth.user,
      group_id: this.$route.params.messenger_id,
      finished: false,
      params: {
        page: 1,
        per_page: 20,
        group_id: this.$route.params.messenger_id,
        keyword: '',
        only_member: 'member'
      },
      last_page: 1,
      isLoading: false,
      tabActive: 1,
      timer: null,
      page_admin: 1,
      last_page_admin: 1,
      page_request: 1,
      last_page_request: 1,
      isAdmin: this.$store.state.chat.chatGroupSelected.is_admin === 1,
    }
  },
  methods : {
    dropDownAction(elementId = false){
      this.dropDownId = this.dropDownId === elementId ? false : elementId;
    },
    hideDropDown(){
      this.dropDownId = false;
    },
    onKeyupSearch(e){
      this.params.keyword = this.searchMember;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.onRefresh();
      }, 800)
    },

    onRefresh(done) {
      this.notifications = [];
      this.params.page = 1;
      this.page_admin = 1;
      this.page_request = 1;
      this.finished = false;
      this.getMembersGroup();
      done();
    },

    onLoad(done) {
      if ((this.params.page < this.last_page) || (this.page_admin < this.last_page_admin) || (this.page_request < this.last_page_request)) {
        if(this.tabActive == 1){
          this.params.page++ 
        }else if (this.tabActive == 2){
          this.page_admin++ 
        } else {
          this.page_request++
        }
        this.getMembersGroup(true);
      } else {
        this.finished = true;
      }
      done();
    },

    getMembersGroup(loadMore = false) {
      this.isLoading = true;
      let params = {...this.params};
      if(this.tabActive == 2){
        params.page = this.page_admin;
      }
      if (this.tabActive == 3) {
        params.page = this.page_request;
        delete params.only_member;
      }
      if (this.tabActive !== 3) {
        ChatService.getMembersGroup(params).then(res => {
          this.isLoading = false;
          if (res) {
            this.last_page = res.meta.last_page
            if (loadMore) {
              this.resultSearch = this.resultSearch.concat(res.data)
            } else {
              this.resultSearch = res.data.reverse();
            }
          }
        });
      } else {
        ChatService.getListRequestJoinChatGroup(params).then(res => {
          this.isLoading = false;
          if (res) {
            this.last_page_request = res.meta.last_page
            if (loadMore) {
              this.resultSearch = this.resultSearch.concat(res.data)
            } else {
              this.resultSearch = res.data.reverse();
            }
          }
        });
      }
    },

    goToProfile(userId){
      window.open('/profile/' + userId, '_blank');
    },

    removeMember(member){
      this.dropDownId = false;
      this.$modal.show('dialog', {
        title: 'Xóa thành viên?',
        text: 'Bạn có chắc chắn muốn xóa thành viên này không?',
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              ChatService.removeMemberChatGroup({ user_id: member.id, group_id: this.group_id }).then(res => {
                if (res.status) {
                  this.$store.dispatch("alerts/success", `Bạn đã xóa ${member.fullname} khỏi nhóm`);
                  this.resultSearch.splice(this.resultSearch.findIndex((item) => item.id === member.id), 1);
                  // this.$store.commit('chat/updateChatGroupSelected', res.data);
                  this.$modal.hide('dialog')
                } else {
                  this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra!');
                }
              });
            }
          }
        ]
      })
    },
    callBackFunction(){
      console.log(1234);
    },

    chosenTab(tabIndex){
      this.tabActive = tabIndex;
      this.params.only_member = tabIndex == 1 ? 'member' : 'admin';
      this.hideDropDown();
      this.getMembersGroup();
    },

    setMemberAdmin(member){
      let addRoleAdmin = this.isAdmin && this.tabActive == 1;
      const params = {
          is_admin : addRoleAdmin,
          user_id : member.id,
          group_id: this.group_id
        }
      ChatService.setMemberAdminChatGroup(params).then(res => {
        if (res.status) {
          let textAlert = addRoleAdmin ? `Bạn đã chỉ định ${member.fullname} làm quản trị viên` : `Bạn đã hủy ${member.fullname} làm quản trị viên`;
          this.$store.dispatch("alerts/success", textAlert);
          this.resultSearch.splice(this.resultSearch.findIndex((item) => item.id === member.id), 1);
        }else{
          this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra!');
        }
      });
    },

    textSetRole(member_id){
      let text = '';
      if(this.isAdmin && this.tabActive == 1){
        text = 'Chỉ định làm quản trị viên';
      } else if(this.isAdmin && this.tabActive == 2 && member_id !== this.user.id){
        text = 'Hủy quyền làm quản trị viên';
      }
      return text;
    },

    browseRequestJoinGroup(userId, accept) {
      let status = accept ? 'MEMBER' : 'PENDING';
      let user;
      let index;
      for (let i = 0; i < this.resultSearch.length; i++) {
        if (this.resultSearch[i].id === userId) {
          index = i;
          user = this.resultSearch[i];
        }
      }
      if (!user) {
        this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng liên hệ bộ phận IT');
        return;
      }
      ChatService.browseRequestJoinChatGroup({group_id: this.params.group_id, user_id: userId, join_state: status}).then(res => {
        if (res.status) {
          let textAlert = (accept ? 'Đã chấp nhận' : 'Đã từ chối') + user.fullname;
          this.$store.dispatch("alerts/success", textAlert);
          this.resultSearch.splice(index, 1);
        }else{
          this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra!');
        }
      });
    }
  }
}
</script>

<style lang="scss">
.container-member-group{
  .list-members{
    height: calc(100vh - 200px);
    overflow-y: scroll;
  }
  .list-members::-webkit-scrollbar {
    width: 7px;
  }
  .list-members::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.4784313725);
    border-radius: 5px;
    margin: 7px;
    border-left: 2px white solid;
  }
  .list-members::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .contact-action-member{
    .remove-member{
      color: red;
    }
  }
}
  
</style>