<template>
  <div>
    <template v-if="!isCategory">
      <h3 class="title-sidebar">Thư viện kiến thức</h3>
      <div class="content-sidebar">
        <sidebar-item
            :link="{
                        name: 'Thư viện kiến thức',
                        path: '/category-home',
                        image: '/img/sidebar/right/workplace/home.svg',
                        is_group: false,
                      }"
        />
        <!--              <sidebar-item-->
        <!--                  :link="{-->
        <!--                        name: 'Hạng mục',-->
        <!--                        path: '/groups',-->
        <!--                        image: '/img/sidebar/right/workplace/group.svg',-->
        <!--                        is_group: false,-->
        <!--                      }"-->
        <!--              />-->
        <template>
          <h3 class="subtitle-sidebar text-500-medium">Hạng mục</h3>
          <div v-if="[1,2,119,141,845,921,920,869].includes(user.id)">
            <li class=""><a class="nav-link is_group"
                            @click="$modal.show('create-category-modal', {categoryParent:null})"><i
                class="md-icon md-icon-font md-theme-default">
              <div class="md-image md-theme-default" style="height: auto; width: auto; min-width: auto;"><img
                  src="/img/sidebar/right/workplace/add.svg"></div>
            </i>
              <p class="text-400-semibold secondary_g400"> Tạo hạng mục </p></a></li>
          </div>
          <sidebar-item v-for="category in categories" :key="category.id"
                        :link="{
                        name: category.title,
                        path: '/category/' + category.id,
                        icon: category.icon ? category.icon : 'star',
                        is_group: false,
                        is_category: true,
                        color: category.color,
                        category: category
                      }"
          />
        </template>
      </div>
    </template>
    <template v-else>
      <h3 class="title-sidebar text-nowrap heading-600" @click="isCategory=false">
        <md-icon class="arrow_back cursor-pointer">arrow_back</md-icon>
        Chi tiết hạng mục
      </h3>
      <div class="content-sidebar">
        <sidebar-item
            :link="{
                        name: chosenCategory.title,
                        path: '/category/' + chosenCategory.id,
                        icon: chosenCategory.icon ? chosenCategory.icon : 'star',
                        is_category: true,
                        color: chosenCategory.color,
                        category: chosenCategory
                      }"
        />
        <h3 class="subtitle-sidebar text-500-medium">Hạng mục con</h3>
        <sidebar-item v-for="sudCategory in chosenCategory.subcategories"
                      :key="sudCategory.id"
                      :link="{
                        name: sudCategory.title,
                        path: '/category/'+sudCategory.id,
                        icon: sudCategory.icon ? sudCategory.icon : 'star',
                        is_category: true,
                        is_sub_category: true,
                        color: sudCategory.color,
                        category: sudCategory
                      }"
        />
        <div v-if="chosenCategory.id && [1,2,119,141,845,921,920,869].includes(user.id)">
          <li><a class="nav-link is_group"
                 @click="$modal.show('create-category-modal', {categoryParent:chosenCategory})"><i
              class="md-icon md-icon-font md-theme-default">
            <div class="md-image md-theme-default" style="height: auto; width: auto; min-width: auto;"><img
                src="/img/sidebar/right/workplace/add.svg"></div>
          </i>
            <p class="text-400-semibold secondary_g400">Tạo hạng mục con</p></a></li>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import moment from "moment";
import CategoryService from "../../../../store/services/CategoryService";

export default {
  name: "category-sidebar",
  watch: {
    '$store.state.category.category': function (newVal, oldVal) {
      if (this.$route.meta.tab === 'category' && newVal.parent_id === 0) {
        this.chosenCategory = newVal;
        this.isCategory = true
      }
      this.getChosenCategory()
    },
    '$store.state.category.categories': function (newVal, oldVal) {
      this.categories = newVal
      this.getChosenCategory()
    },
  },
  created() {
    this.getCategories()
    if (this.$route.meta.tab === 'category') {
      this.isCategory = true
    };
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      categories: [],
      isCategory: false,
      chosenCategory: {},
    }
  },
  methods: {
    getPath(id) {
      return '/group/' + id;
    },

    fromNow(date) {
      return moment(date).locale("vi").fromNow();
    },

    getCategories() {
      CategoryService.getCategories().then(res => {
        if (res && res.status) {
          this.categories = res.data.categories;
          this.getChosenCategory();
        }
      });
    },

    getChosenCategory() {
      if (this.$route.meta.tab === 'category') {
        let categoryId = this.$route.params.category_id

        for (var i = 0; i < this.categories.length; i++) {
          if (this.categories[i].id && this.categories[i].id == categoryId) {
            this.chosenCategory = this.categories[i];
            this.$store.commit("updateCategory", this.categories[i]);
            break;
          }

          if (this.categories[i]['subcategories'] && this.categories[i]['subcategories'].length > 0) {
            var item1 = this.categories[i]['subcategories'];

            for (var x = 0; x < item1.length; x++) {
              if (item1[x].id && item1[x].id == categoryId) {
                this.chosenCategory = this.categories[i];
                this.$store.commit("updateCategory", item1[x]);
                break;
              }

              if (this.categories[i]['subcategories'][x]['subcategories']
                  && this.categories[i]['subcategories'][x]['subcategories'].length > 0) {

                var item2 = this.categories[i]['subcategories'][x]['subcategories'];
                if (item2.length > 0) {
                  for (var z = 0; z < item2.length; z++) {
                    if (item2[z].id && item2[z].id == categoryId) {
                      this.chosenCategory = this.categories[i];
                      this.$store.commit("updateCategory", item2[z]);
                      break;
                    }

                    var item3 = this.categories[i]['subcategories'][x]['subcategories'][z]['subcategories'];
                    if (item3 && item3.length > 0) {
                      for (var y = 0; y < item3.length; y++) {
                        if (item3[y].id && item3[y].id == categoryId) {
                          this.chosenCategory = this.categories[i];
                          this.$store.commit("updateCategory", item3[y]);
                          break;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        // console.log(this.chosenCategory);
      }
    },
  },
};
</script>

