<template>
  <div class="md-layout new-feed" style="padding: 15px;" id="new-feed-content">
    <vue-element-loading :active="isLoad" is-full-screen/>
    <div class="md-layout-item" style="padding: 0;width: 200px;z-index: 0;">
      <create-post></create-post>
      <div class="loading-holder-post post-layout" v-if="loadPageHolder">
        <div class="container-metadata">
          <div class="loader-shimmer loading-image-holder">
          </div>
          <div class="metadata-content">
              <p class="loading-holder text-1-line loader-shimmer"></p>
              <p class="loading-holder text-2-line loader-shimmer"></p>
              <p class="loading-holder text-1-line loader-shimmer"></p>
          </div>
        </div>
      </div>
      <template v-if="postId != undefined">
        <post-layout :post=postDetail :isPost="true"></post-layout>
      </template>
      <template v-else>
        <vue-loadmore
            :on-loadmore="onLoad"
            :finished="finished"
            :loading-text="loadingText"
            :load-offset="400"
            :finished-text="finishedText">
          <div v-for="post of posts" :key="post.id" class="new-feed-item" :data-id="post.post.id"
               :data-read="post.post.is_read">
            <post-layout :post=post></post-layout>
          </div>
        </vue-loadmore>
      </template>
    </div>
    <div class="company-info-section">
      <CompanyInfo></CompanyInfo>
    </div>
    <update-post-modal @update-post="updatePostData"></update-post-modal>
    <update-comment-modal @update-comment="updateCommentData"></update-comment-modal>
  </div>
</template>
<script>

import PostLayout from "../../Layout/Newfeed/PostLayout";
import PostService from "../../../store/services/PostService";
import CreatePost from "../../Components/Post/UserCreatePost/createPost";
import CompanyInfo from "../../Components/RightBar/CompanyInfo";
import UpdatePostModal from "../../Components/Modal/UpdatePostModal";
import UpdateCommentModal from "../../Components/Modal/UpdateCommentModal";
import $ from "jquery";
import NotificationService from "../../../store/services/NotificationService";

export default {
  components: {
    UpdateCommentModal,
    CreatePost,
    PostLayout,
    CompanyInfo,
    UpdatePostModal,
  },
  created() {
    if (this.postId !== undefined) {
      this.getPost();
    } else {
      this.getPosts();
    }
    localStorage.setItem('link', this.$route.fullPath)
  },
  data() {
    return {
      posts: [],
      isLoad: true,
      params: {
        page: 1,
        per_page: 10,
      },
      finished: false,
      last_page: 1,
      loadingText: 'Đang tải',
      finishedText: 'Không còn bài viết mới nào.',
      postDetail: {},
      postId: this.$route.params.post_id,
      postIdsIsRead: [],
      loadPageHolder: true
    };
  },
  mounted() {
    window.addEventListener("scroll", (e) => {
      let pageYOffset = window.pageYOffset;
      let totalItemHeight = 300
      for (const item of $(".new-feed-item")) {
        totalItemHeight += item.offsetHeight
        let id = item.dataset.id;
        let isRead = item.dataset.read
        if (totalItemHeight <= pageYOffset && (isRead == 'false' || !isRead) && this.postIdsIsRead.indexOf(id) === -1) {
          this.userReadPost(id);
          this.postIdsIsRead.push(id);
        }
      };
    });

    if (this.$store.state.post.contentScrollTop !== 0) {
      document.getElementById("new-feed-content").scrollTop = this.$store.state.post.contentScrollTop;
    }

    setTimeout(() => {
      const fcmToken = localStorage.getItem("cmf_token");
      const sendCmfToken = localStorage.getItem("send_cmf_token");
      if (fcmToken != null && sendCmfToken != 'true') {
        NotificationService.postFcmToken({token: fcmToken}).then(res => {
          localStorage.setItem("send_cmf_token", true);
        });
      }
    }, 1000);
  },
  watch: {
    '$store.state.post.posts': function (newVal, oldVal) {
      this.posts = newVal
    },
    '$route.params.post_id'(to, from) {
      this.postId = this.$route.params.post_id;
      this.getPost();
    },
  },
  methods: {
    async getPosts(loadMore = false) {
      this.isLoad = false;
      const routerName = localStorage.getItem('router_name');
      if (routerName !== 'PostDetail') {
       await PostService.getPosts(this.params).then(res => {
          if (res) {
            this.isLoad = false;
            this.last_page = res.meta.last_page
            if (loadMore) {
              this.posts = this.posts.concat(res.data)
            } else {
              this.posts = res.data;
            }
            this.$store.commit("updatePost", this.posts);
          }
        });
      } else {
        this.posts = this.$store.state.post.posts;
        this.isLoad = false;
      }
      this.loadPageHolder = false;
      localStorage.removeItem('router_name');
    },
    onRefresh(done) {
      this.posts = [];
      this.params.page = 1;
      this.finished = false;
      this.getPosts();
      done();
    },
    onLoad(done) {
      if (this.params.page < this.last_page && !this.isLoad) {
        this.params.page++
        this.getPosts(true);
      } else {
        this.finished = true;
      }
      done();
    },
    updatePostData(e) {
      for (let i = 0; i < this.posts.length; i++) {
        if (this.posts[i].post.id === e.id) {
          this.posts[i].post = e;
        }
      }
    },
    updateCommentData(e) {
      let postParentId = e.postParentId;
      let commentData = e.commentData;
      for (let i = 0; i < this.posts.length; i++) {
        if (this.posts[i].post.id === postParentId) {
          for (let j = 0; j < this.posts[i].comments.length; j++) {
            if (this.posts[i].comments[j].id === commentData.id) {
              this.posts[i].comments[j] = commentData;
            }
          }
        }
      }
    },
    async getPost() {
      await PostService.getPost({post_id: this.postId}).then(res => {
        if (res) {
          this.postDetail = res.data
          this.isLoad = false;
        }
      });
    },
    userReadPost(postId) {
      PostService.userReadPost(postId).then(res => {
      });
    },
  }
};
</script>
<style lang="css">
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #232d34; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.company-info-section {
  width: 300px;
  float: right;
  margin: 0 0 0 15px;
}

/*.vuejs-loadmore-finished-text:before {*/
/*  content: 'Đã hết bài post' !important;*/
/*}*/

/*.vuejs-loadmore-loading:before {*/
/*  content: 'Đang Tải' !important;*/
/*}*/

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-holder-post{
    .container-metadata{
      text-decoration: none;
      font-weight: normal;
      font-size: 15px;
      border: 1px solid rgba(0, 0, 0, 0.1803921569);
      position: relative;
      overflow: hidden;
      background-color: #fff;
      border-radius: 12px;
      margin-top: 2px;
      padding: 5px;
      .loading-image-holder{
        min-height: 100px;
        margin: 5px;
      }
    }
    .loader-shimmer{
      animation-duration: 2.2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer-holder;
      animation-timing-function: linear;
      background: #ddd;
      background: linear-gradient(to right, #f7f6f6 8%, #efefef 18%, #F6F6F6 33%);
      background-size: 1200px 100%;
      color: transparent;
      height: 20px;
      margin: 5px;
      img{
        display: none;
      }
    }
}

@-webkit-keyframes shimmer-holder {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer-holder {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}
</style>
