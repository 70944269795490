<template>
  <div class="container-custom">
    <div class="head-custom">
      <div class="avatar-group-member">
        <!--        <span class="firstname-charset two-avatar-group" v-bind:key="avatar" v-for="avatar in avatars">{{ avatar }}</span>-->
        <span class="firstname-charset two-avatar-group cursor-pointer avatar-group" 
        v-popover:tooltip.top="'Click để thay đổi'" 
        @click="changeAvatar"
        v-if="this.$store.state.chat.chatGroupSelected.type === 'GROUP'"
        >
          <img :src="avatars" alt="aza">
          <md-icon class="icon-camera">photo_camera</md-icon>
        </span>

        <span class="firstname-charset two-avatar-group cursor-pointer avatar-group" 
          v-if="this.$store.state.chat.chatGroupSelected.type === 'USER'"
          @click="goToProfile"
        >
          <img :src="avatars" alt="aza">
        </span>

        <input type="file" ref="fileupload" accept="image/jpg, image/png, image/jpeg" hidden id="update-avatar-btn"
               @change="uploadFile">
      </div>
      <div class="name-group">
        <div class="name cursor-pointer" @click="handleGroupName()" v-if="!handleName">
          <span class="heading-500">{{ this.$store.state.chat.chatGroupSelected.name }}</span>
        </div>
        <div class="px-15px" v-if="handleName">
          <input type="text" class="change-group-name text-center" v-model="updateGroupName.name" />
          <div class="handle-button-group-name text-right">
            <span class="button-change-name cancel-changr-group-name cursor-pointer" @click="handleGroupName()">
              Hủy
            </span>
            <span class="button-change-name apply-changr-group-name cursor-pointer" @click="applyChangeGroupName()">
              Lưu
            </span>
          </div>
        </div>
      </div>
      <!--      <div class="description-group ">-->
      <!--        <button class="btn cursor-pointer mx-auto add-description">Thêm mô tả</button>-->
      <!--        <div class="desc display-none">-->
      <!--          <textarea name="" class="change-descript-group" id="" cols="30" rows="10"></textarea>-->
      <!--          <div class="cancel-or-submit">-->
      <!--           <div class="text-length">-->
      <!--             <span>0 / 120</span>-->
      <!--           </div>-->
      <!--            <div class="">-->
      <!--              <button class="button-cancel btn btn-social cancel">Hủy</button>-->
      <!--            </div>-->
      <!--            <div class="">-->
      <!--              <button class="button-submit btn btn-success submit">Lưu</button>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="info-thread__setting">
      <div class="thread-settings">
        <!--        <div class="setting-item enable-click">-->
        <!--          <div class="setting-item__icon">-->
        <!--            <svg width="20px" height="20px" fill="var(&#45;&#45;spectrum-global-color-contentPrimary)"-->
        <!--                 xmlns="http://www.w3.org/2000/svg" class="aza-Icon" role="img" viewBox="0 0 24 24">-->
        <!--              <path fill-rule="evenodd" clip-rule="evenodd"-->
        <!--                    d="M12.143 16.71a1.5 1.5 0 0 1 .766-.21H19a2.5 2.5 0 0 0 2.5-2.5V5A2.5 2.5 0 0 0 19 2.5H5A2.5 2.5 0 0 0 2.5 5v9A2.5 2.5 0 0 0 5 16.5h.545a1.5 1.5 0 0 1 1.469 1.807l-.347 1.66 5.476-3.256Zm.766 1.29H19a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v9a4.002 4.002 0 0 0 4 4h.545l-.58 2.773c-.178.852.742 1.51 1.49 1.064L12.91 18ZM7 7.75A.75.75 0 0 1 7.75 7h8.5a.75.75 0 0 1 0 1.5h-8.5A.75.75 0 0 1 7 7.75ZM7.75 11a.75.75 0 0 0 0 1.5h5.5a.75.75 0 0 0 0-1.5h-5.5Z"-->
        <!--                    fill="#26282C"></path>-->
        <!--            </svg>-->
        <!--          </div>-->
        <!--          <div class="setting-item__content">-->
        <!--            <div class="setting-item__content__title">Quyền gửi tin nhắn</div>-->
        <!--            <span class="setting-item__content__subtitle">Tất cả mọi người</span></div>-->
        <!--        </div>-->
        <!--        <hr class="spectrum-Rule_612bb8 spectrum-Rule&#45;&#45;small_612bb8 spectrum-Rule&#45;&#45;horizontal_612bb8 separator">-->
        <div class="setting-item" v-if="isGroup">
          <div class="setting-item__icon">
            <svg width="20px" height="20px" fill="var(--spectrum-global-color-contentPrimary)"
                 xmlns="http://www.w3.org/2000/svg" class="aza-Icon" role="img" viewBox="0 0 24 24">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.713 2.307A.75.75 0 0 0 13.25 3v4.263c-2.673.094-5.535.714-7.808 2.468C2.918 11.68 1.25 14.923 1.25 20a.75.75 0 0 0 1.397.38c1.34-2.283 2.377-3.623 3.907-4.444 1.444-.774 3.42-1.135 6.696-1.18V19a.75.75 0 0 0 1.28.53l8-8a.75.75 0 0 0 0-1.06l-8-8a.75.75 0 0 0-.817-.163ZM20.939 11l-6.189 6.19V14a.75.75 0 0 0-.75-.75c-3.769 0-6.255.346-8.154 1.364-1.156.62-2.055 1.468-2.885 2.55.488-3.042 1.777-4.995 3.397-6.245C8.471 9.289 11.278 8.75 14 8.75a.75.75 0 0 0 .75-.75V4.81L20.94 11Z"
                    fill="#26282C"></path>
            </svg>
          </div>
          <div class="setting-item__content">
            <div class="setting-item__content__title">
              <div class="flex_e15493" style="align-items: center;">Chia sẻ link của nhóm
                <button class="border-none display-none"
                        type="button" style="border-radius: 50%;">
                  <svg width="16px" height="16px" fill="var(--spectrum-global-color-contentSecondary)"
                       xmlns="http://www.w3.org/2000/svg" class="aza-Icon" role="img" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12 21.5a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11Z"
                          fill="#26282C"></path>
                    <path d="M11.25 9.75a.75.75 0 0 1 1.5 0v6.5a.75.75 0 0 1-1.5 0v-6.5Z" fill="#26282C"></path>
                    <path
                        d="M9.75 9.75A.75.75 0 0 1 10.5 9H12a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75ZM9.25 16.25a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75ZM11 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                        fill="#26282C"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="setting-item__action">
            <vs-switch @change="showLinkGroup()" color="success" v-model="shareLinkGroup.active"/>
          </div>
        </div>
        <!-- <hr class="spectrum-Rule_612bb8 spectrum-Rule--small_612bb8 spectrum-Rule--horizontal_612bb8 separator"> -->
        <div class="collapse" v-if="shareLinkGroup.active" style="">
          <div class="setting-item">
            <div class="setting-item__icon">
              <svg width="20px" height="20px" fill="var(--spectrum-global-color-contentPrimary)"
                   xmlns="http://www.w3.org/2000/svg" class="aza-Icon" role="img" viewBox="0 0 24 24">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M20.308 3.692a5.75 5.75 0 0 0-8.131 0l-2.122 2.121a.75.75 0 0 0 1.061 1.06l2.121-2.12a4.25 4.25 0 1 1 6.01 6.01l-2.12 2.121a.75.75 0 0 0 1.06 1.06l2.121-2.12a5.75 5.75 0 0 0 0-8.132ZM3.691 20.308a5.75 5.75 0 0 1 0-8.132l2.122-2.121a.75.75 0 0 1 1.06 1.06l-2.12 2.122a4.25 4.25 0 0 0 6.01 6.01l2.12-2.12a.75.75 0 0 1 1.062 1.06l-2.122 2.121a5.75 5.75 0 0 1-8.132 0Zm3.536-4.596a.75.75 0 0 0 1.06 1.06l8.486-8.485a.75.75 0 0 0-1.06-1.06l-8.486 8.485Z"
                      fill="#26282C"></path>
              </svg>
            </div>
            <div class="setting-item__content">
              <div class="setting-item__content__title">Sao chép đường dẫn</div>
              <span class="setting-item__content__subtitle">{{ shareLinkGroup.baseUri }}</span>
            </div>
            <div class="setting-item__action cursor-pointer" >
              <!--              <span class="material-icons">content_copy</span>-->
              <popover name="coppy" style="width: auto; padding: 4px 8px">Đã copy</popover>
              <img v-popover.top="{name: 'coppy'}" v-clipboard:copy="''+shareLinkGroup.baseUri" class="ml-15px" src="/img/coppy-link.svg" alt="">
              <span id="close-popover"></span>
            </div>
          </div>
          <hr class="spectrum-Rule_612bb8 spectrum-Rule--small_612bb8 spectrum-Rule--horizontal_612bb8 separator">
        </div>

        <div class="setting-item">
          <div class="setting-item__icon">
            <svg width="20px" height="20px" fill="var(&#45;&#45;spectrum-global-color-contentPrimary)"
              xmlns="http://www.w3.org/2000/svg" class="aza-Icon" role="img" viewBox="0 0 24 24">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.302 22.434A2.838 2.838 0 0 1 9.28 20.96l1.44-.422a1.338 1.338 0 0 0 2.565 0l1.44.422a2.838 2.838 0 0 1-4.423 1.473ZM12 1.25a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75Z"
                fill="#26282C"></path>
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.483 5.232a7.53 7.53 0 0 0-2.285 5.387c0 3.78-.931 6.011-1.7 7.209-.261.408.065 1.172.55 1.172h17.904c.485 0 .811-.764.55-1.172-.769-1.198-1.7-3.429-1.7-7.209a7.53 7.53 0 0 0-2.285-5.387A7.898 7.898 0 0 0 12 3c-2.07 0-4.054.803-5.517 2.232ZM12 4.5c-1.683 0-3.29.653-4.469 1.805a6.03 6.03 0 0 0-1.833 4.314c0 3.14-.604 5.361-1.313 6.881h15.23c-.71-1.52-1.313-3.74-1.313-6.881a6.03 6.03 0 0 0-1.833-4.314A6.398 6.398 0 0 0 12 4.5Z"
                fill="#26282C"></path>
            </svg>
          </div>
          <div class="setting-item__content">
            <div class="setting-item__content__title">Bật thông báo</div>
          </div>
          <div class="setting-item__action">
            <vs-switch color="success" @input="notificationChange" v-model="notificationIsActive" />
          </div>
        </div>

        <div class="setting-item select" v-if="isAdmin">
          <div class="setting-item__icon">
            <svg width="20px" height="20px" fill="var(--spectrum-global-color-contentPrimary)" xmlns="http://www.w3.org/2000/svg" class="gapo-Icon" role="img" viewBox="0 0 24 24"><path d="M14 14a2 2 0 0 1-1 1.732V18a1 1 0 1 1-2 0v-2.268A2 2 0 0 1 12 12a2 2 0 0 1 2 2Z" fill="#18202A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M22 12a4 4 0 0 0-4-4V7A6 6 0 0 0 6 7v1a4 4 0 0 0-4 4v7a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4v-7ZM12 2.5A4.5 4.5 0 0 1 16.5 7v1h-9V7A4.5 4.5 0 0 1 12 2.5ZM3.5 12A2.5 2.5 0 0 1 6 9.5h12a2.5 2.5 0 0 1 2.5 2.5v7a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 19v-7Z" fill="#18202A"></path></svg>
          </div>
          <div class="setting-item__content" style="align-items: flex-start">
            <div class="setting-item__content__title">Quyền thêm thành viên</div>
            <select class="select-box-role w_100 text-400-medium" v-model="join_policy" @change="updateRoleMember">
              <option class="text-400-medium" :selected="join_policy != 2" value="1">
                Chỉ quản trị viên
              </option>
              <option class="text-400-medium" :selected="join_policy == 2" value="2">
                Tất cả mọi người
              </option>
            </select>
          </div>
        </div>

        <div id="setting-item__leave-group" class="setting-item enable-click" v-if="isGroup">
          <div class="setting-item__icon">
            <svg width="20px" height="20px" fill="var(--spectrum-global-color-negativePrimary)"
                 xmlns="http://www.w3.org/2000/svg" class="aza-Icon leave-group" role="img" viewBox="0 0 24 24">
              <path
                  d="M7 3.5h4A3.5 3.5 0 0 1 14.5 7v1A.75.75 0 0 0 16 8V7a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h4a5 5 0 0 0 5-5v-.9a.75.75 0 0 0-1.5 0v.9a3.5 3.5 0 0 1-3.5 3.5H7A3.5 3.5 0 0 1 3.5 17V7A3.5 3.5 0 0 1 7 3.5Z"
                  fill="#26282C"></path>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.47 8.47a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H9a.75.75 0 0 1 0-1.5h10.19l-1.72-1.72a.75.75 0 0 1 0-1.06Z"
                    fill="#26282C"></path>
            </svg>
          </div>
          <div class="setting-item__content" v-on:click="leaveGroupChat">
            <div class="setting-item__content__title title-negative">Rời khỏi nhóm</div>
          </div>
        </div>

        <!--        <hr class="spectrum-Rule_612bb8 spectrum-Rule&#45;&#45;small_612bb8 spectrum-Rule&#45;&#45;horizontal_612bb8 separator">-->
        <!--        <div class="setting-item enable-click">-->
        <!--          <div class="setting-item__icon">-->
        <!--            <svg width="20px" height="20px" fill="var(&#45;&#45;spectrum-global-color-negativePrimary)"-->
        <!--                 xmlns="http://www.w3.org/2000/svg" class="aza-Icon leave-group" role="img" viewBox="0 0 24 24">-->
        <!--              <path-->
        <!--                  d="M3.268 3.268c.39-.391 1.066-.35 1.509.093l15.862 15.862c.024.024.047.05.069.075l.48.481a2.89 2.89 0 0 1-.215.04c.075.332-.003.675-.24.913-.392.391-1.067.35-1.51-.093l-1.46-1.46c-.066.157-.134.313-.205.468a3.78 3.78 0 0 1-2.89 2.157 18.152 18.152 0 0 1-5.337 0 3.78 3.78 0 0 1-2.89-2.157c-.187-.41-.358-.83-.511-1.259-.524-1.464-.086-3.135 1.224-3.973a8.95 8.95 0 0 1 4.44-1.406L6.86 8.277c.093.274.139.582.139.91C7 10.75 6.333 12 5 12s-2-1.25-2-2.813C3 7.938 3.667 7 5 7c.25 0 .476.033.68.095L3.36 4.777c-.442-.443-.484-1.118-.093-1.51ZM20.142 15.904a5.187 5.187 0 0 0-.825-1.852 7.946 7.946 0 0 1 2.81.866c1.072.564 1.485 1.858 1.113 3.01a2.75 2.75 0 0 1-.355.719l-2.743-2.743ZM9.54 5.301l4.944 4.945c.344-.758.516-1.71.516-2.746 0-2-1-3.5-3-3.5-1.152 0-1.972.497-2.46 1.301ZM19 12c1.333 0 2-1.25 2-2.813C21 7.938 20.333 7 19 7s-2 .938-2 2.188C17 10.75 17.667 12 19 12Z"-->
        <!--                  fill="#26282C"></path>-->
        <!--              <path-->
        <!--                  d="M4.698 14.03c-1.052 1.461-1.239 3.389-.651 5.032.11.307.228.612.354.911-.417-.022-.83-.059-1.238-.11a2.87 2.87 0 0 1-2.4-1.964c-.393-1.227.078-2.605 1.245-3.15a7.95 7.95 0 0 1 2.69-.72Z"-->
        <!--                  fill="#26282C"></path>-->
        <!--            </svg>-->
        <!--          </div>-->
        <!--          <div class="setting-item__content">-->
        <!--            <div class="setting-item__content__title title-negative">Giải tán nhóm</div>-->
        <!--          </div>-->
        <!--        </div>-->

      </div>
    </div>
  </div>
</template>

<script>
import ChatService from "../../../../../store/services/ChatService";
import PostService from "../../../../../store/services/PostService";
import { NOTIFICATION_CHAT } from "../../../../../../src/const/const";
import GroupService from "../../../../../store/services/GroupService";

export default {
  name: "Custom",
  props: {},
  mounted() {
    this.updateGroupName.group_id = this.$route.params.messenger_id;
    this.updateGroupName.name = this.$store.state.chat.chatGroupSelected.name;
    this.avatars = this.$store.state.chat.chatGroupSelected.avatar ?? '/img/profile/avatar.png';
    this.notificationIsActive = this.$store.state.chat.chatGroupSelected.noty == NOTIFICATION_CHAT.ON ? true : false;
    this.join_policy = this.$store.state.chat.chatGroupSelected.settings.join_policy;
  },
  data(){
    return {
      avatars : '', //['S', 'T'],
      shareLinkGroup: {
        active : false,
        baseUri : ''
      },
      notificationIsActive: false,
      group_name_input: this.$store.state.chat.chatGroupSelected.name,
      handleName : false,
      updateGroupName : {
        group_id : '',
        name : ''
      },
      isGroup : this.$store.state.chat.chatGroupSelected.type === 'GROUP',
      join_policy: 0,
      isAdmin: this.$store.state.chat.chatGroupSelected.is_admin === 1,
    }
  },
  methods: {
    leaveGroupChat(){
      this.$modal.show('dialog', {
        title: '',
        text: '<div class="leave-group-modal">Bạn có chắc muốn rời nhóm!</div>',
        buttons: [
          {
            title: 'Có',
            handler: () => {
              ChatService.leaveGroupChat({group_id : this.$route.params.messenger_id}).then(res => {
                if (res.status) {
                  // this.$store.dispatch("alerts/success", 'Bạn đã rời khỏi nhóm');
                  this.$router.push('/');
                }else{
                  this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra!');
                }
              });
              // this.$modal.hide('dialog')
            }
          },
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    handleGroupName(){
      if(this.$store.state.chat.chatGroupSelected.type === 'GROUP'){
        this.handleName = !this.handleName;
        this.updateGroupName.name = this.$store.state.chat.chatGroupSelected.name;
      }else{
        this.goToProfile();
      }
    },
    

    applyChangeGroupName(){
      if(this.updateGroupName.name !== ''){
        ChatService.updateNameGroupChat(this.updateGroupName).then(res => {
          if (res.status) {

            let chatGroups = this.$store.state.chat.chatGroups;
            chatGroups.map((item) => {
                  if (item.id == this.$route.params.messenger_id) {
                    item.name = this.updateGroupName.name
                  }
                }
            )

            this.$store.commit('chat/updateChatGroups', chatGroups);

            this.$emit('update-name-group-chat', this.updateGroupName.name)
            this.handleGroupName();
            this.$store.dispatch("alerts/success", 'Cập nhật tên nhóm thành công');
          }else{
            this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra!');
          }
        });
      }
    },
    showLinkGroup(){
      this.shareLinkGroup.baseUri = this.$router.app.$el.baseURI ?? '';
    },
    onMouseleave(e) {
      document.getElementById("close-popover").click();
    },
    changeAvatar(){
      document.getElementById("update-avatar-btn").click();
    },
    async uploadFile(e) {
      const formFile = new FormData();

      formFile.append("files[]", e.target.files[0]);
      //
      await PostService.uploadFile(formFile).then(res => {
        if (res.status) {
          this.changeAvatarGroup(res['data'][0]);
        }else{
          this.$store.dispatch("alerts/error", 'Thay ảnh thất bại.');
        }
      });
    },
    async changeAvatarGroup(file){
      let data = {
        group_id: this.$route.params.messenger_id,
        file_id: file.file_id,
        avatar: file.url
      }

      ChatService.updateAvatarGroup(data).then(res => {
        if (res && res.status && res.data) {
          this.avatars = res.data.avatar ?? '/img/profile/avatar.png';
          this.$store.commit('chat/updateChatGroupSelected', res.data);
          this.$store.dispatch("alerts/success", 'Thay đổi ảnh nhóm thành công');
          this.$store.dispatch("chat/getChatGroups");
        } else {
          this.$store.dispatch("alerts/error", 'Thay ảnh thất bại.');
        }
      });
      const input = this.$refs.fileupload;
      input.type = 'text';
      input.type = 'file';
    },

    goToProfile(){
      window.open('/profile/' + this.$store.state.chat.chatGroupSelected.user_id, '_blank');
    },

    async notificationChange(){
      let params = {
        group_id : this.$store.state.chat.chatGroupSelected.id,
        stage : this.notificationIsActive ? 'ON' : 'OFF'
      };
      
      await ChatService.notificationConvensation(params).then(res => {
        if(res.status){
          // let item = this.$store.state.chat.chatGroupSelected;
          // item.noty = res.data.stage;
          // this.$store.commit("chat/getChatGroups");
          // this.$store.commit("chat/updateChatGroupSelected", item);
          let chatGroups = this.$store.state.chat.chatGroups;
            chatGroups.map((item) => {
                  if (item.id == this.$route.params.messenger_id) {
                    item.noty = res.data.stage
                  }
                }
            )

            this.$store.commit('chat/updateChatGroups', chatGroups);
        }else{
          this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra! Vui lòng thử lại');

          //reset to old state
          this.notificationIsActive = this.notificationIsActive;
        }
      })
    },

    updateRoleMember(){

      if(this.join_policy == 0) return;

      GroupService.updateRoleMemberGroup(this.$route.params.messenger_id, {join_policy: this.join_policy}).then(res => {
        if (res.status) {
          this.$store.dispatch("alerts/success", 'Đã thay đổi quyền thêm thành viên');
        }else{
          this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra!');
        }
      });
    }
  }
}
</script>

<style lang="scss">
.leave-group-modal{
  text-align: center;
  padding: 10px;
  font-size: 15px;
}
.setting-item{
  .select-box-role{
    border: 1px solid #dbdbdb;
    padding: 5px 0;
    border-radius: 4px;
    &:hover{
      cursor: pointer;
    }
  }
}
</style>