<template>
  <div>
    <template>
      <h3 class="title-sidebar">Quản lý nội bộ</h3>
      <div class="content-sidebar">
        <sidebar-item
            :link="{
                        name: 'Quản lý nhóm',
                        path: '/admin-settings/groups',
                        image: '/img/sidebar/right/workplace/group.svg',
                        is_group: false,
                      }"
        />
        <sidebar-item
            :link="{
                        name: 'Quản lý Chat bot',
                        path: '/admin-settings/chat-bot',
                        image: '/img/sidebar/right/workplace/group.svg',
                        is_group: false,
                      }"
        />
        <sidebar-item
            :link="{
                        name: 'Quản lý Stickers',
                        path: '/admin-settings/stickers',
                        image: '/img/sidebar/right/workplace/group.svg',
                        is_group: false,
                      }"
        />
      </div>
    </template>
  </div>
</template>
<script>

export default {
  name: "category-sidebar",
  created() {
  },
  data() {
    return {

    }
  },
  methods: {

  },
};
</script>

