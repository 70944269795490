<template>
  <div class="department-item">
    <div class="tree-view-item-container" v-if="!isModal">
      <li class="tree-view-item-main">
        <div class="tv-item-name mb-5px" :class="{active: $route.params.department_id == model.id}">
        <span class="tv-item-name-left">
          <span class="tv-arrow cursor-pointer" style="margin-right: 0px !important;" @click="toggle">
            <md-icon v-if="isParent">{{ open ? 'arrow_drop_down' : 'arrow_right' }}</md-icon>
          </span>
          <md-icon class="tv-folder-icon cursor-pointer">folder</md-icon>
          <span class="cursor-pointer text-400-medium text-1-line neutral_1"
                @click="viewListMemberForDepartment(model.id)" style="width: calc(100% - 60px)">{{ model.name }}</span>
        </span>
          <md-icon class="company-structure-more-icon cursor-pointer" style="margin-right: 0px !important;">more_horiz
          </md-icon>
          <div class="company-structure-more-dropdown dropdown-popup">
            <div class="dropdown-popup-item">
              <span>Chỉnh sửa</span>
            </div>
            <div class="dropdown-popup-item" @click="showConfirmDeleteDepartment(model)">
              <span>Xóa</span>
            </div>
          </div>
        </div>
        <div v-show="open" v-if="isParent" class="pl-15px">
          <div v-for="item in model.children" :key="item.id">
            <tree-view-item class="item" :model="item"/>
          </div>
        </div>
      </li>
    </div>
    <div v-if="isModal">
      <div class="item-in-modal">
        <span class="cursor-pointer icon-dropdown mr-5px" @click="toggle">
          <md-icon class="arrow" v-if="isParent">{{ open ? 'arrow_drop_down' : 'arrow_right' }}</md-icon>
        </span>
        <md-list-item class="cursor-pointer">
          <md-icon class="tv-folder-icon mr-5px">folder</md-icon>
          <span class="md-list-item-text">{{ model.name }}</span>
          <md-checkbox v-model="items" class="text-right" :value="model.id" @change="onchangeItem(model)"/>
        </md-list-item>
      </div>
      <div v-show="open" v-if="isParent" class="pl-30px">
        <div v-for="item in model.children" :key="item.id">
          <tree-view-item class="item" :model="item" :isModal="isModal" :items="items"
                          @handleUpdateItem="handleUpdateItem"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SettingService from "../../../store/services/SettingService";

export default {
  name: "tree-view-item",
  data() {
    return {
      open: false
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isParent: function () {
      return this.model.children &&
          this.model.children.length
    }
  },
  watch: {
    items(newValue, oldValue) {
      this.$emit('handleUpdateItem', newValue);
    },
  },
  mounted() {
    this.checkOpen();
  },
  methods: {
    toggle: function () {
      if (this.isParent) {
        this.open = !this.open
      }
    },
    showConfirmDeleteDepartment(department) {
      this.$modal.show('dialog', {
        title: 'Xóa chức vụ?',
        text: 'Bạn có chắc chắn muốn xóa phòng ban [' + department.name + '] không?',
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              this.deleteDepartment(department.id);
            }
          }
        ]
      })
    },
    async deleteDepartment(departmentId) {
      await SettingService.deleteDepartment(departmentId).then(res => {
        if (res && res.data) {
          this.$store.dispatch("alerts/success", 'Bạn đã xóa phòng ban thành công.');
          this.$store.dispatch("getDepartmentTree");
        } else {
          this.$store.dispatch("alerts/error", 'Xóa phòng ban thất bại');
        }
        this.$modal.hide('dialog');
      });
    },
    viewListMemberForDepartment(departmentId) {
      this.$router.push('/department/' + departmentId + '/members')
    },
    checkOpen() {
      if (this.model.children) {
        for (let i = 0; i < this.model.children.length; i++) {
          if (this.model.children[i].id == this.$route.params.department_id) {
            this.open = true;
            break;
          }
        }
      }
    },
    handleUpdateItem(items) {
      this.items = items;
    },
    onchangeItem(model) {
      // const childrenIds = model.children.map((obj) => obj.id);
      // this.items = this.items.concat(childrenIds);
      // console.log(this.items);
    }
  }
};
</script>
<style lang="scss">
.department-item {
  .tree-view-item-container {
    .tv-arrow {
      width: 30px;
    }

    .tv-item-name {
      display: flex;
      align-items: center;
      position: relative;

      .tv-item-name-left {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .tv-folder-icon {
        color: #909090 !important;
        width: 12px;
        margin-right: 5px !important;
      }
    }

    .tree-view-item-main {
      overflow: inherit !important;
    }

    li {
      margin-bottom: 5px !important;
    }

    .md-icon {
      margin-right: 0px !important;
    }

    .icon-dropdown {
      width: 30px;
      position: absolute;
      top: 33%;
      z-index: 3;
    }
  }

  .item-in-modal {
    padding: 6px 10px;
    display: flex;

    .icon-dropdown {
      width: 20px;
      position: relative;
      margin-right: 0px !important;
    }

    .md-list-item {
      width: 100%;

      .md-ripple {
        padding: 0 !important;
        min-height: auto;
      }
    }
  }
}

</style>
