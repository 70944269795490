// import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";
import ContentLayout from "@/pages/Layout/ContentLayout.vue";

// Components pages
//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

//Auth
import Login from "@/pages/Dashboard/Pages/Login.vue";

// Workplace Pages
import NewFeeds from "@/pages/Workplace/Pages/NewFeeds.vue";
import Group from "../pages/Workplace/Pages/Group/Group";
import PostDetail from "../pages/Workplace/Pages/PostDetail";
import ListGroup from "../pages/Workplace/Pages/ListGroup/GroupOverview";
import Profile from "../pages/Workplace/Pages/Profile";

//Category
import Category from "@/pages/Workplace/Pages/Category/Category.vue";
import HomeCategory from "@/pages/Workplace/Pages/Category/HomeCategory";

//Other
import News from "@/pages/Workplace/Pages/News.vue";
import GroupSetting from "../pages/Workplace/Pages/Group/GroupSetting";
import ErrorPage from "../pages/Workplace/Pages/ErrorPage";
import CompanyStructure from "../pages/Components/SidebarPlugin/CompanyStructureSideBar";
import MemberList from "../pages/Workplace/Pages/MemberList";
import Messenger from "../pages/Workplace/Pages/Messager/Messager";
import UpdateUserPage from "../pages/Workplace/Pages/UpdateUserPage";
import NoLoginLayout from "../pages/Layout/NoLoginLayout";
import ForgotPassword from "../pages/Workplace/Pages/NoLogin/ForgotPassword";
import ChangePassword from "../pages/Workplace/Pages/NoLogin/ChangePassword";
import ViewFile from "../pages/Workplace/Pages/NoLogin/ViewFile";
import GroupList from "../pages/Workplace/Pages/AdminManagement/GroupList";
import ChatBot from "../pages/Workplace/Pages/AdminManagement/ChatBot";
import Stickers from "../pages/Workplace/Pages/AdminManagement/Stickers";


let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {middleware: guest}
    },
  ]
};

let workplacePages = {
    path: "/",
    component: ContentLayout,
    meta: {middleware: auth},
    children: [
        {
            path: "new-feeds",
            name: "New Feeds",
            components: {default: NewFeeds},
            meta: {middleware: auth, tab: 'workplace'},
            children: [
                {
                    path: ":post_id",
                    name: "New Feeds",
                    components: {default: NewFeeds},
                    meta: {middleware: auth, tab: 'workplace'},
                }
            ]
        },
        {
            path: "groups",
            name: "Groups",
            components: {default: ListGroup},
            meta: {middleware: auth, tab: 'workplace', navbarAbsolute: true},
            children: [
                {
                    path: ":group_type",
                    name: "Groups",
                    components: {default: ListGroup},
                    meta: {middleware: auth, tab: 'workplace', navbarAbsolute: true},
                },
            ]
        },
        {
            path: "news",
            name: "News",
            components: {default: News},
            meta: {middleware: auth, tab: 'workplace'},
        },
        {
            path: "group/:group_id",
            name: "Group",
            components: {default: Group},
            meta: {middleware: auth, tab: 'workplace'},
            children: [
                {
                    path: ":group_tab",
                    name: "Group",
                    components: {default: Group},
                    meta: {middleware: auth, tab: 'workplace'},
                    children: [
                        {
                            path: ":post_id",
                            name: "Group",
                            components: {default: Group},
                            meta: {middleware: auth, tab: 'workplace'},
                        },
                    ]
                },
            ]
        },
        {
            path: "messenger/profile/:user_id",
            name: "profile",
            component: Profile,
            meta: {middleware: auth, tab: 'workplace'},
        },
        {
            path: "/profile/:user_id",
            name: "profile",
            component: Profile,
            meta: {middleware: auth, tab: 'workplace'},
            children: [
                {
                    path: ":tab",
                    name: "profile",
                    components: {default: Profile},
                    meta: {middleware: auth, tab: 'workplace'},
                },
            ]
        },
        {
            path: "department/:department_id/members/",
            name: "department_members",
            components: {default: MemberList},
            meta: {middleware: auth, tab: 'company-structure'},
        },
        {
            path: "position/:position_id/members/",
            name: "position_members",
            components: {default: MemberList},
            meta: {middleware: auth, tab: 'company-structure'},
        },
        {
            path: "/messenger/:messenger_id",
            name: "messenger",
            component: Messenger,
            meta: {middleware: auth, tab: 'messenger'},
        },
        {
            path: "/admin-settings/groups",
            name: "Admin setting groups",
            component: GroupList,
            meta: {middleware: auth, tab: 'admin-settings'},
        },
        {
            path: "/admin-settings/chat-bot",
            name: "Admin setting chat bot",
            component: ChatBot,
            meta: {middleware: auth, tab: 'admin-settings'},
            children: [
                {
                    path: ":messenger_id",
                    name: "Admin setting chat bot",
                    components: {default: ChatBot},
                    meta: {middleware: auth, tab: 'admin-settings'},
                },
            ]
        },
        {
            path: "/admin-settings/stickers",
            name: "Admin setting stickers",
            component: Stickers,
            meta: {middleware: auth, tab: 'admin-settings'},
            children: [
                {
                    path: ":messenger_id",
                    name: "Admin setting stickers",
                    components: {default: Stickers},
                    meta: {middleware: auth, tab: 'admin-settings'},
                },
            ]
        }
    ]
};

let notificationPages = {
  path: "/",
  component: ContentLayout,
  meta: {middleware: auth},
  children: [
    {
      path: "/notification/:notification_id/group/:group_id/post/:post_id",
      name: "Group",
      components: {default: Group},
      meta: {middleware: auth, tab: 'notification'},
      tab: 'notification'
    },
  ]
};

let categoryPages = {
  path: "/",
  component: ContentLayout,
  meta: {middleware: auth},
  children: [
    {
      path: "/category-home",
      name: "home_category",
      component: HomeCategory,
      meta: {middleware: auth,tab: 'category'},
    },
    {
      path: "/category/:category_id",
      name: "category",
      component: Category,
      meta: {middleware: auth, tab: 'category'},
    },
  ]
};

let postDetail = {
  path: "/post/image/:single_post_id",
  name: "PostDetail",
  component: PostDetail,
  meta: {middleware: auth},
};

let groupSetting = {
    path: "/group-setting/:group_id",
    name: "group_setting",
    component: GroupSetting,
    meta: {middleware: auth, tab: 'group_setting'},
    children: [
        {
            path: "/group-setting/:group_id/:tab",
            name: "group_setting_child",
            component: GroupSetting,
            meta: {middleware: auth,tab: 'group_setting'},
        },
    ]
};

let companyStructure = {
    path: "/company-structure/",
    name: "company_structure",
    component: CompanyStructure,
    meta: {middleware: auth, tab: 'company_structure'},
};

let errorPage = {
    path: "/error",
    name: "Error page",
    component: ErrorPage,
};

let viewFilePage = {
    path: "/view-file",
    name: "View file page",
    component: ViewFile,
};

let firstStepPage = {
    path: "/update-user",
    name: "Update user page",
    component: UpdateUserPage,
};

let noLoginPages = {
    path: "/",
    component: NoLoginLayout,
    children: [
        {
            path: "forgot-password",
            name: "Forgot password",
            components: {default: ForgotPassword},
        },
        {
            path: "change-password",
            name: "Change password",
            components: {default: ChangePassword},
        },
    ]
};

let wikiPages = {
    path: "/",
    component: ContentLayout,
    meta: {middleware: auth},
    children: [
        {
            path: "wiki-home",
            name: "home_wiki",
            component: HomeCategory,
            meta: {middleware: auth,tab: 'wiki'},
        },
        {
            path: "wiki/:category_id",
            name: "wiki_page",
            component: Category,
            meta: {middleware: auth, tab: 'wiki'},
        },
    ]
};

// let adminPages = {
//   path: "/dashboard",
//   component: ContentLayout,
//   meta: {middleware: auth},
//   children: [
//     {
//       path: "dashboard",
//       name: "Dashboard",
//       components: {default: Dashboard},
//       meta: {middleware: auth}
//     }
//   ]
// };

const routes = [
    {
        path: "/",
        redirect: "/new-feeds",
        name: "New Feeds",
    },
    authPages,
    workplacePages,
    notificationPages,
    categoryPages,
    postDetail,
    groupSetting,
    errorPage,
    companyStructure,
    firstStepPage,
    noLoginPages,
    viewFilePage,
    wikiPages
];

export default routes;
