<template>
  <div class="chat-bot-manager__wrapper custom-scroll-bar-2">
    <!--    <vue-element-loading :active="isLoad" is-full-screen/>-->
    <div class="d-flex h_100">
      <div class="chat-list">
        <div class="text-300-medium pl-15px pb-15px">
          DANH SÁCH CHAT BOT
        </div>
        <div style="height: calc(100% - 35px); overflow: auto;">
          <vue-loadmore
              :on-loadmore="onLoad"
              :finished="chatGroupPaging.finished"
              :loading-text="'Đang tải'"
              :finished-text="'Hết'">
            <div class="chat-item-wrapper" v-for="(item, index) in chatGroups"
                 :key="'ChatBot_manager_' + item.id + index" :class="{ active: item.id == chatGroupId}"
                 @click="goToChatBotRoom(item.id)">
              <div class="p-relative read-badge">
                <md-avatar>
                  <image-template class="avatar" :size="'small'" :thumbnail="item.thumbnail"/>
                </md-avatar>
              </div>
              <div class="message text-300-medium ml-10px">
                {{ item.name }}
              </div>
            </div>
          </vue-loadmore>

        </div>
      </div>
      <div class="message-list-wrapper">
        <div class="loader" v-show="loadingOnScroll"></div>

        <div class="create-chat-btn-wrapper" v-if="messageList.length != 0">
          <button class="d-flex-center float-right cursor-pointer ml-10px" @click="$modal.show('create-chat-bot-modal', {chatGroupId: chatGroupId})">
            <md-icon>add</md-icon>
            <span>
              Soạn tin nhắn
            </span>
          </button>
          <button class="d-flex-center float-right cursor-pointer" @click="triggerFileUpload">
            <md-icon>photo_camera</md-icon>
            <input type="file" ref="fileupload" accept="image/jpg, image/png, image/jpeg" hidden id="update-avatar-chat-bot-btn"
                   @change="uploadFile">
            <span class="ml-5px">
              Đổi ảnh đại diện nhóm
            </span>
          </button>
        </div>
        <div class="message-list" v-if="messageList.length != 0" @scroll="scrollChatContent($event)" id="chat-bot-manager-content">
          <div v-observe-visibility="reachedEndOfListForMessage"/>
          <div :id="'message-' + mess.id" class="message-item wide" v-for="(mess, index) in messageList"
               :key="mess.id">
            <div class="message-action">
              <div class="message-reaction">
                <div class="full-radius message-text-action" style="overflow: hidden;">
                  <template v-if="mess.type === MESSAGE_CHAT_TYPE.TEXT">
                    <template>
                      <div class="message-text full-radius">
                          <div class="content-display">
                                    <span
                                        :class="'text-400-default neutral_1 wb-break-word'"
                                        v-html=getMessageText(mess)></span>
                          </div>
                        <div v-if="mess.link_metadata != null">
                          <div class="metadata-message" :set="metadata = JSON.parse(mess.link_metadata)">
                            <div v-if="!!metadata.title">
                              <div class="container-linkmetadata"
                                   :class="mess.status_message === 'sending' && typeof mess.status_message !== 'undefined' ? 'loading-holder' : ''">
                                <a target="_blank"
                                   :href="mess.status_message === 'sending' && typeof mess.status_message !== 'undefined' ? '#' : metadata.url"
                                   class="container-metadata">
                                  <div class="metadata-image loader-shimmer">
                                    <img :src="metadata.image || '/img/link.png'" alt="aza-thumbnail">
                                  </div>
                                  <div class="metadata-content">
                                    <p class="title text-1-line loader-shimmer">{{ metadata.title }}</p>
                                    <p class="description text-2-line loader-shimmer">{{ metadata.description }}</p>
                                    <p class="domain text-1-line loader-shimmer"
                                       v-if="metadata.url && metadata.url != '/' ">{{ metadata.url }}</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>

                  <template v-if="mess.type === MESSAGE_CHAT_TYPE.IMAGE">
                    <div v-if="!!mess.text">
                      <div class="message-text full-radius">
                        <div class="content-display" style="margin-bottom: 5px">
                          <div class="text-400-default is-message neutral_1 ws-pre-wrap"
                               v-html=getMessageText(mess)></div>
                        </div>
                        <image-chat-content :currentMess=mess
                                            :nextMess=messageList[index++] v-if="mess.files.length > 0"/>
                      </div>
                    </div>
                    <div v-else-if="mess.files.length > 0">
                      <image-chat-content :currentMess="mess" :nextMess="messageList[index++]" />
                    </div>
                  </template>

                  <template v-if="mess.type === MESSAGE_CHAT_TYPE.VIDEO">
                    <div v-if="!!mess.text">
                      <div class="message-text full-radius">

                        <div class="content-display" style="margin-bottom: 5px">
                          <div class="text-400-default is-message neutral_1 ws-pre-wrap"
                               v-html=getMessageText(mess)></div>
                        </div>
                        <video-chat-content v-if="mess.files.length > 0"
                                            :currentMess="mess" :nextMess= "messageList[index++]" />
                      </div>
                    </div>
                    <div v-else>
                      <video-chat-content :currentMess="mess" :nextMess="messageList[index++]" />
                    </div>
                  </template>

                  <template v-if="mess.type === MESSAGE_CHAT_TYPE.FILE">
                    <div v-if="!!mess.text">
                      <div class="message-text full-radius">

                        <div class="content-display" style="margin-bottom: 5px">
                          <div class="text-400-default is-message neutral_1 ws-pre-wrap"
                               v-html=getMessageText(mess)></div>
                        </div>
                        <file-chat-content v-if="mess.files.length > 0"
                                           :currentMess=mess :nextMess="messageList[index]" />
                      </div>
                    </div>
                    <div v-else>
                      <file-chat-content :currentMess=mess :nextMess="messageList[index]" />
                    </div>
                  </template>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {MESSAGE_CHAT_TYPE} from "../../../../const/const";
import ChatService from "../../../../store/services/ChatService";
import ImageTemplate from "../../../../components/ImageTemplate";
import FileChatContent from "../Messager/Content/FileChatContent";
import VideoChatContent from "../Messager/Content/VideoChatContent";
import ImageChatContent from "../Messager/Content/ImageChatContent";
import CreateChatBotMessageModal from "../../../Components/Modal/AdminManagement/CreateChatBotMessageModal";
import PostService from "../../../../store/services/PostService";

export default {

  components: {
    ImageTemplate,
    FileChatContent,
    VideoChatContent,
    ImageChatContent,
  },

  data() {
    return {
      chatGroups: [],
      isLoad: true,
      chatGroupPaging: {
        finished: false,
        page: 1,
        last_page: 1,
      },
      MESSAGE_CHAT_TYPE,
      messageList: [],
      messagePaging: {
        finished: false,
        page: 1,
        last_page: 1,
      },
      loadingOnScroll: false,
      chatGroupId: this.$route.params.messenger_id,
      hasScrolledToBottom: false,
      onScrollMessageId: null,
    }
  },

  computed: {
    hasNextPageForMessage() {
      return this.messagePaging.page < this.messagePaging.last_page;
    }
  },

  created() {
    this.getMessageList();
    this.hasScrolledToBottom = true;
  },

  mounted() {
    this.getChatGroups();
  },

  methods: {
    triggerFileUpload() {
      document.getElementById("update-avatar-chat-bot-btn").click();
    },
    async uploadFile(e) {
      const formFile = new FormData();

      formFile.append("files[]", e.target.files[0]);
      //
      await PostService.uploadFile(formFile).then(res => {
        if (res.status) {
          this.changeAvatarGroup(res['data'][0]);
        }else{
          this.$store.dispatch("alerts/error", 'Thay ảnh thất bại.');
        }
      });
    },

    async changeAvatarGroup(file){
      let data = {
        group_id: this.chatGroupId,
        file_id: file.file_id,
        avatar: file.url
      }

      ChatService.updateAvatarGroup(data).then(res => {
        if (res && res.status && res.data) {
          window.location.reload();
        } else {
          this.$store.dispatch("alerts/error", 'Thay ảnh thất bại.');
        }
      });
      const input = this.$refs.fileupload;
      input.type = 'text';
      input.type = 'file';
    },

    scrollChatContent({ target: { scrollTop, clientHeight, scrollHeight }}){
      if (Math.ceil(scrollTop) + Math.ceil(clientHeight) + 1 >= scrollHeight) {
        this.hasScrolledToBottom = true;
      }else this.hasScrolledToBottom = false;
    },
    goToChatBotRoom(id) {
      this.$router.push(`/admin-settings/chat-bot/${id}`);
    },
    onLoad(done) {
      if (this.chatGroupPaging.page < this.chatGroupPaging.last_page) {
        this.chatGroupPaging.page++
        this.getChatGroups(true);
      } else {
        this.chatGroupPaging.finished = true;
      }
      done();
    },
    async getChatGroups(isLoadMore = false) {
      const params = {
        'page': isLoadMore ? this.chatGroupPaging.page : 1,
        'per_page': 20,
        'type': "BOT"
      };
      await ChatService.getChatGroups(params).then(res => {
        if (res.data) {
          this.chatGroupPaging.last_page = res.meta.last_page;
          let data = res.data;
          data.sort(function (a, b) {
            return new Date(b?.last_message?.created_at) - new Date(a?.last_message?.created_at);
          });
          if (isLoadMore) {
            this.chatGroups = this.chatGroups.concat(data);
            this.$store.commit('chat/updateChatGroups', this.chatGroups);
          } else {
            this.chatGroups = data;
          }
        }
      });
    },
    getMessageText: function (mess) {
      let text = mess.text;
      if (text == null) return "";

      text = text.replace(/(^|\s)(#[\w\d]+)(\s|$)/g, '$1<span style="background-color: yellow;">$2</span>$3');

      text = text.replace(/([^\s@]+@[^\s@]+\.[^\s@]+)/g, '<a target="_blank" href="mailto:$1">$1</a>');

      const urlRegex = /\bhttps?:[^,\s()<>]+(?:\([\w\d]+\)|([^,[:punct:]\s]|))/ig
      // /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])?[\w.-]+(?:\.[\w\.-]+)+/ig
      text = text.replace(urlRegex, function (url) {
        // tự động thêm http:// khi không có http | https
        // VD: facebook.com => http://facebook.com
        let pattern = /^((http|https):\/\/)/;
        let link_href = url;
        if (!pattern.test(url)) {
          link_href = "http://" + url;
        }
        return '<a target="_blank" href="' + link_href + '">' + url + '</a>';
      });

      text = text.replace(/(^|\s)(\d{2})(\d{2})(\d{4})-(\w{6})(\s|$)/g, (orderCode) => {
        let link = 'https://azagroup.asia/don-hang?code=' + orderCode;
        return '<a target="_blank" href="' + link + '">' + orderCode + '</a>'
      });

      return text;
    },
    reachedEndOfListForMessage(reached, entry) {
      if (reached && !this.hasScrolledToBottom && this.messagePaging.page < this.messagePaging.last_page && this.messageList.length != 0) {
        this.messagePaging.page++;
        this.getMessageList();
      }
    },
    getMessageList() {

      let params = {...this.messagePaging};
      params.per_page = 20;
      params.group_id = this.chatGroupId;

      if (this.messagePaging.page != 1) {
        this.loadingOnScroll = true;
      }

      ChatService.getMessageList(params).then(res => {
        if (res && res.data) {
          let data = res.data.reverse();

          if(this.messagePaging.page > 1){
            this.onScrollMessageId = this.messageList[0].id;
            this.messageList = data.concat(this.messageList);
          } else {
            this.onScrollMessageId = null;
            this.messageList = data;
          }

          this.messagePaging.last_page = res.meta.last_page;
          this.loadingOnScroll = false;
        }
      });

    },
    resetMessageParams() {
      this.messagePaging = {
        finished: false,
        page: 1,
        last_page: 1,
      };
      this.messageList = [];
    },
  },

  watch: {
    '$route.params.messenger_id'(to, from) {
      this.onScrollMessageId = null;
      this.chatGroupId = to;
      this.resetMessageParams();
      this.getMessageList();
    },
    'messageList'(newV) {
      if (newV.length > 0) {
        setTimeout(() => {
          let objDiv = document.getElementById("chat-bot-manager-content");

          if (this.messagePaging.page != 1 && this.onScrollMessageId !== null) {
            let onScrollDiv = document.getElementById("message-" + this.onScrollMessageId);

            objDiv.scrollTop = onScrollDiv.offsetTop - 300;
            this.onScrollMessageId = null;
          } else if (this.messagePaging.page == 1) {
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        }, 200);
      }
    },
  }
};

</script>


<style lang="scss">
.chat-bot-manager__wrapper {
  padding: 15px 15px 0 15px;
  height: 100%;

  .loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    top: 25px;
    left: 45%;
    z-index: 5;
  }

  .chat-list {
    width: 300px;
    height: calc(100% - 35px);
    padding-top: 10px;
    background-color: white;

    .chat-item-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 15px;
      cursor: pointer;
    }

    .chat-item-wrapper:hover, .chat-item-wrapper.active {
      background-color: #FFEB7D;
    }
  }

  .message-list-wrapper {
    width: calc(100% - 300px);
    padding: 10px 10px 0 25px;
    position: relative;

    .create-chat-btn-wrapper {
      height: 50px;
      width: 100%;
      button {
        padding: 4px 12px;
        border-radius: 4px;
        border: none;
        color: white;
        background-color: #376BFB;
        .md-icon {
          color: white !important;
        }
      }
    }

    .message-list {
      overflow: auto;
      height: calc(100% - 60px);
      padding-right: 5px;

      .message-item {
        margin-bottom: 20px !important;
      }
      .content-display-timestamp {
        display: none !important;
      }
      .emojis {
        display: none !important;
      }
      .message-text {
        background-color: #FFF;
        color: var(--spectrum-global-color-contentPrimary);
        border-radius: 12px;
        font-size: 16px;
        padding: 8px 12px;
        border: 1px solid #dfdfdf;
        white-space: pre-wrap;
        .card-media img, .message-file .file-item {
          border-radius: 0px !important;
        }
      }

      .message-media {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        position: relative;
        &:hover .pophover-datetime {
          visibility: visible !important;
        }
        .message-action {
          position: relative;

          .message-text-action {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }


        .image-message {
          width: 230px;
          height: auto;
          background-color: rgb(242, 242, 242);
          border: none;
          border-radius: 15px;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          margin: 0px;
          max-width: 230px !important;
          position: relative;

          img {
            max-height: 350px;
            overflow: hidden;
            min-height: 30px;
            object-fit: cover;
            border-radius: 15px;
          }

          .quality-label {
            position: absolute;
            top: 10px;
            left: 10px;
            background: rgb(0 0 0 / 41%);
            border-radius: 4px;
            padding: 2px 5px;
            color: rgb(255 255 255);
            font-size: 11px;
          }
        }

        .video-message {
          align-items: center;
          position: relative;
          text-align: center;
          width: 100%;
          overflow: hidden;
          margin: 5px 5px 0 0;

          .video-content {
            width: 400px;
            background-color: rgb(242, 242, 242);
            border: none;
            border-radius: 15px;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            margin: 0px;

            .video {
              width: 100%;
            }
          }
        }

        .file-message {

        }
      }
    }
  }

  .vuejs-refresh-head {
    display: none;
  }

  .vuejs-loadmore-finished-text {
    display: none !important;
  }

  .ml-btn-wrapper {
    height: 11%;
  }
}

.content .group-list-wrapper {
  height: 100%;
  overflow-y: overlay;
}

.group-type-select {
  padding: 3px 4px;
  border: 1px solid #9b9ba5;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 25px;
}
</style>