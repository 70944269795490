<template>
  <div>
    <modal name="report-chat-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="create-group-modal modal-custom report-chat-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <template v-if="switchTab == 1">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal">Soạn tin nhắn</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('report-chat-modal')">close</span>
          </md-dialog-title>
        </md-card>
        <md-card-content>
          <div class="content-report-modal">
            <!-- message type -->
            <div class="message-type flex-items">
              <div class="title">
                <span class="required">Loại tin nhắn</span>
              </div>
              <div class="types" v-click-outside="closeDropList">
              <button @click="openDropList"
              :class="{
                  'active-normal' : droplist.selectedType == 'DEFAULT',
                  'active-report' : droplist.selectedType == 'REPORT',
                  'active-bonus' : droplist.selectedType == 'REWARD',
                  'active-discipline' : droplist.selectedType == 'DISCIPLINE'
                }" 
              class="selectd-type-button">{{ droplist.option[param_message.category] }} <md-icon>arrow_right</md-icon></button>
              <Transition name="fade" mode="out-in">
                <div class="dropdown-options" v-if="droplist.isShow">
                  <div @click="optionMessageType('DEFAULT')" class="option chat-normal">KỶ LỤC MỚI</div>
                  <div @click="optionMessageType('REPORT')" class="option chat-report">BÁO CÁO</div>
                  <div @click="optionMessageType('REWARD')" class="option chat-bonus">KHEN THƯỞNG</div>
                  <div @click="optionMessageType('DISCIPLINE')" class="option chat-discipline">KỶ LUẬT</div>
                </div>
              </Transition>
              </div>
            </div>
            <div class="tags">
              <div class="title">
                <span>Thẻ</span>
              </div>
              <div class="tag-list">
                <div class="tag" v-if="datetime.selected">
                  <span>{{ datetime.selected }}</span>
                </div>
                <div class="tag" v-if="tag_team">
                  <span>{{ tag_team }}</span>
                </div>
                <div class="tag" v-if="param_message.tag_list.place">
                  <span>{{ param_message.tag_list.place }}</span>
                </div>
                <div class="tag" v-for="(tag, index) in other_tags" :key="index">
                  <span>{{ tag }}</span>
                </div>
                <div class="add-tag" @click="switchTabContent(2)">
                  <span>Thêm thẻ</span>
                </div>
              </div>
            </div>
            <div class="fileUploadContainer">
              <div class="title-report">
              <span>Nội dung</span>
            </div>
            <div class="uploadfile">
              <img class="footer-icon upload-image" title="Tải lên video" :class="activeTypeUpload === 'video' ? 'disable-file' : ''" src="/img/chat/video.svg" @click="openUpload('video')">
              <img class="footer-icon upload-image" title="Tải lên hình ảnh" :class="activeTypeUpload === 'image' ? 'disable-file' : ''" src="/img/chat/image.svg" @click="openUpload('image')">
              <img class="footer-icon upload-file" title="Tải lên file"  :class="activeTypeUpload === 'file' ? 'disable-file' : ''" src="/img/chat/attachment.svg" @click="openUpload('file')">
              <input ref="imageUpload" @change="onChangeUpload($event, 'image')" type="file" id="image" multiple
                      accept="image/*" hidden>
                <input ref="fileUpload" @change="onChangeUpload($event, 'file')" type="file" id="file" multiple
                      hidden>
                <input ref="videoUpload" @change="onChangeUpload($event, 'video')" type="file" id="video" multiple
                      accept="video/*" hidden>
            </div>
            </div>
            <div class="file-upload border-top aza-scroll" style="overflow-x: auto;overflow-y: hidden;" v-if="medias.length > 0">
              <div class="file-upload__container">
                <!-- <vue-element-loading :active="loadingFile" spinner="bar-fade-scale"/> -->
                <div v-for="(file, index) in medias" :key="index" class="upload-file-chat">
                  <vue-element-loading :active="file.loading == 'loading'" spinner="bar-fade-scale"/>
                  <template v-if="file.type === 'video'">
                    <div class="photo-upload-item">
                      <template>
                        <div class="p-relative">
                          <video class="file-img" controls="false" style="margin: auto">
                            <source :src="file.path">
                            Sorry, your browser doesn't support embedded videos.
                          </video>
                          <div class="w_100 h_100 p-absolute " style="top: 0px; left: 0px"></div>
                        </div>
                      </template>
                      <div class="photo-delete__action" @click="removeFile(index)">
                        <md-icon>close</md-icon>
                      </div>
                    </div>
                  </template>
                  <template v-if="file.type === 'image'">
                    <div class="photo-upload-item">
                      <template>
                        <img class="file-img" :src="file.path">
                      </template>
                      <div class="photo-delete__action" @click="removeFile(index)">
                        <md-icon>close</md-icon>
                      </div>
                    </div>
                  </template>
                  <template v-if="file.type === 'file'">
                    <div class="file-upload-item mx-2">
                      <div class="item-container">
                        <div class="url-colection-thumb mr-5px"><img src="/img/chat/Type-Other.svg" alt="thumb"></div>
                        <div class="file-content">
                          <div class="heading-400 text-1-line wb-break-all">{{ file.name }}</div>
                          <div class="text-300-default neutral_2"><span>{{
                              file.extension
                            }} - {{ bytesToSize(file.file_size) }}</span></div>
                        </div>
                      </div>
                      <div class="photo-delete__action" @click="removeFile(index)">
                        <md-icon>close</md-icon>
                      </div>
                    </div>
                  </template>
                  <template v-if="file.loading == 'error'">
                    <div class="error-upload">
                      <span>Lỗi</span>
                    </div> 
                  </template>
                </div>
              </div>
            </div>
            <div class="content-ckeditor mt-15px">
              <ckeditor v-model="html_content" :config="editorConfig"></ckeditor>
            </div>
          </div>
        </md-card-content>
        <md-dialog-actions class="p-15px w_100">
            <button type="submit" class="btn-active md-button" @click="sendMessageReport">Tạo hạng mục
            </button>
            <button type="submit" class="md-button" @click="closeModal">Hủy
            </button>
        </md-dialog-actions>
      </template>
      <template v-else>
        <md-card class="modal-header-custom">
          <md-dialog-title class="flex-items">
            <div class="flex-items">
              <span class="material-icons cursor-pointer mr-10px"
                    @click="switchTabContent(1)">keyboard_backspace</span>
              <span class="neutral_1 heading-500 text-header-modal">Gắn thẻ</span>
            </div>
            <span class="material-icons float-right cursor-pointer" @click="switchTabContent(1)">close</span>
          </md-dialog-title>
        </md-card>
        <md-card-content>
          <div class="tag-container-modal">
            <div class="tag-datetime">
              <datetime type="date form-control" v-model="datetime.value" 
                          :input-id="'datetimetag'" 
                          :format="datetime.format"
                          :input-class="'hidden-datetime'"
                         :flow="datetime.flow"></datetime>
              <div class="head-datetime container-item">
                  <div class="title">
                  <span>Thời gian</span>
                </div>
                <div class="datetime">
                  <div class="day" @click="openDateTime('day')">
                    <span>Ngày</span>
                  </div>
                  <div class="month" @click="openDateTime('month')">
                    <span>Tháng</span>
                  </div>
                  <div class="year" @click="openDateTime('year')">
                    <span>Năm</span>
                  </div>
                </div>
              </div>
              <div class="date-selected flex-items">
                <div class="selected-time">
                  {{ datetime.selected }}
                </div>
                <div class="remove-selected"  @click="() => {datetime.selected = ''}"><span class="material-icons float-right cursor-pointer">close</span></div>
              </div>
            </div>
            <!--  -->
            <div class="tag-datetime tag-team">
              <div class="head-datetime container-item">
                  <div class="title">
                    <span>Chi nhánh/Team</span>
                  </div>
                  <div class="datetime" v-click-outside="closeTagTeam">
                    <div class="day" @click="openTagTeam(0)">
                      <span>Chi nhánh</span>
                    </div>
                    <div class="month" @click="openTagTeam(1)">
                      <span>Team</span>
                    </div>
                    <template v-if="tag">
                      <md-autocomplete
                        class="seach-branch-team"
                        v-model="selectedTagTeam"
                        @md-selected="selectedTeam" 
                        @md-changed="changedInput" 
                        :md-input-id="'team'" 
                        :md-options="branches" 
                        :md-fuzzy-search="false"
                      >
                      <template slot="md-autocomplete-item" slot-scope="{ item }">
                        <!-- <md-highlight-text :md-term="term">{{ item }}</md-highlight-text> -->
                        <span class="color">{{ item }}</span>
                      </template>

                      <!-- <template class="not-found-data" slot="md-autocomplete-empty" slot-scope="{ term }">
                        Không tìm thấy dữ liệu!
                      </template> -->
                    </md-autocomplete>
                    </template>
                  </div>
              </div>
              <div class="date-selected flex-items">
                <div class="selected-time">
                  {{ param_message.tag_list.team }}
                </div>
                <div class="remove-selected" @click="() => param_message.tag_list.team = ''"><span class="material-icons float-right cursor-pointer">close</span></div>
              </div>
            </div>
            <!-- Location -->
            <div class="location-tag">
                <div class="title">
                  <span>Địa điểm</span>
                </div>
                <input type="text" v-model="param_message.tag_list.place" class="location-input" />
            </div>
            <!-- tag other -->
            <div class="other-tag mt-10px">
              <div class="title">
                <span>Thẻ khác</span>
              </div>
              <md-chips @md-insert="insertOtherTag" v-model="other_tags" md-placeholder="Gõ tên thẻ và nhấn enter..."></md-chips>
            </div>
          </div>
        </md-card-content>
        <md-dialog-actions class="p-15px w_100">
            <button type="submit" class="btn-active md-button" @click="switchTabContent(1)">Đồng ý
            </button>
            <button type="submit" class="md-button" @click="switchTabContent(1)">Hủy
            </button>
        </md-dialog-actions>
      </template>
    </modal>
  </div>
</template>

<script>

import EmployeeService from "../../../store/services/EmployeeService";
import SettingService from "../../../store/services/SettingService";
import {GROUP_CHAT_TYPE, CREATE_POST_LEVEL, MODEL_TYPE, POST_TYPE, TYPE_ADD_GROUP_MEMBER, MESSAGE_CHAT_TYPE} from "../../../const/const";
import PostService from "../../../store/services/PostService";
import ChatService from "../../../store/services/ChatService";
import moment from "moment";
import { helpers } from "../../../helper/helpers";
import {Datetime} from 'vue-datetime';

export default {
  name: 'report-modal',
  components: {datetime: Datetime},
  computed: {},
  mounted() {

  },

  data() {
    return {
      medias: [],
      activeTypeUpload: '',
      html_content: '',
      editorConfig: {
        toolbar: [
          ['Format', 'Font', 'FontSize', 'Bold','Italic','Strike'],
          ['NumberedList','BulletedList'],
        ]
      },
      message_type: null,
      droplist: {
        isShow: false,
        selected: 'Chọn loại tin nhắn',
        selectedType: 'DEFAULT',
        option: {
          'DEFAULT': 'KỶ LỤC MỚI',
          'REPORT': 'BÁO CÁO',
          'REWARD': 'KHEN THƯỞNG',
          'DISCIPLINE': 'KỶ LUẬT',
        },
      },
      other_tags: [],
      datetime: {
        value: null,
        flow: [],
        type: '',
        format: '',
        selected: ''
      },
      maxDate: new Date().toJSON(),
      switchTab: 1,
      selectedTagTeam: null,
      branches: [],
      tag: false,
      form_branches: {
        keyword: '',
        per_page: 10,
        // order_by: 'desc',
        order: 'name',
        is_parent: 1 // Default 1: Chi nhánh
      },
      timer: null,
      param_message: {
        type: MESSAGE_CHAT_TYPE.IMAGE,
        category: 'DEFAULT',
        medias: [],
        tag_list: {
          date: '',
          place: '', //address
          team: ''
        },
        file_ids: [],
        message: ''
      },
      tag_team: ''
    };
  },
  watch: {
    'datetime.value'(newV){
      if(this.datetime.type){
        let text = '';
        switch (this.datetime.type) {
          case 'year':
            text = 'Năm ' + moment(newV).format('YYYY');
            break;
          case 'month':
            text = 'Tháng ' + moment(newV).format('M');
            break;
          default:
            text = moment(newV).format('DD-MM');
            break;
        }
        this.datetime.selected = text;
      }
    }
  },
  methods: {
    beforeOpened(event) {
      this.resetParams();
    },

    beforeClosed(event) {
      
    },
    openUpload(type) {
      if (this.activeTypeUpload == '' || type === this.activeTypeUpload) {
        switch (type) {
          case 'file':
            this.$refs.fileUpload.click();
            break;
          case 'image':
            this.$refs.imageUpload.click();
            break;
          case 'video':
            this.$refs.videoUpload.click();
            break;
        }
        // this.typingMessage = true;
      }
    },

    async onChangeUpload(e, type, fileOnPaste = false) {
      const formFile = new FormData();
      this.activeTypeUpload = type;
      let selectedFiles = fileOnPaste ? fileOnPaste : e.target.files;
      let vm = this;

      //format type file
      let types = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        let typeFile = selectedFiles[i].type.split('/')[0];
        if(!types.includes(typeFile) || types.length == 0){
          types.push(['video', 'image'].includes(typeFile) ? typeFile : 'file');
        }
      }
     let type_upload = types.find((item) => item !== type) ?? type;

      for (let i = 0; i < selectedFiles.length; i++) {
        let typeFile = selectedFiles[i].type;
        let item = {
          name: selectedFiles[i].name,
          file_size: selectedFiles[i].size,
          extension: selectedFiles[i].name.split('.').pop(),
          url: null,
          path: null,
          type: type_upload,
          loading: 'loading'
        }

        switch (type_upload) {
          case 'image':
            this.param_message.type = MESSAGE_CHAT_TYPE.IMAGE
            break;
          case 'video':
            this.param_message.type = MESSAGE_CHAT_TYPE.VIDEO
            break;
          case 'file':
            this.param_message.type = MESSAGE_CHAT_TYPE.FILE
            break;
        }

        let reader = new FileReader();
        reader.onload = (e) => {
          let src = reader.result;
          item.path = src
          item.thumbnail = {
            "small_thumb": src,
            "medium_thumb": src
          }
          vm.medias.push(item);
        };

        reader.readAsDataURL(selectedFiles[i]);

        formFile.append("files[]", selectedFiles[i]);

          await PostService.uploadFile(formFile).then(res => {
            if (res.status) {
              item.path = res.data[0].url;
              item.url = res.data[0].url;
              item.loading = false;
              item.thumbnail = res.data[0].thumbnail;
              let keyIndex = vm.medias.length > 1 ? keyIndex + i : i;
              vm.medias[keyIndex] = item;
              this.param_message.file_ids.push(res.data[0]['file_id']);
            } else {
              // vm.medias.splice(i, 1);
              item.loading = 'error';
            }
          });

        formFile.delete("files[]");
      }
      // this.form_input.uploading = false;
    },

    removeFile(index) {
      this.medias.splice(index, 1);
    },

    bytesToSize(size) {
      return helpers.bytesToSize(size)
    },

    closeModal(){
      this.resetParams();
      this.$modal.hide('report-chat-modal');
    },

    optionMessageType(type){
      // this.droplist.selected = this.droplist.option[type];
      this.droplist.selectedType = type;
      this.param_message.category = type;
      this.closeDropList();
    },

    openDropList(){
      this.droplist.isShow = !this.droplist.isShow;
    },

    closeDropList(){
      this.droplist.isShow = false;
    },
    openDate(e){
      console.log(e);
    },

    openDateTime(type){
      switch(type) {
        case 'day':
          this.datetime.flow = ['date'];
          this.datetime.type = type;
        break;       
        case 'month':          
          this.datetime.flow = ['month'];
          this.datetime.type = type;
          this.datetime.format = 'DD';
          break;
        default:
          this.datetime.flow = ['year'];
          this.datetime.type = 'year';
          this.datetime.format = 'YYYY';
      }
      // this.$refs.datetimetag.click();
      document.getElementById('datetimetag').focus();
    },

    switchTabContent(tab){
      this.switchTab = tab;
    },

    openTagTeam(type){
      this.form_branches.is_parent = type;
      this.getBranches();
      this.tag = true;
      this.$nextTick(() => {
        document.getElementById('team').click();
      });
    },

    closeTagTeam(){
      this.tag = false;
      this.branches = [];
      this.selectedTagTeam = '';
    },

    changedInput(searchTerm){
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.branches = [];
        this.branches = new Promise(resolve => {
            this.form_branches.keyword = searchTerm;
            let branches = this.getBranches();
            resolve(branches);
        });
      }, 500);
    },

    getBranches(){
      ChatService.getBranchesTeams(this.form_branches).then(res => {
          if(res.data){
            this.branches = res.data.map((item) => item.name);
          }
      });
      // this.$nextTick(() => {
      //   document.getElementById('team').click();
      // });
    },

    selectedTeam(selected){
      this.param_message.tag_list.team = selected;
      this.tag_team = selected;
    },

    insertOtherTag(tag){
    },

    sendMessageReport(){
      let tags = this.other_tags;
      tags.map((tag, index) => {
        index++;
        this.param_message.tag_list['other' + index] = tag;
      });

      this.param_message.tag_list.date = this.datetime.selected;
      this.param_message.message = this.html_content;
      this.param_message.medias = this.medias;

      this.$emit('send_message', this.param_message);
      this.resetParams();
    },

    resetParams(){
      this.medias = [];
      this.switchTab = 1,
      this.selectedTagTeam = null,
      this.other_tags = [];
      this.html_content = '';
      this.form_branches = {
        keyword: '',
        per_page: 10,
        // order_by: 'desc',
        order: 'name',
        is_parent: 1 // Default 1: Chi nhánh
      };

      //format message
      this.param_message = {
        type: MESSAGE_CHAT_TYPE.TEXT,
        category: 'DEFAULT',
        tag_list: {
          date: '',
          place: '', //address
          team: ''
        },
        file_ids: [],
        message: ''
      };

      this.datetime = {
        value: null,
        flow: [],
        type: '',
        format: '',
        selected: ''
      }
    }
  }

}
</script>

<style lang="scss">
.md-menu-content{
  z-index: 99999;
}
.not-found-data{
  font-size: 13px;
}
.report-chat-modal{
  .flex-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  padding: 15px;
  .message-type{
    margin: 0 0 10px;
    .types{
      position: relative;
      .selectd-type-button{
        &:hover{
          cursor: pointer;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 10px;
        gap: 8px;
        background: #376BFB;
        border: 1px solid #D8DAE5;
        border-radius: 4px;
        color: #F9FAFC;
        width: max-content;
        font-size: 14px;
        .md-icon{
          color: #fff;
        }
      }
      .dropdown-options {
        position: absolute;
        width: 150px;
        right: 0;
        background: #fff;
        border: 1px solid #e9e9e9;
        box-shadow: 0px 8px 16px #2f31361a;
        border-radius: 5px;
        .option {
          &:hover{
            background: #dfdcdc;
            cursor: pointer;
          }
          padding: 5px 10px;
        }
      }
      .active-normal{
        background: #18a619;
      }
      .active-report{
        background: #376BFB;
      }
      .active-bonus{
        background: #FF7B00;
      }
      .active-discipline{
        background: #D14343;
      }
      .active-kl{
        background: #5CA222;
      }
    }
  }
  .tag-list {
    border: 1px solid #D8DAE5;
    padding: 6px 10px;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .tag {
      &:hover{
        cursor: pointer;
        box-shadow: 0px 8px 16px #2f31361a
      }
      background: #a3eeff;
      border-radius: 5px;
      padding: 3px 6px;
      text-align: center;
      min-width: max-content;
      max-width: 100%;
    }
    .add-tag {
      background: #e2e2e2;
      border-radius: 5px;
      padding: 4px 8px;
      min-width: max-content;
      max-width: 100%;
      &:hover{
        cursor: pointer;
        box-shadow: 0px 8px 16px #2f31361a
      }
    }
  }
  .fileUploadContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    .uploadfile img {
      padding: 5px;
      &:hover{
        background: #c2c0be52;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .file-upload__container {
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    padding: 15px 0;
    width: auto;
    .upload-file-chat {
      position: relative;
    }
    .file-upload-chat{
      .error-upload {
        width: 100% !important;
        left: 50% !important;
      }
    }
    .error-upload {
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translate(-50%, -50%);
      color: red;
      background-color: #e3dedede;
      width: 60px;
      height: 60px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
    }
    .photo-upload-item {
      background-color: #ededed;
      border: 1px solid #e8e7e7;
      border-radius: 3px;
      height: 60px;
      min-height: 60px;
      min-width: 60px;
      position: relative;
      text-align: center;
      width: 60px;
      margin-right: 15px;

      .file-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .file-upload-item {
      background-color: #ededed;
      border-radius: 10px;
      display: inline-block;
      position: relative;
      width: 220px;
      margin-right: 15px;

      .item-container {
        align-items: center;
        display: flex;
        padding: 6px;

        .url-colection-thumb {
          width: 30px;
        }

        .file-content {
          width: 166px;
        }

      }
    }

    .photo-delete__action {
      top: -6px;
      right: -6px;
      position: absolute;
      cursor: pointer;
      border-radius: 20px;
      background: #978ded;
      z-index: 10;
      i {
        font-size: 16px !important;
        color: white;
      }
    }
  }
  .tag-container-modal{
    .container-item, .datetime {
      display: flex;
      grid-gap: 10px;
      .datetime{
        span {
          &:hover{
            cursor: pointer;
          }
          border: 1px solid #D8DAE5;
          border-radius: 4px;
          padding: 2px 6px;
          font-size: 13px;
        }
      }
    }
    .tag-datetime{
        .date-selected {
          border: 1px solid #D8DAE5;
          border-radius: 4px;
          background: #F4F4F4;
          margin: 6px 0 15px;
          padding: 5px 10px;
        }
        .vdatetime{
            position: absolute;
            .hidden-datetime{
              opacity: 0;
              width: 0px;
              height: 0px;
            }
          }
      }
    .location-tag{
      .location-input {
        border: 1px solid #D8DAE5;
        border-radius: 4px;
        width: 100%;
        min-height: 30px;
        padding: 6px 8px;
        color: #101840;
        font-weight: 400;
      }
    }
    .other-tag{
      .md-chips {
        border: 1px solid #D8DAE5;
        border-radius: 4px;
        padding: 0 5px !important;
        margin: 0;
        input{
          padding: 6px;
        }
        .md-chip > .md-ripple{
          background: #FFE4CB;
          color: #101840;
          border-radius: 5px;
        }
        svg {
          fill: #585858 !important;
        }
      }
    }
    .tag-team{
      position: relative;
      &::after{
        content: none;
      }
      .seach-branch-team{
        position: absolute;
        width: 250px;
        height: auto;
        background: #fff;
        top: 15px;
        border: 1px solid #D8DAE5;
        box-shadow: 5px 8px 44px #2f31361a
      }
      .md-input{
        padding: 7px;
      }
    }
    
  }
}
</style>
