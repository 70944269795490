<template>
  <div class="company-structure-container">
      <div class="title-sidebar">Sơ đồ tổ chức</div>
      <div class="cs-tab-menu pl-15px pr-15px">
        <div class="text-400-medium cursor-pointer" :class="{active: tabActive == 1}" @click="changeTab(1)">Phòng ban</div>
        <div class="text-400-medium cursor-pointer" :class="{active: tabActive == 2}" @click="changeTab(2)">Chức vụ</div>
      </div>

      <div class="cs-search-container">
        <div class="input-container mt-15px">
          <md-icon>search</md-icon>
          <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group" v-model="keyword" @keyup="search">
        </div>
      </div>
      <div class="cs-department cs-position-list" v-if="tabActive == 1">
        <div class="department-item d-flex-center mb-5px px-15px py-5px cursor-pointer"
             :class="{active: $route.params.department_id == 'ALL'}" @click="$router.push('/department/ALL/members')">
          <img src="/img/sidebar/left/social_active.png" style="width: 24px" class="mr-5px" alt="">
          <span class="text-500-medium">AZA GROUP</span>
        </div>
        <ul class="cs-department-main">
          <div v-for="item in departmentList" :key="item.id">
            <tree-view-item :model="item"></tree-view-item>
          </div>
        </ul>
      </div>
      <div class="cs-position-list mt-15px" v-if="tabActive == 2">
        <div class="cs-position-item cursor-pointer" v-for="position in positionList" :key="position.id"
             :class="{active: $route.params.position_id == position.id}">
          <div class="cs-position-name">
            <md-icon>account_box</md-icon>
            <div class="heading-400" @click="viewListMemberForPosition(position.id)">{{position.name}}</div>
          </div>
            <md-icon class="company-structure-more-icon cursor-pointer" style="margin-right: 0 !important;">more_horiz</md-icon>
          <div class="company-structure-more-dropdown dropdown-popup">
              <div class="dropdown-popup-item" @click="$modal.show('update-company-structure-modal',
              {pageType: 'POSITION', name: position.name, id: position.id})">
                <span>Chỉnh sửa</span>
              </div>
              <div class="dropdown-popup-item" @click="showConfirmDeletePosition(position)">
                <span>Xóa</span>
              </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>

import SettingService from "../../../store/services/SettingService";
import TreeViewItem from "../../Workplace/Pages/TreeViewItem";
import $ from "jquery";
import PostService from "../../../store/services/PostService";

export default  {
  components: {TreeViewItem},
  data() {
    return {
      tabActive: 1,
      positionList: [],
      keyword: "",
      departmentList: [],
    }
  },
  computed: {
  },
  mounted() {
    if (this.$store.state.companyStructure.positionList.length == 0) {
      this.getPosition(true);
    } else {
      this.positionList = this.$store.state.companyStructure.positionList;
    }
    if (this.$store.state.companyStructure.departmentTree.length == 0) {
      this.getDepartmentTree(true);
    } else {
      this.departmentList = this.$store.state.companyStructure.departmentTree;
    }
    $('.company-structure-more-icon').each(function (event) {
      let dropdown = $(this).parent().find("div.company-structure-more-dropdown");
      delayHover(this, function () {
        $(dropdown).show();
      }, function () {
        $(dropdown).hide();
      }, 500)
    });

    $('.company-structure-more-dropdown').hover(function (e) {
      $(this).show();
    }, function () {
      $(this).hide();
    });
    if (this.$route.name == 'department_members') {
      this.tabActive = 1;
    }
    if (this.$route.name == 'position_members') {
      this.tabActive = 2;
    }
  },
  updated() {
    $('.company-structure-more-dropdown').hover(function (e) {
      $(this).show();
    }, function () {
      $(this).hide();
    });
    $('.company-structure-more-icon').each(function (event) {
      let dropdown = $(this).parent().find("div.company-structure-more-dropdown");
      delayHover(this, function () {
        $(dropdown).show();
      }, function () {
        $(dropdown).hide();
      }, 500)
    });
  },
  watch: {
    '$store.state.companyStructure.positionList': function (newVal, oldVal) {
      this.positionList = newVal;
    },
    '$route.name': function (newVal, oldVal) {
      if (newVal == 'department_members') {
        this.tabActive = 1;
      }
      if (newVal == 'position_members') {
        this.tabActive = 2;
      }
    }
  },
  methods: {
    async getPosition(isFirst) {
      await SettingService.getPositions({keyword: this.keyword}).then(res => {
        if (res && res.data) {
          this.positionList = res.data;
          if (isFirst) {
            this.$store.commit('updatePositionList', res.data);
          }
        }
      });
    },

    async getDepartmentTree(isFirst) {
      await SettingService.getDepartmentTree({keyword: this.keyword}).then(res => {
        if (res && res.data) {
          this.departmentList = res.data;
          if (isFirst) {
            this.$store.commit("updateDepartmentTree", res.data);
          }
        }
      });
    },

    async updatePosition() {

    },

    viewListMemberForPosition(positionId) {
      this.$router.push('/position/' + positionId + '/members')
    },

    showConfirmDeletePosition(position) {
      this.$modal.show('dialog', {
        title: 'Xóa chức vụ?',
        text: 'Bạn có chắc chắn muốn xóa chức vụ [' + position.name + '] không?',
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              this.deletePosition(position.id);
            }
          }
        ]
      })
    },
    async deletePosition(positionId) {
      await SettingService.deletePosition(positionId).then(res => {
        if (res && res.data) {
          this.$store.dispatch("alerts/success", 'Bạn đã xóa chức vụ thành công.');
          this.$store.dispatch("getPositionList");
        } else {
          this.$store.dispatch("alerts/error", 'Xóa chức vụ thất bại');
        }
        this.$modal.hide('dialog');
      });
    },
    changeTab(tabActive) {
      this.tabActive = tabActive;
      this.keyword = '';
      this.positionList = this.$store.state.companyStructure.positionList;
      this.departmentList = this.$store.state.companyStructure.departmentTree;
    },
    search() {
      if (this.tabActive == 1) {
        this.getDepartmentTree();
      } else {
        this.getPosition();
      }
    }
  }
};
function delayHover(element, over, out, delay) {
  let timer;
  $(element).hover(function(){
    if (timer) {
      clearTimeout(timer);
      timer = null;
    } else {
      timer = setTimeout(function(){
        over.apply(this, arguments);
        timer = null;
      }, delay);
    }
  }, function(){
    let that = this,
        args = $.makeArray(arguments);
    timer = setTimeout(function(){
      out.apply(that, args);
      timer = null;
    }, delay);
  });
}
</script>
<style lang="scss">
body {
  height: 100vh;
}

.cs-tab-menu {
    display: flex;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #e9e9e9;

    div {
        display: flex;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
        color: #6c6c6f;
        font-size: 13px !important;
        border-bottom: 3px solid transparent;
    }

    div.active {
        color: #FCAF17;
        border-bottom: 3px solid #FCAF17;
        font-weight: 600 !important;
    }
}

.company-structure-container {
  width: 100%;
  height: 100%;
  background: white;

  .cs-search-container {
    padding: 0 8px;
    .input-container {
      border-radius: 4px !important;
      padding-left: 10px !important;
      .md-icon {
        margin: auto !important;
      }
    }
  }

  .cs-position-list {
    padding: 0 4px;
    .active {
      background-color: #FFF1A6 !important;
    }

    .cs-position-item {
      height: 36px;
      width: 100%;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .cs-position-name {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        div {
          width: 100%;
        }
      }
    }
  }
  .cs-department-main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .company-structure-more-dropdown {
    display: none;
    position: absolute;
    width: 200px;
    right: 0px;
    top: 25px;
    z-index: 5;
    background-color: white;
    border: 1px solid #D8DAE5;
    border-radius: 4px;
  }
  .cs-position-list {
    .company-structure-more-dropdown {
      top: 35px;
    }
  }
  ul {
    list-style-type:none !important;
  }
}
</style>
