<template>
  <div
      class="sidebar sidebar-custom"
      :data-color="activeColor"
      :data-background-color="backgroundColor"
  >
    <!--left-content-->
    <div class="sidebar-workplace">
      <md-list-item class="cursor-pointer workplace-icon" v-popover:tooltip.right="'Bảng tin'"
                    v-bind:class="tab === 'workplace' ? 'icon-active' : ''"
                    v-on:click="changeMenu('workplace')">
        <i class="material-icons social-network background-no-repeat"></i>
      </md-list-item>
      <md-list-item class="cursor-pointer workplace-icon" v-popover:tooltip.right="'Trò chuyện'"
                    v-bind:class="tab === 'messenger' ? 'icon-active' : ''"
                    v-on:click="changeMenu('messenger')">
        <i class="material-icons chat background-no-repeat"></i>
        <md-badge v-if="$store.state.chat.unCountChatRead > 0" :md-content="$store.state.chat.unCountChatRead"></md-badge>
      </md-list-item>
      <md-list-item class="cursor-pointer workplace-icon" v-popover:tooltip.right="'Thông báo'"
                    v-bind:class="tab === 'notification' ? 'icon-active' : ''"
                    v-on:click="changeMenu('notification')">
        <i class="material-icons notification background-no-repeat"></i>
        <md-badge v-if="$store.state.notification.unCountRead > 0" :md-content="$store.state.notification.unCountRead > 99 ? '99+' : $store.state.notification.unCountRead"></md-badge>
      </md-list-item>
      <md-list-item class="cursor-pointer workplace-icon" v-popover:tooltip.right="'Thư viện kiến thức'"
                    v-if="[1,2,119,141,845,921,920,869].includes(user.id)"
                    v-bind:class="tab === 'category' ? 'icon-active' : ''"
                    v-on:click="changeMenu('category')">
        <i class="material-icons library background-no-repeat"></i>
      </md-list-item>
      <md-list-item class="cursor-pointer workplace-icon" v-popover:tooltip.right="'Sơ đồ tổ chức'"
                    v-if="[1,2,119,141,845,921,920,869].includes(user.id)"
                    v-bind:class="tab === 'company-structure' ? 'icon-active' : ''"
                    v-on:click="changeMenu('company-structure')">
        <i class="material-icons company-structure background-no-repeat"></i>
      </md-list-item>

      <md-list-item class="cursor-pointer workplace-icon" v-popover:tooltip.right="'Quản lý nội bộ'"
                    v-if="[1,2,119,141,845,921,920,869].includes(user.id)"
                    v-bind:class="tab === 'admin-settings' ? 'icon-active' : ''"
                    v-on:click="changeMenu('admin-settings')">
        <i class="material-icons admin-settings background-no-repeat"></i>
      </md-list-item>

      <md-list-item class="cursor-pointer workplace-icon" v-popover:tooltip.right="'Aza Wiki'"
                    v-bind:class="tab === 'wiki' ? 'icon-active' : ''"
                    v-on:click="changeMenu('wiki')">
        <i class="material-icons wiki background-no-repeat"></i>
      </md-list-item>

<!--      <img v-popover:avatars.right :src="user.avatar_url ? user.avatar_url : '/img/profile/avatar.png'"-->
<!--           class="group-image avatar cursor-pointer" id="mySettingBtn"/>-->
      <div class="link-to-email" v-popover:tooltip.right="'Mở quản lý email nội bộ'">
        <a href="https://mail.azagroup.vn/" target="_blank">
          <img src="/img/email.svg" alt="aza">
        </a>
      </div>
      <image-template v-popover:avatars.right class="group-image avatar cursor-pointer" id="mySettingBtn" :size="'small'" :image="user.avatar_url" :thumbnail="user.thumbnail"/>
      <popover name="avatars">
        <md-list class="p-0px popover-avatar">
          <md-list-item class="profile" @click="redirectToProfile">
            <md-avatar>
              <image-template :class="'pop-avatar-avatar'" :size="'small'" :image="user.avatar_url" :thumbnail="user.thumbnail"/>
            </md-avatar>
            <div class="md-list-item-text">
              <div class="">
                <div class="fullname">{{ user.fullname }}</div>
                <div class="text-300-default neutral_2">
                  Xem trang cá nhân
                </div>
              </div>
            </div>
          </md-list-item>
          <md-list-item v-on:click="logout()">
            <md-icon>logout</md-icon>
            <span class="md-list-item-text">Đăng xuất</span>
          </md-list-item>
        </md-list>
      </popover>
    </div>

    <div class="sidebar-wrapper custom-scroll-bar-2" ref="sidebarScrollArea">
      <md-list class="nav h_100" :class="{'ps' : tab === 'notification' || tab === 'messenger', 'overflow-y-overlay_hover' : tab !== 'notification' || tab !== 'messenger'}">
        <div v-if="tab === 'workplace'" class="social-bar">
          <WorkPlaceSideBar/>
        </div>
        <div v-if="tab === 'notification'" class="notification-bar">
          <NotificationSideBar/>
        </div>
        <div v-if="tab === 'category'" class="social-bar">
          <CategorySideBar/>
        </div>
        <div v-if="tab === 'company-structure'" class="social-bar">
          <company-structure/>
        </div>
        <div v-if="tab === 'messenger'" class="social-bar">
          <MessageSideBar/>
        </div>
        <div v-if="tab === 'admin-settings'"  class="social-bar">
          <AdminSettingSideBar/>
        </div>
        <div v-if="tab === 'wiki'"  class="social-bar">
          <WikiSideBar/>
        </div>
      </md-list>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import CompanyStructure from "./CompanyStructureSideBar";
import NotificationSideBar from "./SideBarContent/NotificationSideBar";
import CategorySideBar from "./SideBarContent/CategorySideBar";
import WorkPlaceSideBar from "./SideBarContent/WorkPlaceSideBar";
import MessageSideBar from "./SideBarContent/MessageSideBar";
import ChatService from "../../../store/services/ChatService";
import AuthService from "../../../store/services/AuthService";
import NotificationService from "../../../store/services/NotificationService";
import ImageTemplate from "../../../components/ImageTemplate";
import {GROUP_CHAT_TYPE, TYPE_OPTIONS} from "../../../const/const";
import AdminSettingSideBar from "./SideBarContent/AdminSettingSideBar";
import WikiSideBar from "./SideBarContent/WikiSideBar";

export default {
  name: "sidebar",
  components: {CompanyStructure, NotificationSideBar, CategorySideBar, WorkPlaceSideBar, MessageSideBar, ImageTemplate, AdminSettingSideBar, WikiSideBar},
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`
      };
    }
  },
  watch: {
    '$route'(to, from) {
      this.router_name = to.name
      this.tab = to.meta.tab
    },
    '$store.state.chat.unCountChatRead'(newV) {
      this.unCountChatRead = newV;
    },
    '$store.state.auth.user'(newV) {
      this.user = newV;
    },
    '$store.state.chat.dataSocket'(newV) {
      if(this.$route.name != 'messenger' || this.$route.params.messenger_id != newV.group.id 
        && newV.user_notification_off.indexOf(this.user.id) == -1
      ){
        if(newV.sender.id != this.user.id){
          this.$store.dispatch("alerts/chatNoti", newV);
        }
        this.updateTitlePage(newV)
      }
      // if(newV.sender.id != this.user.id){
      //   console.log('socker sibar.vue');
      //   this.$store.dispatch('chat/getUnreadGroupChat');
      // }
    },
    '$store.state.chat.dataReactionEmoji'(newV) {
      if(newV.sender.id == this.user.id && newV.sender.id !== newV.user_reacted.id){
        newV.scroll_to_id = true;
        this.$store.dispatch("alerts/chatNotiReaction", newV);
      }
    },
    '$store.state.chat.reconnectSocket'(){
      this.$store.dispatch('chat/getUnreadGroupChat');
    },
  },
  props: {
    activeColor: {
      type: String,
      default: "green",
    },
    backgroundImage: {
      type: String,
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: value => {
        let acceptedValues = ["", "black", "white", "red"];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.$sidebar.toggleMinimize();
    this.tab = this.$route.meta.tab;
    this.getUnreadCount();
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      tab: 'workplace',
      unCountRead: 0,
      unCountChatRead: 0,
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },

    changeMenu(type) {
      this.tab = type;
    },

    async logout() {
      const fcmToken = localStorage.getItem("cmf_token");
      Cookies.remove('access_token');
      Cookies.remove('user_id');
      await this.deleteAllCookies;
      await AuthService.logout({fcm_token: fcmToken})
      window.location.reload();
    },

    deleteAllCookies() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },

    redirectToProfile() {
      this.$router.push('/profile/' + this.user.id);
      document.getElementById('mySettingBtn').click();
    },

    getUnreadGroupChat() {
      this.$store.dispatch('chat/getUnreadGroupChat');
    },

    getUnreadCount() {
      NotificationService.getUnreadCount().then(res => {
        if (res.data) {
          this.unCountRead = res.data.count;
          this.$store.commit("updateUnCountRead", this.unCountRead)
        }
      });
    },
    updateTitlePage (data) {
      if (data.sender.id == this.user.id) return;
      let title = 'Aza Social';

      if (data.group.type === GROUP_CHAT_TYPE.USER){
        title = data.sender.fullname + ' đã nhắn tin cho bạn';
      }else{
        title = data.sender.fullname + ' đã nhắn tin vào ' + data.group.name;
      }

      document.title = title;
    },
  },

  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.title-sidebar {
  margin: 20px 15px 15px;
  font-size: 22px;
  font-weight: 600;
}

.subtitle-sidebar {
  margin: 22px 0 12px;
}
.link-to-email{
  position: absolute;
  bottom: 80px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 26px !important;
  height: 26px !important;
  background-size: cover;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
}
</style>
