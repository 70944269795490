<template>
  <div>
    <modal name="branch-detail-modal"
           :click-to-close="false"
           width='800px'
           height="auto"
           class="modal-custom branch-detail-modal"
           transition="easeInOut"
           @before-open="beforeOpen"
           @before-close="beforeClosed"
    >

        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 modal-title">Thông tin công ty / chi nhánh</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('branch-detail-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content class="modal-content">
          <div class="branch-detail-main">
            <div class="branch-img-wrapper">
              <img class="branch-img" :src="branchInfo.avatar" alt="">
            </div>
            <div class="branch-info-wrapper">
              <div class="heading-600" style="color: #3E4C96">{{ branchInfo.name }}</div>
              <div class="text-400-default uppercase mt-5px">{{ branchInfo.slogan }}</div>
              <div class="line"></div>
              <div class="heading-400 neutral_2 mt-10px">Địa chỉ</div>
              <div class="branch-info mt-5px">
                <span>{{ branchInfo.address }}</span>
              </div>
              <div class="heading-400 neutral_2 mt-10px">Ngày thành lập</div>
              <div class="branch-info mt-5px">
                <span>{{ branchInfo.birthday }}</span>
              </div>
              <template v-if="branchInfo.phone">
                <div class="heading-400 neutral_2 mt-10px">Hotline</div>
                <div class="branch-info mt-5px">
                  <span>{{ branchInfo.phone }}</span>
                </div>
              </template>
              <div class="heading-400 neutral_2 mt-10px">Group chính thức</div>
              <div class="branch-info mt-5px">
                <span>{{ branchInfo.group_link }}</span>
                <a :href="branchInfo.group_link" target="_blank">
                  <md-icon>launch</md-icon>
                </a>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-dialog-actions>
          <button type="submit" class="btn-stroke md-button heading-300" @click="$modal.hide('branch-detail-modal')">Đóng</button>
        </md-dialog-actions>
    </modal>
  </div>
</template>

<script>

export default {
  name: 'branch-detail-modal',
  computed: {
  },

  created() {
  },
  data: () => ({
    branchInfo: {},
  }),

  methods: {
    beforeOpen(event) {
      this.branchInfo = event.params.branchInfo;
      this.branchInfo.group_link = window.location.origin + '/group/' + this.branchInfo.id;
      // this.$store.commit("updateModalIsOpen", true);
    },
    beforeClosed(event){
      // this.$store.commit("updateModalIsOpen", false);
    },
  },
}
</script>

<style lang="scss">
.modal-content {
  min-height: 430px;
}

.branch-detail-main {
  display: flex;
  .branch-img-wrapper {
    width: 35%;
    .branch-img {
      width: 240px;
      height: 240px;
    }
  }

  .branch-info-wrapper {
    width: 65%;
    .line {
      width: 100px;
      height: 20px;
      border-bottom: 3px solid #D8DAE5;
    }
    .branch-info {
      width: 100%;
      height: 40px;
      background-color: #EDEFF5;
      border: 1px solid #EDEFF5;
      color: black;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

</style>
