<template>
    <div class="mention-container" v-on:click-outside="onBlur">
          <!-- <textarea
          rows="6"
          placeholder="Type @ or # to trigger the mention"
          @input="onInput"
          @keydown="onKeyDown"
          @keyup="onKeyUp"
          @scroll="onScroll"
          ref="textarea">
        </textarea> -->
        <slot></slot>
        <div class="absolute z-30 popupItem" style="display:none;" :style="`top: ${caretPosition.top - 5}px; left: ${caretPosition.left - 10}px`" v-show="showPopover">
            <span class="absolute top-0 left-0 w-2 h-2 bg-white transform rotate-45 -mt-1 ml-3 border-gray-300 border-r border-b z-20"></span>
            <template v-if="isLoading">
            <div class="popupItem-list">
              <md-list class="p-0px popover-avatar list-reset">
                <md-list-item class="profile">
                      <div class="md-list-item-text">
                        <div class="text-400-medium">Đang tải dữ liệu!</div>
                      </div>
                    </md-list-item>
              </md-list>
            </div>
            </template>
            <!--  -->
            <template v-else>
                <div class="popupItem-list">
                <md-list class="p-0px popover-avatar list-reset" v-if="displayedItems.length > 0">
                    <md-list-item class="profile" v-for="(item,index) in displayedItems" :key="index"
                                    @mousedown="applyMention(index)"
                                    :class="selectedIndex == index ? 'mention-selected' : ''">
                        <md-avatar>
                            <img class="avatar" :src="item.avatar.small_thumb || '/img/profile/avatar.png'">
                        </md-avatar>
                        <div class="md-list-item-text">
                            <div class="text-400-medium mention-username">@{{ item.fullname }}</div>
                            <div class="detail-info" v-if="item.team && item.team.agency && item.team.department">
                                <i class="company-structure background-no-repeat"></i>
                                <div class="ml-5px text-2-line">
                                    <span>{{ item.team.agency }}</span>
                                    <span class="ml-5px" v-if="item.team.department">></span>
                                    <span class="ml-5px" v-if="item.team.department">{{ item.team.department }}</span>
                                </div>
                            </div>
                            <div class="detail-info" v-if="item.team && item.team.team">
                                <span class="material-icons">group</span>
                                <span class="ml-5px">{{ item.team.team }}</span>
                            </div>
                        </div>
                        </md-list-item>
                </md-list>
                <md-list class="p-0px popover-avatar list-reset" v-else>
                    <md-list-item class="profile">
                        <div class="md-list-item-text">
                            <div class="text-400-medium">Không có dữ liệu!</div>
                        </div>
                        </md-list-item>
                </md-list>
                </div>
            </template>
        </div>
      </div>
  </template>

<script>
  import ChatService from "../../../../store/services/ChatService";
  import GroupService from "../../../../store/services/GroupService";
  import {GROUP_CHAT_TYPE} from "@/const/const";

  export default {
    name: 'MentionChat',
    // emits: ['search', 'open', 'close', 'apply'],
    props: {
        group: {
            type: Object,
            default: null,
        },
    },
    data: function() {
      return{
        keys: ['@'],
        items: [],
        placement: 'top-start',
        omitKey: false,
        filteringDisabled: false,
        insertSpace: true,
        mapInsert: null,
        limit: 4,
        key: null,
        oldKey: null,
        searchText: null,
        caretPosition: {
            top: 0,
            left: 0,
        },
        selectedIndex: 0,
        input: null,
        showPopover: false,
        displayedItems: [],
        isLoading: false,
        allTextMentions: [],
        except_users: [],
        param_member: {
          group_id: this.$route.params.messenger_id,
          page: 1,
          per_page: 6,
          keyword: null,
          except: []
        },
        chatGroupSelected: this.$store.state.chat.chatGroupSelected,
        timer: null,
        is_all : true,
        members: []
      }
    },
    created(){
        // nếu mention dùng cho comment bài viết thì sẽ call rất nhiều lần api
        // chỉ call api khi gõ @ thì mới load api lấy dữ liệu
        if(this.group === null){
            this.getMembersMention();
        }
    },
    mounted(){
        if (this.chatGroupSelected.type === "GROUP" || this.group) {
            this.input = this.getInput()
            this.attach();
            this.displayedItems = this.members;
            if(this.group === null){
                this.loadData(true, false);
            }
        }
        this.$emit('close');
    },
    updated(){
      if(this.chatGroupSelected.type === "GROUP" || this.group){
        if(!this.searchText){
            // this.displayedItems = this.members;
            // this.getMembersMention();
        }
        const newInput = this.getInput()
        if (newInput !== this.input) {
          this.detach()
          this.input = newInput
          this.attach()
        }

        if(!this.input.value){
          this.param_member.except = [];
          this.except_users = [];
        }
      }
    },
    unmounted(){
      if(this.chatGroupSelected.type === "GROUP" || this.group) {
        this.detach()
      }
    },
    watch: {
      '$route.params.messenger_id'(to, from) {
        this.param_member.group_id = to;
        this.allTextMentions = [];
        // this.members_group = 
        this.getMembersMention();
        this.loadData(true, false);
      },
      '$store.state.chat.chatGroupSelected'(newV) {
        this.chatGroupSelected = newV
      }
    },
    methods: {
        updateDisplayedItems: function() {
            this.selectedIndex = 0;
            this.loadData(!this.searchText);
        },
        //
        isIe: function() {
            const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : '';
            return userAgent.indexOf('MSIE ') !== -1 || userAgent.indexOf('Trident/') !== -1;
        },
        isFirefox: function() {
            return !(window.mozInnerScreenX == null);
        },
        setInput: function(el) {
            this.input = el;
        },
        getCaretPosition: function(element, position) {
            let mirrorDiv, computed, style;
            // The properties that we copy into a mirrored div.
            // Note that some browsers, such as Firefox,
            // do not concatenate properties, i.e. padding-top, bottom etc. -> padding,
            // so we have to do every single property specifically.
            var properties = [
                'boxSizing',
                'width', // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
                'height',
                'overflowX',
                'overflowY', // copy the scrollbar for IE

                'borderTopWidth',
                'borderRightWidth',
                'borderBottomWidth',
                'borderLeftWidth',

                'paddingTop',
                'paddingRight',
                'paddingBottom',
                'paddingLeft',

                // https://developer.mozilla.org/en-US/docs/Web/CSS/font
                'fontStyle',
                'fontVariant',
                'fontWeight',
                'fontStretch',
                'fontSize',
                'lineHeight',
                'fontFamily',

                'textAlign',
                'textTransform',
                'textIndent',
                'textDecoration', // might not make a difference, but better be safe

                'letterSpacing',
                'wordSpacing'
            ];
            // mirrored div
            mirrorDiv = document.getElementById(element.nodeName + '--mirror-div');
            if (!mirrorDiv) {
                mirrorDiv = document.createElement('div');
                mirrorDiv.id = element.nodeName + '--mirror-div';
                document.body.appendChild(mirrorDiv);
            }

            style = mirrorDiv.style;
            computed = getComputedStyle(element);

            // default textarea styles
            style.whiteSpace = 'pre-wrap';
            if (element.nodeName !== 'INPUT')
                style.wordWrap = 'break-word'; // only for textarea-s

            // position off-screen
            style.position = 'absolute'; // required to return coordinates properly
            style.top = element.offsetTop + parseInt(computed.borderTopWidth) + 'px';
            style.left = "400px";
            style.visibility = 'hidden'; // not 'display: none' because we want rendering

            // transfer the element's properties to the div
            properties.forEach(function(prop) {
                style[prop] = computed[prop];
            });

            if (this.isFirefox()) {
                style.width = parseInt(computed.width) - 2 + 'px' // Firefox adds 2 pixels to the padding - https://bugzilla.mozilla.org/show_bug.cgi?id=753662
                // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
                if (element.scrollHeight > parseInt(computed.height))
                    style.overflowY = 'scroll';
            } else {
                style.overflow = 'hidden'; // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
            }

            mirrorDiv.textContent = element.value.substring(0, position);
            // the second special handling for input type="text" vs textarea: spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
            if (element.nodeName === 'INPUT')
                mirrorDiv.textContent = mirrorDiv.textContent.replace(/\s/g, "\u00a0");

            var span = document.createElement('span');
            // Wrapping must be replicated *exactly*, including when a long word gets
            // onto the next line, with whitespace at the end of the line before (#7).
            // The  *only* reliable way to do that is to copy the *entire* rest of the
            // textarea's content into the <span> created at the caret position.
            // for inputs, just '.' would be enough, but why bother?
            span.textContent = element.value.substring(position) || '.'; // || because a completely empty faux span doesn't render at all
            mirrorDiv.appendChild(span);

            var coordinates = {
                top: span.offsetTop + parseInt(computed['borderTopWidth']) - element.scrollTop,
                left: span.offsetLeft + parseInt(computed['borderLeftWidth']),
            };
            return coordinates;
        },
        onInput: function() {
            this.checkKey()
        },
        onBlur: function() {
            this.closeMenu()
        },
        onKeyDown: function(e) {
            // this.timer = setTimeout(() => {
                if (this.key) {
                if( this.keys.includes(e.key) ) {
                    this.closeMenu()
                    // return this.cancelEvent(e);
                }
                // this.updateDisplayedItems();
                if (e.key === 'ArrowDown' || e.keyCode === 40) {
                      this.selectedIndex++
                      if (this.selectedIndex >= this.displayedItems.length) {
                          this.selectedIndex = 0
                      }
                      this.cancelEvent(e)
                  }
                  if (e.key === 'ArrowUp' || e.keyCode === 38) {
                      this.selectedIndex--
                      if (this.selectedIndex < 0) {
                          this.selectedIndex = this.displayedItems.length - 1
                      }
                      this.cancelEvent(e)
                  }
                  if ((e.key === 'Enter' || e.key === 'Tab' || e.keyCode === 13 || e.keyCode === 9) &&
                      this.displayedItems.length > 0) {
                      this.showPopover = false;
                      this.applyMention(this.selectedIndex)
                      this.cancelEvent(e)
                  }
                  if (e.key === 'Escape' || e.keyCode === 27) {
                      this.closeMenu()
                      this.cancelEvent(e)
                  }
            }
            // }, 500);
        },
        onKeyUp: function(e) {
            if (this.cancelKeyUp && (e.key === this.cancelKeyUp || e.keyCode === this.cancelKeyCode)) {
                this.cancelEvent(e)
            }
            this.cancelKeyUp = null
            // IE
            this.cancelKeyCode = null
        },
        cancelEvent: function(e) {
            e.preventDefault()
            e.stopPropagation()
            this.cancelKeyUp = e.key
            // IE
            this.cancelKeyCode = e.keyCode
        },
        onScroll: function() {
            this.updateCaretPosition()
        },
        getSelectionStart: function() {
            return this.input.selectionStart
        },
        setCaretPosition: function(index) {
            this.$nextTick(() => {
                this.input.selectionEnd = index
            })
        },
        getValue: function() {
            return this.input.value
        },
        setValue: function(value) {
            this.input.value = value;
            this.input.dispatchEvent(new Event('input'));
        },
        checkKey: function() {
            const index = this.getSelectionStart();
            if (index >= 0 && this.chatGroupSelected.type == 'GROUP' || this.group) {
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    const { key, keyIndex } = this.getLastKeyBeforeCaret(index);

                    const searchText = this.lastSearchText = this.getLastSearchText(index, keyIndex)
                    if (!(keyIndex < 1 || /\s/.test(this.getValue()[keyIndex - 1]))) {
                        return false
                    }

                    if (searchText != null) {
                        this.openMenu(key, keyIndex)
                        this.searchText = searchText;
                        this.updateDisplayedItems();
                        return true
                    }
                }, 250);
            }
            this.closeMenu()
            return false
        },
        getLastKeyBeforeCaret: function(caretIndex) {
            const [keyData] = this.keys.map(key => ({
                key,
                keyIndex: this.getValue().lastIndexOf(key, caretIndex - 1),
            })).sort((a, b) => b.keyIndex - a.keyIndex)
            return keyData
        },
        getLastSearchText: function(caretIndex, keyIndex) {
            if (keyIndex !== -1) {
                const searchText = this.getValue().substring(keyIndex + 1, caretIndex);
                if(searchText.split(' ').length < 4){
                 return searchText
                }
                this.$emit('close', null);
            }
            return null
        },
        openMenu: function(key, keyIndex) {
            if (this.key !== key && this.chatGroupSelected.type === 'GROUP' || this.group) {
                this.key = key
                this.keyIndex = keyIndex
                this.updateCaretPosition()
                this.selectedIndex = 0
                this.showPopover = true;
                // this.allTextMentions = [];
                if(this.searchText){
                    this.loadData();
                }
            }
        },
        closeMenu: function() {
            if (this.key != null) {
                this.oldKey = this.key
                this.showPopover = false;
                this.key = null;
                this.searchText = null;
                // this.displayedItems = [];
                this.$emit('close', this.oldKey);
            }
            // this.cancelKeyUp = this.cancelKeyCode = null;
        },
        updateCaretPosition: function() {
            if (this.key) {
                this.caretPosition = this.getCaretPosition(this.input, this.keyIndex);
            }
        },
        applyMention: function(itemIndex) {
            const item = this.displayedItems[itemIndex];

            let fullname = item.fullname;
            
            if(item.id && this.chatGroupSelected.member_count > 30){
              this.except_users.push(item.id);
            }
            // else{
                // this.displayedItems.splice(itemIndex, 1);
            // }
            const value = (this.omitKey ? '' : this.key || '') + String(this.mapInsert ? this.mapInsert(item, this.key) : fullname) + (this.insertSpace ? ' ' : '')
            this.setValue(this.replaceText(this.getValue(), this.searchText, value, this.keyIndex))
            this.setCaretPosition(this.keyIndex + value.length);
            this.$emit('apply', item, this.key, value);
            this.highlightTextarea();
            this.closeMenu()
        },
        replaceText: function(text, searchText, newText, index) {
            return text.slice(0, index) + newText + text.slice(index + searchText.length + 1, text.length)
        },

        attach () {
            if (this.input) {
                this.input.addEventListener('input', this.onInput)
                this.input.addEventListener('keydown', this.onKeyDown)
                this.input.addEventListener('keyup', this.onKeyUp)
                this.input.addEventListener('scroll', this.onScroll)
                this.input.addEventListener('blur', this.onBlur)
            }
        },

        detach () {
            if (this.input) {
                this.input.removeEventListener('input', this.onInput)
                this.input.removeEventListener('keydown', this.onKeyDown)
                this.input.removeEventListener('keyup', this.onKeyUp)
                this.input.removeEventListener('scroll', this.onScroll)
                this.input.removeEventListener('blur', this.onBlur)
            }
        },

        getInput(){
            return this.$el.querySelector('textarea');
        },

        async loadData(onLoad = true, emit = true) {
            this.is_all = true;
            // this.displayedItems = this.members;
            this.isLoading = true;

            if(!this.searchText){
                this.displayedItems = this.members;
            }

            if(this.searchText && this.chatGroupSelected.member_count > 2 || this.group){
                this.param_member.keyword = this.searchText;

                if (this.except_users.length > 0) {
                    this.param_member.except = this.except_users;
                }
                
                await this.getMembersMention();
                // await ChatService.getMembersMention(this.param_member).then(res => {
                //     if (res.data) {
                //         is_all = false;
                //         this.displayedItems = res.data;
                //     }
                    
                // });
            }else if(this.searchText){
                const finalSearchText = this.searchText.toLowerCase();
                this.displayedItems = this.displayedItems.filter((elm) => elm.fullname.toUpperCase().trim().includes(finalSearchText.trim().toUpperCase()));
            }

            for (let i = 0; i < this.displayedItems.length; i++) {
                const text = '@' + this.displayedItems[i]['fullname'];
                if (!this.allTextMentions.includes(text)) {
                    this.allTextMentions.push(text);
                }
            }
            if (emit) {
                this.$emit('open', this.allTextMentions);
            }
            this.isLoading = false;
        },

        async getMembersMention(){
            if(this.group){
                this.param_member.group_id = this.group.id;
                await GroupService.getMemberGroupPost(this.param_member).then(res => {
                    if (res.data) {
                        this.is_all = true;
                        this.displayedItems = res.data;
                        if (this.searchText == null || this.searchText == '') {
                            this.members = res.data;
                            this.displayedItems.unshift({
                                value: 'All',
                                fullname: 'Tất cả ',
                                department: {
                                    name: '• Báo cho cả nhóm •'
                                },
                                avatar: {
                                    url: '/img/chat/ic_mention.svg',
                                    small_thumb: '/img/chat/ic_mention.svg'
                                }
                            })
                        }
                    }

                });
            } else {
               await ChatService.getMembersMention(this.param_member).then(res => {
                    if (res.data) {
                        this.is_all = true;
                        this.displayedItems = res.data;
                        if (this.searchText == null) {
                            this.members = res.data;
                            this.displayedItems.unshift({
                                value: 'All',
                                fullname: 'Tất cả ',
                                department: {
                                    name: '• Báo cho cả nhóm •'
                                },
                                avatar: {
                                    url: '/img/chat/ic_mention.svg',
                                    small_thumb: '/img/chat/ic_mention.svg'
                                }
                            })
                        }
                    }

                });
            }
            
        },

        highlightTextarea() {
            $(this.input).highlightTextarea({
                words: [{color: "rgb(204,227,255)", words: this.allTextMentions}],
                caseSensitive: false,
            });
            $(this.input).focus();
        },
    }
  }
  </script>

  <style lang="scss">
  .mention-container{
    position: relative;
    .popupItem{
        position: absolute;
        .popupItem-list{
            position: absolute;
            z-index: 10;
            bottom: 100%;
            background: #fff;
            padding: 10px 0;
            border-radius: 5px;
            border: 1px solid rgba(209,213,219,1);
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
              li {
                margin: 0;
                min-width: 320px;
                .md-ripple {
                  padding: 4px 12px 4px 6px !important;
                }
                .mention-username {
                  color: #2145a2;
                  margin-bottom: 1px;
                }
              }
            }
        }
    }
    .detail-info{
        display: flex;
        margin-bottom: 2px;
        font-size: 11px;
        color: #1d1d1d;
        align-items: center;
        span{
            font-size: 11px;
        }
        .company-structure{
            background: url('/img/sidebar/left/department_normal.svg') no-repeat;
            height: 14px;
            width: 14px;
            background-size: 14px;
            justify-content: center;
        }
        .material-icons{
            font-size: 15px;
            color: #808080;
            width: max-content;
        }
    }
  }
  </style>