<template>
      <div class="post-detail-container ">
        <vue-element-loading :active="isLoad" is-full-screen :background-color="'transparent'"/>
        <div class="image-main">
          <div class="arrow-left-main" v-if="previousId !== null">
            <span @click="previousPage">
              <div class="arrow-parent">
                <img src="/img/arrow-left.svg" alt="">
              </div>
            </span>
          </div>
<!--          <router-link :to="getLink()">-->
            <img class="close-btn" src="/img/button-close.svg" alt="" @click="getLink">
<!--          </router-link>-->

          <template v-if="extension !== 'mp4' && extension !== 'avi'">
            <img class="post-img" :src="img_url" alt="">
          </template>

          <template v-else >
            <video controls="false" style="margin: auto">
              <source :src="img_url">
              Sorry, your browser doesn't support embedded videos.
            </video>
          </template>


          <div class="arrow-right-main" v-if="nextId !== null">
            <span @click="nextPage">
              <div class="arrow-parent">
                <img src="/img/arrow-right.svg" alt="">
              </div>
            </span>
          </div>
        </div>
        <div class="content-main" v-if="post">
          <div class="post-layout" style="height: 100%">
            <md-card>
              <div class="post-header">
                <post-header :post=post.post :userPost=post.user :group=post.group :isGroup="isGroup" :isPostDetail="true"></post-header>
              </div>
              <div class="post-content">
                <post-content :post=post.post :poll-data="post.poll"></post-content>
              </div>
              <div class="post-footer">
                <post-footer :post=post.post
                             :comments=post.comments
                             :userPost=post.user
                             :liked=post.post.liked
                             :likeCount=post.post.like_count
                             :commentCount=post.post.comment_count
                             :reaction-current="post.post.reaction_current"
                             :reaction-stats="post.post.reaction_stats"
                             :group=post.group
                ></post-footer>
              </div>
            </md-card>
          </div>
        </div>
        <update-comment-modal @update-comment="updateCommentData"></update-comment-modal>
        <user-post-interactive-modal></user-post-interactive-modal>
        <notification-setting-modal @toggle-notification="toggleNotification"/>
      </div>
</template>
<script>

import PostService from "../../../store/services/PostService";
import postContent from "../../Components/Post/postContent";
import postHeader from "../../Components/Post/postHeader";
import postFooter from "../../Components/Post/postFooter";
import UpdateCommentModal from "../../Components/Modal/UpdateCommentModal";
import UserPostInteractiveModal from "../../Components/Modal/UserPostInteractive";

export default  {
  components: {UserPostInteractiveModal, postContent, postHeader, postFooter, UpdateCommentModal},
  mounted() {
    this.getPostDetail();
    localStorage.setItem('router_name', this.$route.name);
  },

  data() {
    return {
      loading: true,
      nextId: null,
      previousId: null,
      img_url: null,
      extension: null,
      post: {},
      isLoad: true,
      postTotalPage: this.$store.state.post.postTotalPage,
    };
  },

  watch: {
    '$store.state.post.postTotalPage': function (newVal, oldVal) {
      this.postTotalPage = newVal;
    },
    '$route.params.single_post_id': function (newVal, oldVal) {
      this.previousId = null;
      this.nextId = null;
      this.isLoad = true;
      this.getPostDetail();
    },
  },

  methods: {
    getPostDetail() {
      let singlePostId = parseInt(this.$route.params.single_post_id);
      PostService.getPost({post_id: singlePostId}).then(res => {
        this.isLoad = false;
        if (res && res.data) {
          let medias = res.data.post.medias;
          let imageIndex = 0;
          for (let i = 0; i < medias.length; i++) {
            if (medias[i].single_post_id === singlePostId) {
              imageIndex = i;
              this.img_url = medias[i].path;
              this.extension = medias[i].extension;
            }
            if (!medias[i].single_post_id) {
              this.img_url = medias[i].path;
              this.extension = medias[i].extension;
            }
          }
          if (imageIndex > 0) {
            this.previousId = medias[imageIndex - 1].single_post_id;
          }
          if (imageIndex < medias.length - 1) {
            this.nextId = medias[imageIndex + 1].single_post_id;
          }
          delete res.data.post.medias;
          this.post = res.data;
        }
      });
    },
    getLink() {
      // const link = localStorage.getItem('link');
      // return link
      // console.log(this.$store.state.post.postTotalPage);
      // console.log(this.$store.state.post.previousDetail);
      if (this.$store.state.post.previousDetail === 1) {
        this.$router.push("/new-feeds");
      } else {
        this.$store.state.post.previousDetail = 1;
        this.$router.go(0 - this.$store.state.post.postTotalPage);
      }
      // console.log(this.$router);
    },
    updateCommentData(e) {
      let commentData = e.commentData;
      for (let j = 0; j < this.post.comments.length; j++) {
        if (this.post.comments[j].id === commentData.id) {
          this.post.comments[j] = commentData;
        }
      }
    },
    previousPage() {
      this.$store.state.post.postTotalPage++;
      this.$router.push('/post/image/' + this.previousId);
    },
    nextPage() {
      this.$store.state.post.postTotalPage++;
      this.$router.push('/post/image/' + this.nextId);
    },
    toggleNotification(e) {
      if (e.postId) {
        this.post.enable_notification = e.enableNotification;
      }
    }
  }
};
</script>
<style lang="scss">
.post-detail-container {
  width: 100%;
  height: 100%;
  display: flex;

  .image-main {
    width: 100%;
    height: 100%;
    background-color: black;
    position: relative;
    display: flex;

    .close-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      cursor: pointer;
      width: 32px;
      height: 32px;
    }

    .post-img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }

    .arrow-left-main, .arrow-right-main {
      height: 100%;
      width: 70px;

      .arrow-parent {
        width: 100%;
        height: 100%;
        position: relative;
        background: rgba(255, 255, 255, 0.05);
        cursor: pointer;

        img {
          cursor: pointer;
          opacity: 0.5;
          position: absolute;
          top: 49%;
          left: 27%;
          width: 30px;
          height: 30px;
          align-self: center;
          text-align: center;
        }
      }
      .arrow-parent:hover {
        background: rgba(255, 255, 255, 0.15);
        img {
          width: 32px;
          height: 32px;
          opacity: 1;
        }
      }
    }

    .arrow-left-main {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }

    .arrow-right-main {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  .content-main {
    width: 420px;
    height: 100%;
    background: white;

    .md-card {
      border: none;
      box-shadow: none;
      overflow-y: auto;
      height: 100vh;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    .md-card::-webkit-scrollbar {
      display: none;
    }
  }

  .emoji{
    //top: 140px;
    //height: 300px;
  }
}
@media (max-width: 900px) {
  .post-detail-container {
    flex-direction: column;
    .image-main {
      height: 60%;
    }
    .content-main {
      width: auto;
    }
  }

}

body {
  height: 100%;
}
</style>
