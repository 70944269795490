<template>
  <div>
    <h3 class="title-sidebar">Trang chủ</h3>
    <div class="content-sidebar">
      <sidebar-item
          :link="{
                        name: 'Bảng tin',
                        path: '/new-feeds',
                        image: '/img/sidebar/right/workplace/home.svg',
                        is_group: false,
                      }"
      />
      <sidebar-item
          :link="{
                        name: 'Nhóm',
                        path: '/groups',
                        image: '/img/sidebar/right/workplace/group.svg',
                        is_group: false,
                      }"
      />
      <div>
        <li class=""><a href="https://azagroup.asia/report" target="_blank" class="nav-link">
          <i class="md-icon md-icon-font md-theme-default" style="color: #474D66">
            <span class="material-symbols-outlined">wysiwyg</span>
          </i>
          <p class="text-400-semibold"> Trang làm việc </p></a></li>
      </div>
      <h3 class="subtitle-sidebar text-500-medium"
          v-if="$store.state.group.groups.companyWallGroups.length > 0 || $store.state.group.groups.branchGroups.length > 0">
        Tổ
        chức</h3>
      <sidebar-item v-for="group in $store.state.group.groups.companyWallGroups" :key="'cwg' + group.id"
                    :link="{
                        name: group.name,
                        path: getPath(group.id),
                        image: group.avatar_url ? group.avatar_url : groupImageDefault,
                        is_group: true,
                      }"
      />
      <sidebar-item v-for="group in $store.state.group.groups.branchGroups" :key="'bg' + group.id"
                    :link="{
                        name: group.name,
                        path: getPath(group.id),
                        image: group.avatar_url ? group.avatar_url : groupImageDefault,
                        is_group: true,
                      }"
      />
      <h3 class="subtitle-sidebar text-500-medium" v-if="$store.state.group.groups.workGroups.length > 0">Nhóm làm
        việc</h3>
      <sidebar-item v-for="group in $store.state.group.groups.workGroups" :key="'wg' + group.id"
                    :link="{
                        name: group.name,
                        path: getPath(group.id),
                        image: group.avatar_url ? group.avatar_url : groupImageDefault,
                        is_group: true,
                      }"
      />
      <h3 class="subtitle-sidebar text-500-medium" v-if="$store.state.group.groups.pinnedGroups.length > 0">Nhóm
        đã ghim</h3>
      <sidebar-item v-for="group in $store.state.group.groups.pinnedGroups" :key="'pg' + group.id"
                    :link="{
                        name: group.name,
                        path: getPath(group.id),
                        image: group.avatar_url ? group.avatar_url : groupImageDefault,
                        is_group: true,
                      }"
      />

      <div>
        <li class=""><a class="nav-link is_group" @click="openDialog()"><i
            class="md-icon md-icon-font md-theme-default">
          <div class="md-image md-theme-default" style="height: auto; width: auto; min-width: auto;"><img
              src="/img/sidebar/right/workplace/add.svg"></div>
        </i>
          <p class="text-400-semibold secondary_g400"> Tạo nhóm </p></a></li>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "workplace-sidebar",
  watch: {
    '$route'(to, from) {
      this.router_name = to.name
      this.tab = to.meta.tab
    },
    '$store.state.group.groups.pinnedGroups': function (newVal, oldVal) {
      this.pinnedGroups = newVal
    },
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      workGroups: this.$store.state.group.groups.workGroups,
      branchGroups: this.$store.state.group.groups.branchGroups,
      companyWallGroups: this.$store.state.group.groups.companyWallGroups,
      pinnedGroups: this.$store.state.group.groups.pinnedGroups,
      groupImageDefault: '/img/group/group_image_low.jpg',
      router_name: this.$route.name,
    }
  },
  methods: {
    getPath(id) {
      return '/group/' + id;
    },
    openDialog() {
      this.$modal.show('create-group-modal')
    },
  },
};
</script>

