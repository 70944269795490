var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('h3',{staticClass:"title-sidebar"},[_vm._v("Quản lý nội bộ")]),_c('div',{staticClass:"content-sidebar"},[_c('sidebar-item',{attrs:{"link":{
                      name: 'Quản lý nhóm',
                      path: '/admin-settings/groups',
                      image: '/img/sidebar/right/workplace/group.svg',
                      is_group: false,
                    }}}),_c('sidebar-item',{attrs:{"link":{
                      name: 'Quản lý Chat bot',
                      path: '/admin-settings/chat-bot',
                      image: '/img/sidebar/right/workplace/group.svg',
                      is_group: false,
                    }}}),_c('sidebar-item',{attrs:{"link":{
                      name: 'Quản lý Stickers',
                      path: '/admin-settings/stickers',
                      image: '/img/sidebar/right/workplace/group.svg',
                      is_group: false,
                    }}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }