<template>
  <div class="md-layout p-15px">
    {{$route.name === 'home_wiki' ? 'Aza Wiki' : 'Tổng hợp các hạng mục kiến thức'}}
  </div>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
    };
  },

  methods: {
  }
};
</script>
