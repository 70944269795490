import BaseService from "./BaseService";

export default class PostService extends BaseService {

    static async getPosts(params = {}) {
        const res = await this.get(`/posts`, params);
        return res.data;
    }

    static async getPost(params = {}) {
        const res = await this.get(`/posts/` + params.post_id);
        return res.data;
    }

    static async createPost(params = {}) {
        const res = await this.post(`/posts`, params);
        return res.data;
    }

    static async updatePost(postId, params = {}) {
        const res = await this.post(`/posts/` + postId, params);
        return res.data;
    }

    static async getWorkProcess(params = {}) {
        const res = await this.get(`/categories/1/tree`, params);
        return res.data;
    }

    static async likePost(params = {}) {
        const res = await this.post(`/posts/` + params.post_id + '/like', params);
        return res.data;
    }

    static async getComments(postId, params = {}) {
        const res = await this.get(`/posts/` + postId + `/comments`, params);
        return res.data;
    }

    static async deletePost(postId, params = {}) {
        const res = await this.post(`/posts/${postId}/delete`);
        return res.data;
    }

    static async getUserLikePost(postId, params = {}) {
        const res = await this.get(`/posts/`+postId+`/like/users`, params);
        return res.data;
    }

    static async getUserReadPost(postId, params = {}) {
        const res = await this.get(`/posts/`+postId+`/read/users`, params);
        return res.data;
    }

    static async userReadPost(postId) {
        const res = await this.post(`/posts/`+postId+`/read`);
        return res.data;
    }

    static async uploadFile(params = {}) {
        const res = await this.post(`/media/upload-multiple`, params);
        return res.data;
    }

    static async votePoll(pollId, params = {}) {
        const res = await this.post(`/polls/` + pollId + `/vote`, params);
        return res.data;
    }

    static async addNewPollOption(pollId, params = {}) {
        const res = await this.post(`/polls/` + pollId + `/add-option`, params);
        return res.data;
    }

    static async toggleNotification(postId, params = {}) {
        const res = await this.post(`/posts/` + postId + `/turn-off-notification`, params);
        return res.data;
    }

    static async getLinkMetadata(params = {}) {
        const res = await this.get(`/posts/get-metadata`, params);
        return res.data;
    }
}

