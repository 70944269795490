<template>
  <div>
    <modal name="create-chat-social-modal"
           :click-to-close="false"
           width="600px"
           height="auto"
           class="modal-custom create-chat-social-modal"
           transition="easeInOut"
           @before-open="beforeOpen"
    >
          <md-card-content class="notification-setting__main">
            <div class="text-center heading-500">
              <span>Đoạn chat cộng đồng mới</span>
              <span class="material-icons float-right cursor-pointer"
                    @click="$modal.hide('create-chat-social-modal')">close</span>
            </div>
            <div class="mt-20px">
              <div class="option">
                <div>
                  <div class="heading-400">Tiêu chuẩn</div>
                  <div class="text-300-default">Mọi thành viên đều có thể nhìn thấy và tham gia đoạn chat này</div>
                </div>
                <md-radio v-model="chat_privacy" :value="'standard'"></md-radio>
              </div>
              <div class="option mt-20px">
                <div>
                  <div class="heading-400">Chỉ xem</div>
                  <div class="text-300-default">Chỉ quản trị viên có thể gửi tin nhắn. Tất cả thành viên chỉ được bày tỏ cảm xúc tin nhắn</div>
                </div>
                <md-radio v-model="chat_privacy" :value="'readonly'"></md-radio>
              </div>
              <div class="note">
                <span>Lựa chọn này sẽ không thay đổi được nữa</span>
              </div>
              <div class="mt-20px w_100 btn-wrapper">
                <button @click="$modal.hide('create-chat-social-modal')">Hủy</button>
                <button class="ml-10px btn-success create-chat" @click="createChatSocial">Tạo đoạn chat</button>
              </div>
            </div>
          </md-card-content>
    </modal>
  </div>
</template>

<script>

import GroupService from "../../../store/services/GroupService";
import PostService from "../../../store/services/PostService";

export default {
  name: 'create-chat-social-modal',
  computed: {
  },
  mounted() {

  },

  created() {
  },
  data: () => ({
    chat_privacy: null,
    groupId: null
  }),
  methods: {
    beforeOpen(event) {
      if (event.params.groupId) {
        this.groupId = event.params.groupId;
      }
    },
    createChatSocial(){
      GroupService.createChatSocical(this.groupId, {chat_privacy: this.chat_privacy})
      .then(res => {
        this.$router.push("/messenger/" + res.data.id);
        this.$modal.hide('create-chat-social-modal');
        wind
      });
    }
  },
}
</script>

<style lang="scss">
.create-chat-social-modal{
  .notification-setting__main {
    padding: 0.75rem;
    border-radius: 24px;
  }
  .vm--modal {
    border-radius: 24px;
  }
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn-wrapper {
    text-align: center;
    button {
      color: rgb(77, 77, 77);
      width: 159px;
      border: none;
      padding: 8px 47px;
      border-radius: 8px;
      cursor: pointer;
    }
    .btn-success {
      background: rgb(48, 169, 96);
      color: rgb(255, 255, 255);
    }
  }
  .create-chat{
    padding: 8px !important;
  }
  .note {
    font-weight: 500;
    color: #000;
    font-style: italic;
    padding-top: 10px;
  }
}

</style>
