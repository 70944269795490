<template>
  <div class="poll-message-container">
    <div class="polls">
      <div class="poll-question">
        <span>{{ mess.poll.question }}</span>
      </div>
      <div class="poll-list">
       <md-list>
        <div class="lists" v-for="(poll, index) in pollOptions" :key="index"
        :style="'background:linear-gradient(to right, ' + (poll.isChecked ?
             'rgba(86, 202, 118, 0.16) ' : 'rgb(241, 242, 244) ') + (totalVote == 0 ? 0 : ((poll.vote_ids.length / totalVote) * 100)) + '%, white '+ (totalVote == 0 ? 0 : ((poll.vote_ids.length / totalVote) * 100)) + '%)'"
        >
          <!-- <div class="checkbox-voted"></div> -->
          <div class="answer">
            <md-checkbox v-if="!isExpiredTime" class="md-success" @change="vote(index)" v-model="poll.isChecked"></md-checkbox>
            {{ poll.answer }}
          </div>
          <div class="user-answer">
            {{ poll.vote_ids.length }} bình chọn
            <!-- <md-avatar>
              <img class="avatar" src="/img/profile/avatar.png">
            </md-avatar> -->
          </div>
        </div>
        <md-list-item class="new-option-poll" v-if="mess.poll.is_add_option">
          <div class="answer-new">
            <input class="option-new" placeholder="Thêm lựa chọn..." v-model="answerNew" @keyup.enter="addOption" />
          </div>
        </md-list-item>
       </md-list>
      </div>
    </div>
  </div>
</template>
<script>
  import {MESSAGE_CHAT_TYPE} from "../../../../../const/const";
  import ChatService from "../../../../../store/services/ChatService";
  import moment from "moment";
  import PostService from "@/store/services/PostService";
  
  export default {
    data() {
      return {
        MESSAGE_CHAT_TYPE,
        user: this.$store.state.auth.user,
        answerNew: '',
        pollOptions: []
      }
    },
    name: "share-message-content",
    components: {},
    props: {
      mess: {
        type: Object,
        default: () => {
          return {};
        }
      },
    },
    computed: {
      totalVote() {
        let total = 0;
        for (let i = 0; i < this.pollOptions.length; i++) {
          total += this.pollOptions[i].vote_ids.length;
        }
        return total;
      },
      isExpiredTime() {
        if (this.mess.poll.expired_time != null && this.mess.poll.expired_time != '') {
          let expiredTimeString = moment(this.mess.poll.expired_time, 'yyyy-MM-DD HH:mm:ss');
          let currentDate = new Date();
          let expiredTime = new Date(expiredTimeString);
          if (expiredTime < currentDate) {
            return true;
          }
        }
        return false;
      }
    },
    mounted(){
      let currentUserId = this.$store.state.auth.user.id;
      for (let i = 0; i < this.mess.poll.data.length; i++) {
        let option = { ...this.mess.poll.data[i]};
        option.isChecked = false;
        if (option.vote_ids.length > 0 && option.vote_ids.includes(currentUserId)) {
          option.isChecked = true;
        }
        this.pollOptions.push(option);
      }
    },
    methods: {
      vote(index) {
        this.votePoll(this.pollOptions[index].answer, this.pollOptions[index].isChecked);
      },
      votePoll(poll, isVote){
        let params = {
          "answer": poll,
          "is_vote": isVote
        };

        ChatService.votePoll(this.mess.poll.id, params).then(res => {
          if (res.status) {
            let currentUserId = this.$store.state.auth.user.id;
            this.pollOptions = [];
            for (let i = 0; i < res.data.length; i++) {
              let option = { ...res.data[i]};
              option.isChecked = false;
              if (option.vote_ids.includes(currentUserId)) {
                option.isChecked = true;
              }
              this.pollOptions.push(option);
            }
          }
          // if(res.status){
          //   this.pollOptions.map((item) => item.isChecked = false);
          //   if(is_vote){
          //     poll.vote_ids.push(this.user.id);
          //   }else{
          //     var index = poll.vote_ids.indexOf(this.user.id);
          //     if (index !== -1) {
          //       poll.isChecked = true;
          //       poll.vote_ids.splice(index, 1);
          //     }
          //   }
          // }else{
          //   this.$store.dispatch("alerts/error", res.message);
          // }
        });
      },
      addOption(){
        if (this.answerNew.trim() == '') {
          return;
        }
        for (let i = 0; i < this.pollOptions.length; i++) {
          if (this.pollOptions[i].answer.toLowerCase() == this.answerNew.trim().toLowerCase()) {
            return;
          }
        }

        ChatService.addOptionPoll(this.mess.poll.id, {answer: this.answerNew}).then(res => {
          if (res.status) {
            let currentUserId = this.$store.state.auth.user.id;
            this.pollOptions = [];
            for (let i = 0; i < res.data.length; i++) {
              let option = {...res.data[i]};
              option.isChecked = false;
              if (option.vote_ids.includes(currentUserId)) {
                option.isChecked = true;
              }
              this.pollOptions.push(option);
              this.answerNew = '';
            }
          }else{
            this.$store.dispatch("alerts/error", res.message);
          }
        });
      },
      pollUpdate(poll){
        this.$modal.show('create-poll-chat-modal',{poll: this.mess.poll});
        // console.log(poll);
        // this.$emit('poll-update', poll);
      }
    }
  }
</script>

<style lang="scss">
  .poll-message-container {
    position: relative;
    width: 100%;
    // min-height: 230px;
    min-width: 400px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(233, 234, 236);
    box-sizing: border-box;
    box-shadow: rgba(47, 49, 54, 0.1) 0px 4px 8px;
    border-radius: 8px;
    padding: 16px;
    height: max-content;
    .new-option-poll{
      margin: 0;
    }
    .answer-new{
      width: 100%;
    }
    .poll-question {
      text-align: center;
      font-size: 20px;
    }
    .poll-list{
      margin-bottom: 10px;
      .lists {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        margin-top: 10px;
        border-radius: 5px;
        border: 1px solid rgb(233, 234, 236);
        align-items: center;
      }
      .option-new{
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        background: rgb(255, 255, 255);
        border: 1px solid rgb(222, 223, 226);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 12px;
        font-size: 16px;
        line-height: 24px;
        color: rgb(38, 40, 44);
        outline: none;
        width: 100%;
      }
      .md-list-item-container{
        &:hover{
          background: #fff !important;
        }
      }
      .new-option-poll{
        .md-list-item-content{
          border: none;
          padding: 0 !important;
        }
        .md-list-item-content{
          &:hover{
            background: #fff !important;
          }
        }
        
      }
      .md-list-item-content{
        &:hover{
          background: rgba(0, 0, 0, 0.12) !important;
        }
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        border: 1px solid rgb(233, 234, 236);
        box-sizing: border-box;
        border-radius: 8px;
        // padding: 8px 12px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        background: linear-gradient(to right, rgb(241, 242, 244) 0%, white 0%, white 100%);
        margin-top: 10px;
      }
    }
    .poll-add-option {
      text-align: center;
      font-size: 15px;
      span {
        border: 1px solid rgb(233, 234, 236);
        padding: 5px 10px;
        border-radius: 10px;
      }
    }
  }
</style>

