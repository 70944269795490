<template>
  <div class="post-file-list">
    <div class="post-file mb-10px" v-for="(file , index) in files" :key="file.id">
      <img src="/img/post/content/file.svg" class="file-image">
      <span class="file-text text-400-medium text-1-line" @click="openFile(file)">{{ file.name }}</span>
      <div class="file-option">
        <span class="file-type secondary_o400">.{{ file.extension }}</span>
        <span class="file-size text-400-default">{{ bytesToSize(file.file_size) }}</span>
        <a class="d-inline" v-if="isModal" @click="deleteFile(index)">
             <span class="file-btn-clear">&#10006;</span>
        </a>
        <a :href="file.url" class="d-inline file-download" v-else>
                    <span class="file-btn-download">
                      <img src="/img/post/content/dowload.svg">
                    </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "file-content",
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    isModal: {
      type: Boolean,
      default: false,
    }
  },
  data() {
  },
  methods: {
    bytesToSize(bytes) {
      let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    deleteFile(index) {
      localStorage.setItem("delete_file_index", index);
      window.dispatchEvent(new Event('storage'))
    },
    openFile(file) {
      if (file.extension == 'doc' || file.extension == 'docx') {
        localStorage.setItem("doc_file", file.path);
        window.open('/view-file?link=' + file.path, '_blank');
      } else {
        window.open(file.path, '_blank');
      }
    }
  },
};
</script>

<style lang="scss">
  .post-file-list {
    padding: 0 15px;
    .post-file {
      display: flex;
      border-radius: 4px;
      border: 1px solid #dcdcdc;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: #ececff;
      }

      .file-option {
        display: flex;

        .file-size {
          display: inline-block;
          width: 80px;
          text-align: right;
          margin-right: 16px;
        }
      }
    }
  }
</style>
