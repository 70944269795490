<template>
  <div class="righ-bar">
    <md-card class="company-info-wrapper background_neutral_white">
      <div class="company-info">
        <div class="company-name">
          {{headquarters.name}}
        </div>
        <div class="company-address text-400-default" v-html=headquarters.slogan>
        </div>
      </div>
      <div class="branch-list">
        <div class="branch-item" v-for="(branch, index) in branchList" :key="branch.id" @click="showBranchDetailModal(index)">
          <div class="branch-img-wrapper">
            <img :src="branch.avatar ? branch.avatar : groupImageDefault" alt="">
          </div>
          <div class="branch-info text-400-medium ">
              {{branch.name}}
          </div>
        </div>
      </div>
    </md-card>

    <md-card class="core-value-wrapper background_neutral_white">
      <div class="core-value-title text-400-medium">
        GIÁ TRỊ CỐT LÕI - AZA GROUP
      </div>
      <div class="core-value-item hover-link cursor-pointer" v-for="(item, index) in coreValues" :key="item.id" @click="showCoreValueModal(index)">
        <div class="cardinal-number">
          <div class="text-300-medium neutral_white">{{index + 1}}</div>
        </div>
        <div class="value">{{item.value}}</div>
        <div class="more-icon">
          <li class="fa fa-angle-right"></li>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
import SettingService from "../../../store/services/SettingService";
export default {
  name: "company-info",
  components: {
  },
  data() {
    return {
      headquarters: {},
      branchList: [],
      coreValues: [],
      groupImageDefault: '/img/group/group_image_low.jpg',
    }
  },

  mounted() {
    this.getCompanyInfo();
    this.getTeamBranch();
  },

  methods: {
    getCompanyInfo() {
      SettingService.getCompanyInfo().then(res => {
        if (res) {
          this.headquarters = res.company.headquarters;
          this.coreValues = res.core_values;
        }
      });
    },
    showCoreValueModal(index) {
      this.$modal.show('core-value-modal', {
        coreValues: this.coreValues,
        checkedIndex: index
      });
    },
    showBranchDetailModal(index) {
      this.branchList[index].avatar = this.branchList[index].avatar ? this.branchList[index].avatar : this.groupImageDefault;
      this.$modal.show('branch-detail-modal', {
        branchInfo: this.branchList[index]
      });
    },
    getTeamBranch(){
      SettingService.getBranchs().then(res => {
        if (res) {
          this.branchList = res.data;
        }
      });
    }
  }
};
</script>

<style lang="scss">

</style>
