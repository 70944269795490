<template>
  <div class="container-search-chat">
    <div class="container-input-search">
      <svg width="20px" height="20px"
           xmlns="http://www.w3.org/2000/svg" class="aza-Icon" role="img" viewBox="0 0 24 24">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.47 18.47a.75.75 0 0 1 1.06 0l2 2a.75.75 0 1 1-1.06 1.06l-2-2a.75.75 0 0 1 0-1.06ZM11 18.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm9-7.5a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              fill="#26282C"></path>
      </svg>
      <input name="search" type="text" v-model="search" placeholder="Tìm kiếm tin nhắn" autocomplete="off" class="input-search">
    </div>
    <div class="option-search" v-click-outside="onBlur">
     
<!--        <div class="search-datetime">-->
<!--          <div class="title" @click="toActivePopup('time')">-->
<!--            <span>Thời gian</span>-->
<!--            <md-icon>expand_more</md-icon>-->
<!--          </div>-->
<!--          <Transition name="fade" mode="out-in">-->
<!--            <div class="popup-search-time" v-if="datetime.show">-->
<!--              <div class="option-search-datetime">-->
<!--                  <div class="datetime">-->
<!--                    <div class="from_date">-->
<!--                      <label for="">Từ ngày</label>-->
<!--                      <input type="date" v-model="datetime.start_date" />-->
<!--                    </div>-->
<!--                    <div class="to_date">-->
<!--                      <label for="">Đến ngày</label>-->
<!--                      <input type="date" v-model="datetime.end_date" />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="button-box">-->
<!--                    <button type="submit" @click="onBlur">-->
<!--                      Hủy-->
<!--                    </button>-->
<!--                    <button type="submit" class="accept-time" @click="getMessageFilterTime">-->
<!--                      Xác nhận-->
<!--                    </button>-->
<!--                  </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </Transition>-->
<!--        </div>-->
        <div class="search-by-user">
          <div class="title" @click="toActivePopup('user')">
            <span>{{ choose_user }}</span>
            <md-icon>expand_more</md-icon>
          </div>
          <Transition name="fade" mode="out-in">
            <div class="popup-search-user" v-if="user.show">
              <div class="search-user-message">
                <div class="container-input-search">
                  <svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="aza-Icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.47 18.47a.75.75 0 0 1 1.06 0l2 2a.75.75 0 1 1-1.06 1.06l-2-2a.75.75 0 0 1 0-1.06ZM11 18.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm9-7.5a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" fill="#26282C"></path></svg>
                  <input name="search" v-model="searchMember" type="text" placeholder="Tìm kiếm tin nhắn" autocomplete="off" class="input-search">
                </div>
              </div>

              <div class="scroll-memberlist custom-scroll-bar">
                <div class="users" @click="getMessageByMember({id: 0})">
                  <div class="user-avatar">
                    <img src="/img/group/group_image_low.jpg" />
                  </div>
                  <div class="text-400-medium">
                    Tất cả thành viên
                  </div>
                </div>
                <div class="users" v-for="member in memberList" :key="member.id" @click="getMessageByMember(member)">
                  <div class="user-avatar">
                    <img :src="member.avatar.small_thumb || '/img/profile/avatar.png'" />
                  </div>
                  <div class="text-400-medium text-1-line">
                    {{ member.display_name || member.fullname }}
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      
    </div>
    <hr class="">
    <div class="search_keyword custom-scroll-bar">
      <div class="enter-keyword" v-if="messageList.length == 0">
        <span>Nhập từ khoá tìm kiếm</span>
      </div>
      <div class="flex-space-between search_lists" v-for="(mess, index) in messageList" :key="index" @click="scrollToMessage(mess.id)">
        <div class="search-avatar">
          <md-avatar>
            <img class="avatar" :src="mess.sender.thumbnail.small_thumb || '/img/profile/avatar.png'">
          </md-avatar>
        </div>
        <div class="" style="flex: 1 1 0%; flex-direction: column; align-items: stretch;">
          <div class="flex-space-between"><span
              class="text-300-semibold text-1-line">{{ mess.sender.display_name || mess.sender.fullname }}</span><span
              class="text-300-default">{{ getDate(mess) }}</span></div>
          <div ref="text_search" class="text-2-line text-300-default" style="max-width: 255px;" v-html="highlight(mess.text)">
            <!-- <b>@@@@sss</b>ss -->
            <!-- {{ mess.text }} -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatService from "../../../../../store/services/ChatService";
import moment from "moment";

export default {
  name: "SearchChat",
  props: {
    searchKeyword: {
      type: String,
      default: () => {
        return null;
      },
    },
  },
  data: function() {
    return {
      search: '',
      searchMember: '',
      choose_user: 'Tất cả thành viên',
      messageList: [],
      memberList: [],
      datetime: {
        show: false,
        start_date: '',
        end_date: ''
      },
      user: {
        show: false
      },
      params: {
        chat_group_id: this.$store.state.chat.chatGroupSelected.id,
        user_id: null,
        keyword: '',
        page: 1,
        per_page: 20
      },
      param_member: {
        group_id: this.$store.state.chat.chatGroupSelected.id,
        keyword: ''
      },
      time: null
    }
  },
  watch: {
    'search'(newV){
      this.onSearch('message');
    },
    'searchMember'(newV){
      this.onSearch('member');
    },
    'searchKeyword'(newV){
      this.search = newV;
      this.onSearch('message');
    }
  },
  created(){
    this.getMembers();
  },
  mounted() {
    if(this.searchKeyword){
      this.search = this.searchKeyword;
    }
  },
  methods: {
    searchMessage(){
      ChatService.searchMessageInGroup(this.params).then(res => {
          if (res.data) {
            this.messageList = res.data;
            // this.highlight();
          }
        });
    },

    onSearch(type){
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }

        this.timer = setTimeout(() => {
          if(type == 'member'){
            this.param_member.keyword = this.searchMember;
            this.getMembers();
          }else{
            this.params.keyword = this.search;
            this.searchMessage();
          }
        }, 500);
    },

    getMembers(){
      ChatService.getMembersGroup(this.param_member).then(res => {
        if(res.data){
          this.memberList = res.data;
        }
      });
    },

    toActivePopup(name){
      this.onBlur();
      if(name == 'time'){
        this.datetime.show = true
      }else{
        this.user.show = true
      }
    },

    getMessageByMember(member){
      this.choose_user = member.id == 0 ? 'Tất cả thành viên' : member.fullname;
      this.params.user_id = member.id == 0 ? null : member.id;
      this.searchMessage();
      this.onBlur();
    },

    getMessageFilterTime(){
      this.params.start_date = this.datetime.start_date;
      this.params.end_date = this.datetime.end_date;
      this.searchMessage();
      this.onBlur();
    },

    scrollToMessage(message_id){
      this.$emit('scrollToMessageId', message_id);
    },

    getDate(mess){
      return moment(mess.created_at).format('DD/MM')
    },

    onBlur(){
      this.datetime = {
        show: false,
        start_date: '',
        end_date: ''
      }
      
      this.user = {
        show: false
      }
    },

    highlight(text) {
      if(text == null) return;
      const searchTerm = "tìm kiếm";
      const text2 = "Đây là đoạn văn bản tìm kiếm trong JavaScript";
      const regex = new RegExp(this.search, 'gi');
      const highlightedText = text.replace(regex, '<span class="highlighted">$&</span>');

      const index = highlightedText.indexOf('<span class="highlighted">');
      const truncatedText = index > 20 ? `...${highlightedText.slice(index - 20)}` : highlightedText;
      return truncatedText;
      // Sử dụng `highlightedText` để hiển thị đoạn văn bản đã được làm nổi bật

      // console.log(this.$refs['text_search']);
      // r;
      // var inputText = text;
      // var innerHTML = inputText.innerHTML;
      // var index = innerHTML.indexOf(text);
      // if (index >= 0) { 
      // innerHTML = innerHTML.substring(0,index) + "<span class='highlight'>" + innerHTML.substring(index,index+text.length) + "</span>" + innerHTML.substring(index + text.length);
      // inputText.innerHTML = innerHTML;
      // }
//       var textToHighlight = document.getElementById("text-to-highlight").innerHTML;
// var highlightedText = textToHighlight.replace(/(Sau đó)/gi, "<span style='background-color: yellow;'>$1</span>");
// document.getElementById("text-to-highlight").innerHTML = highlightedText;
    }
  }
}
</script>

<style lang="scss">
.container-search-chat{
  .highlighted {
    color: #3ab67b;
    font-weight: bold;
  }
  .search-avatar{
    .avatar{
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  .enter-keyword{
    text-align: center;
  }
  .option-search{
    display: flex;
    justify-content: start;
    align-items: center;
    grid-gap: 15px;
    margin-bottom: 10px;
    .title{
      &:hover{
        cursor: pointer;
      }
      color: #3ab67b;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      .md-icon{
        color: #3ab67b;
        font-size: 16px !important;
      }
    }
  }
  .search-by-user, .popup-search-time{
    position: relative;
  }
  .option-search-datetime {
    position: absolute;
    top: 0;
    width: 300px;
    height: 100px;
    left: 0;
    box-shadow: 0px 8px 16px #2f31361a;
    padding: 8px;
    border: 1px solid #dadde1;
    z-index: 10;
    background: #fff;
    border-radius: 8px;
    /* visibility: hidden; */
    .datetime{
      display: flex;
      justify-content: space-between;
      grid-gap: 10px;
      height: 50px;
    }
    .button-box {
      width: max-content;
      margin: auto;
      padding-top: 6px;
      button {
        &:hover{
          cursor: pointer;
        }
        border: 1px solid #dfdcdc;
        padding: 5px 10px;
        margin-right: 5px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 600;
      }
      .accept-time{
        background: #376BFB;
        color: #fff;
      }
    }
    .from_date, .to_date {
      width: 50%;
      height: 25px;
      label{
        font-size: 13px;
      }
      input{
        width: 100%;
        height: 100%;
      }
    }
  }
  .popup-search-user{
    position: absolute;
    top: 25px;
    width: 300px;
    right: 0;
    box-shadow: 0px 8px 16px #2f31361a;
    padding: 8px;
    border: 1px solid #dadde1;
    z-index: 10;
    background: #fff;
    border-radius: 8px;
    // visibility: hidden;
    .container-input-search{
      margin-bottom: 5px;
    }
  }
  .selected{
    background: #F6F7F7;
  }
  .scroll-memberlist{
    height: 300px;
    overflow-y: auto;
  }
  .users {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 4px 8px;
    border-radius: 8px;
    &:hover{
      background: #F6F7F7;
      cursor: pointer;
      border-radius: 8px;
    }
    .user-avatar{
      padding-right: 8px;
      img{
        width: 30px;
        height: 30px;
        max-width: none;
        border-radius: 50%;
        object-fit: cover;
        position: relative;
      }
    }
  }
}
</style>