<template>
  <div class="md-layout group-detail-wrapper category__container category">
    <vue-element-loading :active="isLoad" is-full-screen/>
    <div class="w_100 group-tab-content">
      <div class="tab-content-main background_neutral_white p-0px">

        <template v-if="[1,2,119,141,845,921,920,869].includes(user.id)">
          <div class="md-double-line" style="border-bottom: 1px solid #ccc;">
            <md-card-actions>
              <md-button class="add-member-btn neutral_2 btn-primary btn-edit" v-if="!isEdit" @click="edit()">
                Chỉnh sửa
              </md-button>
              <md-button class="add-member-btn neutral_2 btn-primary btn-save" v-if="isEdit" @click="save()">
                Cập nhật
              </md-button>
            </md-card-actions>
          </div>
        </template>

        <md-content class="category-wrapper p-15px">
          <template v-if="isEdit">
            <div>
              <div class="mb-10px">
                <label class="required heading-400 neutral_2">Tên hạng mục</label>
                <input type="text" placeholder="Nhập tên hạng mục" required class="form-control form-info"
                       v-model="paramCategory.title">
              </div>
              <div class="mb-10px">
                <label class="heading-400 neutral_2">Mô tả hạng mục</label>
                <textarea type="text" placeholder="Nhập mô tả" required class="form-control form-info" style="min-height: 80px"
                       v-model="paramCategory.description"/>
              </div>
              <p class="required heading-400 neutral_2">Chi tiết</p>
              <div class="p-relative">
                <ckeditor v-model="paramCategory.content" :config="editorConfig">
                </ckeditor>
                <img class="img-emoji" src="/img/post/footer/smile.png" @click="toogleDialogEmoji">
                <VEmojiPicker class="p-absolute emoji" v-if="showDialogEmoji" @select="onSelectEmoji" v-click-outside="hiddenDialogEmoji"/>
              </div>

              <div class="md-layout mt-15px select-group-modal">
                <div class="upload-btn-wrapper">
                  <button class="btn btn-file cursor-pointer" :class="{'active': paramCategory.files.length > 0}">
                    <div class="btn-icon file"></div>
                    Thêm file
                    <input type="file" class="custom-input cursor-pointer"
                           @click="selectUpload()"
                           name="files"
                           @change="onFileChange"
                           multiple
                           accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf">
                  </button>
                </div>
              </div>
              <div class="post-layout m-0px p-0px pt-15px" style="max-width: 100%">
                <div class="post-content">
                  <md-card-content class="p-0px">
                    <file-content :files=medias></file-content>
                  </md-card-content>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="mt-10px">
              <md-list-item class="header-category ml-0px">
                <div class="md-list-item-text">
                  <div class="heading-600 neutral_1 text-center">{{ category.title }}</div>
                  <span class="mt-10px neutral_2 text-center" v-if="!!category.description">{{ category.description }}</span>
                  <div class="line"></div>
                </div>
              </md-list-item>
              <div v-if="category" class="category-content my-15px" v-html="category.content"></div>
              <template v-if="medias.length > 0">
                <div class="text-400-semibold">Tài liệu đính kèm</div>
                <div class="file-list-main w_100 mt-10px">
                  <file-content :files="medias"></file-content>
                </div>
              </template>
            </div>
          </template>
        </md-content>
      </div>
    </div>
  </div>
</template>

<script>

import FileContent from "./../../../Components/Post/Content/FileContent";
import CategoryService from "../../../../store/services/CategoryService";
import moment from "moment";

export default {
  components: {FileContent},

  mounted() {
    window.addEventListener("storage", this.storageListener);
  },

  created() {
    this.$store.dispatch("getCategories");
    this.category = this.$store.state.category.category;
    this.getCategory();
    if (this.$route.query.type && this.$route.query.type == 'edit') {
      this.isEdit = true;
      localStorage.setItem("create_post_modal_open", true);
    }
  },

  watch: {
    '$route.params.category_id'(to, from) {
      this.categoryId = to;
      this.category = this.$store.state.category.category
      if (this.$route.query.type && this.$route.query.type == 'edit') {
        this.isEdit = true;
        localStorage.setItem("create_post_modal_open", true);
      } else {
        this.isEdit = false;
        localStorage.removeItem("create_post_modal_open");
      }
      this.getCategory();
    },
    '$store.state.category.category': function (newVal, oldVal) {
      this.category = newVal
      this.getCategory();
    },
  },

  destroyed() {
    localStorage.removeItem("create_post_modal_open");
    window.removeEventListener("storage", this.storageListener);
  },

  data() {
    return {
      category: {},
      isLoad: true,
      categoryId: this.$route.params.category_id,
      paramCategory: {
        "title": '',
        "content": '',
        "slug": '',
        "files": [],
        "thumbnail": [],
        "icon": '',
        "color": '',
        "parent_id": 0,
        'remove_file_ids': []
      },
      showDialogEmoji: false,
      medias: [],
      files: [],
      isEdit: false,
      user: this.$store.state.auth.user,
      editorConfig: {
        toolbar: [
          [ 'Source' ],
          [ 'Styles', 'Format', 'Font', 'FontSize' ],
          [ 'Bold', 'Italic' ],
          [ 'Undo', 'Redo' ],
          ['Link'],
          ['numberedList', 'bulletedList']
          // [ 'About' ]
        ]
      }
    };
  },

  methods: {
    getCategory() {
      this.paramCategory.description = this.category.description;
      this.paramCategory.color = this.category.color;
      this.paramCategory.icon = this.category.icon;
      this.paramCategory.slug = this.category.slug;
      this.paramCategory.content = this.category.content;
      this.paramCategory.parent_id = this.category.parent_id;
      this.paramCategory.description = this.category.description;
      CategoryService.getDetailCategory({category_id: this.categoryId}).then(res => {
        if (res) {
          this.paramCategory.files = res.data.files;
          this.paramCategory.title = res.data.title;
          this.medias = res.data.files;
          this.files = res.data.files;
        }
      });
      this.isLoad = false;
    },

    edit() {
      this.isEdit = true;
      localStorage.setItem("create_post_modal_open", true);
    },

    save() {
      this.updateCategory(this.paramCategory);
      this.isLoad = true;
    },

    async updateCategory(data) {
      const formData = new FormData();

      formData.append("content", data.content);
      formData.append("title", data.title);
      formData.append("icon", data.icon);
      formData.append("color", data.title);
      formData.append("slug", data.slug);
      formData.append("description", data.description);

      for (let i = 0; i < this.files.length; i++) {
          formData.append("files[]", this.files[i]);
      }

      for (let x = 0; x < data.remove_file_ids.length; x++) {
        formData.append("remove_file_ids[]", data.remove_file_ids[x]);
      }


      await CategoryService.updateCategory(this.categoryId, formData).then(res => {
        this.isLoad = false;
        if (res && res.status) {
          this.$store.dispatch("alerts/success", 'Bạn cập nhật hạng mục thành công.');
          this.$modal.hide('create-group-modal');
          this.category.content = data.content
          this.$router.push("/category/" + this.categoryId);
        } else {
          let html = ''
          if (res.data) {
            for (let i = 0; i < res.data.length; i++) {
              html += '<p>' + res.data[i][0] + '</p>';
            }
          }
          this.$store.dispatch("alerts/error", 'Bạn tạo hạng mục không thành công. Nguyên nhân : ' + res.message + html);
        }
      });
    },

    toogleDialogEmoji() {
      this.showDialogEmoji = !this.showDialogEmoji;
    },

    onSelectEmoji(emoji) {
      this.paramCategory.content += emoji.data;
    },

    hiddenDialogEmoji() {
      this.showDialogEmoji = false
    },

    selectUpload() {
      if (this.files.length > 0) {
        return;
      }
      // this.medias = [];
      this.files = [];
    },

    // removeFileUpload() {
    //   this.medias = [];
    //   this.files = [];
    // },

    onFileChange(e) {
      let selectedFiles = e.target.files;
      let files = [];
      let vm = this;

      for (let i = 0; i < selectedFiles.length; i++) {
        files.push(selectedFiles[i]);
        vm.medias.push({
          name: selectedFiles[i].name,
          file_size: selectedFiles[i].size,
          extension: selectedFiles[i].name.split('.').pop(),
          url: null,
          id: null
        });
      }

      this.files = Object.values(e.target.files);
    },

    storageListener() {
      let index = localStorage.getItem("delete_file_index");

      if (index) {
        if (this.medias[index].id != null) {
         this.paramCategory.remove_file_ids.push(this.medias[index].id);
          this.medias.splice(index, 1);
        }
        if(this.medias[index].id == null){
          this.files.splice((index), 1);
        }
        this.medias.splice(index, 1);
        localStorage.removeItem("delete_file_index");
      }
    },
    fromNow(date) {
      return moment(date).format('DD-MM-YYYY hh:mm:ss');
    },
  }
};
</script>

<style lang="scss">

.category {
  .img-emoji {
    position: absolute;
    width: 22px !important;
    cursor: pointer;
    right: 10px;
    bottom: 10px;
  }

  .emoji {
    top: 45px;
  }

  .add-member-btn {
    background-color: #FFD700;
  }

  .btn-edit, .btn-save{
    padding: 5px 10px;
  }

  .btn-save {
    background-color: #FFD700 !important;
    border: 1px solid #FFD700;
    color: #101840 !important;
  }

  .btn-stroke .md-ripple{
    padding: 13.5px !important;
  }
  .category-wrapper {
    .header-category {
      .md-list-item-content {
        padding: 0px !important;
        .md-list-item-text{
          align-items: center;
        }
      }
      .line {
        width: 60px;
        height: 20px;
        border-bottom: 3px solid #D8DAE5;
      }
    }
    .category-content {
      line-height: inherit;
      p {
        margin: 8px 0;
      }
      min-height: 40px;
    }
  }
}


</style>

