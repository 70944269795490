<template>
  <div>
    <modal name="list-message-history-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="create-group-modal modal-custom list-message-history-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Danh sách lịch sử tin nhắn</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('list-message-history-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <div class="history-list">
        <div class="history-item" v-for="item in historyList" :key="item.time">
          <div class="text-300-semibold">{{ item.time }}</div>
          <div class="text-400-default mb-5px">{{ item.text }}</div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import EmployeeService from "../../../store/services/EmployeeService";
import SettingService from "../../../store/services/SettingService";
import {GROUP_CHAT_TYPE, CREATE_POST_LEVEL, MODEL_TYPE, POST_TYPE} from "../../../const/const";
import PostService from "../../../store/services/PostService";
import ChatService from "../../../store/services/ChatService";
import moment from "moment";

export default {
  name: 'member-list-modal',
  computed: {},
  mounted() {

  },
  watch: {},
  data() {
    return {
      historyList: [],
    };
  },
  methods: {
    beforeOpened(event) {
      this.historyList = event.params.historyList;
    },

    beforeClosed(event) {
    },
  }

}
</script>

<style lang="scss">
.list-message-history-modal{
  padding: 15px;
  .md-list-item-text{
    border-bottom: 1px solid #cccccc;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .history-list {
    min-height: 150px;
    padding: 10px 15px;
    .history-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 5px 0;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
