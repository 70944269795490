<template>
  <div class="sitcker-manage-container">
    <modal name="sticker-manage-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="modal-custom sticker-manage-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
    <template v-if="tabActive == 1">
      <md-card class="modal-header-custom modal-header-custom-store">
          <span class="heading-500 text-header-modal">Kho Sticker</span>
        <md-dialog-title>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('sticker-manage-modal')">close</span>
        </md-dialog-title>
      </md-card>
      <md-card-content>
        <div class="header-sticker d-flex-center">
          <!-- <div class="package-title">
            <div class="new-package title-tab">
              <a class="activeTab">Nổi bật</a>
            </div>
            <div class="outstanding-package title-tab">
              <a>
                Mới nhất
              </a>
            </div>
          </div> -->
          <div class="management-package cursor-pointer"  @click="switchTab(2)">
            <img src="/img/chat/icon/gear-setting.svg" alt="" /> <span>Quản lý bộ sưu tập</span>
          </div>
        </div>
        <md-content class="md-scrollbar">
          <div class="packages-container">
              <div class="containers" v-for="(list, index) in packageList" :key="index">
              <div class="package-card-title d-flex-center">
                <div class="title-package">{{ list.title }}</div>
                <div class="status-install uninstall">Đã thêm</div>
              </div>
              <div class="package d-flex-center">
                <div class="image-avatar">
<!--                  <img src="https://gapo-work-files.statics.pancake.vn/sticker/origin/76373e82-2fa2-4add-a1e3-f7e561b7070d.png">-->
                  <img :src="list.stickers[0] ? list.stickers[0].image_url : 'https://gapo-work-files.statics.pancake.vn/sticker/origin/76373e82-2fa2-4add-a1e3-f7e561b7070d.png'">
                </div>
                <div class="list-package-demo">
                  <div class="item-package" v-for="(sticker, index) in list.stickers" :key="sticker.id" v-if="index < 6">
<!--                    <img src="https://gapo-work-files.statics.pancake.vn/sticker/origin/76373e82-2fa2-4add-a1e3-f7e561b7070d.png">-->
                    <img :src="sticker.image_url">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </md-content>
      </md-card-content>
    </template>
    <template v-else>
      <md-card class="modal-header-custom">
        <md-dialog-title class="d-flex-center">
          <div class="d-flex-center">
            <span class="material-icons cursor-pointer mr-10px"
                  @click="switchTab(1)">keyboard_backspace</span>
            <span class="neutral_1 heading-500 text-header-modal">Quản lý bộ sưu tập</span>
          </div>
          <span class="material-icons float-right cursor-pointer" @click="$modal.hide('sticker-manage-modal')">close</span>
        </md-dialog-title>
      </md-card>
      <md-card-content class="container-management-list">
        <div class="management-description">Kéo để sắp xếp vị trí các bộ sticker</div>
        <md-content class="md-scrollbar">
          <!-- <draggable
        class="list-group"
        tag="ul"
        v-model="storeManagement"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <li
            class="list-group-item"
            v-for="(element, index) in storeManagement"
            :key="index"
          >
            <div class="package-management d-flex-center">
              <div class="title-management d-flex-center">
                <div class="avatar-sticky">
                  <img src="https://gapo-work-files.statics.pancake.vn/sticker/origin/76373e82-2fa2-4add-a1e3-f7e561b7070d.png" alt="">
                </div>
                <div class="stickey-name">
                  {{ element.title }}
                </div>
              </div>
              <div class="button-delete">
                <md-icon>delete</md-icon>
              </div>
            </div>
          </li>
        </transition-group>
          </draggable> -->
        </md-content>
      </md-card-content>
    </template>
    </modal>
  </div>
</template>

<script>
//  import draggable from 'vuedraggable'
 import ChatService from "../../../store/services/ChatService";
 const message = [
  "vue.draggable",
  "draggable",
  "component",
  "for",
  "vue.js 2.0",
  "based",
  "on",
  "Sortablejs",
  "ReactJs",
  "AngularJs"
];

export default {
  name: 'sticker-manage-modal',
  components: {
    // draggable,
  },
  data() {
    return {
      packageList: [],
      storeManagement: [],
      list: message.map((name, index) => {
        return { name, order: index + 1 };
      }),
      drag: false,
      tabActive: 1
    }
  },
  props: {
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  created() {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    beforeOpened(event){
      this.tabActive = event.params.tab ?? 1;
      this.getPackages();
    },

    beforeClosed(){

    },

    switchTab(tab){
      this.tabActive = tab;
    },

    getPackages(){
      const formData = new FormData();
      formData.append("per_page", 60);
      formData.append("user_id", this.$store.state.auth.user.id);
      formData.append("page", 1);
      // const formData = {
      //   per_page: 60,
      //   user_id: this.$store.state.auth.user.id,
      //   page: 1
      // };
      ChatService.getPackages(formData).then(res => {
        // console.log(res);
        if(res.data){
          this.packageList = res.data;
          this.storeManagement = res.data;
        }
      });
    },

    deletePackages(){
      const formData = new FormData();
      formData.append("per_page", 60);
      formData.append("user_id", this.$store.state.auth.user.id);
      formData.append("page", 1);
      // const formData = {
      //   per_page: 60,
      //   user_id: this.$store.state.auth.user.id,
      //   page: 1
      // };
      ChatService.getPackages(formData).then(res => {
        // console.log(res);
        if(res.data){
          this.packageList = res.data;
          this.storeManagement = res.data;
        }
      });
    }
  },

}
</script>

<style lang="scss">
.sitcker-manage-container{
  .d-flex-center{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .modal-header-custom-store{
    height: 112px;
    background: url(https://www.gapowork.vn/assets/images/sticker-store-bg.svg) 100% 50% no-repeat rgb(48, 169, 96);
    .text-header-modal {
      font-size: 35px !important;
      color: #fff;
      position: absolute;
      top: 50%;
      transform: translate(32px, -50%)
    }
    .md-dialog-title{
      border: unset;
      .material-icons{
        background: #ffffffa1;
          border-radius: 50%;
      }
    }
  }
  .md-card-content{
    padding: 0 !important;
    .header-sticker{
      padding: 20px 10px;
      .management-package {
        background: #F1F2F4;
        padding: 10px 15px;
        border-radius: 10px;
        font-weight: 600;
      }
      .title-tab{
        margin: 0px 5px;
        display: inline-block;
        position: relative;
        width: 64px;
        text-align: center;
        height: 30px;
        a{
          &:hover{
            cursor: pointer;
          }
          color: #000000;
        }
        .activeTab {
          &::after{
            content: "";
            width: 100%;
            height: 4px;
            background: rgb(26, 26, 26);
            border-radius: 5px;
            position: absolute;
            bottom: 0px;
            left: calc(50% - 32px);
          }
        }
      }
      .package-title{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        grid-gap: 10px;
      }
    }
    .md-scrollbar{
      width: 100%;
      height: 500px;
      min-height: 400px;
      overflow: auto;
      background: rgb(245, 245, 245);
      padding: 10px 0 10px 10px;
    }
    .packages-container{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .containers{
        background: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
        border-radius: 12px;
        width: 320px;
        max-width: calc(50% - 16px);
        height: 172px;
        margin-bottom: 12px;
        margin-right: 12px;
        padding: 10px;
        .image-avatar {
          width: 84px;
          height: 84px;
          margin-right: 8px;
          min-width: 84px;
        }
        .list-package-demo {
          display: flex;
          flex-wrap: wrap;
          margin-right: -4px;
          .item-package {
            width: 44px;
            height: 44px;
            border-radius: 8px;
            overflow: hidden;
            margin: 0px 4px 4px 0px;
          }
        }
        .status-install {
          color: rgb(77, 77, 77);
          border-radius: 4px;
          padding: 0px 4px;
          height: 24px;
          display: flex;
          border: none;
          align-items: center;
          justify-content: center;
          line-height: 16px;
        }
        .uninstall{
          background: rgb(231, 231, 231);
        }
        .installed{
          background: rgb(48, 169, 96);
          color: #ffffff;
        }
      }
    }
  }
  .container-management-list{
    .md-scrollbar{
      padding: 0;
      background: unset;
    }
    .management-description {
      font-size: 12px;
      line-height: 16px;
      color: rgb(128, 128, 128);
      height: 30px;
      background: rgb(231, 231, 231);
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      margin-bottom: 8px;
    }
    .package-management {
      &:hover{
        background: rgb(245, 245, 245);
        border-radius: 8px;
      }
      width: 100%;
      padding: 6px 8px;
      background: rgb(255, 255, 255);
      .avatar-sticky {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
      .button-delete{
        .md-icon {
          color: red !important;
        }
      }
    }
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group {
    min-height: 20px;
    line-height: 35px;
  }
  .list-group-item {
    cursor: grab;
    padding: 0 10px;
  }
  .list-group-item i {
    cursor: pointer;
  }
}
</style>