<template>
  <div class="pinned-wrapper" v-if="pinListMessage.length !== 0">
    <div class="pinned-item item-pinned-header">
      <div class="pinned-highlight"></div>
      <div class="pinned-content" @click="scrollToMessage(pinListMessage[0].id)">
        <div class="pinned-thumb">
          <md-icon>stars</md-icon>
        </div>
        <div class="pinned-text">
          <div class="text-400-semibold">Tin nhắn đã ghim ({{ pinListMessage.length }})</div>
          <div class="text-400-medium text-1-line">
            <span class="mr-5px" style="color: #0054db">{{ pinListMessage[0].sender.fullname }} :</span>
            <template v-if="pinListMessage[0].type === MESSAGE_CHAT_TYPE.FILE">
              <span class="text"><md-icon>description</md-icon> File</span>
            </template>
            <template v-else-if="pinListMessage[0].type === MESSAGE_CHAT_TYPE.IMAGE">
              <span class="text"><md-icon>image</md-icon> Hình ảnh</span>
            </template>
            <template v-else-if="pinListMessage[0].type === MESSAGE_CHAT_TYPE.VIDEO">
              <span class="text"><md-icon>videocam</md-icon> Video</span>
            </template>
            <template v-else>
              <span class="text">{{ pinListMessage[0].text }}</span>
            </template>
          </div>
        </div>
      </div>
      <div class="pinned-actions">
        <button class="cursor-pointer pinned-action unpin-action pin-header"
          type="button" @click="unPinMessage(pinListMessage[0].id)">
          <md-icon>close</md-icon>
        </button>
        <button class="cursor-pointer text-300-semibold number-pinned" v-if="pinListMessage.length > 1"
          type="button" @click="() => showListPin = !showListPin">
          {{pinListMessage.length - 1}} ghim khác <md-icon>arrow_drop_down</md-icon>
        </button></div>
    </div>
    <div class="w-100 position-absolute collapse show" style="transition-duration: 350ms;" v-show="showListPin">
      <div class="pinned-list">
        <div class="gapo-scroll gapo-scroll-overlay">
          <div class="pinned-item item-in-list" :key="pin.id" v-for="(pin, index) in pinListMessage" v-if="index > 0">
              <div class="pinned-content" @click="scrollToMessage(pin.id)">
                <div class="pinned-thumb">
                  <md-icon>stars</md-icon>
                </div>
                <div class="pinned-text">
                  <div class="text-400-semibold">{{ pin.sender.fullname }}</div>
                  <div class="text-400-medium">
                    <template v-if="pin.type === MESSAGE_CHAT_TYPE.FILE">
                      <span class="text"><md-icon>description</md-icon> File</span>
                    </template>
                    <template v-else-if="pin.type === MESSAGE_CHAT_TYPE.IMAGE">
                      <span class="text"><md-icon>image</md-icon> Hình ảnh</span>
                    </template>
                    <template v-else-if="pin.type === MESSAGE_CHAT_TYPE.VIDEO">
                      <span class="text"><md-icon>videocam</md-icon> Video</span>
                    </template>
                    <template v-else>
                      <span class="text-1-line text">{{ pin.text }}</span>
                    </template>
                  </div>
                </div>
              </div>
              <div class="pinned-actions">
                <button class="cursor-pointer pinned-action pin-action"
                  type="button" @click="pinMessagePriority(pin.id)">
                  <md-icon>push_pin</md-icon>
                </button>
                <button class="cursor-pointer pinned-action"
                        type="button" @click="unPinMessage(pin.id)">
                  <md-icon>close</md-icon>
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatService from "../../../../store/services/ChatService";
import {MESSAGE_CHAT_TYPE, TEXT_CHAT_SYSTEM} from "../../../../const/const";

export default {
  name: "pin-message",
  props: {
    
  },
  mounted() {
  },
  created(){
    this.getPinMessage();
  },
  data(){
    return {
      showListPin: false,
      pinListMessage : [],
      MESSAGE_CHAT_TYPE
    }
  },
  methods: {
    getPinMessage(){
      ChatService.getPinListMessage({group_id : this.$route.params.messenger_id}).then(res => {
          if (res) {
            this.pinListMessage = res.data;
          }
        });
    },

    hanldeMessage(message_id, pin_type){
      switch (pin_type) {
        case TEXT_CHAT_SYSTEM.PIN_MESSAGE:
          this.pinMessage(message_id);
          break;
        case TEXT_CHAT_SYSTEM.UNPIN_MESSAGE:
          this.unPinMessage(message_id);
          break;
      }
    },

    pinMessage(message_id){
      ChatService.pinMessage({message_id : message_id}).then(res => {
        if (res) {
          this.pinListMessage.push(res.data);
        }
      });
    },

    unPinMessage(message_id){
      this.$modal.show('dialog', {
        title: 'BỎ GHIM TIN NHẮN',
        text: 'Bạn có chắc muốn bỏ ghim tin nhắn',
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Đồng ý',
            handler: () => {
              ChatService.unPinMessage({message_id : message_id}).then(res => {
                if (res) {
                  this.pinListMessage.splice(this.pinListMessage.findIndex((item) => item.id == message_id), 1);
                }
              });
              this.$modal.hide('dialog')
            }
          }
        ]
      });
    },
    
    pinMessagePriority(message_id){
      ChatService.pinMessagePriority({message_id : message_id}).then(res => {
        if (res) {
          this.getPinMessage();
        }
      });
    },

    scrollToMessage(message_id){
      this.$emit('scroll-message', message_id)
    }
  }
}
</script>

<style lang="scss">
.pinned-wrapper {
    width: 100%;
    z-index: 1040;
  padding: 4px 0;
  border-bottom: 1px solid #eaeaea;
  background-color: #fff;
    .item-pinned-header {
      .pin-header{
        visibility: hidden;
      }
      &:hover .pin-header{
        visibility: visible;
      }
    }
    .pinned-item {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 56px;
      position: relative;
      width: 100%;
      .pinned-content{
        align-items: center;
        display: flex;
        flex-grow: 1;
        min-width: 0;
        padding-left: 10px;
        .pinned-thumb {
            align-items: center;
            background-color: #fff;
            border-radius: 8px;
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            overflow: hidden;
            width: 44px;
          height: 44px;
          .md-icon {
            color: #30A960;
            font-size: 30px!important;
          }
        }
        .pinned-text {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            min-width: 0;
            padding-left: 4px;
            .pinned-subtitle {
                font-size: 13px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
          .text {
            color: #626262;
          }
          .md-icon {
            font-size: 18px !important;
            width: auto !important;
            height: auto !important;
            min-width: auto;
          }
        }
      }
      .pinned-actions {
        align-items: center;
        display: flex;
        padding-right: 12px;

        .md-icon {
          width: auto;
          height: auto;
          min-width: auto;
          font-size: 20px !important;
          color: #0054db;
        }
        .pinned-action {
          align-items: center;
          border: none;
          border-radius: 16px;
          display: flex;
          height: 32px;
          width: 32px;
          justify-content: center;
          margin-left: 8px;
          transition: background-color .3s ease;
        }
        .number-pinned {
          margin-left: 8px;
          padding: 4px 8px;
          border-radius: 4px;
          border: 1px solid #0054db;
          color: #0054db;
          background: white;
          width: 134px;
          .md-icon {
            color: #0054db !important;
          }
        }
      }
    }
    .pinned-list {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        height: auto;
        max-height: 256px;
      border-top: 1px solid #F0F0F0;
      overflow-y: auto;
      .pinned-item:not(:first-child) {
        border-top: 1px solid #F0F0F0;
      }
        .pinned-actions{
          visibility: hidden;
        }
        &:hover .pinned-actions{
          visibility: visible;
        }
    }
}

</style>