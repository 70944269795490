<template>
  <div class="member-list-wrapper custom-scroll-bar-2">
    <vue-element-loading :active="isLoad" is-full-screen/>
    <div class="ml-btn-wrapper">
      <div class="d-flex">
        <div>
          <button class="btn btn-circle" :class="{active: memberData.status == null}" @click="memberData.status = null">Tất cả</button>
          <button class="btn btn-circle ml-10px" :class="{active: memberData.status == 1}" @click="memberData.status = 1">Hoạt động</button>
          <button class="btn btn-circle ml-10px" :class="{active: memberData.status == 0}" @click="memberData.status = 0">Không hoạt động</button>
<!--          <button class="btn btn-circle ml-10px">Vô hiệu hóa</button>-->
        </div>
        <div class="ml-search-container">
          <div class="input-container">
            <md-icon>search</md-icon>
            <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group" v-model="memberData.searchMemberInput" @keyup="getMembers(true)">
          </div>
        </div>
      </div>
    </div>
    <div class="member-list-tbl">
      <vue-loadmore
          :on-loadmore="onLoadForMember"
          :finished="memberData.finished"
          :loading-text="'Đang tải'"
          :finished-text="''">
      <member-table :member-list="memberData.memberList" />
      </vue-loadmore>
    </div>
  </div>
</template>

<script>

import EmployeeService from "../../../store/services/EmployeeService";
import MemberTable from "./MemberTable";

export default {

  components: {MemberTable},

  data() {
    return {
      routeName: this.$route.name,
      memberData: {
        searchMemberInput: "",
        memberList: [],
        memberPage: 1,
        finished: false,
        lastPage: 1,
        status: null,
      },
      isLoad: true,
    }
  },

  mounted() {
    this.getMembers();
  },

  methods: {
    async getMembers(isSearch) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (isSearch) {
          this.memberData.memberPage = 1;
          this.memberData.lastPage = 1;
          this.memberData.memberList = [];
        }

        let params = {
          page: this.memberData.memberPage,
          per_page: 25,
          member_type: 2,
          keyword: this.memberData.searchMemberInput,
        }
        if (this.routeName == 'department_members') {
          if (this.$route.params.department_id != 'ALL') {
            params.department_id = this.$route.params.department_id;
          }
        } else if (this.routeName == 'position_members') {
          params.position_id = this.$route.params.position_id;
        }
        if (this.memberData.status != null) {
          params.status = this.memberData.status;
        }
        EmployeeService.getEmployees(params).then(res => {
          this.isLoad = false;
          if (res && res.data && res.data.length > 0) {
            this.memberData.lastPage = res.meta.last_page
            this.memberData.memberList = this.memberData.memberList.concat(res.data);
            this.memberData.totalMember = res.meta.total;
          }
        });
      }, 800);
    },
    onRefreshForMember(done) {
      this.memberData.memberList = [];
      this.memberData.memberPage = 1;
      this.memberData.finished = false;
      this.getMembers();
      done();
    },
    onLoadForMember(done) {
      if (this.memberData.memberPage < this.memberData.lastPage) {
        this.memberData.memberPage++
        this.getMembers();
      } else {
        this.memberData.finished = true;
      }
      done();
    },
    refreshDataFilter() {
      this.memberData = {
        searchMemberInput: "",
        memberList: [],
        memberPage: 1,
        finished: false,
        lastPage: 1,
        status: null,
      };
    }
  },

  watch: {
    '$route.params.position_id': function (newVal, oldVal) {
      this.refreshDataFilter();
      this.getMembers(true);
    },
    '$route.params.department_id': function (newVal, oldVal) {
      this.refreshDataFilter();
      this.getMembers(true);
    },
    '$route.name': function (newVal, oldVal) {
      this.routeName = this.$route.name;
    },
    'memberData.status': function (newVal, oldVal) {
      this.getMembers(true);
    },
  }
};

</script>


<style lang="scss">
 .member-list-wrapper {
   padding: 15px 35px;
   height: 100%;
   .vuejs-refresh-head {
     display: none;
   }
   .d-flex {
     justify-content: space-between;
   }

   .input-container {
     border-radius: 4px !important;
     padding-left: 10px !important;
     background-color: white !important;
     margin-bottom: 0 !important;
     .md-icon {
       margin: auto !important;
     }
   }

   .vuejs-loadmore-finished-text {
     display: none !important;
   }

   .ml-btn-wrapper {
     height: 10%;
   }
 }
 .content .member-list-wrapper {
   height: 100%;
   overflow-y: auto;
 }
</style>