<template>
  <div class="md-layout">
   News
  </div>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
    };
  },

  methods: {
  }
};
</script>
