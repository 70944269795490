<template>
  <div class="show-comment-image">
    <modal name="show-comment-image"
           :click-to-close="false"
           width="100%"
           height="100%"
           class="select-group-modal modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading spinner="bar-fade-scale"/>
      <md-card-content>
        <img class="post-img" :src="imgUrl" alt="">
      </md-card-content>
      <span class="material-icons close-modal cursor-pointer" @click="$modal.hide('show-comment-image')">close</span>
    </modal>
  </div>
</template>

<script>

export default {
  name: 'show-comment-image',
  data() {
    return {
      imgUrl: ''
    }
  },
  methods: {
    beforeOpened(event) {
      this.imgUrl = event.params.imgUrl;
      // this.$store.commit("updateModalIsOpen", true);
    },
    beforeClosed(event){
      // this.$store.commit("updateModalIsOpen", false);
    },
  },
}

</script>

<style lang="scss">
.show-comment-image {
  .modal-custom {
    .md-card-content {
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 100%;
      height: 100%;
    }
  }
  .post-img {
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: scale-down;
    background: black;
  }
  .close-modal {
    position: absolute;
    right: 20px;
    top: 20px;
    color: white;
  }
}
</style>
