<template>
  <div class="group-list-wrapper custom-scroll-bar-2">
    <vue-element-loading :active="isLoad" is-full-screen/>
    <div class="ml-btn-wrapper">
      <div class="d-flex">
        <div>
          <button class="btn btn-circle" :class="{active: groupParams.status == null}" @click="groupParams.status = null">Tất cả</button>
          <button class="btn btn-circle ml-10px" :class="{active: groupParams.status == 1}" @click="groupParams.status = 1">Hoạt động</button>
          <button class="btn btn-circle ml-10px" :class="{active: groupParams.status == 0}" @click="groupParams.status = 0">Không hoạt động</button>
<!--          <button class="btn btn-circle ml-10px">Vô hiệu hóa</button>-->
        </div>
        <div class="ml-search-container">
          <md-icon>search</md-icon>
          <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group" v-model="groupParams.searchGroupInput" @keyup="getGroups(true)">
        </div>
      </div>
      <div class="d-flex-space-between mt-20px">
        <div>
          <span class="text-400-medium">
            Loại nhóm
          </span>
          <select class="group-type-select cursor-pointer" v-model="groupParams.type">
            <option value="">Tất cả</option>
            <option class="cursor-pointer" :value="item.value" v-for="item in groupTypeList" :key="'group_type_' + item.value">{{item.text}}</option>
          </select>
        </div>
        <pagination-template :class="'mb-10px float-right'" :params="groupPaging" @change-params="changePagingParam" />
      </div>
    </div>
    <div class="member-list-tbl">
      <group-table :member-list="groupParams.groupList" />
    </div>
    <group-member-list-modal @approve-request="handleApproveRequest" @remove-from-group="removeFromGroup" />
  </div>
</template>

<script>

import AdminManagementService from "../../../../store/services/AdminManagementService";
import GroupTable from "./GroupTable";
import PaginationTemplate from "../../../Components/PaginationTemplate";
import {
  BRANCH_GROUP_TYPE,
  BRANCH_GROUP_TYPE_NAME,
  CLANDESTINE_GROUP_TYPE, CLANDESTINE_GROUP_TYPE_NAME, COMPANY_WALL_GROUP_TYPE, COMPANY_WALL_GROUP_TYPE_NAME,
  GROUP_TYPE,
  PRIVATE_GROUP_TYPE,
  PRIVATE_GROUP_TYPE_NAME, TEAM_GROUP_TYPE, TEAM_GROUP_TYPE_NAME,
  WORK_GROUP_TYPE,
  WORK_GROUP_TYPE_NAME
} from "../../../../const/const";
import GroupMemberListModal from "../../../Components/Modal/AdminManagement/GroupMemberListModal";

export default {

  components: {GroupTable, PaginationTemplate, GroupMemberListModal},

  data() {
    return {
      routeName: this.$route.name,
      groupParams: {
        searchGroupInput: "",
        groupList: [],
        finished: false,
        status: null,
        type: '',
      },
      groupPaging: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      },
      isLoad: true,
    }
  },

  computed: {
    groupTypeList() {
      let groupTypeOptions = [];
      groupTypeOptions.push({value: WORK_GROUP_TYPE, text: WORK_GROUP_TYPE_NAME});
      groupTypeOptions.push({value: PRIVATE_GROUP_TYPE, text: PRIVATE_GROUP_TYPE_NAME});
      groupTypeOptions.push({value: COMPANY_WALL_GROUP_TYPE, text: COMPANY_WALL_GROUP_TYPE_NAME});
      groupTypeOptions.push({value: BRANCH_GROUP_TYPE, text: BRANCH_GROUP_TYPE_NAME});
      groupTypeOptions.push({value: TEAM_GROUP_TYPE, text: TEAM_GROUP_TYPE_NAME});
      return groupTypeOptions;
    }
  },

  mounted() {
    this.getGroups();
  },

  methods: {
    async getGroups(isSearch) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (isSearch) {
          this.groupPaging.groupPage = 1;
          this.groupPaging.lastPage = 1;
          this.groupParams.groupList = [];
        }

        let params = {
          page: this.groupPaging.page,
          per_page: this.groupPaging.perPage,
          member_type: 2,
          keyword: this.groupParams.searchGroupInput,
        }
        if (this.groupParams.status != null) {
          params.status = this.groupParams.status;
        }
        if (this.groupParams.type !== '') {
          params.type = this.groupParams.type;
        }
        AdminManagementService.getGroups(params).then(res => {
          this.isLoad = false;
          if (res && res.data && res.data.length > 0) {
            this.groupPaging.lastPage = res.meta.last_page
            this.groupParams.groupList = res.data;
            this.groupPaging.total = res.meta.total;
          }
        });
      }, 800);
    },

    changePagingParam(value) {
      this.groupPaging = value;
      this.isLoad = true;
      this.getGroups();
    },
    handleApproveRequest(value) {
      if (value && value.groupId) {
        for (let i = 0; i < this.groupParams.groupList.length; i++) {
          if (this.groupParams.groupList[i].id === value.groupId) {
            this.groupParams.groupList[i].request_count --;
            if (value.accept) {
              this.groupParams.groupList[i].member_count ++;
            }
            break;
          }
        }
      }
    },
    removeFromGroup(value) {
      if (value && value.groupId) {
        for (let i = 0; i < this.groupParams.groupList.length; i++) {
          if (this.groupParams.groupList[i].id === value.groupId) {
            this.groupParams.groupList[i].member_count --;
            break;
          }
        }
      }
    }

  },

  watch: {
    'groupParams.status': function (newVal, oldVal) {
      this.isLoad = true;
      this.getGroups(true);
    },
    'groupParams.type': function () {
      this.isLoad = true;
      this.getGroups(true);
    }
  }
};

</script>


<style lang="scss">
 .group-list-wrapper {
   padding: 15px 35px 35px;
   height: 100%;
   .vuejs-refresh-head {
     display: none;
   }
   .d-flex {
     justify-content: space-between;
   }

   .ml-search-container {
     border: 1px solid #9d9d9d;
     border-radius: 4px !important;
     padding-left: 10px !important;
     background-color: white !important;
     margin-bottom: 0 !important;
     display: flex;
     overflow: hidden;
       input {
         border: none;
         margin-left: 5px;
       }
       .md-icon {
         margin: auto !important;
     }
   }
   .vuejs-loadmore-finished-text {
     display: none !important;
   }

   .ml-btn-wrapper {
     height: 11%;
   }
 }
 .content .group-list-wrapper {
   height: 100%;
   overflow-y: overlay;
 }
 .group-type-select {
   padding: 3px 4px;
   border: 1px solid #9b9ba5;
   border-radius: 4px;
   font-size: 14px;
   margin-right: 25px;
 }
</style>