<template>
  <div class="confirm-file-modal">
    <modal name="confirm-file-modal"
    :click-to-close="false"
    width='600px'
    height="200px"
    class="modal-custom confirm-file-modal"
    transition="easeInOut"
    @before-open="beforeOpened"
    @before-close="beforeClosed"
  >
    <md-card class="modal-header-custom">
      <md-dialog-title>
        <span class="neutral_1 heading-500 text-header-modal">Tin nhắn chứa file và media</span>
      </md-dialog-title>
    </md-card>
   <md-card-content class="modal-content">
      <div class="content">
        Nếu gửi đồng thời cả file và ảnh/video trong 1 tin nhắn thì ảnh/video sẽ không hiển thị đúng cách. Bạn có muốn tách thành 2 tin nhắn: 1 tin nhắn dạng file, 1 tin nhắn dạng ảnh/video để có thể xem ảnh/video mà không cần tải về?
      </div>
   </md-card-content>
   <md-dialog-actions>
     <button type="submit" class="btn-active md-button text-400-default" @click="$emit('postMulti', true)">Tách 2 tin nhắn</button>
      <button type="submit" class="btn-stroke md-button text-400-default" @click="$emit('postMulti', false)">Gửi dạng file</button>
    </md-dialog-actions>
  </modal>
  </div>
</template>

<script>

export default {
  name: "confirm-file-modal",
  data() {
    return {
      messageText: ''
    }
  },
  methods: {
    beforeOpened(event) {
    },
    beforeClosed(event){

    },
  },
}
;
</script>

<style lang="scss">
.confirm-file-modal{
  .md-dialog-actions button {
    width: auto !important;
  }
  .modal-content {
    display: flex;
    min-height: 100px;
    text-align: center;
    align-items: center;
  }
}
</style>
