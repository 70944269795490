<template>
  <div class="post-layout create-post">
    <md-card>
      <md-list-item class="post-comment user-post">
        <md-avatar>
          <img class="avatar" :src="user.avatar_url ? user.avatar_url : '/img/profile/avatar.png'">
        </md-avatar>
        <div class="md-list-item-text">
            <div class="post-button text-500-medium neutral_2" @click="$modal.show('create-post-modal',{postType: POST_TYPE.TEXT })">Đăng bài viết</div>
        </div>
      </md-list-item>
      <span class="hr"></span>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-list-item class="p-0px cursor-pointer" @click="$modal.show('create-post-modal', {postType: POST_TYPE.TEXT })">
            <div class="item-image">
              <img src="/img/post/create/ic_picture.png">
            </div>
            <span class="item-text text-400-medium" >Ảnh/Video</span>
          </md-list-item>
        </div>
        <div class="md-layout-item">
          <md-list-item class="p-0px cursor-pointer" @click="$modal.show('create-post-modal', {postType: POST_TYPE.TEXT })">
            <div class="item-image">
              <img src="/img/post/create/ic_noti.png">
            </div>
            <span class="item-text text-400-medium">Thông báo</span>
          </md-list-item>
        </div>
        <div class="md-layout-item">
          <md-list-item class="p-0px cursor-pointer" @click="$modal.show('create-post-modal', {postType: POST_TYPE.TEXT })">
            <div class="item-image">
              <img src="/img/post/create/ic_report.png">
            </div>
            <span class="md-list-item-text item-text text-400-medium">Đăng báo cáo</span>
          </md-list-item>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>

import {POST_TYPE} from "../../../../const/const";

export default {
  name: "create-post",
  mounted() {
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      POST_TYPE: POST_TYPE
    };
  },
  watch: {
    '$store.state.auth.user'(newV) {
      this.user = newV;
    }
  }
};
</script>

<style lang="css">
.hr{
  border-bottom: 1px solid #EDEFF5;
  margin: 0px 15px;
}
.md-list-item-content {
  justify-content: center;
}
.md-layout-item .item-image{
    width: 24px;
    height: 24px;
    margin: 6px 10px 6px 0;
  }
.md-layout-item .item-text {
  flex: none;
  position: initial;
  color: #65676b;
  width: auto;
}
.img-user-post{
  height: 28px !important;
  width: 28px !important;
}

</style>
