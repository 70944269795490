<template>
 <div class="layout">
  <table class="ml-member-tbl">
    <thead>
    <tr>
      <th class="text-400-semibold ml-table-cell cursor-pointer">Thành viên</th>
      <th class="text-400-semibold ml-table-cell">Email</th>
      <th class="text-400-semibold ml-table-cell">Số điện thoại</th>
      <th class="text-400-semibold ml-table-cell">Phòng ban</th>
      <th class="text-400-semibold ml-table-cell w_min_120">Chức vụ</th>
      <th class="text-400-semibold ml-table-cell w_min_120" v-if="tableType === 'MEMBERS'">Trạng thái</th>
      <th class="text-400-semibold ml-table-cell w_min_120"></th>
      <th class="ml-table-cell"></th>
    </tr>
    </thead>
    <tbody class="ml-member-tbody">
    <tr v-for="member in memberList" :key="member.id">
      <td class="gs-mem-avt ml-table-cell">
        <md-list-item>
          <md-avatar>
            <image-template :class="'group-avatar'" :size="'small'" :image="member.avatar_url" :thumbnail="member.thumbnail"/>
          </md-avatar>
          <div class="md-list-item-text">
            <router-link :to="getProfileLink(member.id)" class="member-name">
              <span class=" text-400-medium neutral_1 cursor-pointer hover-link">{{ member.fullname }}</span>
              <div class="text-300-default gs-admin-flag" v-if="tableType !== 'MEMBERS' && member.is_admin && member.is_admin === 1">Admin
              </div>
            </router-link>
            <!--                    <span class="text-300-default neutral_2">Người tạo nhóm</span>-->
          </div>
        </md-list-item>
      </td>
      <td class="text-400-medium ml-table-cell">{{member.email}}</td>
      <td class="text-400-medium ml-table-cell">{{member.phone}}</td>
      <td class="text-400-medium ml-table-cell">{{member.department ? member.department.name : ''}}</td>
      <td class="text-400-medium ml-table-cell w_min_120">{{member.position ? member.position.name : ''}}</td>
      <td class="text-400-medium ml-table-cell w_min_120" v-if="tableType === 'MEMBERS'">
                <span class="text-300-default ml-mem-status" :class="member.status == 1 ? 'active' : 'non-active'">
                  {{member.status == 1 ? 'Hoạt động' : 'Không hoạt động'}}
                </span>
      </td>
      <td class="text-400-medium ml-table-cell" style="text-align: center" v-if="tableType === 'MEMBERS'" >
        <div class="drop-list-function p-relative">
            <div class="button-more cursor-pointer">
              <div class="icons" @click="showPopup(member.id)">
                <span class="material-icons more-horiz">more_horiz</span>
              </div>
            </div>
            <transition name="fade">
              <div class="profile-member" v-if="show === member.id">
                <span @click="showModalUpdate(member)">Chỉnh sửa</span>
              </div>
            </transition>
          </div>
      </td>
      <td class="text-400-medium ml-table-cell" style="text-align: center" v-if="tableType === 'GROUP_SETTING_MEMBERS'">
        <g-s-more-member-btn :is-admin="member.is_admin == 1" @remove-from-group="removeFromGroup(member.id)"
                         @handle-user-role="handleUserRole(member.is_admin, member.id)" />
      </td>
      <td class="text-400-medium ml-table-cell" style="text-align: center" v-if="tableType === 'GROUP_SETTING_REQUEST_MEM'">
        <div class="gs-table-action" v-if="member.approved">
          <div class="text-300-medium"
               :class="member.accept ? 'secondary_G400 gs-request-accept' : 'secondary_r400 gs-request-decline'">
            {{ member.accept ? 'Đã được thêm' : 'Bị từ chối' }}
          </div>
        </div>
        <div class="gs-table-action" v-else>
          <button class="gs-btn-active cursor-pointer heading-300" @click="approveRequest(true, member.id)">Đồng ý
          </button>
          <button class="gs-btn-stroke cursor-pointer heading-300 ml-15px"
                  @click="approveRequest(false, member.id)">Từ chối
          </button>
        </div>
        </td>
    </tr>
    </tbody>
  </table>
  <update-profile-modal  @update-profile="updateProfile($event)"></update-profile-modal>
 </div>
</template>

<script>
import ImageTemplate from "../../../components/ImageTemplate";
import GSMoreMemberBtn from "./Group/GSMoreMemberBtn";
import UpdateProfileModal from '../../Components/Modal/UpdateProfileModal'
import ProfileService from "../../../store/services/ProfileService";
import moment from "moment";

export default {
  name: "MemberTable",
  components: {
    ImageTemplate,
    GSMoreMemberBtn,
    UpdateProfileModal
  },
  props: {
    memberList: {
      type: Array,
      default: () => [],
    },
    tableType: {
      type: String,
      default: 'MEMBERS',
    }
  },
  data() {
    return{
      show: false,
      oldValueProfile: {}
    }
  },
  methods: {
    getProfileLink(id) {
      return '/profile/' + id;
    },
    approveRequest(accept, userId) {
      this.$emit("approve-request", {accept: accept, userId: userId});
    },
    handleUserRole(isAdmin, userId) {
      this.$emit("handle-user-role", {isAdmin: isAdmin, userId: userId});
    },
    removeFromGroup(userId) {
      this.$emit("remove-from-group", {userId: userId});
    },
    showModalUpdate(member){
      this.show = false;
      this.$modal.show('update-profile-modal', {
        fields: member,
        type: 'all'
      });
      this.oldValueProfile = member;
    },
    showPopup(member_id){
      this.show = member_id === this.show ? false : member_id;
    },
    hidePopup(){
      this.show = false;
    },
    async updateProfile(member) {

      const formData = new FormData();
      if(this.oldValueProfile.fullname !== member.fullname){
        formData.append("fullname", member.fullname);
      }

      if(this.oldValueProfile.display_name !== member.display_name){
        formData.append("display_name", member.display_name);
      }

      if(this.oldValueProfile.phone !== member.phone){
        formData.append("phone", member.phone);
      }

      if(this.oldValueProfile.address !== member.address){
        formData.append("address", member.address);
      }

      let date = member.birthday;
      if (member.birthday && member.birthday !== '') {
        let dateTime = new Date(member.birthday);
        date = moment(dateTime).format('yyyy-MM-DD');
      }

      formData.append("birthday", date);

      await ProfileService.updateUserProfile(formData, member.id).then(res => {
        if (res.status) {
          this.$store.dispatch("alerts/success", 'Cập nhật thông tin thành công');
          this.$modal.hide('update-profile-modal');
          let position = this.memberList.findIndex((elm) => elm.id === member.id);
          for (var key in member) {
            if (member.hasOwnProperty(key) && this.memberList[position][key] !== member[key]) {
              this.memberList[position][key] = member[key];
            }
          }
          this.oldValueProfile = {};
        } else {
          this.$store.dispatch("alerts/error", res.message);
        }
      });

    }
  }
}
</script>

<style lang="scss">
.gs-btn-active {
  background: #FFD700;
  border-radius: 4px;
  border: none;
}

.gs-btn-stroke {
  background: #F9FAFC;
  border-radius: 4px;
  border: 1px solid #D8DAE5;
}

.drop-list-function{
  // width: max-content;
  //   margin: auto;
  .profile-member {
    &:hover{
      cursor: pointer;
    }
    position: absolute;
    background: #fff;
    border: 1px solid #e5e0e0;
    font-size: 12px;
    padding: 5px 14px;
    border-radius: 5px;
    top: 20px;
    box-shadow: 0px 0px 4px 0px #00000024;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>