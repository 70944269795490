import router from "@/router";
import AuthService from "../services/AuthService";
import SettingService from "../services/SettingService";
import axios from "axios";
import Cookies from 'js-cookie';
import io from "socket.io-client";
import {helpers} from "../../helper/helpers";


export default {
    state: {
        isAuthenticated: localStorage.getItem("access_token") !== null,
        user: {},
        userPosition: {},
        userTeam: {},
        userTitle: {},
        modalIsOpen: false,
        dictionaryTagSetting: {}
    },

    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        },

        user(state) {
            return state.user;
        }
    },

    mutations: {
        isAuthenticated(state, data) {
            state.isAuthenticated = data.isAuthenticated;
        },

        updateUser(state, data) {
            state.user = data;
        },

        updateUserPosition(state, data) {
            state.userPosition = data;
        },

        updateUserTeam(state, data) {
            state.userTeam = data;
        },

        updateUserTitle(state, data) {
            state.userTitle = data;
        },

        updateModalIsOpen(state, data) {
            state.modalIsOpen = data;
        },

        updateDictionarySetting(state, data) {
            state.dictionaryTagSetting = data;
        },
    },

    actions: {
        login({commit, dispatch}, payload) {
            //get X-XSRF-TOKEN
            return AuthService.login(payload).then(res => {
                if (res.data && res.status) {
                    let token = res.data.access_token;
                    let userId = res.data.user.id;
                    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                    localStorage.setItem("access_token", res.data.access_token);
                    localStorage.setItem("user_id", userId);
                    Cookies.set('access_token', res.data.access_token);
                    Cookies.set('user_id', userId);
                    commit("isAuthenticated", {isAuthenticated: true});
                    commit("updateUser", res.data.user);
                    commit("updateUserPosition", res.data.position);
                    commit("updateUserTeam", res.data.team);
                    commit("updateUserTitle", res.data.title);
                    dispatch("getPost");
                    dispatch("getSocialSettings");
                    dispatch("refreshPostTotalPage");
                    dispatch("refreshPreviousDetail");
                    dispatch("getGroups");
                    dispatch("chat/getChatGroup");
                    // dispatch("getGroups");
                    if ([1, 2, 119, 141, 845, 921, 920, 869].includes(res.data.user.id)) {
                        dispatch("getPositionList");
                        dispatch("getDepartmentTree");
                    }
                    router.push({name: "New Feeds"});
                    dispatch("refreshNotificationSetting");
                    dispatch("changePostFlag", 0);


                    let ws = io('wss://socket.azagroup.asia/', {
                        transports: ['websocket'],
                    });
                    const messageTopic = 'message_' + userId;
                    const messageReactionEmojiTopic = 'message_reaction_emoji_' + userId;
                    const modifyInformationChatTopic = 'modify_information_chat_' + userId;
                    const systemTopic = 'system_' + userId;
                    const userTypingTopic = 'user_typing_' + userId;
                    const userRecall = 'user_recall_' + userId;
                    const dataLogin = JSON.stringify({
                        action: "login",
                        token: `${token}`,
                        user_id: `${userId}`
                    });

                    ws.on('connect', () => {
                        ws.emit('connect_socket', dataLogin);
                        ws.emit(messageTopic, dataLogin);
                        ws.emit(messageReactionEmojiTopic, dataLogin);
                        ws.emit(modifyInformationChatTopic, dataLogin);
                        ws.emit(systemTopic, dataLogin);
                        ws.emit(userTypingTopic, dataLogin);
                        ws.emit(userRecall, dataLogin);
                    });
                }
                return res;
            });
        },

        logout({commit, dispatch}, payload) {
            window.isAuthenticated = false;
            Cookies.remove('access_token');
            Cookies.remove('user_id');
            localStorage.removeItem("access_token");
            localStorage.removeItem("user_id");
            commit("isAuthenticated", {isAuthenticated: false});
            axios.defaults.headers.common.Authorization = ``;
            window.location.reload();
        },

        redirectToLogin({commit, dispatch}, payload) {
            window.isAuthenticated = false;
            Cookies.remove('access_token');
            Cookies.remove('user_id');
            localStorage.removeItem("access_token");
            localStorage.removeItem("user_id");
            commit("isAuthenticated", {isAuthenticated: false});
            axios.defaults.headers.common.Authorization = ``;
            window.location.href = '/login';
        },

        getXsrfToken(context, payload) {
            AuthService.getXsrfToken().then(res => {
                let XsrfToken = res.config.headers['X-XSRF-TOKEN'];
                axios.defaults.headers.common.X_XSRF_TOKEN = XsrfToken;
            });
        },

        setAuth() {
            AuthService.getXsrfToken().then(res => {
                const XsrfToken = res.config.headers['X-XSRF-TOKEN'];
                console.log(XsrfToken);
                axios.defaults.headers.common.X_XSRF_TOKEN = XsrfToken;
                localStorage.setItem("X_XSRF_TOKEN", XsrfToken);
            });

            // axios.interceptors.request.use(function (config) {
            //     console.log('axios.interceptors');
            //     const access_token = localStorage.getItem("access_token");
            //     const XsrfToken = localStorage.getItem("X_XSRF_TOKEN");
            //
            //     if (access_token) {
            //         config.headers.Authorization = `Bearer ${access_token}`;
            //         config.headers.X_XSRF_TOKEN = XsrfToken;
            //     }
            //
            //     return config;
            // });
        },

        // setAuth(auth) {
        //   // axios.interceptors.request.use(function (config) {
        //   //   if (auth) {
        //   axios.defaults.headers.common['Authorization'] = `Bearer ${auth.access_token}`;
        //   // config.headers.Authorization = `Bearer ${auth.access_token}`;
        //   //   }
        //   //   return config;
        //   // });
        // }
        //
        connectToSocket(token) {
            // ws.on('connect', () => {
            //     console.log('socket connect');
            //     ws.emit('message', JSON.stringify({"action": "login", "token": `${token}`}));
            // });
            //
            // ws.on('connect', () => {
            //     console.log('socket connect');
            //     ws.emit('message_reaction_emoji', JSON.stringify({"action": "login", "token": `${token}`}));
            // });
            //
            // ws.on('connect', () => {
            //     console.log('socket connect');
            //     ws.emit('modify_information_chat', JSON.stringify({"action": "login", "token": `${token}`}));
            // });
        },

        async getSocialSettings({commit, dispatch}, payload){
            await SettingService.getSettings().then(res => {
            let setting_tag = res.data.find((item) => item.name === 'tag_dictionary');
            console.log('selected settings', setting_tag);
            setting_tag.value = JSON.parse(setting_tag.value).map((item) => '#' + item);
            commit("updateDictionarySetting", setting_tag);
            });
          }
    }
};
